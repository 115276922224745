import { Extendcreditfacilities, IfYesvolumeofCredit, Iso9001ceertified, PaidUpCapital, Tenor } from "../../../../Constant";

export const vendor_form_fields = [
  {
    id: 'paid_up_capital',
    name: 'paid_up_capital',
    label: `${PaidUpCapital}`,
    type: 'text',
    value: '',
    required: false,
    colSize: 6,
  },
  {
    id: 'is_iso_certified',
    name: 'is_iso_certified',
    label: `${Iso9001ceertified}`,
    type: 'radio',
    value: '',
    required: true,
    colSize: 6,
    options: [
        {'label': 'Yes', 'value': 1},
        {'label': 'No', 'value': 0},
    ],
},
{
  id: 'can_extend_credit_facilities',
  name: 'can_extend_credit_facilities',
  label: `${Extendcreditfacilities}`,
  type: 'radio',
  value: '',
  required: true,
  colSize: 6,
  options: [
      {'label': 'Yes', 'value': 1},
      {'label': 'No', 'value': 0},
  ],
},
{
id: 'credit_volume',
name: 'credit_volume',
label: `${IfYesvolumeofCredit}`,
type: 'number',
value: '',
required: false,
colSize: 6,
},    
{
    id: 'tenor',
    name: 'tenor',
    label: `${Tenor}`,
    type: 'number',
    value: '',
    required: false,
    colSize: 6,
  }
];