import { FormEvent, useContext, useEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { CaseTypeFormDataPayload, MiniResType, PriorityResType, TeamMappingData } from "../CaseType.type";
import { CaseGroupApi, CaseTeamMappingApi, CaseTypeApi, PriorityApi, ServiceTypeApi, TeamApi } from "../../../../../api";
import { toast } from "react-toastify";
import { InputProviderFormSchema } from "../../../../Finance/Template/types";
import { AutoAssignToTeamMember, CaseGroup, DelegateTeam, DelegationTime, Description, EscalationExecutiveTeam, EscalationExecutiveTime, EscalationFunctionalTime, EscalationTeam, GlobalResolutionTime, Name, PickUpTime, Priority, ResolutionTime, ServiceCategory, ServiceTeam, Status } from "../../../../../Constant";
import { validateInputs } from "../../../../Finance/Template/InputProvider";
import { convertDuration } from "../../../../../_helper/utils";

export default function useCaseTypeController() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/crm/settings/case-type/${layoutURL}`;
    
    const tabResolution: string = "resolution";
    const tabDelegation: string = "delagation";
    const tabXDelegation: string = "x-delagation";

    const [prioritiesDetails, setPrioritiesDetails] = useState<PriorityResType[]>([]); 
    const [activeTab, setActiveTab] = useState<string>(tabResolution); 
    const [clearData, setClearData] = useState<boolean>(false); 
    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    const [switchLabels, setSwitchLabels] = useState([
        { id: 1, label: Status, value: false },
        { id: 2, label: AutoAssignToTeamMember, value: false },
    ]);

    const [inputErrors, setInputErrors] = useState<{
        caseFormErrors: { [key: string]: string };
        resolutionTeamFormErrors: { [key: string]: string };
        delegationTeamFormErrors: { [key: string]: string };
        externalDelegationTeamErrors: { [key: string]: string };
      }>({
        caseFormErrors: {},
        resolutionTeamFormErrors: {},
        delegationTeamFormErrors: {},
        externalDelegationTeamErrors: {},
    });

    // ==== FORM SCHEMA'S ==== //
    const [caseFormSchema, setCaseFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "service_type_id",
            label: ServiceCategory,
            type: "select",
            required: true,
        },
        {
            name: "case_group_id",
            label: CaseGroup,
            type: "select",
            required: true,
        },
        {
            name: "name",
            label: Name,
            type: "text",
            required: true,
        },
        {
            name: "description",
            label: Description,
            type: "textarea",
            required: true,
        },
        {
            name: "priority_id",
            label: Priority,
            type: "select",
            required: true,
        },
        {
            name: "global_resolution_time",
            label: GlobalResolutionTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
    ]);

    const [resolutionTeamFormSchema, setResolutionTeamFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "service_team_id",
            label: ServiceTeam,
            type: "select",
            required: true,
        },
        {
            name: "resolution_escalation_team_id",
            label: EscalationTeam,
            type: "select",
            required: true,
        },
        {
            name: "resolution_escalation_executive_team_id",
            label: EscalationExecutiveTeam,
            type: "select",
            required: true,
        },
        {
            name: "resolution_pickup_time",
            label: PickUpTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "resolution_delegation_time",
            label: DelegationTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "resolution_escalation_time1",
            label: EscalationFunctionalTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "resolution_escalation_time2",
            label: EscalationExecutiveTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "resolution_time",
            label: ResolutionTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
    ]);

    const [delegationTeamFormSchema, setDelegationTeamFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "delegate_team_id",
            label: DelegateTeam,
            type: "select",
            required: true,
        },
        {
            name: "delegation_escalation_team_id",
            label: EscalationTeam,
            type: "select",
            required: true,
        },
        {
            name: "delegation_escalation_executive_team_id",
            label: EscalationExecutiveTeam,
            type: "select",
            required: true,
        },
        {
            name: "delegation_pickup_time",
            label: PickUpTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "delegation_delegation_time",
            label: DelegationTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "delegation_escalation_time1",
            label: EscalationFunctionalTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "delegation_escalation_time2",
            label: EscalationExecutiveTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "delegation_resolution_time",
            label: ResolutionTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
    ]);
    //, setExternalDelegationTeamFormSchema
    const [externalDelegationTeamFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        /* {
            name: "external_delegation_escalation_team_id",
            label: EscalationTeam,
            type: "select",
            required: false,
        },
        {
            name: "external_delegation_escalation_executive_team_id",
            label: EscalationExecutiveTeam,
            type: "select",
            required: false,
        }, */
        {
            name: "external_delegation_pickup_time",
            label: PickUpTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "external_delegation_escalation_time1",
            label: EscalationFunctionalTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "external_delegation_escalation_time2",
            label: EscalationExecutiveTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "external_delegation_resolution_time",
            label: ResolutionTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        /* {
            name: "time_inclusive",
            label: `Inclusive Time`,
            type: "checkbox",
            required: false,
        }, */
    ]);

    // ==== INPUT DATA'S ==== //
    const [caseInputData, setCaseInputData] = useState<{
        [key: string]: any;
    }>({});

    const [resolutionTeamInputData, setResolutionTeamInputData] = useState<{
        [key: string]: any;
    }>({});

    const [delegationTeamInputData, setDelegationTeamInputData] = useState<{
        [key: string]: any;
    }>({});

    const [externalDelegationTeamInputData, setExternalDelegationTeamInputData] = useState<{
        [key: string]: any;
    }>({});

    // ==== ON CHANGE HANDLER'S ==== //
    const handleCaseChange = (name: string, value: any) => {
        setCaseInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        name === "priority_id" && getImpactUrgency(value);
        name === "service_type_id" && fetchCaseGroup(value);
    };

    const handleResolutionTeamChange = (name: string, value: any) => {
        setResolutionTeamInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDelegationTeamChange = (name: string, value: any) => {
        setDelegationTeamInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleExternalDelegationTeamChange = (name: string, value: any) => {
        setExternalDelegationTeamInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const fetchCaseGroup = async (value: string) => {
        const api = `${CaseGroupApi}?with_trashed=${false}&organisation_id=${userOrganisationId}&service_type_id=${value}`;

        try {
            setCaseFormSchema((prevSchema) => {
                return prevSchema.map((field) => {
                    if (field.name === "case_group_id") {
                        return {
                            ...field,
                            options: value ? [] : [], // Clear existing options if value is invalid
                        };
                    }
                    return field;
                });
            });
    
            if (!value) {
                return; 
            }

            axiosPrivate.get(api).then((res) => {
                const response: any[] = res.data;
                //console.log(response);
                setCaseFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "case_group_id") {
                            return {
                                ...field,
                                options: response.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }
                        return field;
                    })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                  toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                  toast.error(err.response.data.detail);
                } else {
                  toast.error("Whoops! cannot fetch case group");
                }
                //toast.error(err.response.message || "Whoops! Something went wrong");
              });
            //return;
        } 
        catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    /* const fetchCaseGroup = async (value: string) => {
        const api = `${CaseGroupApi}?with_trashed=${false}&organisation_id=${userOrganisationId}&service_type_id=${value}`;
    
        try {
            // Handle case when value is not valid
            setCaseFormSchema((prevSchema) => {
                return prevSchema.map((field) => {
                    if (field.name === "case_group_id") {
                        return {
                            ...field,
                            options: value ? [] : [], // Clear existing options if value is invalid
                        };
                    }
                    return field;
                });
            });
    
            // Exit if value is invalid
            if (!value) {
                return; 
            }
    
            // Fetch new data
            const res = await axiosPrivate.get(api);
            const response = res.data;
    
            // Update the schema with new options
            setCaseFormSchema((prevSchema) => {
                return prevSchema.map((field) => {
                    if (field.name === "case_group_id") {
                        return {
                            ...field,
                            options: response.map((item) => ({
                                label: item.name,
                                value: item.id,
                            })),
                        };
                    }
                    return field;
                });
            });
        } catch (err) {
            // Type guard for error handling
            if (axios.isAxiosError(error) && error.response) {
                const errorMessage =
                    error.response.status === 422
                        ? "Validation Errors"
                        : error.response.status === 400
                        ? error.response.data.detail
                        : "Whoops! cannot fetch case group";

                toast.error(errorMessage);
            } else {
                toast.error("An unexpected error occurred.");
            }
        }
    }; */
    
    const getImpactUrgency = async (value: string) => {
        setCaseFormSchema((prevSchema) => {
            if (value <= "0") {
                return prevSchema.filter(field => field.name !== "impact_urgency_info");
            } 
    
            const priorityId = parseInt(value);
            const priorityDetails = prioritiesDetails.find(priority => priority.id === priorityId);
    
            if (!priorityDetails) {
                return prevSchema;
            }
    
            const newElement = {
                name: "impact_urgency_info",
                label: "",
                type: "html",
                html: `
                  <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 5px;">
                    <h7 style="font-weight:600; margin-bottom:10px">Impact & Urgency Details</h7>
                    <div>
                      <h7 style="font-weight:600;">Impact</h7>
                      <p>
                        Name: <span style="color: ${priorityDetails.impact?.color}" class="">
                            <span className='m-r-5'><i className="fa fa-flag"></i> ${priorityDetails.impact.name}</span>
                        </span><br>
                        Weight: ${priorityDetails.impact.weight}
                      </p>
                    </div>
                    <div>
                      <h7 style="font-weight:600;">Urgency</h7>
                      <p>
                        Name: <span style="color: ${priorityDetails.urgency?.color}" class="">
                            <span className='m-r-5'><i className="fa fa-flag"></i> ${priorityDetails.urgency.name}</span>
                        </span><br>
                        Weight: ${priorityDetails.urgency.weight}
                      </p>
                    </div>
                  </div>
                `,
            };
    
            const existingIndex = prevSchema.findIndex(field => field.name === "impact_urgency_info");
            if (existingIndex > -1) {
                const updatedSchema = [...prevSchema];
                updatedSchema[existingIndex] = newElement;
                return updatedSchema;
            } else {
                return [...prevSchema, newElement];
            }
        });
    };
    
    const fetchCaseTypeData = async () => {
        try {
            const serviceCategoriesUrl = `${ServiceTypeApi}`;
            const prioritiesUrl = `${PriorityApi}?with_trashed=false&organisation_id=${userOrganisationId}`;
            const teamUrl = `${TeamApi}?with_trashed=false&organisation_id=${userOrganisationId}`;
            const caseTeamMappingUrl = `${CaseTeamMappingApi}`;

            // Use Promise.all to fetch both APIs concurrently
            /* const [
                serviceCategoriesRes,
                prioritiesRes,
                teamRes,
                caseTeamMappingRes,
            ] = await Promise.all([
                axiosPrivate.get(serviceCategoriesUrl),
                axiosPrivate.get(prioritiesUrl),
                axiosPrivate.get(teamUrl),
                axiosPrivate.get(caseTeamMappingUrl),
            ]); 
            
            const serviceCategoriesData: MiniResType[] = await serviceCategoriesRes.data;
            const prioritiesData: PriorityResType[] = await prioritiesRes.data;
            const teamData: MiniResType[] = await teamRes.data;
            const caseTeamMappingData: TeamMappingData[] = await caseTeamMappingRes.data.data.settings; */

            const results = await Promise.allSettled([
                axiosPrivate.get(serviceCategoriesUrl),
                axiosPrivate.get(prioritiesUrl),
                axiosPrivate.get(teamUrl),
                axiosPrivate.get(caseTeamMappingUrl),
            ]);
            
            const serviceCategoriesData: MiniResType[] = results[0].status === 'fulfilled' ? results[0].value.data : null;
            const prioritiesData: PriorityResType[] = results[1].status === 'fulfilled' ? results[1].value.data : null;
            const teamData: MiniResType[] = results[2].status === 'fulfilled' ? results[2].value.data : null;
            const caseTeamMappingData: TeamMappingData[] = results[3].status === 'fulfilled' ? results[3].value.data.data.settings : null;

            setPrioritiesDetails(prioritiesData);

            // Return or use the combined data
            return {
                serviceCategoriesData,
                prioritiesData,
                teamData,
                caseTeamMappingData,
            };
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchCaseTypeData().then((data) => {
            if(data) {
                
                const resolution_team_rec = data.caseTeamMappingData?.find(item => item.key === "resolution_team")
                const delegation_team_rec = data.caseTeamMappingData?.find(item => item.key === "delegation_team")
                const escalation_functional_team_rec = data.caseTeamMappingData?.find(item => item.key === "escalation_functional_team")
                const escalation_executive_team_rec = data.caseTeamMappingData?.find(item => item.key === "escalation_executive_team")
                
                const serviceTeam = data.teamData?.filter(item => String(item.team_type_id) === resolution_team_rec?.value)
                const delgationTeam = data.teamData?.filter(item => String(item.team_type_id) === delegation_team_rec?.value)
                const escalationFunctionalTeam = data.teamData?.filter(item => String(item.team_type_id) === escalation_functional_team_rec?.value)
                const escalationExecutiveTeam = data.teamData?.filter(item => String(item.team_type_id) === escalation_executive_team_rec?.value)
                
                setCaseFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "service_type_id") {
                            return {
                                ...field,
                                options: data.serviceCategoriesData?.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "priority_id") {
                            return {
                                ...field,
                                options: data.prioritiesData?.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        return field; 
                    })
                ); 

                setResolutionTeamFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "service_team_id") {
                            return {
                                ...field,
                                options: serviceTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "resolution_escalation_team_id") {
                            return {
                                ...field,
                                options: escalationFunctionalTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "resolution_escalation_executive_team_id") {
                            return {
                                ...field,
                                options: escalationExecutiveTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        return field; 
                    })
                );

                setDelegationTeamFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "delegate_team_id") {
                            return {
                                ...field,
                                options: delgationTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "delegation_escalation_team_id") {
                            return {
                                ...field,
                                options: escalationFunctionalTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "delegation_escalation_executive_team_id") {
                            return {
                                ...field,
                                options: escalationExecutiveTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        return field; 
                    })
                );

                /* setExternalDelegationTeamFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if ((field.name === "external_delegation_escalation_team_id") || (field.name === "external_delegation_escalation_executive_team_id")) {
                            return {
                                ...field,
                                options: data.teamData.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        return field; 
                    })
                ); */
            }
        });
    }, []);

    //const convertToMinutes = (hours: number): number => hours * 60;
    /* const convertToMinutes = (hours: number | undefined): number => {
        // If hours is empty or not a number, return 0
        if (hours === undefined || hours === null || isNaN(hours)) {
          return 0;
        }
        return hours * 60;
      }; */

    const calculateTeamTime = () => {
        const resolutionTotalTime =
            convertDuration(resolutionTeamInputData.resolution_pickup_time) +
            //convertToMinutes(resolutionTeamInputData.resolution_escalation_time1) +
            //convertToMinutes(resolutionTeamInputData.resolution_escalation_time2) +
            convertDuration(resolutionTeamInputData.resolution_time);

        const delegationTotalTime =
            convertDuration(delegationTeamInputData.delegation_pickup_time) +
            //convertToMinutes(delegationTeamInputData.delegation_escalation_time1) +
            //convertToMinutes(delegationTeamInputData.delegation_escalation_time2) +
            convertDuration(delegationTeamInputData.delegation_resolution_time);

        const consultingTotalTime =
            convertDuration(externalDelegationTeamInputData.external_delegation_pickup_time) +
            //convertToMinutes(externalDelegationTeamInputData.external_delegation_escalation_time1) +
            //convertToMinutes(externalDelegationTeamInputData.external_delegation_escalation_time2) +
            convertDuration(externalDelegationTeamInputData.external_delegation_resolution_time);

        // Sum total time for all teams, excluding delegation times
        const totalTeamTime = 
            resolutionTotalTime +
            delegationTotalTime +
            consultingTotalTime;

        return {
            resolutionTotalTime,
            delegationTotalTime,
            consultingTotalTime,
            totalTeamTime
        }    
    }
    useEffect(() => {
        if(clearData) {
            /* setCaseInputData({
                global_resolution_time: undefined,
            }); */
            //console.log(`E:${JSON.stringify(caseInputData, null,2)}`)
            //handleCaseChange('global_resolution_time', undefined)    
            /* setCaseInputData({
                service_type_id: '',
                case_group_id: '',
                priority_id: '',
                name: '',
                description: '',
                global_resolution_time: undefined,
            }); */
            setClearData(false)
        }
    }, [clearData, caseInputData])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Validate required fields for each form
        /* if (!isValidInput(caseFormSchema, caseInputData) ||
            !isValidInput(resolutionTeamFormSchema, resolutionTeamInputData) ||
            !isValidInput(delegationTeamFormSchema, delegationTeamInputData)) {
            return; // Stop submission if any validation fails
        } */
        
        /* setCaseInputData({
            global_resolution_time: '',
            service_type_id: '',
        }); */
        setClearData(true)

        setInputErrors({
            caseFormErrors: {},
            resolutionTeamFormErrors: {},
            delegationTeamFormErrors: {},
            externalDelegationTeamErrors: {},
        }); 
        
        const caseValidation = validateInputs(caseFormSchema, caseInputData);
        const resolutionValidation = validateInputs(resolutionTeamFormSchema, resolutionTeamInputData);
        const delegationValidation = validateInputs(delegationTeamFormSchema, delegationTeamInputData);
        const externalDelegationValidation = validateInputs(externalDelegationTeamFormSchema, externalDelegationTeamInputData);
        
        if (!caseValidation.isValid) {
            setInputErrors(prev => ({ ...prev, caseFormErrors: caseValidation.errors }));
            return;
        }

        if (!resolutionValidation.isValid) {
            //toast.error(resolutionValidation.error);
            setInputErrors(prev => ({ ...prev, resolutionTeamFormErrors: resolutionValidation.errors }));
            setActiveTab(tabResolution)
            return;
        }

        if (!delegationValidation.isValid) {
            setInputErrors(prev => ({ ...prev, delegationTeamFormErrors: delegationValidation.errors }));
            setActiveTab(tabDelegation)
            return;
        }

        if (!externalDelegationValidation.isValid) {
            setInputErrors(prev => ({ ...prev, externalDelegationTeamErrors: externalDelegationValidation.errors }));
            setActiveTab(tabXDelegation)
            return;
        }

        const {
            resolutionTotalTime,
            delegationTotalTime,
            consultingTotalTime,
            totalTeamTime
        } = calculateTeamTime();

        const global_resolution_time = convertDuration(caseInputData.global_resolution_time);
        
        if(global_resolution_time <= 0) {
            toast.error("Global Resolution Time is not defined");
            return false;
        }

        if(resolutionTotalTime <= 0) {
            toast.error("Resolution Team time is not defined");
            return false;
        }

        if (resolutionTotalTime > global_resolution_time) {
            toast.error("Resolution Team allocation time exceeds the Global Resolution Time");
            return false;
        }

        if(delegationTotalTime <= 0) {
            toast.error("Delegation Team time is not defined");
            return false;
        }

        if (delegationTotalTime > global_resolution_time) {
            toast.error("Delegation Team allocation time exceeds the Global Resolution Time");
            return false;
        }

        if ((consultingTotalTime > 0) && (consultingTotalTime > global_resolution_time)) {
            toast.error("External Delegation Team allocation time exceeds the Global Resolution Time");
            return false;
        }

        // Check against Global Resolution Time
        if (totalTeamTime > global_resolution_time) {
            toast.error("Total team allocation time exceeds the Global Resolution Time");
            return;
        }

        /* console.log("Case Input:", caseInputData);
        console.log("Resolution Input:", resolutionTeamInputData);
        console.log("Delegation Input:", delegationTeamInputData);
        console.log("XDelegation Input:", externalDelegationTeamInputData);
        console.log("Switch Labels:", switchLabels);
        console.log("Status:", switchLabels[0].value);
        
        return false; */

        const payload: CaseTypeFormDataPayload = {
            organisation_id: userOrganisationId,
            case_group_id: caseInputData.case_group_id,
            priority_id: caseInputData.priority_id,
            name: caseInputData.name,
            description: caseInputData.description,
            global_resolution_time: global_resolution_time,

            service_team_id: resolutionTeamInputData.service_team_id,
            escalation_team_id: resolutionTeamInputData.resolution_escalation_team_id,
            escalation_executive_team_id: resolutionTeamInputData.resolution_escalation_executive_team_id,
            resolution_pickup_time: convertDuration(resolutionTeamInputData.resolution_pickup_time),
            resolution_delegation_time: convertDuration(resolutionTeamInputData.resolution_delegation_time),
            resolution_escalation_time1: convertDuration(resolutionTeamInputData.resolution_escalation_time1),
            resolution_escalation_time2: convertDuration(resolutionTeamInputData.resolution_escalation_time2),
            resolution_time: convertDuration(resolutionTeamInputData.resolution_time),

            delegate_team_id: delegationTeamInputData.delegate_team_id,
            delegation_escalation_team_id: delegationTeamInputData.delegation_escalation_team_id,
            delegation_escalation_executive_team_id: delegationTeamInputData.delegation_escalation_executive_team_id,
            delegation_pickup_time: convertDuration(delegationTeamInputData.delegation_pickup_time),
            delegation_delegation_time: convertDuration(delegationTeamInputData.delegation_delegation_time),
            delegation_escalation_time1: convertDuration(delegationTeamInputData.delegation_escalation_time1),
            delegation_escalation_time2: convertDuration(delegationTeamInputData.delegation_escalation_time2),
            delegation_resolution_time: convertDuration(delegationTeamInputData.delegation_resolution_time),

            //external_delegation_escalation_team_id: externalDelegationTeamInputData.external_delegation_escalation_team_id || null,
            //external_delegation_escalation_executive_team_id: externalDelegationTeamInputData.external_delegation_escalation_executive_team_id || null,
            external_delegation_pickup_time: convertDuration(externalDelegationTeamInputData.external_delegation_pickup_time) || 0,
            external_delegation_escalation_time1: convertDuration(externalDelegationTeamInputData.external_delegation_escalation_time1) || 0,
            external_delegation_escalation_time2: convertDuration(externalDelegationTeamInputData.external_delegation_escalation_time2) || 0,
            external_delegation_resolution_time: convertDuration(externalDelegationTeamInputData.external_delegation_resolution_time) || 0,
            /* sla_id: newRecord.sla_id,
            delegation_sla_id: newRecord.delegation_sla_id,
            escalation_sla_id: newRecord.escalation_sla_id,
            
            name: newRecord.name,*/

            is_active: switchLabels[0].value,
            auto_assign: switchLabels[1].value,
        }

        await axiosPrivate
            .post(CaseTypeApi, payload)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`);

                setCaseFormSchema((prevSchema) => {
                    return prevSchema.filter(field => field.name !== "impact_urgency_info");
                })
                setCaseInputData({
                    service_type_id: '',
                    case_group_id: '',
                    priority_id: '',
                    name: '',
                    description: '',
                    global_resolution_time: '',
                });
        
                setResolutionTeamInputData({
                    service_team_id: '',
                    resolution_escalation_team_id: '',
                    resolution_escalation_executive_team_id: '',
                    resolution_pickup_time: '',
                    resolution_delegation_time: '',
                    resolution_escalation_time1: '',
                    resolution_escalation_time2: '',
                    resolution_time: '',
                });
        
                setDelegationTeamInputData({
                    delegate_team_id: '',
                    delegation_escalation_team_id: '',
                    delegation_escalation_executive_team_id: '',
                    delegation_pickup_time: '',
                    delegation_delegation_time: '',
                    delegation_escalation_time1: '',
                    delegation_escalation_time2: '',
                    delegation_resolution_time: '',
                });
        
                setExternalDelegationTeamInputData({
                    external_delegation_pickup_time: '',
                    external_delegation_escalation_time1: '',
                    external_delegation_escalation_time2: '',
                    external_delegation_resolution_time: '',
                });
                
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                //throw err;
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
    }

    return {
        backBtn,

        tabResolution,
        tabDelegation,
        tabXDelegation,
        activeTab,
        handleTabClick,

        handleSubmit,
        inputErrors,

        caseFormSchema,
        caseInputData,
        handleCaseChange,

        resolutionTeamFormSchema,
        resolutionTeamInputData,
        handleResolutionTeamChange,

        delegationTeamFormSchema,
        delegationTeamInputData,
        handleDelegationTeamChange,

        externalDelegationTeamFormSchema,
        externalDelegationTeamInputData,
        handleExternalDelegationTeamChange,

        switchLabels,
        setSwitchLabels,
    }
}

