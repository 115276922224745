import { useState } from "react";
import { TeamMember } from "../../../../Constant";

export default function useTeamMember() {
    const title = TeamMember;

    const INTERNALTEAMTAB = 'internal-team';
    const EXTERNALTEAMTAB = 'external-team';

    const [commissionTab, setCommissionTab] = useState<string>(INTERNALTEAMTAB); 

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
    };

    return {
        title,
        commissionTab,
        INTERNALTEAMTAB,
        EXTERNALTEAMTAB,
        handleTabClick,
    };
}
