import { Fragment, useContext, useState } from 'react';
import { Delegation, DelegationTime, EscalationExecutiveTime, EscalationFunctionalTime, ExternalDelegation, PickUpTime, Resolution, ResolutionTime } from '../../../../../Constant';
import { H5, Btn } from '../../../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Table, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { CaseDelegationMemberPickupUri, CaseExternalDelegationMemberPickupUri, CaseResolutionMemberPickupUri } from '../../../../../api';
import SLATimeCounter from '../../../../Common/Component/SLATimeCounter';

import CustomizerContext from '../../../../../_helper/Customizer';
import { Link } from 'react-router-dom';
import ScrollBars from 'react-custom-scrollbars-2';
import { formatToDuration } from '../../../../../_helper/utils';

const CaseInfo = ({ details, onCaseUpdate }) => {
    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/crm/case-management/case/${layoutURL}`;
    const [isFilter, setIsFilter] = useState(true);
    
    const RESOLUTION = 'resolution';
    const DELEGATION = 'delegation';
    const EXTERNALDELEGATION = 'external-delegation';

    const [caseTab, setCaseTab] = useState(RESOLUTION);

    const handleDelegatePickup = async () => {
        const methodName = `${CaseDelegationMemberPickupUri}`;
        const payload = {};
        
        await onCaseUpdate(methodName, payload);
    }; 

    const handleExternalDelegatePickup = async () => {
        const methodName = `${CaseExternalDelegationMemberPickupUri}`;
        const payload = {};
        
        await onCaseUpdate(methodName, payload);
    }; 

    const handlePickup = async () => {
        const methodName = `${CaseResolutionMemberPickupUri}`;
        const payload = {};
        await onCaseUpdate(methodName, payload);
    };

    return (
        <Fragment>
            <Col xl="12">
                <div className='text-end mb-2'>
                    {
                        (!details.resolved_at && (!details.resolution_pickup_at || !details.assigned_to)) &&
                        (<Button color="info" 
                            style={{ width:'auto' }}
                            className='btn-sm me-2'
                            onClick={handlePickup}>
                            <i className='fa fa-folder-open'></i> PickUp
                        </Button>)
                    }

                    {
                        (!details.resolved_at && (details.resolution_delegation_at && !details.delegation_pickup_at)) &&
                        (<Button color="info" 
                            style={{ width:'auto' }}
                            className='btn-sm me-2'
                            onClick={handleDelegatePickup}>
                            <i className='fa fa-folder-open'></i> Delegate PickUp
                        </Button>)
                    }

                    {
                        (!details.resolved_at && (details.delegation_delegation_at && !details.external_delegation_pickup_at)) &&
                        (<Button color="info" 
                            style={{ width:'auto' }}
                            className='btn-sm me-2'
                            onClick={handleExternalDelegatePickup}>
                            <i className='fa fa-folder-open'></i> External Delegate PickUp
                        </Button>)
                    }

                    <Link to={backBtn}>
                        <Button color="primary" 
                         style={{ width:'auto' }}
                         className='btn-sm'>
                        <i className='fa fa-arrow-circle-o-left'></i> Back to List
                        </Button>
                    </Link>
                </div>
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >

                            <Btn attrBtn={{
                                className: 'd-flex justify-content-between btn btn-link',
                                onClick: () => setIsFilter(!isFilter),
                                color: 'transperant'
                            }} >&nbsp;
                            {isFilter ? <ChevronUp className='m-0' /> : <ChevronDown className='m-0' />}
                            </Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                                <Table>
                                    <tbody>
                                        { details.resolved_at && 
                                            <tr className=''>
                                                <th scope='row' colSpan={2}>
                                                <h4 className={`text-success`}>CASE SOLVED</h4>
                                                </th>
                                            </tr>
                                        }
                                        { !details.resolved_at && 
                                        <tr className=''>
                                            <th scope='row' colSpan={2}>
                                                {
                                                    (details.resolution_pickup_at && !details.resolution_delegation_at) &&
                                                    <SLATimeCounter 
                                                        dueDate={details.resolution_time}
                                                        title={`Resolution`} />
                                                }
                                                {
                                                    !details.resolution_pickup_at && 
                                                    (<SLATimeCounter 
                                                        dueDate={details.resolution_pickup_time}
                                                        title={`PickUp`} />)
                                                }

                                                {
                                                    (details.delegation_pickup_at && !details.delegation_delegation_at) &&
                                                    <SLATimeCounter 
                                                        dueDate={details.delegation_resolution_time}
                                                        title={`Delegation Resolution`} />
                                                }    
                                                {
                                                    (details.resolution_delegation_at && !details.delegation_pickup_at) && 
                                                    (<SLATimeCounter 
                                                        dueDate={details.resolution_pickup_time}
                                                        title={`Delegate PickUp`} />)
                                                }

                                                {
                                                    (details.external_delegation_pickup_at) &&
                                                    <SLATimeCounter 
                                                        dueDate={details.delegation_resolution_time}
                                                        title={`External Delegation Resolution`} />
                                                }    
                                                {
                                                    (details.delegation_delegation_at && !details.external_delegation_pickup_at) && 
                                                    (<SLATimeCounter 
                                                        dueDate={details.resolution_pickup_time}
                                                        title={`External Delegate PickUp`} />)
                                                }
                                                
                                                
                                            </th>
                                        </tr>
                                        }
                                    </tbody>
                                </Table>
                                
                                <Nav className='border-tab nav-secondary nav-pills overflow-x' tabs>
                                    <NavItem>
                                        <NavLink href='#' className={caseTab === `${RESOLUTION}` ? 'active' : ''}
                                                    onClick={() => setCaseTab(`${RESOLUTION}`)}>
                                            <i className='icofont icofont-users'></i>
                                            {Resolution}
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink href='#' className={caseTab === `${DELEGATION}` ? 'active' : ''}
                                                    onClick={() => setCaseTab(`${DELEGATION}`)}>
                                            <i className='fa fa-users'></i>
                                            {Delegation}
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink href='#' className={caseTab === `${EXTERNALDELEGATION}` ? 'active' : ''}
                                                    onClick={() => setCaseTab(`${EXTERNALDELEGATION}`)}>
                                            <i className='fa fa-users'></i>
                                            {ExternalDelegation}
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={caseTab}>
                                    
                                    <TabPane className='fade show' tabId={RESOLUTION}>
                                        <ScrollBars className='vertical-scroll scroll-demo' style={{width: '100%',height:250}}>
                                            <div className="table-responsive">                
                                                <Table>
                                                    <tbody>

                                                        {/* {
                                                            !details.resolution_delegation_at &&
                                                            ( */}
                                                                <>
                                                                    <tr className=''>
                                                                        <th scope='row'>{PickUpTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.resolution_pickup_time)}&nbsp; 

                                                                            {
                                                                                details.resolution_pickup_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-info">
                                                                                        Picked <i className="fa fa-check-circle-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }

                                                                            {
                                                                                details.resolution_pickup_breached_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-danger">
                                                                                        Breached <i className="fa fa-clock-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{ResolutionTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.resolution_time)}&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    <tr className=''>
                                                                        <th scope='row'>{DelegationTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.resolution_delegation_time)}&nbsp;
                                                                            
                                                                            {
                                                                                details.resolution_delegation_at &&
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-info">
                                                                                        Delegated <i className="fa fa-tasks"></i>
                                                                                    </span>
                                                                                </>
                                                                            }

                                                                            {
                                                                                details.resolution_delegation_breached_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-danger">
                                                                                        Breached <i className="fa fa-clock-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{EscalationFunctionalTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.resolution_escalation_time1)}&nbsp;
                                                                            
                                                                            {
                                                                                details.resolution_escalation1_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-danger">
                                                                                        Escalated <i className="fa fa-bell-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{EscalationExecutiveTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.resolution_escalation_time2)}&nbsp;
                                                                            
                                                                            {
                                                                                details.resolution_escalation2_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-warning">
                                                                                        Escalated <i className="fa fa-bullhorn"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            {/* )
                                                        } */}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </ScrollBars>
                                    </TabPane>

                                    <TabPane className='fade show' tabId={DELEGATION}>
                                        <ScrollBars className='vertical-scroll scroll-demo' style={{width: '100%',height:250}}>
                                            <div className="table-responsive">
                                                <Table>
                                                    <tbody>        
                                                        {/* {
                                                            details.resolution_delegation_at &&
                                                            ( */}
                                                                <>
                                                                    <tr className=''>
                                                                        <th scope='row'>{PickUpTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.delegation_pickup_time)}&nbsp;

                                                                            {
                                                                                details.delegation_pickup_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-info">
                                                                                        Picked <i className="fa fa-check-circle-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }

                                                                            {
                                                                                details.delegation_pickup_breached_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-danger">
                                                                                        Breached <i className="fa fa-clock-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{ResolutionTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.delegation_resolution_time)}&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{DelegationTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.delegation_delegation_time)}&nbsp;

                                                                            {
                                                                                details.delegation_delegation_at &&
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-info">
                                                                                        Delegated <i className="fa fa-tasks"></i>
                                                                                    </span>
                                                                                </>
                                                                            }

                                                                            {
                                                                                details.delegation_delegation_breached_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-danger">
                                                                                        Breached <i className="fa fa-clock-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{EscalationFunctionalTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.delegation_escalation_time1)}&nbsp;

                                                                            {
                                                                                details.delegation_escalation1_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-danger">
                                                                                        Escalated <i className="fa fa-bell-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{EscalationExecutiveTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.delegation_escalation_time2)}&nbsp;
                                                                            
                                                                            {
                                                                                details.delegation_escalation2_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-warning">
                                                                                        Escalated <i className="fa fa-bullhorn"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            {/* )
                                                        } */}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </ScrollBars>
                                    </TabPane>

                                    <TabPane className='fade show' tabId={EXTERNALDELEGATION}>
                                        <ScrollBars className='vertical-scroll scroll-demo' style={{width: '100%',height:250}}>
                                            <div className="table-responsive">
                                                <Table>
                                                    <tbody>        

                                                        {/* {
                                                            details.delegation_delegation_at &&
                                                            ( */}
                                                                <>
                                                                    <tr className=''>
                                                                        <th scope='row'>{PickUpTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.external_delegation_pickup_time || 0)}&nbsp; 

                                                                            {
                                                                                details.external_delegation_pickup_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-info">
                                                                                        Picked <i className="fa fa-check-circle-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }

                                                                            {
                                                                                details.external_delegation_pickup_breached_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-danger">
                                                                                        Breached <i className="fa fa-clock-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{ResolutionTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.external_delegation_resolution_time || 0)}&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{EscalationFunctionalTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.external_delegation_escalation_time1 || 0)}&nbsp;

                                                                            {
                                                                                details.external_delegation_escalation1_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-danger">
                                                                                        Escalated <i className="fa fa-bell-o"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{EscalationExecutiveTime}</th>
                                                                        <td>
                                                                            {formatToDuration(details?.case_type?.external_delegation_escalation_time2 || 0)}&nbsp;

                                                                            {
                                                                                details.external_delegation_escalation2_at && 
                                                                                <>
                                                                                    &nbsp;<span className="badge badge-light-warning">
                                                                                        Escalated <i className="fa fa-bullhorn"></i>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            {/*  )
                                                        }  */}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </ScrollBars>
                                    </TabPane>
                                    
                                </TabContent>

                                

                               

                                
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default CaseInfo;