import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent  } from 'reactstrap';
import {Breadcrumbs} from '../../../../AbstractElements';
import {Administration, FixedAssetGroup, FixedAssetClass, FixedAssetType, FixedAssetDescription} from '../../../../Constant';
import {Link} from "react-router-dom";
import {PlusCircle} from "react-feather";
import useFixedAssetSettings from './controller';
import FixedAssetGroupList from './Group/List/FixedAssetGroupList';
import FixedAssetClassList from './Class/List/FixedAssetClassList';
import FixedAssetDescriptionList from './Description/List/FixedAssetDescriptionList';
import FixedAssetTypeList from './Type/List/FixedAssetTypeList';

const FixedAssetSettings = () => {
    const {
        layoutURL,
        GROUP,
        CLASS,
        TYPE,
        DESCRIPTION,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    } = useFixedAssetSettings();
    
    return (
        <Fragment>
            <Breadcrumbs parent={Administration} title={tabDisplayNames[commissionTab]} mainTitle={tabDisplayNames[commissionTab]}/>
            <Container fluid={true}>
                <Row>
                    <Col sm='12' xl='12' className='xl-100'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        {/*<H5></H5>*/}
                                    </Col>
                                    <Col md="6">
                                        <div className="text-end file-content">
                                            <Link className="btn btn-primary ms-2" style={{color: 'white'}} to={`/administration/settings/fixed-asset/${commissionTab}/create/${layoutURL}`}>
                                                <PlusCircle/> New {tabDisplayNames[commissionTab] || 'Item'}
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={'12'}>
                                        <Nav className='border-tab nav-secondary nav-pills' tabs>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${GROUP}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${GROUP}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {FixedAssetGroup}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${CLASS}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${CLASS}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {FixedAssetClass}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${TYPE}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${TYPE}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {FixedAssetType}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${DESCRIPTION}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${DESCRIPTION}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {FixedAssetDescription}
                                                </NavLink>
                                            </NavItem>
                                        </Nav> 
                                        <TabContent activeTab={commissionTab}>
                                            {commissionTab === `${GROUP}` && <FixedAssetGroupList name={GROUP} />}
                                            {commissionTab === `${CLASS}` && <FixedAssetClassList name={CLASS} />}
                                            {commissionTab === `${TYPE}` && <FixedAssetTypeList name={TYPE} />}
                                            {commissionTab === `${DESCRIPTION}` && <FixedAssetDescriptionList name={DESCRIPTION} />}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default  FixedAssetSettings