import { FormEvent, useEffect, useState } from "react";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { TeamMappingDataPayload, MiniResType, TeamMappingData, FetchDataResponse } from "../CaseTeamMapping.type";
import { CaseTeamMappingApi, TeamTypeApi } from "../../../../../api";
import { toast } from "react-toastify";
import { InputProviderFormSchema } from "../../../../Finance/Template/types";
import { DelegateTeamType, EscalationExecutiveTeamType, EscalationTeamType, ExternalDelegateTeamType, ResolutionTeamType } from "../../../../../Constant";
import { validateInputs } from "../../../../Finance/Template/InputProvider";

export default function useCaseTeamMappingController() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    //const { layoutURL } = useContext(CustomizerContext);
    
    const [inputErrors, setInputErrors] = useState<{
        caseTeamMappingFormErrors: { [key: string]: string };
        }>({
        caseTeamMappingFormErrors: {},
    });

    // ==== FORM SCHEMA'S ==== //
    const [caseTeamMappingFormSchema, setCaseTeamMappingFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "resolution_team",
            label: ResolutionTeamType,
            type: "select",
            required: true,
        },
        {
            name: "delegation_team",
            label: DelegateTeamType,
            type: "select",
            required: true,
        },
        {
            name: "escalation_functional_team",
            label: EscalationTeamType,
            type: "select",
            required: true,
        },
        {
            name: "escalation_executive_team",
            label: EscalationExecutiveTeamType,
            type: "select",
            required: true,
        },
        {
            name: "external_delegation_team",
            label: ExternalDelegateTeamType,
            type: "select",
            required: true,
        },
    ]);

    // ==== INPUT DATA'S ==== //
    const [caseTeamMappingInputData, setCaseTeamMappingInputData] = useState<{
        [key: string]: any;
    }>({});

    // ==== ON CHANGE HANDLER'S ==== //
    const handleCaseTeamMappingChange = (name: string, value: any) => {
        setCaseTeamMappingInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const fetchCaseTeamMappingData = async () => {
        try {
            const teamTypeUrl = `${TeamTypeApi}?with_trashed=false&organisation_id=${userOrganisationId}`;
            const CaseTeamMappingUrl = `${CaseTeamMappingApi}`;

            // Use Promise.all to fetch both APIs concurrently
            /* const [
                teamTypeRes,
                CaseTeamMappingRes,
            ] = await Promise.allSettled([
                axiosPrivate.get(teamTypeUrl),
                axiosPrivate.get(CaseTeamMappingUrl),
            ]); */

            const results = await Promise.allSettled([
                axiosPrivate.get(teamTypeUrl),
                axiosPrivate.get(CaseTeamMappingUrl),
            ]);

            //const teamTypesData: MiniResType[] = await teamTypeRes.data;
            //const CaseTeamMappingData: TeamMappingData[] = await CaseTeamMappingRes.data.data.settings;
            
            const teamTypesData: MiniResType[] = results[0].status === 'fulfilled' ? results[0].value.data : null;
            const CaseTeamMappingData: TeamMappingData[] = results[1].status === 'fulfilled' ? results[1].value.data.data.settings : null;

            return {
                teamTypesData,
                CaseTeamMappingData
            };
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchCaseTeamMappingData().then((data) => {
            if(data) {
                
                setCaseTeamMappingFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "resolution_team"
                            || field.name === "delegation_team"
                            || field.name === "escalation_functional_team"
                            || field.name === "escalation_executive_team"
                            || field.name === "external_delegation_team"
                        ) {
                            return {
                                ...field,
                                /* options: data.teamTypesData.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })), */
                                options: data.teamTypesData
                                ? data.teamTypesData.map((item: MiniResType) => ({
                                    label: item.name,
                                    value: item.id,
                                }))
                                : [],
                            };
                        }

                        return field; 
                    })
                ); 

                
                /*const caseTeamMappingRec = data.CaseTeamMappingData.map((item) => ({
                    [item.key]: item.value
                })) 

                 const caseTeamMappingRec = [
                    {
                        "resolution_team": "4"
                    },
                    {
                        "delegation_team": "2"
                    },
                    {
                        "external_delegation_team": "8"
                    },
                    {
                        "escalation_functional_team": "1"
                    },
                    {
                        "escalation_executive_team": "3"
                    }
                ] */
                
                const resolution_team_rec = data.CaseTeamMappingData?.find((item: { key: string; }) => item.key === "resolution_team")
                const delegation_team_rec = data.CaseTeamMappingData?.find((item: { key: string; }) => item.key === "delegation_team")
                const escalation_functional_team_rec = data.CaseTeamMappingData?.find((item: { key: string; }) => item.key === "escalation_functional_team")
                const escalation_executive_team_rec = data.CaseTeamMappingData?.find((item: { key: string; }) => item.key === "escalation_executive_team")
                const external_delegation_team_rec = data.CaseTeamMappingData?.find((item: { key: string; }) => item.key === "external_delegation_team")
                
                setCaseTeamMappingInputData((prevData) => ({
                    ...prevData,
                    ...{
                        resolution_team: resolution_team_rec?.value,
                        delegation_team: delegation_team_rec?.value,
                        escalation_functional_team: escalation_functional_team_rec?.value,
                        escalation_executive_team: escalation_executive_team_rec?.value,
                        external_delegation_team: external_delegation_team_rec?.value,
                    },

                    /* ["resolution_team"] : resolution_team_rec?.value,
                    ["delegation_team"] : delegation_team_rec?.value,
                    ["escalation_functional_team"] : escalation_functional_team_rec?.value,
                    ["escalation_executive_team"] : escalation_executive_team_rec?.value,
                    ["external_delegation_team"] : external_delegation_team_rec?.value, */
                    //...caseTeamMappingRec
                }));
            }
        });
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setInputErrors({
            caseTeamMappingFormErrors: {},
        }); 
        
        const caseValidation = validateInputs(caseTeamMappingFormSchema, caseTeamMappingInputData);
        if (!caseValidation.isValid) {
            setInputErrors(prev => ({ ...prev, caseTeamMappingFormErrors: caseValidation.errors }));
            return;
        }
        
        const payload: TeamMappingDataPayload = {
            settings: [
                {
                    organisation_id: userOrganisationId,
                    key: "resolution_team",
                    value: caseTeamMappingInputData.resolution_team
                },
                {
                    organisation_id: userOrganisationId,
                    key: "delegation_team",
                    value: caseTeamMappingInputData.delegation_team
                },
                {
                    organisation_id: userOrganisationId,
                    key: "escalation_functional_team",
                    value: caseTeamMappingInputData.escalation_functional_team
                },
                {
                    organisation_id: userOrganisationId,
                    key: "escalation_executive_team",
                    value: caseTeamMappingInputData.escalation_executive_team
                },
                {
                    organisation_id: userOrganisationId,
                    key: "external_delegation_team",
                    value: caseTeamMappingInputData.external_delegation_team
                }
            ]
        }

        await axiosPrivate
            .put(CaseTeamMappingApi, payload)
            .then((_res) => {
                toast.success(`case team mapping updated successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                //throw err;
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
    }

    return {
        handleSubmit,
        inputErrors,

        caseTeamMappingFormSchema,
        caseTeamMappingInputData,
        handleCaseTeamMappingChange,
    }
}

