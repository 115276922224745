import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent  } from 'reactstrap';
import {Breadcrumbs} from '../../../../AbstractElements';
import {Payroll, PayCategory, PayHeadline, GradeGroupPayHeadline, PayStructure} from '../../../../Constant';
import {Link } from "react-router-dom";
import {PlusCircle} from "react-feather";
import PayrollPayCategoryList from "./PayCategory/List/PayrollPayCategoryList";
import PayrollPayHeadlineList from './PayHeadline/List/PayrollPayHeadlineList';
import GradeGroupPayHeadlineForm from './GradeGroupPayHeadline/GradeGroupPayHeadlineForm';
import usePayGroupSettings from './controller';

const PayGroupSettings = () => {
    const {
        layoutURL,
        PAY_CATEGORY,
        PAY_HEADLINE,
        GRADE_GROUP_PAY_HEADLINE,
        PAY_STRUCTURE,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    } = usePayGroupSettings();

    return (
        <Fragment>
            <Breadcrumbs parent={Payroll} title={tabDisplayNames[commissionTab]} mainTitle={tabDisplayNames[commissionTab]}/>
            <Container fluid={true}>
                <Row>
                    <Col sm='12' xl='12' className='xl-100'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        {/*<H5>{GradeGroup}</H5>*/}
                                    </Col>
                                    <Col md="6">
                                        <div className="text-end file-content">
                                            {commissionTab !== `${GRADE_GROUP_PAY_HEADLINE}` && (
                                                <Link className="btn btn-primary ms-2" style={{color: 'white'}} to={`/payroll/settings/${commissionTab}/create/${layoutURL}`}>
                                                    <PlusCircle/> New {tabDisplayNames[commissionTab] || 'Item'}
                                                </Link>     
                                            )}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={'12'}>
                                        <Nav className='border-tab nav-secondary nav-pills' tabs>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${PAY_CATEGORY}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${PAY_CATEGORY}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {PayCategory}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${PAY_HEADLINE}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${PAY_HEADLINE}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {PayHeadline}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${GRADE_GROUP_PAY_HEADLINE}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${GRADE_GROUP_PAY_HEADLINE}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {GradeGroupPayHeadline}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${PAY_STRUCTURE}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${PAY_STRUCTURE}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {PayStructure}
                                                </NavLink>
                                            </NavItem>
                                        </Nav> 
                                        <TabContent activeTab={commissionTab}>
                                            {commissionTab === `${PAY_CATEGORY}` && <PayrollPayCategoryList name={PAY_CATEGORY} />}
                                            {commissionTab === `${PAY_HEADLINE}` && <PayrollPayHeadlineList name={PAY_HEADLINE} />}
                                            {commissionTab === `${GRADE_GROUP_PAY_HEADLINE}` && <GradeGroupPayHeadlineForm name={GRADE_GROUP_PAY_HEADLINE} />}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default  PayGroupSettings