import moment from 'moment';
import 'moment-timezone';

export const formatDateWithTimeZone = (dateString, timeZone = "Africa/Lagos", format = 'MMMM D, YYYY h:mm A') => {
  const timeZoneToUse = timeZone || moment.tz.guess(); // Use provided timezone or guess the default timezone
  return moment.tz(dateString, timeZoneToUse).format(format);
  //return moment.tz(dateString, timeZone).format(format);
};

export const timeAgo = (date) => {
  const currentDate = new Date();
  const previousDate = new Date(date);

  const timeDifference = currentDate.getTime() - previousDate.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? '1 year ago' : `${years} years ago`;
  } else if (days > 0) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
    return 'few seconds ago';
  }
};

export const moneyFormatter = new Intl.NumberFormat('en-NG', {
  style: 'currency',
  currency: 'NGN',
  minimumFractionDigits: 2,
});

export const formatMoney = (amount) => {
  return moneyFormatter.format(amount);
}