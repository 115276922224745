import { useContext, useState } from "react";
import data from "./../Request/data.json";
import { StatusRow } from "../../../Finance/Settings/ReportType/List/ReportTypeList";
import CustomizerContext from "../../../../_helper/Customizer";

export default function useExpenseRequestController() {
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);

  const { layoutURL } = useContext(CustomizerContext);
  const newExpenseBtn = `${process.env.PUBLIC_URL}/finance/expense/request/create/${layoutURL}`;

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const [editMode, setEditMode] = useState(false);
  const [expenseRequestData, setExpenseRequestData] = useState(data);

  const tableColumns = [
    {
      name: "REQUESTER",
      selector: (row: (typeof data)[0]) => `${row.REQUESTER}`,
      sortable: true,
      center: false
    },
    {
      name: "BENEFICIARY",
      selector: (row: (typeof data)[0]) => `${row.BENEFICIARY}`,
      sortable: true,
      center: true
    },
    {
      name: "TOTAL",
      selector: (row: (typeof data)[0]) => `${row.TOTAL}`,
      sortable: true,
      center: false
    },
    {
      name: "AMORTISATION TYPE",
      selector: (row: (typeof data)[0]) => `${row.AMORTISATION_TYPE}`,
      sortable: true,
      center: false
    },
    {
      name: "TRANSACTION DATA",
      selector: (row: (typeof data)[0]) => `${row.TRANSACTION_DATA}`,
      sortable: true,
      center: false
    },
    {
      name: "STATUS",
      selector: (row: (typeof data)[0]) => `${row.STATUS}`,
      cell: (row: (typeof data)[0]) => StatusRow(row),
      sortable: true,
      center: false
    }
  ];

  const handleFilteringByNav = (_value: string) => {
    setExpenseRequestData(
      data.filter(_ => (_value == "Impress" ? data : _.category == _value))
    );
  };

  const [navsTextNode] = useState([
    { id: 21, txtNode: "Impress" },
    { id: 24, txtNode: "Claim" },
    { id: 14, txtNode: "Cash advance" },
    { id: 15, txtNode: "Bills" }
  ]);

  return {
    newExpenseBtn,
    tableColumns,
    editMode,
    setEditMode,
    viewModalOpen,
    expenseRequestData,
    handleView,
    selectedRowData,
    setViewModalOpen,
    handleFilteringByNav,
    navsTextNode
  };
}
