import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent  } from 'reactstrap';
import {Breadcrumbs} from '../../../../AbstractElements';
import {Administration, InventoryGroup, InventoryClass, InventoryType, InventoryDescription} from '../../../../Constant';
import {Link} from "react-router-dom";
import {PlusCircle} from "react-feather";
import useInventorySettings from './controller';
import InventoryGroupList from './Group/List/InventoryGroupList';
import InventoryClassList from './Class/List/InventoryClassList';
import InventoryDescriptionList from './Description/List/InventoryDescriptionList';
import InventoryTypeList from './Type/List/InventoryTypeList';

const InventorySettings = () => {
    const {
        layoutURL,
        GROUP,
        CLASS,
        TYPE,
        DESCRIPTION,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    } = useInventorySettings();
    
    return (
        <Fragment>
            <Breadcrumbs parent={Administration} title={tabDisplayNames[commissionTab]} mainTitle={tabDisplayNames[commissionTab]}/>
            <Container fluid={true}>
                <Row>
                    <Col sm='12' xl='12' className='xl-100'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        {/*<H5></H5>*/}
                                    </Col>
                                    <Col md="6">
                                        <div className="text-end file-content">
                                            <Link className="btn btn-primary ms-2" style={{color: 'white'}} to={`/administration/settings/inventory/${commissionTab}/create/${layoutURL}`}>
                                                <PlusCircle/> New {tabDisplayNames[commissionTab] || 'Item'}
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={'12'}>
                                        <Nav className='border-tab nav-secondary nav-pills' tabs>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${GROUP}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${GROUP}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {InventoryGroup}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${CLASS}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${CLASS}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {InventoryClass}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${TYPE}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${TYPE}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {InventoryType}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${DESCRIPTION}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${DESCRIPTION}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {InventoryDescription}
                                                </NavLink>
                                            </NavItem>
                                        </Nav> 
                                        <TabContent activeTab={commissionTab}>
                                            {commissionTab === `${GROUP}` && <InventoryGroupList name={GROUP} />}
                                            {commissionTab === `${CLASS}` && <InventoryClassList name={CLASS} />}
                                            {commissionTab === `${TYPE}` && <InventoryTypeList name={TYPE} />}
                                            {commissionTab === `${DESCRIPTION}` && <InventoryDescriptionList name={DESCRIPTION} />}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default  InventorySettings