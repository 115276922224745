import { useLayoutEffect, useState } from "react";
import {
    Name,
    AssignUnAssignGradeGroupPayHeadline,
    GradeGroup,
    Status,
    Alias,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";
import {
    AssignPayHeadlineURI,
    FetchPayHeadlineURI,
    HrmGradeGroupApi,
    PayrollGradeGroupPayHeadlineApi,
    RemovePayHeadlinesURI,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { StatusRow } from "../../../../Finance/Settings/ReportType/List/ReportTypeList";

export default function useGradeGroupPayHeadline() {
    const axiosPrivate = useAxiosPrivate()
    const title = AssignUnAssignGradeGroupPayHeadline;
    const { userOrganisationId }: any = useAuth();
    const [hrmGradeGroupId, setHrmGradeGroupId] = useState(0);
    const [available, setAvailable] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [formFields, setFormFields] = useState<any[]>([]);

    const HrmGradeGroupDropDown = createDropdownField(axiosPrivate,
        "hrm_grade_group_id",
        "hrm_grade_group_id",
        GradeGroup,
        `${HrmGradeGroupApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );

    HrmGradeGroupDropDown.onChange = async (id, updatedFields) => {
        try {
            setHrmGradeGroupId(id);
            const endPoint = `${PayrollGradeGroupPayHeadlineApi(id)}/${FetchPayHeadlineURI}`;
            axiosPrivate
                .get(endPoint)
                .then((res) => {
                    //console.log(res.data);
                    setAssigned([]);
                    setAvailable([]);

                    if (res.data) {
                        setAssigned(res.data.data.assigned_items);
                        setAvailable(res.data.data.available_items);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                    toast.error("Error: Please Select All Fields");
                    setAssigned([]);
                    setAvailable([]);
                });
        } catch (error) {
            //console.log("CAUGHT ERROR:", error);
        }
    };

    const tableColumns = [
        {
            name: Name,
            selector: (row: any) => `${row.name}`,
            sortable: true,
            center: false,
        },
        {
            name: Alias,
            selector: (row: any) => `${row.short_name}`,
            sortable: true,
            center: false,
        },
        {
            name: Status,
            selector: (row: any) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: any) => StatusRow(row),
        },
    ];

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [...formFields, HrmGradeGroupDropDown];
        await HrmGradeGroupDropDown.fetchOptions();
        setFormFields(updatedFields);
       // console.log(updatedFields);
    }
    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const assignTask = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No Pay Headline (s) selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `${PayrollGradeGroupPayHeadlineApi(hrmGradeGroupId)}/${AssignPayHeadlineURI}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Pay Headline (s) assigned successfully");
                //console.log(res.data);
            });
    };

    const usassignTask = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No Pay Headline (s) selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `${PayrollGradeGroupPayHeadlineApi(hrmGradeGroupId)}/${RemovePayHeadlinesURI}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Pay Headline (s) unassigned successfully");
            });
    };

    return {
        title,
        formFields,
        tableColumns,
        available,
        assigned,
        assignTask,
        usassignTask,
    };
}
