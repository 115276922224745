import { H5 } from '../../../AbstractElements';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

const NoRecord = ({ msg, btnAction, backgroundImg, buttonData }) => {
  
  return (
    <Fragment>
      <Col xs="12">
          {/* <div className="no-record-bg" style={{ backgroundImage: `url(${require('../../../../../assets/images/other-images/no_record_found.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}> */}
          <div className="no-record-bg" style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
          </div>
          <div className="no-record-search">
              <div>
                  <H5>{msg}</H5>
                  {btnAction && 
                    <button className={`btn btn-outline-${btnAction.color} btn-sm`}
                      onClick={() => btnAction.action()}
                      >
                      <i className={btnAction.icon}></i> {btnAction.label}
                    </button>
                  }
                  {buttonData && (
                    <Link to={buttonData.link}>
                    <div className="btn btn-outline-primary">
                      {buttonData.label}
                    </div>
                    </Link>
                  )}
                  
                  {/* <Form className="form-inline" action="#" method="get">
                      <div className="w-100 mb-0"><Search />
                          <Input className="form-control-plaintext w-100" type="text" onChange={handleChange} value={searchTerm} placeholder="Type question here" />
                      </div>
                  </Form> */}
              </div>
          </div>
      </Col>
    </Fragment>
  );
};

export default NoRecord;
