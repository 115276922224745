import { useContext, useLayoutEffect, useMemo, useState } from "react";
import useAuth from "../../../../../Hooks/useAuth";
import CustomizerContext from "../../../../../_helper/Customizer";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { VendorTypes } from "../types";
import { toast } from "react-toastify";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { HrmGradeApi, HrmGradeGroupApi, VendorApi } from "../../../../../api";
import {
  Name,
  Status,
  Description,
  GradeGroup,
  DateCreated,
  DateModified,
  Alias,
  Grade,
  ContactID,
  Email,
  PhoneNumber,
  ContactType,
  View,
  Vendor,
} from "../../../../../Constant";
import { ContactTypeVal, createDropdownField } from "../../../../../Data/FormField/PublicData";
import { FullNameRow, StatusRow } from "./VendorList";
import { useNavigate } from "react-router-dom";


export default function useVendor() {
  const { userOrganisationId }: any = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const history = useNavigate();
  const title = Vendor;
  const [data, setData] = useState<VendorTypes[]>([]);
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "short_name",
      name: "short_name",
      label: Alias,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const tableColumns = [
    {
      name: Name,
      selector: (row: (typeof data)[0]) => `${row.last_name}`,
      sortable: true,
      center: false,
      cell: (row: (typeof data)[0]) => FullNameRow(row),
    },
    {
      name: ContactID,
      selector: (row: (typeof data)[0]) => `${row.contact_number}`,
      sortable: true,
      center: false,
    },
    {
      name: Email,
      selector: (row: (typeof data)[0]) => `${row.user_login.email}`,
      sortable: true,
      center: false,
    },

    {
      name: PhoneNumber,
      selector: (row: (typeof data)[0]) => `${row.phone_number}`,
      sortable: true,
      center: false,
    },

    {
      name: ContactType,
      selector: (row: (typeof data)[0]) => row.contact_type === ContactTypeVal.Individual ? "Individual" : "Corporate",
      sortable: true,
      center: false,
    },

    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    {
      name: DateCreated,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: DateModified,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `#`;
  const [loading, setLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axiosPrivate
      .get(
        `${VendorApi}?with_trashed=${false}&organisation_id=${userOrganisationId}`
      )
      .then((res) => {
        const response: VendorTypes[] = res.data.items;
        setData(response);

        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);

  const handleView = (row: (typeof data)[0]) => {
    /* setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row); */
    history(`${process.env.PUBLIC_URL}/administration/vendor/${row.contact_number}/details/${layoutURL}`)
  };

  const vendorViewAction = [
    {
      label: View,
      action: handleView,
      color: 'success',
      icon: 'icon-eye',
    },
  ];

  return {
    vendorViewAction,
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    loading,
    setViewModalOpen,
  };
}
