import React, { Fragment, useState } from 'react';
import { Col, Card, CardHeader, Row } from 'reactstrap';
import CountUp from 'react-countup';
import { H6, Image, LI, UL } from '../../../../AbstractElements';
import { BOD, ContactUs, ContactUsNumber, DDMMYY, Designer, Email, LocationDetails, MarekjecnoMailId, MarkJecno, Location, ContactType } from '../../../../Constant';
import useVendorDetail from './controller';
import { Link } from 'react-router-dom';

interface UserProfileProps {
  fullName: string | undefined
  contactNumber: string | undefined
  contactType: string | undefined
  email: string | undefined
  phoneNumber: string | undefined
  location: string | undefined
  buttonData: {label: string, link: string} | undefined
}

const UserProfile: React.FC<UserProfileProps> = ({ fullName, contactNumber, contactType, email, phoneNumber, location, buttonData }) => {
  const { url, readUrl } = useVendorDetail()

  return (
    <Fragment>
      <Col sm='12'>
        <Card className='hovercard text-center b-l-secondary border-2'>
          <CardHeader className='cardheader'>
            {buttonData && (
              <Link to={buttonData.link}>
                <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                    {buttonData.label}
                </div>
              </Link>
            )}
          </CardHeader>
          <div className='user-image'>
            <div className='avatar'>
              <Image attrImage={{ className: 'step1', alt: '', src: `${url ? url : require('../../../../assets/images/no-image.png')}` }} />
            </div>
            <div className='icon-wrapper step2' data-intro='Change Profile image here'>
              <i className='icofont icofont-pencil-alt-5' 
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => readUrl(e)}>
                <input className='upload' type='file' 
                    onChange={(e) => readUrl(e)} />
              </i>
            </div>
          </div>
          <div className='info'>
            <Row className='step3' data-intro='This is the your details'>
              <Col sm='6' lg='4' className='order-sm-1 order-xl-0'>
                <Row>
                  <Col md='6'>
                    <div className='ttl-info text-start'>
                      <H6>
                        <i className='fa fa-envelope me-2'></i> {Email}
                      </H6>
                      <span>{email}</span>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-sm-mb-0'>
                      <H6>
                        <i className='fa fa-book me-2'></i>
                        {ContactType}
                      </H6>
                      <span>{contactType}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' lg='4' className='order-sm-0 order-xl-1'>
                <div className='user-designation'>
                  <div className='title'>
                    <a target='_blank' href='#javascript'>
                      {fullName}
                    </a>
                  </div>
                  <div className='desc mt-2'>{contactNumber}</div>
                </div>
              </Col>
              <Col sm='6' lg='4' className='order-sm-2 order-xl-2'>
                <Row>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-xs-mt'>
                      <H6>
                        <i className='fa fa-phone me-2'></i>
                        {ContactUs}
                      </H6>
                      <span>{phoneNumber}</span>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-sm-mb-0'>
                      <H6>
                        <i className='fa fa-location-arrow me-2'></i>
                        {Location}
                      </H6>
                      <span>{location}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default UserProfile;
