import { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Label, Media, Row, TabPane } from 'reactstrap';
import { Btn, H6, Image, P } from '../../../../../../AbstractElements';
import three from '../../../../../../assets/images/user/3.jpg';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';
import { AddNewNote, AddNote, CaseNotes, Comment, RefRootCause, RootCause } from '../../../../../../Constant';
import Select from 'react-select';
import { RootCauseApi } from '../../../../../../api';
import { createDropdownField } from '../../../../../../Data/FormField/PublicData';
import useAuth from '../../../../../../Hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import CaseNoteList from '../CaseNoteList';
import Spinner from '../../../../../Common/Component/Spinner';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';

const CaseNote = ({ Details, onCaseNoteSubmit, canPerformAction }) => {
  const axiosPrivate = useAxiosPrivate()
  const { contact, case_notes } = Details;
  const messageInputRef = useRef(null);
  const { userOrganisationId } = useAuth();
  const [rootCauseOptions, setRootCauseOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const RootCauseDropdownField = createDropdownField(axiosPrivate,
    'root_cause_id',
    'root_cause_id',
    `${RootCause}`,
    `${RootCauseApi}?organisation_id=${userOrganisationId}`,
  );

  useLayoutEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      setIsLoading((state) => !state)
      await RootCauseDropdownField.fetchOptions();
      setRootCauseOptions(RootCauseDropdownField.options)
      setIsLoading((state) => !state)
    }

    fetchData();

    return () => { abortController.abort();};
  },[])

  
 const onSubmit = async (data) => {
    //event.preventDefault();
    setBtnLoading(true);
    if (data !== '') {
      data.root_cause_id = data.root_cause_id.value;
      await onCaseNoteSubmit(data);
      reset();
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      errors.showMessages();
    }
  };
  

  const statusOptions = [
    { value: '1', label: 'Ongoing', color: '#00B8D9' },
    { value: '2', label: 'Completed', color: '#0052CC' },
    { value: '3', label: 'Dropped', color: '#FF5630' }
  ]

  return (
    <Fragment>
      <TabPane className='fade show' tabId='case-note'>
        <Row>
          <Col sm='12'>
            <Card>
              <CardBody>
                <div className='new-users-social'>
                  <Media className='align-items-center'>
                    <Image attrImage={{ className: 'rounded-circle image-radius m-r-15', src: `${three}`, alt: '' }} />
                    <Media body>
                      <H6 attrH6={{ className: 'mb-0 f-w-700' }}>
                        {contact && (
                          contact.company_name ? contact.company_name :
                          (contact.last_name ? `${contact.last_name} ${contact.first_name} ${contact.middle_name}` : '')
                        )}
                      </H6>
                      <P>{formatDateWithTimeZone(Details.created_at)}</P>
                    </Media>
                    <span className='d-flex justify-content-between pull-right mt-0 '>
                      { !Details.resolved_at && 
                        ( <><span className='m-r-20' 
                          style={{ cursor: 'pointer' }}
                          onClick={toggleFormVisibility}>
                          <i className='fa fa-save font-primary'></i> {AddNewNote}
                        </span></>)
                      }
                        {/* <DropdownCommon 
                          dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} 
                          options={TicketReplyMenu} 
                          iconName='icon-more-alt' 
                          btn={{ tag: 'span' }} /> */}
                    </span>
                    {/* <span className='pull-right mt-0'>
                      <MoreVertical />
                    </span> */}
                  </Media>
                </div>
                
                <div className='timeline-content'>
                  <div className={`display-content ${showForm ? 'show' : 'hide'}`}>
                    {showForm && (
                      <>
                         
                        <H6 attrH6={{ className: 'pb-2 f-w-600' }}>{AddNewNote}</H6>
                        <hr className="mt-1 mb-4" />
                        <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <Controller
                          control={control}
                          defaultValue={rootCauseOptions[1]}
                          name="status"
                          render={({ field }) => <Select {...field} options={rootCauseOptions} />}
                        /> */}
                          <Row>
                            <Col md='6 mb-3'>
                              <Label className='form-label'>
                                {RootCause}
                              </Label>
                              <Controller
                                name="root_cause_id"
                                control={control}
                                rules={{ required: true }}
                                //defaultValue={2}
                                render={({ field, value }) => (
                                  <Select 
                                    {...field}
                                    name={field.name}
                                    options={rootCauseOptions}
                                    isClearable={true}
                                    className="js-example-basic-single col-sm-12"
                                    //value={value}
                                    /* onChange={(selectedOption) => {
                                      field.onChange(selectedOption ? selectedOption.value : null); // Pass only the value
                                    }} */
                                    //onChange={(e) => field.onChange && field.onChange(e ? e.value : null)}
                                  />
                                )}
                              />
                                <span className='d-block' style={{ color: 'red' }}>{errors.root_cause_id && errors.root_cause_id.type === 'required' && 'Root Cause is required'}</span>
                            </Col>

                            {/* <Col md='6 mb-3'>
                              <Label className='form-label'>
                                {RootCause}
                              </Label>
                              <Controller
                                name="root_cause_id"
                                control={control}
                                rules={{ required: true }}
                                //defaultValue={rootCauseOptions.length > 0 ? rootCauseOptions[0] : null}
                                render={({ field, value }) => (
                                  <Select 
                                    {...field}
                                    name={field.name}
                                    //defaultValue={'1'}
                                    options={rootCauseOptions}
                                    isClearable={true}
                                    className="js-example-basic-single col-sm-12"
                                    value={value}
                                    onChange={(e) => field.onChange && field.onChange(e ? e.value : null)}
                                  />
                                )}
                              />
                                <span className='d-block' style={{ color: 'red' }}>{errors.root_cause_id && errors.root_cause_id.type === 'required' && 'Root Cause is required'}</span>
                            </Col> */}
                            <Col md='6 mb-3'>
                              <Label className='form-label'>
                                {RefRootCause}
                              </Label>
                              <Controller
                                name="ref_root_cause_id"
                                control={control}
                                render={({ field, value }) => (
                                  <Select 
                                    {...field}
                                    name={field.name}
                                    //options={}
                                    isClearable={true}
                                    className="js-example-basic-single col-sm-12"
                                    value={value}
                                    onChange={(e) => field.onChange && field.onChange(e ? e.value : null)}
                                  />
                                )}
                              />
                              <span className='d-block' style={{ color: 'red' }}>{errors.ref_root_cause_id && 'Reference Root Cause is required'}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md='12 mb-3'>
                              <Label className='form-label'>
                                {Comment}
                              </Label>
                              <textarea ref={messageInputRef} 
                              {...register('message', { required: true })}
                              className='form-control' style={{ height: '6rem' }} />
                              <span className='d-block' style={{ color: 'red' }}>{errors.message && 'Root Cause Comment is required'}</span>
                            </Col>
                          </Row>
                          {
                            canPerformAction &&
                            <div className='text-end'>
                              <Btn attrBtn={{ color: 'primary' }}>
                                {btnLoading ? <Spinner /> : <><i className='fa fa-save'></i> {AddNote}</>}
                              </Btn>
                            </div>
                          }
                          
                        </form>
                      </>
                    )} 
                  </div>
                  
                  <H6 attrH6={{ className: 'pb-2 f-w-600' }}>{CaseNotes}</H6>
                  <hr className="mt-1 mb-4" />
                  <div className='social-chat'>
                    {case_notes && (<CaseNoteList Notes={case_notes}/>)}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default CaseNote;
