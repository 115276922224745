import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { StatusRow } from "./FinanceProcessTypeList";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    CreatedBy,
    Description,
    FinanceProcessType,
    ModifiedBy,
    Status,
    Name,
} from "../../../../../Constant";
import { financesFinanceProcessTypeApi } from "../../../../../api";
import { IFinanceProcessTypeResponse } from "../FinanceProcessType.types";
import { toast } from "react-toastify";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useFinanceProcessTypeListController() {
    const axiosPrivate = useAxiosPrivate();
    const title = FinanceProcessType;
    const { userOrganisationId }: any = useAuth();
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },

        {
            id: "prefix",
            name: "prefix",
            label: "Prefix",
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "backend_label",
            name: "backend_label",
            label: "Backend Label",
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },
    ]);

    const tableColumns = [
        {
            name: Name,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: "Prefix",
            selector: (row: (typeof data)[0]) => `${row.prefix}`,
            sortable: true,
            center: false,
        },

        {
            name: "Backend Label",
            selector: (row: (typeof data)[0]) => `${row.backend_label}`,
            sortable: true,
            center: false,
        },

        {
            name: Description,
            selector: (row: (typeof data)[0]) => `${row.description}`,
            sortable: false,
            center: false,
        },

        {
            name: Status,
            selector: (row: (typeof data)[0]) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },

        {
            name: CreatedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.created_at)}`,
            sortable: false,
            center: false,
        },

        {
            name: ModifiedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.updated_at)}`,
            sortable: false,
            center: false,
        },
    ];

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        (async function () {
            const updatedFields = [...formFields];
            setFormFields(updatedFields);
        })();
    }, []);

    function fetchData() {
        setLoading(true);
        axiosPrivate
            .get(
                `${financesFinanceProcessTypeApi}?with_trashed=${false}&organisation_id=${userOrganisationId}`
            )
            .then((res) => {
                const response: IFinanceProcessTypeResponse[] = res.data.data;
                console.log(response);
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useLayoutEffect(() => {
        fetchData();
    }, []);

    const handleEdit = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(true);
        setSelectedRowData(row);
    };

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/process-type/create/${layoutURL}`;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IFinanceProcessTypeResponse[]>([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
        await axiosPrivate
            .put(`${financesFinanceProcessTypeApi}/${id}`, {
                ...editRecord,
                organisation_id: userOrganisationId,
            })
            .then((_res) => {
                const updatedData = data.map((row) =>
                    row.id === selectedRowData!.id
                        ? { ...row, ..._res.data.data }
                        : row
                );
                setData(updatedData);
                toast.success(`${_res.data.name} Editted Successfully.`);
                //fetchData();
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                throw err;
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        editMode,
        newBtn,
        title,
        formFields,
        loading,
        setViewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    };
}
