import { Fragment, useContext, useState, useLayoutEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CaseApi, CaseAssignedToUri, CaseDelegateToTeamMemberUri, CaseDelegateToTeamUri, CaseDelegationMemberPickupUri, CaseEscalateUri, CaseExternalDelegateToTeamMemberUri, CaseExternalDelegateToTeamUri, CaseExternalDelegationMemberPickupUri, CasePriorityUri, CaseResolutionMemberPickupUri, CaseResolutionResolvedUri, CaseResolutionStatusUri, TeamApi, TeamMemberApi } from '../../../../../api';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import CaseHistory from './Tab/CaseHistory';
import CaseMessage from './Tab/CaseMessage';
import useAuth from '../../../../../Hooks/useAuth';
import CaseInfo from './CaseInfo';
import CaseMoreInfo from './CaseMoreInfo';
import CaseNote from './Tab/CaseNote';
import CaseResolution from './Tab/CaseResolution';
import Spinner from '../../../../Common/Component/Spinner';
import CaseEscalation from './Tab/CaseEscalation';

const CaseDetail = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId, auth0_profile } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const listUrl = `${process.env.PUBLIC_URL}/crm/case-management/case/${layoutURL}`;

  let {track_number} = useParams();

  const { formState: { errors },} = useForm(); 

  const [allData, setAllData] = useState([]);
  const [prioritiesData, setPrioritiesData] = useState([]);
  const [resolutionStatusesData, setResolutionStatusesData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);

  const [teamMembersData, setTeamMembersData] = useState([]);
  const [delegateTeamMembersData, setDelegateTeamMembersData] = useState([]);

  const [externalDelegateTeamsData, setExternalDelegateTeamsData] = useState([]);
  const [externalDelegateTeamMembersData, setExternalDelegateTeamMembersData] = useState([]);

  const [externalFunctionalExecutiveTeamData, setExternalFunctionalExecutiveTeamData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [caseTab, setCaseTab] = useState('case-detail');
  const [canPerformAction, setCanPerformAction] = useState(false);
  
  const getCaseDetails = async () => {
    try {
        await axiosPrivate.get(`${CaseApi}/${track_number}/by-track-number`).then((resp) => {
            //setAllData(resp.data);

            setAllData(prevData => ({
              ...prevData,
              ...resp.data
            }));

            //let delegate_team_id = resp.data?.delegates?.find(delegate => delegate.is_active === true && delegate.is_internal_external_delegation === false)?.team_id
            let external_delegate_team_id = resp.data?.delegates?.find(delegate => delegate.is_active === true && delegate.is_internal_external_delegation === true)?.team_id
            //etTeams(resp.data?.case_type?.service_team?.team_type_id, delegate_team_id)
            getTeams(resp.data?.case_type?.delegate_team_id, external_delegate_team_id)
            getTeamMembers(resp.data?.case_type?.service_team_id)
            
            //setIsLoading(false);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
        //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
  };

  const getPriorityResolutionStatus = async () => {
    try {
        await axiosPrivate.get(`${CaseApi}/priorities-resolution-statuses?organisation_id=${userOrganisationId}`).then((resp) => {
            setPrioritiesData(resp.data.priorities);
            setResolutionStatusesData(resp.data.resolution_statuses);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  const getTeams = async (delegate_team_id, external_delegate_team_id) => {
    try {
      //&team_type_id=${team_type_id}
        //await axiosPrivate.get(`${TeamApi}?organisation_id=${userOrganisationId}`)
        await axiosPrivate.get(`${TeamApi}/${delegate_team_id}`)
          .then((resp) => {
            /* const teamsData = resp.data.map(team => ({
              id: team.id,
              name: team.name
            })); */

            const teamsData = [{
              id: resp.data.id,
              name: resp.data.name
            }];
          
          setTeamsData(teamsData);
          getDelegateTeamMembers(delegate_team_id)
          getExternalDelegationTeams(external_delegate_team_id)
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  const getExternalDelegationTeams = async (external_delegate_team_id) => {
    try {
        await axiosPrivate.get(`${TeamApi}/internal-external-delegation-teams`)
          .then((resp) => {
            const xTeamsData = resp.data.map(team => ({
              id: team.id,
              name: team.name,
              escalation_team_functional: team.escalation_team_functional,
              escalation_team_executive: team.escalation_team_executive
            })); 
          
          setExternalDelegateTeamsData(xTeamsData);
          if(external_delegate_team_id){
            getExternalDelegateTeamMembers(external_delegate_team_id)
          }
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  const getTeamMembers = async (team_id) => {
    try {
        await axiosPrivate.get(`${TeamMemberApi}?organisation_id=${userOrganisationId}&team_id=${team_id}`).then((resp) => {
          const teamMembersData = resp.data.map(teamMember => ({
            id: teamMember.employee_id,
            name: teamMember.contact_name
          }));
          
          setTeamMembersData(teamMembersData);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  const getDelegateTeamMembers = async (team_id) => {
    if (!team_id)
      return

    try {
        await axiosPrivate.get(`${TeamMemberApi}?organisation_id=${userOrganisationId}&team_id=${team_id}`).then((resp) => {
          const teamMembersData = resp.data.map(teamMember => ({
            id: teamMember.employee_id,
            name: teamMember.contact_name
          }));
          
          setDelegateTeamMembersData(teamMembersData);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  const getExternalDelegateTeamMembers = async (team_id) => {
    if (!team_id)
      return

    try {
        await axiosPrivate.get(`${TeamMemberApi}?organisation_id=${userOrganisationId}&team_id=${team_id}`).then((resp) => {
          const teamMembersData = resp.data.map(teamMember => ({
            id: teamMember.employee_id,
            name: teamMember.contact_name
          }));
          
          setExternalDelegateTeamMembersData(teamMembersData);
         });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    const abortController = new AbortController();
    getCaseDetails();
    getPriorityResolutionStatus()
    
    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  //const handlePerformAction = () => {
  useLayoutEffect(() => {
    const abortController = new AbortController();  
    let performAction = false;

    if (allData.resolution_pickup_at && !allData.resolution_delegation_at) {
      performAction = true;
    }
    else if (allData.resolution_delegation_at && allData.delegation_pickup_at && !allData.delegation_delegation_at) {
      performAction = true;
    }
    else if (allData.delegation_delegation_at && allData.external_delegation_pickup_at) {
      performAction = true;
    }

    setCanPerformAction(performAction)
    /* setCanPerformAction(prevData => ({
      ...prevData,
      ...performAction
    })); */
    
    console.log(`called, ${performAction}`);
    //return canPerformAction
    return () => {
      abortController.abort();
    };
  }, [allData]);
  
  //const addBtn = ""; //`${process.env.PUBLIC_URL}/crm/contacts/create/${layoutURL}`;
  
  const handleCaseReply = async (event, message, timeWorked) => {
    event.preventDefault();
    setIsLoading(true);
    //console.log("CaseRepliedData: ", message);
    //console.log("Time: ", timeWorked);
    try {
      const newRecordTemp = {
        //contact_id: CaseRepliedData.contact_id,
        employee_id: 1, //CaseRepliedData.employee_id,
        message: message,
        time_worked: timeWorked,
        //rating: CaseRepliedData.rating,
      };

      await axiosPrivate.post(`${CaseApi}/${allData.id}/reply`, newRecordTemp).then((resp) => {
        history(listUrl)

        toast.success(`Case ${allData.track_number} replied.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404 || error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Reply submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCaseUpdate = async (methodName, payload) => {
    setIsLoading(true);

    try {
      await axiosPrivate.put(`${CaseApi}/${allData.id}/${methodName}`, payload).then((resp) => {
        //history(listUrl)
        if (methodName === CaseResolutionStatusUri) {
          setAllData(prevData => ({
            ...prevData,
            history: resp.data.history,
            resolution_status: resp.data.resolution_status,
            resolution_id: resp.data.resolution_id
          }));

          toast.success(`Case ${allData.track_number} resolution status is successful.`)
        }
        
        if (methodName === CasePriorityUri) {
          setAllData(prevData => ({
            ...prevData,
            history: resp.data.history,
            priority: resp.data.priority,
            priority_id: resp.data.priority_id
          }));

          toast.success(`Case ${allData.track_number} priority is successful.`)
        }

        if (methodName === CaseAssignedToUri) {
          setAllData(prevData => ({
            ...prevData,
            //assigned_by: resp.data.assigned_by,
            history: resp.data.history,
            assigned_at: resp.data.assigned_at,
            assigned_to: resp.data.assigned_to
          }));

          toast.success(`Case ${allData.track_number} is successfully assigned to a team member.`)
        }

        if (methodName === CaseResolutionMemberPickupUri) {
          setAllData(prevData => ({
            ...prevData,
            resolution_pickup_at: resp.data.resolution_pickup_at,
            resolution_delegation_time: resp.data.resolution_delegation_time,
            resolution_escalation_time1: resp.data.resolution_escalation_time1,
            resolution_escalation_time2: resp.data.resolution_escalation_time2,
            resolution_time: resp.data.resolution_time,
            history: resp.data.history,
            assigned_at: resp.data.assigned_at,
            assigned_to: resp.data.assigned_to
          }));

          toast.success(`Case ${allData.track_number} is successfully picked up by you.`)
        }

        if (methodName === CaseDelegateToTeamUri) {
          setAllData(prevData => ({
            ...prevData,
            history: resp.data.history,
            delegates: resp.data.delegates,
            resolution_delegation_at: resp.data.resolution_delegation_at,
            resolution_delegation_breached_at: resp.data.resolution_delegation_breached_at,
          }));
          getDelegateTeamMembers(payload.team_id)

          toast.success(`Case ${allData.track_number} delegation is successful.`)
        }

        if (methodName === CaseDelegateToTeamMemberUri) {
          setAllData(prevData => ({
            ...prevData,
            delegates: resp.data.delegates,
            resolution_delegation_at: resp.data.resolution_delegation_at,
            delegation_pickup_time: resp.data.delegation_pickup_time,
            delegation_pickup_breached_at: resp.data.delegation_pickup_breached_at,
            resolution_pickup_breached_at: resp.data.resolution_pickup_breached_at,
            resolution_delegation_breached_at: resp.data.resolution_delegation_breached_at,
            delegation_resolution_time: resp.data.delegation_resolution_time,
            history: resp.data.history,
          }));

          toast.success(`Case ${allData.track_number} is successfully assigned to a team member.`)
        }

        if(methodName === CaseDelegationMemberPickupUri) {
          setAllData(prevData => ({
            ...prevData,
            delegation_pickup_at: resp.data.delegation_pickup_at,
            delegation_pickup_breached_at: resp.data.delegation_pickup_breached_at,
            delegation_delegation_time: resp.data.delegation_delegation_time,
            delegation_escalation_time1: resp.data.delegation_escalation_time1,
            delegation_escalation_time2: resp.data.delegation_escalation_time2,
            delegation_resolution_time: resp.data.delegation_resolution_time,
            history: resp.data.history,
            //assigned_at: resp.data.assigned_at,
            //assigned_to: resp.data.assigned_to
          }));

          toast.success(`Case ${allData.track_number} is successfully picked up by you.`)
        }

        if (methodName === CaseExternalDelegateToTeamUri) {
          setAllData(prevData => ({
            ...prevData,
            delegation_delegation_at: resp.data.delegation_delegation_at,
            delegation_delegation_breached_at: resp.data.delegation_delegation_breached_at,
            history: resp.data.history,
            delegates: resp.data.delegates,
          }));
          getExternalDelegateTeamMembers(payload.team_id)

          toast.success(`Case ${allData.track_number} external delegation is successful.`)
        }

        if (methodName === CaseExternalDelegateToTeamMemberUri) {
          setAllData(prevData => ({
            ...prevData,
            delegates: resp.data.delegates,
            delegation_delegation_at: resp.data.delegation_delegation_at,
            external_delegation_pickup_time: resp.data.external_delegation_pickup_time,
            external_delegation_pickup_at: resp.data.external_delegation_pickup_at,
            external_delegation_resolution_time: resp.data.external_delegation_resolution_time,
            history: resp.data.history,
          }));

          toast.success(`Case ${allData.track_number} is successfully assigned to a team member.`)
        }

        if(methodName === CaseExternalDelegationMemberPickupUri) {
          setAllData(prevData => ({
            ...prevData,
            external_delegation_pickup_at: resp.data.external_delegation_pickup_at,
            external_delegation_pickup_breached_at: resp.data.external_delegation_pickup_breached_at,
            external_delegation_escalation_time1: resp.data.external_delegation_escalation_time1,
            external_delegation_escalation_time2: resp.data.external_delegation_escalation_time2,
            external_delegation_resolution_time: resp.data.external_delegation_resolution_time,
            history: resp.data.history,
            //assigned_at: resp.data.assigned_at,
            //assigned_to: resp.data.assigned_to
          }));

          toast.success(`Case ${allData.track_number} is successfully picked up by you.`)
        }

        
      });
    } catch (error) {
        if (error.response && error.response.status === 404 || error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Case update error');
        }
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCaseNoteSubmit = async (data) => {
    // event.preventDefault();
    setIsLoading(true);

    try {
      const newCaseNoteTemp = {
        case_id: allData.id,
        employee_id: auth0_profile.contact.id,// remember to chnage to employee //CaseRepliedData.employee_id,
        message: data.message,
        root_cause_id: data.root_cause_id,
        //ref_root_cause_id: data.ref_root_cause_id,
      };

      await axiosPrivate.post(`${CaseApi}/${allData.id}/note`, newCaseNoteTemp).then((resp) => {
        //history(listUrl)
        allData.case_notes.unshift(resp.data);
        toast.success(`Case Note added to ${allData.track_number}.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404 || error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Case Note submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCaseResolutionSubmit = async(data) => {
    
    setIsLoading(true);

    try {
      const newRecTemp = {
        case_id: allData.id,
        employee_id: auth0_profile.contact.id,// remember to chnage to employee //CaseRepliedData.employee_id,
        comment: data.message,
        resolution_code_id: data.resolution_code_id,
      };

      await axiosPrivate.put(`${CaseApi}/${allData.id}/${CaseResolutionResolvedUri}`, newRecTemp).then((resp) => {
        history(listUrl)

        toast.success(`Case ${allData.track_number} resolution has been updated.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404 || error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Case Note submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCaseEscalationSubmit = async(data) => {
    setIsLoading(true);
    //console.log(data)
    
    try {
      const newRecTemp = {
        case_id: allData.id,
        team_id: data.team_id,
        employee_id: data.employee_id,
        priority_id: allData.priority_id,
        resolution_status_id: allData.resolution_status_id,
        escalation_category_id: data.escalation_category_id,
        escalation_reason_id: data.escalation_reason_id,
        message: data.message,
      };

      await axiosPrivate.put(`${CaseApi}/${allData.id}/${CaseEscalateUri}`, newRecTemp).then((resp) => {
        //history(listUrl)

        toast.success(`Case ${allData.track_number} has been escalated.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404 || error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Case Escalation submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="Case Details" mainTitle="Case Details" />
      <Container fluid={true}>
        <Row className='learning-block user-profile social-app-profile'>
          {/* { isLoading ? <Spinner /> :
          (

          <> */}
          <Col xl='8' className='xl-60 box-col-8 order-2 order-xl-1'>
            <Row> 
              <Nav className='border-tab nav-secondary nav-pills overflow-x' tabs>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-detail' ? 'active' : ''} onClick={() => setCaseTab('case-detail')}>
                    <i className='icofont icofont-book-alt'></i>
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-note' ? 'active' : ''} onClick={() => setCaseTab('case-note')}>
                    <i className='icofont icofont-notebook'></i>
                    Note
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-resolution' ? 'active' : ''} onClick={() => setCaseTab('case-resolution')}>
                    <i className='icofont icofont-files'></i>
                    Resolution
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-escalation' ? 'active' : ''} onClick={() => setCaseTab('case-escalation')}>
                    <i className='icofont icofont-alarm'></i>
                    Escalation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-history' ? 'active' : ''} onClick={() => setCaseTab('case-history')}>
                    <i className='icofont icofont-book'></i>
                    History
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={caseTab}>
                {allData && (
                  <>
                    <CaseMessage 
                      Details={allData} 
                      onCaseReply={handleCaseReply} 
                      canPerformAction={canPerformAction}
                    />

                    <CaseNote 
                      Details={allData} 
                      onCaseNoteSubmit={handleCaseNoteSubmit} 
                      canPerformAction={canPerformAction}
                    />

                    <CaseResolution 
                      Details={allData} 
                      onCaseResolutionSubmit={handleCaseResolutionSubmit} 
                      canPerformAction={canPerformAction}
                    />

                    <CaseEscalation
                      Details={allData} 
                      externalDelegateTeams={externalDelegateTeamsData}
                      onCaseEscalationSubmit={handleCaseEscalationSubmit} 
                      canPerformAction={canPerformAction}
                    />

                    <CaseHistory history={allData.history} />
                  </>
                )}
              </TabContent>
            </Row>
          </Col>

          <Col xl='4' className='xl-40 box-col-12 learning-filter order-1 order-xl-2'>
            <div className='md-sidebar'>
              <Btn attrBtn={{ onClick: (e) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>
                <i className="fa fa-folder-open-o"></i>&nbsp;
                Case Details
              </Btn>
              <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'} `}>
                <div className='default-according style-1 faq-accordion job-accordion' id='accordionoc1'>
                  <Row>
                    <CaseInfo details={allData} 
                      onCaseUpdate={handleCaseUpdate} />

                    <CaseMoreInfo 
                      details={allData}
                      priorities={prioritiesData} 
                      resolutionStatuses={resolutionStatusesData}
                      teams={teamsData}
                      teamMembers={teamMembersData}
                      delegateTeamMembers={delegateTeamMembersData}
                      externalDelegateTeams={externalDelegateTeamsData}
                      externalDelegateTeamMembers={externalDelegateTeamMembersData}
                      onCaseUpdate={handleCaseUpdate}
                     />
                    {/* <CaseHistory histories={undefined} /> */}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          {/* </>
          )

          } */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default CaseDetail;