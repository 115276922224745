import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { StatusRow } from "./GeneralLedgerTypeList";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    Code,
    CreatedBy,
    Description,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    GeneralLedgerType,
    ModifiedBy,
    Name,
    Status,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    financesGeneralLedgerClassApi,
    financesGeneralLedgerGroupApi,
    financesGeneralLedgerTypeApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { IGeneralLedgerTypeResponse } from "../GeneralLedgerType.types";
import useAuth from "../../../../../Hooks/useAuth";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { toast } from "react-toastify";

export default function useGeneralLedgerTypeListController() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const title = GeneralLedgerType;
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //?? DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, updatedFields) => {
        // await generalLedgerClassDropDown.fetchOptions();
        //console.log(updatedFields);
        const nextField = updatedFields.find(
            (field: any) => field.id === "gl_class_id"
        );
        if (nextField) {
            const endPoint = `${financesGeneralLedgerClassApi}?organisation_id=${userOrganisationId}&gl_group_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_group_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const generalLedgerClassDropDown = createDropdownField(
        axiosPrivate,
        "gl_class_id",
        "gl_class_id",
        GeneralLedgerClass,
        `${financesGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerClassDropDown.onChange = async (id, updatedFields) => {
        // await generalLedgerTypeDropDown.fetchOptions();
        const updatedFormFields = updatedFields.map((field: any) => {
            if (field.id === "gl_class_id") {
                return { ...field, value: id };
            }
            return field;
        });
        setFormFields([...updatedFormFields]);
    };

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        (async function () {
            const updatedFields = [
                generalLedgerGroupDropDown,
                generalLedgerClassDropDown,
                ...formFields,
            ];
            await generalLedgerGroupDropDown.fetchOptions();
            setFormFields(updatedFields);
        })();
    }, []);

    function fetchData() {
        setLoading(true);
        axiosPrivate
            .get(
                `${financesGeneralLedgerTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
            )
            .then((res) => {
                const response: IGeneralLedgerTypeResponse[] = res.data;
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useLayoutEffect(() => {
        fetchData();
    }, []);

    const handleEdit = (row: (typeof data)[0] | any) => {
        setViewModalOpen(true);
        setEditMode(true);

        row.gl_class_id = row.gl_class.id;
        generalLedgerClassDropDown.onChange(row.gl_class_id, formFields);

        row.gl_group_id = row.gl_class.gl_group.id;
        generalLedgerGroupDropDown.onChange(row.gl_group_id, formFields);

        setSelectedRowData(row);
    };

    const tableColumns = [
        {
            name: Name,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Code,
            selector: (row: (typeof data)[0]) => `${row.code}`,
            sortable: false,
            center: false,
        },

        {
            name: Description,
            selector: (row: (typeof data)[0]) => `${row.description}`,
            sortable: false,
            center: false,
        },

        {
            name: Status,
            selector: (row: (typeof data)[0]) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },
        {
            name: CreatedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.created_at)}`,
            sortable: false,
            center: false,
        },

        {
            name: ModifiedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.updated_at)}`,
            sortable: false,
            center: false,
        },
    ];

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/general-ledger-type/create/${layoutURL}`;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IGeneralLedgerTypeResponse[]>([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
        //console.log(id, editRecord, "");

        await axiosPrivate
            .put(`${financesGeneralLedgerTypeApi}/${id}`, {
                ...editRecord,
                organisation_id: userOrganisationId,
            })
            .then((_res) => {
                const updatedData = data.map((row) =>
                    row.id === selectedRowData!.id
                        ? { ...row, ..._res.data }
                        : row
                );
                setData(updatedData);
                toast.success(`${_res.data.name} Editted Successfully.`);
                //fetchData();
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                throw err;
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        editMode,
        newBtn,
        title,
        formFields,
        setViewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    };
}
