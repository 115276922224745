import { useLayoutEffect, useState } from "react";
import {
    Team,
    TeamMember,
    Name,
    TeamType,
    ContactNumber,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";
import {
    TeamApi,
    TeamTypeApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useInternalTeamAssign() {
    const axiosPrivate = useAxiosPrivate()
    const title = TeamMember;
    const { userOrganisationId }: any = useAuth();
    const [TeamId, setTeamId] = useState(0);
    const [available, setAvailable] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [formFields, setFormFields] = useState<any[]>([]);

    //?? DROPDOWNS INITS >
    const TeamTypeDropDown = createDropdownField(axiosPrivate,
        'team_type_id',
        'team_type_id',
        `${TeamType}`,
        `${TeamTypeApi}?organisation_id=${userOrganisationId}`,
        '', 6
      );

    TeamTypeDropDown.onChange = async (id, updatedFields) => {
        setTeamId(0);
        setAssigned([]);
        setAvailable([]);
        const TeamDropDown = updatedFields.find(
            (field: any) => field.id === "team_id"
        );
        if (TeamDropDown) {
            const endPoint = `${TeamApi}?organisation_id=${userOrganisationId}&team_type_id=${id}&is_external=false`;
            await TeamDropDown.fetchOptions(id, false, endPoint);
            setFormFields([...updatedFields]);
        }
    };

    const TeamDropDown = createDropdownField(axiosPrivate,
        "team_id",
        "team_id",
        Team,
        "",
        "",
        6
    );

    TeamDropDown.onChange = async (id, updatedFields) => {
        try {
            setTeamId(id);
            const endPoint = `${TeamApi}/${id}/members?organisation_id=${userOrganisationId}`;
            axiosPrivate
                .get(endPoint)
                .then((res) => {
                    //console.log(res.data);
                    setAssigned([]);
                    setAvailable([]);

                    if (res.data) {
                        setAssigned(res.data.assigned_items);
                        setAvailable(res.data.available_items);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                    toast.error("Error: Please Select All Fields");
                    setAssigned([]);
                    setAvailable([]);
                });
        } catch (error) {
            //console.log("CAUGHT ERROR:", error);
        }
    };

    const INTERNALTEAMTAB = 'internal-team';
    const EXTERNALTEAMTAB = 'external-team';

    const [commissionTab, setCommissionTab] = useState<string>(INTERNALTEAMTAB); 

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
        //navigate(`/administration/vendor/${routeName}/${layoutURL}`); // Append to route
    };

    const tableColumns = [
        {
            name: ContactNumber,
            selector: (row: any) => `${row.contact_number}`,
            sortable: true,
            center: false,
        },

        {
            name: Name,
            selector: (row: any) => `${row.last_name} ${row.first_name} ${row.middle_name}`,
            sortable: true,
            center: false,
        },

        /* {
            name: Status,
            selector: (row: any) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: any) => StatusRow(row),
        }, */
    ];

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [...formFields, TeamTypeDropDown, TeamDropDown];
        await TeamTypeDropDown.fetchOptions();
        setFormFields(updatedFields);
       // console.log(updatedFields);
    }
    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const assignTask = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No members selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `/hrm/teams/${TeamId}/assign-members?organisation_id=${userOrganisationId}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Members assigned successfully");
                //console.log(res.data);
            });
    };

    const usassignTask = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No member selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `/hrm/teams/${TeamId}/remove-members?organisation_id=${userOrganisationId}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Members unassigned successfully");
            });
    };

    return {
        title,
        formFields,
        tableColumns,
        available,
        assigned,
        assignTask,
        usassignTask,
        commissionTab,
        INTERNALTEAMTAB,
        EXTERNALTEAMTAB,
        handleTabClick,
    };
}
