import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent  } from 'reactstrap';
import {Breadcrumbs} from '../../../../AbstractElements';
import {GradeGroup, Hrm, Grade, Step, GradeStep} from '../../../../Constant';
import {Link} from "react-router-dom";
import {PlusCircle} from "react-feather";
import HrmGradeGroupList from "./GradeGroup/List/HrmGradeGroupList";
import HrmGradeList from "./Grade/List/HrmGradeList";
import StepList from "./Step/List/StepList";
import GradeStepForm from './GradeStep/GradeStepForm';
import useGradeGroupSettings from './controller';

const GradeGroupSettings = () => {
    const {
        layoutURL,
        GRADE_GROUP,
        GRADE,
        STEP,
        GRADE_STEP,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    } = useGradeGroupSettings();
    
    return (
        <Fragment>
            <Breadcrumbs parent={Hrm} title={tabDisplayNames[commissionTab]} mainTitle={tabDisplayNames[commissionTab]}/>
            <Container fluid={true}>
                <Row>
                    <Col sm='12' xl='12' className='xl-100'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        {/*<H5>{GradeGroup}</H5>*/}
                                    </Col>
                                    <Col md="6">
                                        <div className="text-end file-content">
                                        {commissionTab !== `${GRADE_STEP}` && (
                                            <Link className="btn btn-primary ms-2" style={{color: 'white'}} to={`/hrm/settings/${commissionTab}/create/${layoutURL}`}>
                                                <PlusCircle/> New {tabDisplayNames[commissionTab] || 'Item'}
                                            </Link>
                                        )}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={'12'}>
                                        <Nav className='border-tab nav-secondary nav-pills' tabs>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${GRADE_GROUP}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${GRADE_GROUP}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {GradeGroup}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${GRADE}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${GRADE}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {Grade}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${STEP}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${STEP}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {Step}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${GRADE_STEP}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${GRADE_STEP}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {GradeStep}
                                                </NavLink>
                                            </NavItem>
                                        </Nav> 
                                        <TabContent activeTab={commissionTab}>
                                            {commissionTab === `${GRADE_GROUP}` && <HrmGradeGroupList name={GRADE_GROUP} />}
                                            {commissionTab === `${GRADE}` && <HrmGradeList name={GRADE} />}
                                            {commissionTab === `${STEP}` && <StepList name={STEP} />}
                                            {commissionTab === `${GRADE_STEP}` && <GradeStepForm name={GRADE_STEP} />}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default  GradeGroupSettings