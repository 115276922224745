import React, { useEffect } from "react";
import { Input } from "reactstrap";

export type TYearInputsFigures = {
    year: string;
    date: {
        month: string;
        amount: string;
    }[];
    total?: string;
};

export type TYearInputs = {
    figures: TYearInputsFigures[];
    setFigures: React.Dispatch<React.SetStateAction<TYearInputsFigures[]>>;
};

export default function YearInputs({ figures, setFigures }: TYearInputs) {
    // Handle input change
    const handleAmountChange = (
        yearIndex: number,
        monthIndex: number,
        value: string
    ) => {
        const newFigures = [...figures];
        newFigures[yearIndex].date[monthIndex].amount = value;
        setFigures(newFigures); // Update the state with new values
        const updatedFigures = figures.map((yearData) => ({
            ...yearData,
            total: calculateTotal(yearData.date),
        }));
        setFigures(updatedFigures); // Update the figures state with totals
    };

    // ??
    const calculateTotal = (dates: any) => {
        return dates
            .reduce((total: string, date: any) => {
                return total + parseFloat(date.amount.replace(/,/g, ""));
            }, 0)
            .toLocaleString();
    };

    // ??
    useEffect(() => {
        const updatedFigures = figures.map((yearData) => ({
            ...yearData,
            total: calculateTotal(yearData.date),
        }));
        setFigures(updatedFigures);
    }, []);

    return (
        <div>
            {figures.map((yearData, yearIndex) => (
                <div key={yearIndex}>
                    <div className="d-flex justify-content-between gap-4 my-4">
                        <h6>{yearData.year}</h6>
                        <div className="w-100 gap-4 d-flex">
                            <Input
                                type="text"
                                className="form-control w-25 align-self-baseline"
                                placeholder="134,343"
                                value={yearData.total || "0"}
                            />
                            <div className="w-100 ">
                                <div className="d-flex flex-wrap gap-3">
                                    {yearData.date.map(
                                        (monthData, monthIndex) => (
                                            <span
                                                key={monthIndex}
                                                className="d-flex justify-content-between gap-5 rounded-2 bg-light text-dark align-items-center px-2 py-1 border"
                                            >
                                                <small>{monthData.month}</small>
                                                <small
                                                    contentEditable
                                                    className="rounded-5 px-2 py-1 bg-dark text-light"
                                                    onBlur={(e) =>
                                                        handleAmountChange(
                                                            yearIndex,
                                                            monthIndex,
                                                            e.target.innerText
                                                        )
                                                    }
                                                >
                                                    {monthData.amount}
                                                </small>
                                            </span>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            ))}
        </div>
    );
}
