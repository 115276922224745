import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../../_helper/Customizer";
import {
  Description,
  Status,
  Name,
  Alias,
  InventoryClass,
  InventoryGroup,
} from "../../../../../../Constant";

import { toast } from "react-toastify";
import useAuth from "../../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../../Hooks/useAxiosPrivate";
import { InventoryClassApi, InventoryGroupApi } from "../../../../../../api";
import { createDropdownField } from "../../../../../../Data/FormField/PublicData";

export default function useInventoryClassForm() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = InventoryClass;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const InventoryGroupDropDown = createDropdownField(
      axiosPrivate,
      "administration_inventory_group_id",
      "administration_inventory_group_id",
      InventoryGroup,
      `${InventoryGroupApi}?organisation_id=${userOrganisationId}`,
      "",
      6
  );
  
  async function updateFields() {
      const updatedFields = [InventoryGroupDropDown, ...formFields];
      await InventoryGroupDropDown.fetchOptions();
      setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/administration/settings/inventory/classes/${layoutURL}`;

  const onSubmit = async (data: any) => {
    data.organisation_id = userOrganisationId;
    // console.log(data);
    await axiosPrivate
      .post(InventoryClassApi, data)
      .then((_res) => {
        toast.success(`${_res.data.data.name} Created Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  return { formTitle, formFields, backBtn, onSubmit };
}
