import { Fragment } from 'react';
import { Table, TabPane  } from 'reactstrap';
import { Code, DateCreated, DateModified, Description, FixedAssetClass, FixedAssetGroup, FixedAssetType, Name, Status } from '../../../../../../Constant';
import DataTableComponent from "../../../../../Common/Component/DataTableComponent";
import useFixedAssetDescription from "./controller";
import ViewModal from '../../../../../Common/Component/ViewModal';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';

const FixedAssetDescriptionList = (name: any) => {
    const {
        data,
        tableColumns,
        handleView,
        handleEdit,
        viewModalOpen,
        selectedRowData,
        setViewModalOpen,
        formFields,
        memoizedHandleEdit,
        editMode,
        title,
        loading,
    } = useFixedAssetDescription();

    return (
        <Fragment>
            <TabPane className='fade show' tabId={name.name}>
                <DataTableComponent
                    tabledata={data}
                    tableColumns={tableColumns}
                    isLoading={loading}
                    onView={handleView}
                    // onDelete={handleDelete}
                    onEdit={handleEdit}
                    useTableAction={undefined}
                    addToDefaultAction={undefined}
                    onDelete={undefined}
                />

                <ViewModal
                    isOpen={viewModalOpen}
                    toggle={() => setViewModalOpen(!viewModalOpen)}
                    rowData={selectedRowData}
                    formFields={formFields}
                    editMode={editMode}
                    onEdit={memoizedHandleEdit}
                    onSubmit={undefined}
                    modalTitle={undefined}
                >
                    {selectedRowData && (
                    <>
                        <Table>
                        <tbody>
                            <tr className="border-bottom-primary">
                                <th scope="row">{Name}</th>
                                <td>{selectedRowData.name}</td>
                            </tr>

                            <tr className="border-bottom-primary">
                                <th scope="row">{Code}</th>
                                <td>{selectedRowData.code}</td>
                            </tr>

                            <tr className="border-bottom-primary">
                                <th scope="row">{FixedAssetType}</th>
                                <td>{selectedRowData.administration_fixed_asset_type.name}</td>
                            </tr>

                            <tr className="border-bottom-primary">
                                <th scope="row">{FixedAssetClass}</th>
                                <td>{selectedRowData.administration_fixed_asset_type.administration_fixed_asset_class.name}</td>
                            </tr>

                            <tr className="border-bottom-primary">
                                <th scope="row">{FixedAssetGroup}</th>
                                <td>{selectedRowData.administration_fixed_asset_type.administration_fixed_asset_class.administration_fixed_asset_group.name}</td>
                            </tr>

                            <tr className="border-bottom-primary">
                            <th scope="row">{Description}</th>
                            <td>{selectedRowData.description}</td>
                            </tr>

                            <tr className="border-bottom-primary">
                            <th scope="row">{Status}</th>
                            <td>
                                {selectedRowData.is_active ? (
                                <span className="badge badge-light-success">
                                    Active
                                </span>
                                ) : (
                                <span className="badge badge-light-danger">
                                    Inactive
                                </span>
                                )}
                            </td>
                            </tr>
                            <tr className="border-bottom-primary">
                            <th scope="row">{DateCreated}</th>
                            <td>
                                {formatDateWithTimeZone(selectedRowData.created_at)}
                            </td>
                            </tr>

                            <tr className="border-bottom-primary">
                            <th scope="row">{DateModified}</th>
                            <td>
                                {formatDateWithTimeZone(selectedRowData.updated_at)}
                            </td>
                            </tr>
                        </tbody>
                        </Table>
                    </>
                    )}
                </ViewModal>
            </TabPane>
        </Fragment>
    );
}

export default  FixedAssetDescriptionList