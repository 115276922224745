import TaskAssignerTwinTables from "../../../../Common/TwinTables/TwinTable";
import useExternalTeamAssign from "./ExternalTeamAssign.controller";
import GenerateForm from "../../../../Common/Component/GenerateForm";
import { Fragment } from "react";

export default function ExternalTeamAssign() {
    const {
        title,
        formFields,
        tableColumns,
        available,
        assigned,
        assignTask,
        usassignTask,
    } = useExternalTeamAssign();

    return (
        <Fragment>
            <div className="filter-head-pane">
                <GenerateForm
                    //onSubmit={(e: any) => {}}
                    onSubmit={undefined}
                    formFields={formFields}
                    formTitle={title}
                    listUrl={undefined}
                    onEdit={undefined}
                    editRecord={undefined}
                />
            </div>

            <br />

            <TaskAssignerTwinTables
                rightTableTitle={"Assign Vendor Members"}
                rightTable={assigned}
                rightTableColumns={tableColumns}
                //rightButtonClick={(e: any) => assignTask(e)}
                rightButtonTitle={"Assign"}
                //
                leftTableTitle={"Un Assign Vendor Members"}
                leftTable={available}
                leftTableColumns={tableColumns}
                //leftButtonClick={(e: any) => usassignTask(e)}
                leftButtonTitle={"Un Assign"}
            />
        </Fragment>
    );
}
