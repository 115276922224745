import { useContext, useLayoutEffect, useMemo, useState } from "react";
import useAuth from "../../../../../Hooks/useAuth";
import CustomizerContext from "../../../../../_helper/Customizer";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

import { Data } from "../types";
import { toast } from "react-toastify";
import { StatusRow } from "../../ReportType/List/ReportTypeList";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { financesBudgetCyclesApi } from "../../../../../api";
import {
  Name,
  Status,
  Description,
  ModifiedBy,
  CreatedBy,
  FinanceBudgetCycles,
  Code,
} from "../../../../../Constant";

export default function useFinanceBudgetCycle() {
  const { userOrganisationId }: any = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const title = FinanceBudgetCycles;
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
    {
      id: "code",
      name: "code",
      label: Code,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
  ]);

  const tableColumns = [
    {
      name: Name,
      selector: (row: (typeof data)[0]) => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: Description,
      selector: (row: (typeof data)[0]) => `${row.description}`,
      sortable: true,
      center: false,
    },

    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    {
      name: CreatedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: ModifiedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const [data, setData] = useState<Data[]>([]);

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `${process.env.PUBLIC_URL}/finance/budgets/cycles/create/${layoutURL}`;
  const [loading, setLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axiosPrivate
      .get(
        `${financesBudgetCyclesApi}?with_trashed=${false}&organisation_id=${userOrganisationId}`
      )
      .then((res) => {
        const response: Data[] = res.data.data;
        setData(response);

        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useLayoutEffect(() => {
    fetchData();
  }, []);

  //
  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0] | any
  ) => {
    await axiosPrivate
      .put(`${financesBudgetCyclesApi}/${id}`, {
        ...editRecord,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.data.name} Editted Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        // throw err;
        toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };
  const handleEdit = (row: (typeof data)[0] | any) => {
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };
  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  return {
    handleView,
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    loading,
    setViewModalOpen,
    handleEdit,
    memoizedHandleEdit,
  };
}
