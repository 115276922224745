import { useContext, useEffect, useState } from "react";
import { 
    InputProviderFormSchema, 
    IYearInputsFigures 
} from "../../../Template/types";
import {
    financesFiscalYearApi,
    financesBudgetCategoriesApi,
    financeBudgetsPurposeApi,
    financesExpenseGroupApi,
    financesIncomeGroupApi,
    financesExpenseClassApi,
    financesIncomeClassApi,
    financesExpenseTypeApi,
    financesIncomeTypeApi,
    financesExpenseDescriptionApi,
    financesIncomeDescriptionApi,
    financeBudgetApi,
    financesBudgetDecisionApi,
    DepartmentApi,
    DivisionApi,
    UnitApi,
    CostCentreApi,
    financesFiscalYearCurrentApi,
    InventoryClassApi,
    FixedAssetClassApi,
    InventoryGroupApi,
    FixedAssetGroupApi,
    InventoryTypeApi,
    FixedAssetTypeApi,
    InventoryDescriptionApi,
    FixedAssetDescriptionApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { IFiscalYearResponse } from "../../../Settings/FiscalYear/FiscalYear.types";
import { IFinanceBudgetDecisionResponse } from "../../../Settings/FinanceBudgetDecision/FinanceBudgetDecision.types";
import { FinanceBudgetPurposeTypes } from "../../../Settings/FinanceBudgetPurpose/types";
import { IExpenseGroupResponse } from "../../../Settings/ExpenseGroup/ExpenseGroup.types";
import { IIncomeGroupResponse } from "../../../Settings/IncomeGroup/IIncomeGroup.types";
import { FinanceBudgetInputFormDataPayload } from "../FinanceBudgetInputFormData.types";
import { toast } from "react-toastify";
import { createYearFigures } from "../../../Template/YearInputs";
import { BudgetCategory, BudgetDecision, BudgetPurpose, FiscalYear } from "../../../../../Constant";
import CustomizerContext from "../../../../../_helper/Customizer";
import { validateInputs } from "../../../Template/InputProvider";
import { InventoryGroupTypes } from "../../../../Administration/Settings/InventorySettings/Group/types";
import { FixedAssetGroupTypes } from "../../../../Administration/Settings/FixedAssetSettings/Group/types";
import { InventoryClassTypes } from "../../../../Administration/Settings/InventorySettings/Class/types";
import { FixedAssetClassTypes } from "../../../../Administration/Settings/FixedAssetSettings/Class/types";
import { ExpenseClassResponse } from "../../../Settings/ExpenseClass/ExpenseClass.types";
import { IncomeClassType } from "../../../Settings/IncomeClass/IncomeClass.types";
import { InventoryDescriptionTypes, InventoryTypeTypes } from "../../../../Administration/Settings/InventorySettings/Description/types";
import { FixedAssetDescriptionTypes, FixedAssetTypeTypes } from "../../../../Administration/Settings/FixedAssetSettings/Description/types";
import { ExpenseTypeResponse } from "../../../Settings/ExpenseType/ExpenseType.types";


export default function useFinanceBudgetInputFormData() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/budgets/${layoutURL}`;
    const monthMapping: { [key: string]: number } = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
    };
    const months = Object.keys(monthMapping);

    const [inputErrors, setInputErrors] = useState<{
        financeFormErrors: { [key: string]: string };
        expenseIncomeFormErrors: { [key: string]: string };
        costCenterFormErrors: { [key: string]: string };
        monthAmountFormErrors: { [key: string]: string };
      }>({
        financeFormErrors: {},
        expenseIncomeFormErrors: {},
        costCenterFormErrors: {},
        monthAmountFormErrors: {},
    });

    const [financialFYStartEndDate, setFinancialFYStartEndDate] = useState<[string, string]>(['', '']);
    
    /* const getFYStartYear = () => {
        return financialFYStartEndDate[0] ? new Date(financialFYStartEndDate[0]).getFullYear() : '';
    };
    
    const getFYEndYear = () => {
        return financialFYStartEndDate[1] ? new Date(financialFYStartEndDate[1]).getFullYear() : '';
    }; */

    //?? --- FORM SCHEMA'S ---- ??//
    //?? --- FORM SCHEMA'S ---- ??//
    const [financeFormSchema, setFinanceFormSchema] = useState<
        InputProviderFormSchema[]
    >([]);
    const [monthAmountFormSchema, setMonthAmountFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "justification",
            label: "Justification",
            type: "textarea",
            required: true,
        },

        /* {
            name: "period_month",
            label: "Period month",
            type: "number",
            type: "select",
            options: [
                { label: "January", value: 1 },
                { label: "February", value: 2 },
                { label: "March", value: 3 },
                { label: "April", value: 4 },
                { label: "May", value: 5 },
                { label: "June", value: 6 },
                { label: "July", value: 7 },
                { label: "August", value: 8 },
                { label: "September", value: 9 },
                { label: "October", value: 10 },
                { label: "November", value: 11 },
                { label: "December", value: 12 },
            ], 
            required: true,
        },*/

        {
            name: "start_month",
            label: "Start Month",
            type: "select",
            options: [
                /* { label: "January", value: 1 },
                { label: "February", value: 2 },
                { label: "March", value: 3 },
                { label: "April", value: 4 },
                { label: "May", value: 5 },
                { label: "June", value: 6 },
                { label: "July", value: 7 },
                { label: "August", value: 8 },
                { label: "September", value: 9 },
                { label: "October", value: 10 },
                { label: "November", value: 11 },
                { label: "December", value: 12 }, */
            ],
            required: true,
        },

        {
            name: "end_month",
            label: "End Month",
            type: "select",
            options: [
                /* { label: "January", value: 1 },
                { label: "February", value: 2 },
                { label: "March", value: 3 },
                { label: "April", value: 4 },
                { label: "May", value: 5 },
                { label: "June", value: 6 },
                { label: "July", value: 7 },
                { label: "August", value: 8 },
                { label: "September", value: 9 },
                { label: "October", value: 10 },
                { label: "November", value: 11 },
                { label: "December", value: 12 }, */
            ],
            required: true,
        },

        {
            name: "total_amount",
            label: "Total Amount",
            type: "number",
            required: true,
        },

        /* {
            name: "accrued_amount",
            label: "Accrued Amount",
            type: "number",
            required: true,
        },

        {
            name: "remaining_amount",
            label: "Remaining Amount",
            type: "number",
            required: true,
        }, */
    ]);
    const [expenseIncomeFormSchema, setExpenseIncomeFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "item_description_type",
            label: "Process Type",
            type: "radio",
            options: [
                { label: "Expense", value: "expense" },
                { label: "Income", value: "income" },
                { label: "Inventory", value: "inventory" },
                { label: "CapEx", value: "capex" },
            ],
            required: true,
        },
        {
            name: "item_group",
            label: "Item Group",
            type: "select",
            required: true,
        },
        {
            name: "item_class",
            label: "Item Class",
            type: "select",
            required: true,
        },
        {
            name: "item_type",
            label: "Item Type",
            type: "select",
            required: true,
        },
        {
            name: "item_description",
            label: "Item Description",
            type: "select",
            required: true,
        },
    ]);
    const [costCenterFormSchema, setCostCenterFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "division",
            label: "Division",
            type: "select",
            required: true,
        },
        {
            name: "department",
            label: "Department",
            type: "select",
            required: true,
        },
        {
            name: "unit",
            label: "Unit",
            type: "select",
            required: true,
        },
        {
            name: "cost_centre_id",
            label: "Cost Centre",
            type: "select",
            required: true,
        },
    ]);

    //?? --- INPUT DATA'S ---- ??//
    //?? --- INPUT DATA'S ---- ??//
    const [financeInputData, setFinanceInputData] = useState<{
        [key: string]: any;
    }>({});
    const [monthAmountInputData, setMonthAmountInputData] = useState<{
        [key: string]: any;
    }>({});
    const [expenseIncomeInputData, setExpenseIncomeInputData] = useState<{
        [key: string]: any;
    }>({});
    const [costCenterInputData, setCostCenterInputData] = useState<{
        [key: string]: any;
    }>({});

    //?? --- ON CHANGE HANDLER'S ---- ??//
    //?? --- ON CHANGE HANDLER'S ---- ??//
    const handleFinanceChange = (name: string, value: any) => {
        setFinanceInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        
        name === "finance_fiscal_year_id" && updateMonthOptions(value)
    };
    const handleMonthAmountChange = (name: string, value: any) => {
        setMonthAmountInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleExpenseIncomeChange = (name: string, value: any) => {
        setExpenseIncomeInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        
        name === "item_description_type" && fetchExpenseIncomeData(value);
        name === "item_group" &&
            fetchItemClassData(
                expenseIncomeInputData.item_description_type,
                value
            );
        name === "item_class" &&
            fetchItemTypeData(
                expenseIncomeInputData.item_description_type,
                value
            );
        name === "item_type" &&
            fetchItemDescriptionData(
                expenseIncomeInputData.item_description_type,
                value
            );
    };
    const handleCostCenterChange = (name: string, value: any) => {
        setCostCenterInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        name === "division" && fetchDepartment(value);
        name === "department" && fetchUnit(value);
        name === "unit" && fetchCostCenter(value);
    };

    const [yearFigures, setYearFigures] = useState<IYearInputsFigures[]>([
       /*  {
            year: "2024",
            total: "",
            date: [
                { month: "June", amount: "0.00" },
                { month: "July", amount: "0.00" },
                { month: "August", amount: "0.00" },
                { month: "September", amount: "0.00" },
                { month: "October", amount: "0.00" },
                { month: "November", amount: "0.00" },
                { month: "December", amount: "0.00" },
            ],
        },
        {
            year: "2025",
            total: "",
            date: [
                { month: "January", amount: "0.00" },
                { month: "February", amount: "0.00" },
                { month: "March", amount: "0.00" },
                { month: "April", amount: "0.00" },
                { month: "May", amount: "0.00" },
            ],
        }, */
    ]);

    const fetchFinanceData = async () => {
        try {
            // Define your API URLs
            //const financeFiscalYearUrl = `${financesFiscalYearApi}?with_trashed=false&organisation_id=${userOrganisationId}`;
            const financeFiscalYearUrl = `${financesFiscalYearCurrentApi}`;
            const financesBudgetCategoriesUrl = `${financesBudgetCategoriesApi}?with_trashed=false&organisation_id=${userOrganisationId}`;
            const financeBudgetsPurposeUrl = `${financeBudgetsPurposeApi}?with_trashed=false&organisation_id=${userOrganisationId}`;
            const financeBudgetDecisionUrl = `${financesBudgetDecisionApi}?with_trashed=false&organisation_id=${userOrganisationId}`;

            // Use Promise.all to fetch both APIs concurrently
            const [
                financeRes,
                budgetCategoriesRes,
                budgetPurposeRes,
                budgetDecisionRes,
            ] = await Promise.all([
                axiosPrivate.get(financeFiscalYearUrl),
                axiosPrivate.get(financesBudgetCategoriesUrl),
                axiosPrivate.get(financeBudgetsPurposeUrl),
                axiosPrivate.get(financeBudgetDecisionUrl),
            ]);

            const financeFiscalYearData: IFiscalYearResponse =
                await financeRes.data.data;
            const budgetCategoriesData: IFinanceBudgetDecisionResponse[] =
                await budgetCategoriesRes.data.data;
            const budgetPurposeData: FinanceBudgetPurposeTypes[] =
                await budgetPurposeRes.data.data;
            const budgetDecisionData: IFinanceBudgetDecisionResponse[] =
                await budgetDecisionRes.data.data;

            // Return or use the combined data
            return {
                financeFiscalYearData,
                budgetCategoriesData,
                budgetPurposeData,
                budgetDecisionData,
            };
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchExpenseIncomeData = async (type: string) => {
        /* const api =
            type === "expense"
                ? financesExpenseGroupApi
                : financesIncomeGroupApi; */
        let api;
        
        switch (type) {
            case "expense":
                api = financesExpenseGroupApi;
                break;
            case "income":
                api = financesIncomeGroupApi;
                break;
            case "inventory":
                api = InventoryGroupApi;
                break;
            case "capex":
                api = FixedAssetGroupApi;
                break;
            default:
                throw new Error(`Unknown type: ${type}`);
        }

        try {
            axiosPrivate
                .get(
                    `${api}?with_trashed=${false}&organisation_id=${userOrganisationId}`
                )
                .then((res) => {
                    const response:
                        | IExpenseGroupResponse[]
                        | IIncomeGroupResponse[] 
                        | InventoryGroupTypes[]
                        | FixedAssetGroupTypes[]
                        = res.data.data || res.data;
                    setExpenseIncomeFormSchema((prevSchema) =>
                        prevSchema.map((field) => {
                            if (field.name === "item_group") {
                                // Update the specific field you want
                                return {
                                    ...field,
                                    options: response.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    })),
                                };
                            }
                            return field; // Keep other fields unchanged
                        })
                    );
                })
                .catch((err) => {
                    if (err.response && err.response.status === 422) {
                      toast.error("Validation Errors");
                    } else if (err.response && err.response.status === 400) {
                      toast.error(err.response.data.detail);
                    } else {
                      toast.error("Whoops! cannot fetch item group");
                    }
                    //toast.error(err.response.message || "Whoops! Something went wrong");
                });
            //return;
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const fetchItemClassData = async (type: string, value: string) => {
        /* const api =
            type === "expense"
                ? financesExpenseClassApi
                : financesIncomeClassApi;
        const subParam =
            type === "expense" ? "expense_group_id" : "income_group_id";
 */
        let api;
        let subParam;
        
        switch (type) {
            case "expense":
                api = financesExpenseClassApi;
                subParam = "expense_group_id";
                break;
            case "income":
                api = financesIncomeClassApi;
                subParam = "income_group_id";
                break;
            case "inventory":
                api = InventoryClassApi;
                subParam = "inventory_group_id";
                break;
            case "capex":
                api = FixedAssetClassApi;
                subParam = "fixed_asset_group_id";
                break;
            default:
                throw new Error(`Unknown type: ${type}`);
        }

        try {
            axiosPrivate
                .get(
                    `${api}?with_trashed=${false}&organisation_id=${userOrganisationId}&${subParam}=${value}`
                )
                .then((res) => {
                    const response:
                        | ExpenseClassResponse[]
                        | IncomeClassType[] 
                        | InventoryClassTypes[] 
                        | FixedAssetClassTypes[] 
                        = res.data.data || res.data;
                    setExpenseIncomeFormSchema((prevSchema) =>
                        prevSchema.map((field) => {
                            if (field.name === "item_class") {
                                // Update the specific field you want
                                return {
                                    ...field,
                                    options: response.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    })),
                                };
                            }
                            return field; // Keep other fields unchanged
                        })
                    );
                })
                .catch((err) => {
                    if (err.response && err.response.status === 422) {
                      toast.error("Validation Errors");
                    } else if (err.response && err.response.status === 400) {
                      toast.error(err.response.data.detail);
                    } else {
                      toast.error("Whoops! cannot fetch item class");
                    }
                    //toast.error(err.response.message || "Whoops! Something went wrong");
                });
            //return;
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const fetchItemTypeData = async (type: string, value: string) => {
        /* const api =
            type === "expense" ? financesExpenseTypeApi : financesIncomeTypeApi;
        const subParam =
            type === "expense" ? "expense_class_id" : "income_class_id";
        */
        let api;
        let subParam;
        
        switch (type) {
            case "expense":
                api = financesExpenseTypeApi;
                subParam = "expense_class_id";
                break;
            case "income":
                api = financesIncomeTypeApi;
                subParam = "income_class_id";
                break;
            case "inventory":
                api = InventoryTypeApi;
                subParam = "inventory_class_id";
                break;
            case "capex":
                api = FixedAssetTypeApi;
                subParam = "fixed_asset_class_id";
                break;
            default:
                throw new Error(`Unknown type: ${type}`);
        }    

        try {
            axiosPrivate
                .get(
                    `${api}?with_trashed=${false}&organisation_id=${userOrganisationId}&${subParam}=${value}`
                )
                .then((res) => {
                    const response:
                        | ExpenseTypeResponse[]
                        | IIncomeGroupResponse[] 
                        | InventoryTypeTypes[] 
                        | FixedAssetTypeTypes[] 
                        = res.data.data || res.data;
                    setExpenseIncomeFormSchema((prevSchema) =>
                        prevSchema.map((field) => {
                            if (field.name === "item_type") {
                                // Update the specific field you want
                                return {
                                    ...field,
                                    options: response.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    })),
                                };
                            }
                            return field; // Keep other fields unchanged
                        })
                    );
                })
                .catch((err) => {
                    if (err.response && err.response.status === 422) {
                      toast.error("Validation Errors");
                    } else if (err.response && err.response.status === 400) {
                      toast.error(err.response.data.detail);
                    } else {
                      toast.error("Whoops! cannot fetch item type");
                    }
                    //toast.error(err.response.message || "Whoops! Something went wrong");
                });
            //return;
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const fetchItemDescriptionData = async (type: string, value: string) => {
        /* const api =
            type === "expense"
                ? financesExpenseDescriptionApi
                : financesIncomeDescriptionApi;
        const subParam =
            type === "expense" ? "expense_type_id" : "income_type_id";
        */
        let api;
        let subParam;
        
        switch (type) {
            case "expense":
                api = financesExpenseDescriptionApi;
                subParam = "expense_type_id";
                break;
            case "income":
                api = financesIncomeDescriptionApi;
                subParam = "income_type_id";
                break;
            case "inventory":
                api = InventoryDescriptionApi;
                subParam = "inventory_type_id";
                break;
            case "capex":
                api = FixedAssetDescriptionApi;
                subParam = "fixed_asset_type_id";
                break;
            default:
                throw new Error(`Unknown type: ${type}`);
        } 

        try {
            axiosPrivate
                .get(
                    `${api}?with_trashed=${false}&organisation_id=${userOrganisationId}&${subParam}=${value}`
                )
                .then((res) => {
                    const response:
                        | IExpenseGroupResponse[]
                        | IIncomeGroupResponse[] 
                        | InventoryDescriptionTypes[] 
                        | FixedAssetDescriptionTypes[] 
                        = res.data.data || res.data;
                    setExpenseIncomeFormSchema((prevSchema) =>
                        prevSchema.map((field) => {
                            if (field.name === "item_description") {
                                // Update the specific field you want
                                return {
                                    ...field,
                                    options: response.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    })),
                                };
                            }
                            return field; // Keep other fields unchanged
                        })
                    );
                })
                .catch((err) => {
                    if (err.response && err.response.status === 422) {
                      toast.error("Validation Errors");
                    } else if (err.response && err.response.status === 400) {
                      toast.error(err.response.data.detail);
                    } else {
                      toast.error("Whoops! cannot fetch item description");
                    }
                    //toast.error(err.response.message || "Whoops! Something went wrong");
                });
            //return;
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const fetchDivision = async () => {
        const api = `${DivisionApi(userOrganisationId)}?with_trashed=${false}`;

        try {
            axiosPrivate.get(api).then((res) => {
                const response: any[] = res.data;
                //console.log(response);
                setCostCenterFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "division") {
                            return {
                                ...field,
                                options: response.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }
                        return field; // Keep other fields unchanged
                    })
                );
            })
            .catch((err) => {
                    if (err.response && err.response.status === 422) {
                      toast.error("Validation Errors");
                    } else if (err.response && err.response.status === 400) {
                      toast.error(err.response.data.detail);
                    } else {
                      toast.error("Whoops! cannot fetch division");
                    }
                    //toast.error(err.response.message || "Whoops! Something went wrong");
                })
                .catch((err) => {
                    if (err.response && err.response.status === 422) {
                      toast.error("Validation Errors");
                    } else if (err.response && err.response.status === 400) {
                      toast.error(err.response.data.detail);
                    } else {
                      toast.error("Whoops! cannot fetch record");
                    }
                    //toast.error(err.response.message || "Whoops! Something went wrong");
                });
            //return;
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const fetchDepartment = async (value: string) => {
        const api = `${DepartmentApi(
            userOrganisationId
        )}?with_trashed=${false}&division_id=${value}`;

        try {
            axiosPrivate.get(api).then((res) => {
                const response: any[] = res.data;
                //console.log(response);
                setCostCenterFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "department") {
                            return {
                                ...field,
                                options: response.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }
                        return field; // Keep other fields unchanged
                    })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                  toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                  toast.error(err.response.data.detail);
                } else {
                  toast.error("Whoops! cannot fetch department");
                }
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
            //return;
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const fetchUnit = async (value: string) => {
        const api = `${UnitApi(
            userOrganisationId
        )}?with_trashed=${false}&department_id=${value}`;

        try {
            axiosPrivate.get(api).then((res) => {
                const response: any[] = res.data;
                //console.log(response);
                setCostCenterFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "unit") {
                            return {
                                ...field,
                                options: response.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }
                        return field; // Keep other fields unchanged
                    })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                  toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                  toast.error(err.response.data.detail);
                } else {
                  toast.error("Whoops! cannot fetch unit");
                }
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
            //return;
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const fetchCostCenter = async (value: string) => {
        const api = `${CostCentreApi(
            userOrganisationId
        )}?with_trashed=${false}&unit_id=${value}`;

        try {
            axiosPrivate.get(api).then((res) => {
                const response: any[] = res.data;
                //console.log(response);
                setCostCenterFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "cost_centre_id") {
                            return {
                                ...field,
                                options: response.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }
                        return field; // Keep other fields unchanged
                    })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                  toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                  toast.error(err.response.data.detail);
                } else {
                  toast.error("Whoops! cannot fetch cost centre");
                }
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
        //return;
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    // Function to update month options based on financialStartEndDate
    //const updateMonthOptions = (fyStartDate: string, fyEndDate: string) => {
    const updateMonthOptions = (fye: number) => {
        let startMonthOptions: any[] = [];
        let endMonthOptions: any[] = [];

        if(fye) {
            const startDate = new Date(financialFYStartEndDate[0]);
            const endDate = new Date(financialFYStartEndDate[1]);

            /* const startDate = new Date(fyStartDate);
            const endDate = new Date(fyEndDate); */

            const startMonthValue = startDate.getMonth() + 1; // Month is 0-indexed
            const endMonthValue = endDate.getMonth() + 1;
            
            // Generate options for start_month
            startMonthOptions = months
            .filter((month) => monthMapping[month] >= startMonthValue) // From start month to December
            .map((month) => ({
                label: month,
                value: monthMapping[month],
            }));

            // Generate options for end_month
            endMonthOptions = months
            .filter((month) => monthMapping[month] <= endMonthValue) // From January to end month
            .map((month) => ({
                label: month,
                value: monthMapping[month],
            }));
        }

        setMonthAmountFormSchema((prevSchema) =>
            prevSchema.map((field) => {
                if (field.name === "start_month") {
                return {
                    ...field,
                    options: startMonthOptions,
                };
                } else if (field.name === "end_month") {
                return {
                    ...field,
                    options: endMonthOptions,
                };
                }
                return field; // Keep other fields unchanged
            })
        );
    };

    useEffect(() => {
        fetchFinanceData().then((data) => {
            if (data) {
                setFinanceFormSchema([
                    {
                        name: "finance_fiscal_year_id",
                        label: FiscalYear,
                        type: "select",
                        options: [{
                            label: data.financeFiscalYearData.name,
                            value: data.financeFiscalYearData.id,
                        }],
                        /* options: data.financeFiscalYearData.map((item) => ({
                            label: item.name,
                            value: item.id,
                        })), */
                        required: true,
                    },
                    {
                        name: "finance_budget_category_id",
                        label: BudgetCategory,
                        type: "select",
                        options: data.budgetCategoriesData.map((item) => ({
                            label: item.name,
                            value: item.id,
                        })),
                        required: true,
                    },
                    {
                        name: "finance_budget_purpose_id",
                        label: BudgetPurpose,
                        type: "select",
                        options: data.budgetPurposeData.map((item) => ({
                            label: item.name,
                            value: item.id,
                        })),
                        required: true,
                    },
                    {
                        name: "finance_budget_decision_id",
                        label: BudgetDecision,
                        type: "select",
                        options: data.budgetDecisionData.map((item) => ({
                            label: item.name,
                            value: item.id,
                        })),
                        required: false,
                    },
                ]);

                setFinancialFYStartEndDate([data.financeFiscalYearData.start_date, data.financeFiscalYearData.end_date]);
                //updateMonthOptions(data.financeFiscalYearData.start_date, data.financeFiscalYearData.end_date);
                
                const generatedYearFigures = createYearFigures(data.financeFiscalYearData.start_date, data.financeFiscalYearData.end_date);
                /* const generatedYearFigures = data.financeFiscalYearData.map(
                    (res) => createYearFigures(res.start_date, res.end_date)
                ); */

                // Set the generated yearFigures to state

                // loop over all arrays and join it to one big array
                const joinedArray = generatedYearFigures.flatMap((arr) => arr);
                setYearFigures(joinedArray);
                //console.log(joinedArray);
            }
        });
        fetchDivision();
    }, []);

    const calculateMonthPeriod = (startMonth: number, endMonth: number): number => {
        if (endMonth >= startMonth) {
          return endMonth - startMonth + 1; // Include both start and end months
        } else {
          return (12 - startMonth + 1) + endMonth; // Account for year transition
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setInputErrors({
            financeFormErrors: {},
            expenseIncomeFormErrors: {},
            costCenterFormErrors: {},
            monthAmountFormErrors: {},
        })

        const financeFormValidation = validateInputs(financeFormSchema, financeInputData);
        const expenseIncomeValidation = validateInputs(expenseIncomeFormSchema, expenseIncomeInputData);
        const costCenterFormValidation = validateInputs(costCenterFormSchema, costCenterInputData);
        const monthAmountValidation = validateInputs(monthAmountFormSchema, monthAmountInputData);
        
        if (!financeFormValidation.isValid) {
            setInputErrors(prev => ({ ...prev, financeFormErrors: financeFormValidation.errors }));
            return;
        }

        if (!expenseIncomeValidation.isValid) {
            setInputErrors(prev => ({ ...prev, expenseIncomeFormErrors: expenseIncomeValidation.errors }));
            return;
        }

        if (!costCenterFormValidation.isValid) {
            setInputErrors(prev => ({ ...prev, costCenterFormErrors: costCenterFormValidation.errors }));
            return;
        }

        if (!monthAmountValidation.isValid) {
            setInputErrors(prev => ({ ...prev, monthAmountFormErrors: monthAmountValidation.errors }));
            return;
        }

        /* const transformedData = yearFigures.flatMap((yearFigure) =>
            yearFigure.date.map((monthData) => ({
                year: parseInt(yearFigure.year),
                month: monthMapping[monthData.month], // Use the month name to find the correct month number
                quantity: 1, // Assuming default quantity is 1
                price: parseFloat(monthData.amount), // === 0 ? 0 : 100, // Adjust logic based on your price determination
                cost: 0, // Assuming default cost is 0
                accrual_amount: parseFloat(monthData.amount), // Assuming accrual_amount equals the amount
            }))
        ); */

        const startMonth = Number(monthAmountInputData.start_month);
        const endMonth = Number(monthAmountInputData.end_month);

        const doTransformedData = () => {
        const monthDataMap: { [key: string]: any } = {};
        const currentYear = parseInt(yearFigures[0].year);
        const nextYear = currentYear + 1;
        
        // Identify months in the current year based on start and end month
        yearFigures[0].date.forEach(monthData => {
            const monthValue = monthMapping[monthData.month];
        
            // Check if month is in the current year range
            if (startMonth <= endMonth) {
            if (monthValue >= startMonth && monthValue <= endMonth) {
                monthDataMap[`${currentYear}-${monthValue}`] = {
                year: currentYear,
                month: monthValue,
                quantity: 1,
                price: parseFloat(monthData.amount),
                cost: 1 * parseFloat(monthData.amount),
                accrual_amount: parseFloat(monthData.amount),
                };
            }
            } else {
            // Handle spanning over the year
            if (monthValue >= startMonth || monthValue <= endMonth) {
                monthDataMap[`${currentYear}-${monthValue}`] = {
                year: currentYear,
                month: monthValue,
                quantity: 1,
                price: parseFloat(monthData.amount),
                cost: 0,
                accrual_amount: parseFloat(monthData.amount),
                };
            }
            }
        });
        
        // Include relevant months from next year
        if (endMonth < startMonth) {
            const nextYearData = yearFigures[1]?.date || [];
        
            nextYearData.forEach(monthData => {
            const monthValue = monthMapping[monthData.month];
            // Include only months from January to endMonth
            if (monthValue >= 1 && monthValue <= endMonth) {
                monthDataMap[`${nextYear}-${monthValue}`] = {
                year: nextYear,
                month: monthValue,
                quantity: 1,
                price: parseFloat(monthData.amount),
                cost: 0,
                accrual_amount: parseFloat(monthData.amount),
                };
            }
            });
        }
        
        // Return the final transformed data as an array
        return Object.values(monthDataMap);
        };
        
        const transformedData = doTransformedData();

        // turn all months to type number
        const period_month = calculateMonthPeriod(startMonth, endMonth);
        
        if (period_month <=0 ) {
            toast.error("Select Start Month and End Month");
            return;
        }

        const payload: FinanceBudgetInputFormDataPayload = {
            organisation_id: userOrganisationId,
            finance_budget_category_id:
                financeInputData.finance_budget_category_id,
            finance_budget_decision_id:
                financeInputData.finance_budget_decision_id,
            finance_budget_purpose_id:
                financeInputData.finance_budget_purpose_id,
            finance_fiscal_year_id: financeInputData.finance_fiscal_year_id,
            cost_centre_id: costCenterInputData.cost_centre_id,
            justification: monthAmountInputData.justification,
            end_month: Number(monthAmountInputData.end_month),
            period_month: period_month,//Number(monthAmountInputData.period_month),
            start_month: Number(monthAmountInputData.start_month),
            accrued_amount: 0, //monthAmountInputData.accrued_amount,
            remaining_amount: 0, //monthAmountInputData.remaining_amount,
            total_amount: monthAmountInputData.total_amount,
            item_description_id: expenseIncomeInputData.item_description,
            item_description_type: expenseIncomeInputData.item_description_type,
            parent_id: 0,
            finance_budget_details: transformedData,
        };

        await axiosPrivate
            .post(financeBudgetApi, payload)
            .then((_res) => {
                toast.success(`Budget Created Successfully.`);

                setFinanceInputData({
                    finance_fiscal_year_id: '',
                    finance_budget_category_id: '',
                    finance_budget_purpose_id: '',
                    finance_budget_decision_id: '',
                });

                setExpenseIncomeInputData({
                    item_description_type: '',
                    item_group: '',
                    item_class: '',
                    item_type: '',
                    item_description: '',
                })

                setCostCenterInputData({
                    'division': '',
                    'department': '',
                    'unit': '',
                    'cost_centre_id': '',
                })

                setMonthAmountInputData({
                    'justification': '',
                    'start_month': '',
                    'end_month': '',
                    'total_amount': '',
                })
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                //throw err;
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });

        //console.log(payload);
    };

    return {
        backBtn,
        handleSubmit,
        inputErrors,

        financeFormSchema,
        setFinanceFormSchema,
        financeInputData,
        handleFinanceChange,

        monthAmountFormSchema,
        setMonthAmountFormSchema,
        monthAmountInputData,
        handleMonthAmountChange,

        expenseIncomeFormSchema,
        setExpenseIncomeFormSchema,
        expenseIncomeInputData,
        handleExpenseIncomeChange,

        costCenterFormSchema,
        setCostCenterFormSchema,
        costCenterInputData,
        handleCostCenterChange,

        yearFigures,
        setYearFigures,
    };
}
