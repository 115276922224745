import { Fragment, useState } from 'react';
import { Media } from 'reactstrap';
import { Image, P } from '../../../../../AbstractElements';
import three from '../../../../../assets/images/user/3.jpg';
import { timeAgo } from '../../../../../_helper/dateHelper';
import { TicketReplyMenu } from '../../../../../Constant';
import DropdownCommon from '../../../../Common/Dropdown';

const CaseNoteList = ({ Notes }) => {
    const [heart, setHeart] = useState(0);
     
    return (
        <Fragment>
            {Notes.map((item, i) => (
                <div key={i} className={`your-msg ${item.employee_id ? 'font-primary' : ''}`}>
                    <Media>
                        <Image attrImage={{ className: 'img-50 img-fluid m-r-20 rounded-circle', alt: '', src: `${three}` }} />
                        <Media body>
                            <span className='f-w-500'>
                                `Staff: ` 
                                &nbsp; 
                            <span>
                            {timeAgo(item.created_at)} <i className='fa fa-reply font-primary'></i>
                            </span>
                            {/* <span className='d-flex justify-content-between pull-right mt-0 '>
                                <span className='m-r-20'><i className='fa fa-edit font-primary'></i> Edit</span>
                                <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={TicketReplyMenu} iconName='icon-more-alt' btn={{ tag: 'span' }} />
                            </span> */}
                        </span>
                        <P>{item.message}</P>

                        </Media>
                    </Media>
                </div>
            ))}
        </Fragment>
    );
};

export default CaseNoteList;