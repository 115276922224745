import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent  } from 'reactstrap';
import {Breadcrumbs} from '../../../../AbstractElements';
import {Administration, DisposalMethod, DisposalReason, ItemCondition, ItemStatus, StorageMode} from '../../../../Constant';
import {Link} from "react-router-dom";
import {PlusCircle} from "react-feather";
import useControlSettings from './controller';
import DisposalReasonList from './DisposalReason/List/DisposalReasonList';
import DisposalMethodList from './DisposalMethod/List/DisposalMethodList';
import ItemConditionList from './ItemCondition/List/ItemConditionList';
import ItemStatusList from './ItemStatus/List/ItemStatusList';
import StorageModeList from './StorageMode/List/StorageModeList';

const ControlSettings = () => {
    const {
        layoutURL,
        DISPOSAL_REASON,
        DISPOSAL_METHOD,
        ITEM_CONDITION,
        ITEM_STATUS,
        STORAGE_MODE,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    } = useControlSettings();
    
    return (
        <Fragment>
            <Breadcrumbs parent={Administration} title={tabDisplayNames[commissionTab]} mainTitle={tabDisplayNames[commissionTab]}/>
            <Container fluid={true}>
                <Row>
                    <Col sm='12' xl='12' className='xl-100'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        {/*<H5></H5>*/}
                                    </Col>
                                    <Col md="6">
                                        <div className="text-end file-content">
                                            <Link className="btn btn-primary ms-2" style={{color: 'white'}} to={`/administration/settings/${commissionTab}/create/${layoutURL}`}>
                                                <PlusCircle/> New {tabDisplayNames[commissionTab] || 'Item'}
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={'12'}>
                                        <Nav className='border-tab nav-secondary nav-pills' tabs>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${DISPOSAL_REASON}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${DISPOSAL_REASON}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {DisposalReason}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${DISPOSAL_METHOD}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${DISPOSAL_METHOD}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {DisposalMethod}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${ITEM_CONDITION}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${ITEM_CONDITION}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {ItemCondition}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${ITEM_STATUS}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${ITEM_STATUS}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {ItemStatus}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${STORAGE_MODE}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${STORAGE_MODE}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {StorageMode}
                                                </NavLink>
                                            </NavItem>
                                        </Nav> 
                                        <TabContent activeTab={commissionTab}>
                                            {commissionTab === `${DISPOSAL_REASON}` && <DisposalReasonList name={DISPOSAL_REASON} />}
                                            {commissionTab === `${DISPOSAL_METHOD}` && <DisposalMethodList name={DISPOSAL_METHOD} />}
                                            {commissionTab === `${ITEM_CONDITION}` && <ItemConditionList name={ITEM_CONDITION} />}
                                            {commissionTab === `${ITEM_STATUS}` && <ItemStatusList name={ITEM_STATUS} />}
                                            {commissionTab === `${STORAGE_MODE}` && <StorageModeList name={STORAGE_MODE} />}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default  ControlSettings