import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  Description,
  Status,
  Name,
  Role,
  Alias,
  Division,
  Department,
  Unit,
} from "../../../../../Constant";

import { toast } from "react-toastify";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { DepartmentApi, DivisionApi, HrmRoleApi, UnitApi } from "../../../../../api";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";

export default function useHrmRoleForm() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = Role;
  const { userOrganisationId }: any = useAuth();
  const departmentApi = DepartmentApi(userOrganisationId);
  const unitApi = UnitApi(userOrganisationId);
  const divisionApi = DivisionApi(userOrganisationId);
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "short_name",
      name: "short_name",
      label: Alias,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const divisionDropDown = createDropdownField(
      axiosPrivate,
      "division_id",
      "division_id",
      Division,
      divisionApi,
      "",
      6
  );
  
  divisionDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "department_id"
      );
      if (nextField) {
          const endPoint = `${departmentApi}?division_id=${id}`;
          await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "division_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
      //updateFields();
  };

  const departmentDropDown = createDropdownField(
      axiosPrivate,
      "department_id",
      "department_id",
      Department,
      "",
      "",
      6
  );

  departmentDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "unit_id"
      );
      if (nextField) {
          const endPoint = `${unitApi}?department_id=${id}`;
          await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "department_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
      //updateFields();
  };

  const unitDropDown = createDropdownField(
      axiosPrivate,
      "unit_id",
      "unit_id",
      Unit,
      "",
      "",
      6
  );

  // ?? Call on every dependent change
  async function updateFields() {
      const updatedFields = [divisionDropDown, departmentDropDown, unitDropDown, ...formFields];
      await divisionDropDown.fetchOptions();
      setFormFields(updatedFields);
  }


  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/hrm/settings/roles/${layoutURL}`;

  const onSubmit = async (data: any) => {
    data.organisation_id = userOrganisationId;
    // console.log(data);
    await axiosPrivate
      .post(HrmRoleApi, data)
      .then((_res) => {
        toast.success(`${_res.data.data.name} Created Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        throw err;
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  return { formTitle, formFields, backBtn, onSubmit };
}
