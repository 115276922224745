import React, { Fragment } from "react";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Button,
    Input,
    Badge,
} from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
    Account,
    Topupwallet,
    Wallet,
    UseFullBalance,
    WalletAmount,
    Submit,
    TotalSummary,
    FancyShirtPrice,
    To,
    Topup,
} from "../../../../Constant";
import HeaderCard from "../../../Common/Component/HeaderCard";
import "./TopupWallet.scss";
import logoImage from "../../../../assets/images/logo/Vector.png";
import useTopupWallet from "./controller";
import InputProvider from "../../../Finance/Template/InputProvider";

export default function TopupWallet() {
    const {
        handleSubmit,
        topupWalletFormSchema,
        setTopupWalletFormSchema,
        topupWalletInputData,
        handleTopupWalletChange,
    } = useTopupWallet();

    return (
        <div>
            <Fragment>
                <Breadcrumbs
                    parent={Account}
                    title={Topupwallet}
                    mainTitle={Topupwallet}
                />
                <Container fluid={true}>
                    <Card>
                        <HeaderCard
                            title={Topupwallet}
                            span1={null}
                            span2={null}
                            mainClasses={null}
                            btnUrl={null}
                        />
                        <CardBody>
                            <Row className="gap-3">
                                <Col
                                    className="border rounded p-2"
                                    md="6"
                                    sm="12"
                                >
                                    <div className="d-flex justify-content-between">
                                        <h6>{WalletAmount}</h6>

                                        <Button color="info">
                                            {UseFullBalance}
                                        </Button>
                                    </div>
                                    <br />
                                    
                                    <form onSubmit={handleSubmit}>
                                        <InputProvider
                                            schema={topupWalletFormSchema}
                                            inputData={topupWalletInputData}
                                            onChange={handleTopupWalletChange}
                                        />

                                        <div className="text-end">
                                            <Button color="primary">
                                                Topup Now
                                            </Button>
                                        </div>
                                    </form>
                                </Col>
                                
                                
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </Fragment>
        </div>
    );
}
