import { Fragment, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ServiceTypeApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { base_form_fields } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { ServiceCategory } from '../../../../Constant';

const ServiceTypeCreate = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/crm/settings/service-category/${layoutURL}`;
  
  const { formState: { errors }, } = useForm();

  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      name: newRecord.name,
      description: newRecord.description,
      is_active: newRecord.is_active,
    };

    try {
      await axiosPrivate.post(ServiceTypeApi, newRecordTemp, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="New Service Type" mainTitle="New Service Type" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={base_form_fields(`${ServiceCategory}`)} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ServiceTypeCreate;