import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useFinanceBudgetInputListController from "./controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import ViewModal from "../../../../Common/Component/ViewModal";
import {
    BudgetCategory,
    BudgetPurpose,
    Code,
    DateCreated,
    DateModified,
    FiscalYear,
    Justification,
    ProcessType,
    Settings,
    Total,
} from "../../../../../Constant";
import { formatDateWithTimeZone, formatMoney } from "../../../../../_helper/dateHelper";

const FinanceBudgetInputList = () => {
    const {
        data,
        tableColumns,
        newBtn,
        editMode,
        selectedRowData,
        viewModalOpen,
        title,
        formFields,
        setViewModalOpen,
        useTableAction,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    } = useFinanceBudgetInputListController();

    return (
        <Fragment>
            <Breadcrumbs parent={Settings} title={title} mainTitle={title} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard
                                title={title}
                                btnUrl={newBtn}
                                span1={undefined}
                                span2={undefined}
                                mainClasses={undefined}
                            />
                            <CardBody>
                                <DataTableComponent
                                    tabledata={data}
                                    tableColumns={tableColumns}
                                    isLoading={false}
                                    onView={handleView}
                                    // onDelete={handleDelete}
                                    onEdit={undefined}
                                    useTableAction={useTableAction}
                                    addToDefaultAction={undefined}
                                    onDelete={undefined}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ViewModal
                    isOpen={viewModalOpen}
                    toggle={() => setViewModalOpen(!viewModalOpen)}
                    rowData={selectedRowData}
                    formFields={formFields}
                    editMode={editMode}
                    onEdit={memoizedHandleEdit}
                    onSubmit={undefined}
                    modalTitle={undefined}
                >
                    {selectedRowData && (
                        <>
                            <Table>
                                <tbody>
                                    <tr className="border-bottom-primary">
                                        <th scope="row">{Code}</th>
                                        <td>{selectedRowData.code}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{ProcessType}</th>
                                        <td>{selectedRowData.item_description_type}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{Total}</th>
                                        <td>{formatMoney(selectedRowData.total_amount)}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{FiscalYear}</th>
                                        <td>{selectedRowData.finance_fiscal_year.short_name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{BudgetCategory}</th>
                                        <td>{selectedRowData.finance_budget_category.name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{BudgetPurpose}</th>
                                        <td>{selectedRowData.finance_budget_purpose.name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{Justification}</th>
                                        <td>{selectedRowData.justification}</td>
                                    </tr>

                                    <tr className="border-bottom-secondary">
                                        <th scope="row">{DateCreated}</th>
                                        <td>
                                            {formatDateWithTimeZone(
                                                selectedRowData.created_at
                                            )}
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-secondary">
                                        <th scope="row">{DateModified}</th>
                                        <td>
                                            {formatDateWithTimeZone(
                                                selectedRowData.updated_at
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}
                </ViewModal>
            </Container>
        </Fragment>
    );
};

export default FinanceBudgetInputList;
