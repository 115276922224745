import { useContext, useLayoutEffect, useMemo, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  DescriptionName,
  Description,
  IncomeCategory,
  IncomeGroup,
  IncomeClass,
  Name,
  Status,
  vatApply,
  whtApply,
  CreatedBy,
  ModifiedBy,
} from "../../../../../Constant";
import useAuth from "../../../../../Hooks/useAuth";
import {
  financesIncomeCategoryApi,
  financesIncomeClassApi,
  financesIncomeGroupApi,
} from "../../../../../api";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { IncomeClassType } from "../IncomeClass.types";
import { StatusRow } from "../../ReportType/List/ReportTypeList";
import { toast } from "react-toastify";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";

export default function useIncomeClassListController() {
  const axiosPrivate = useAxiosPrivate();
  const title = IncomeClass;

  const { userOrganisationId }: any = useAuth();
  const [data, setData] = useState<IncomeClassType[]>([]);
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
    {
      id: "is_vat_apply", 
      name: "is_vat_apply",
      label: vatApply,
      type: "switch",
      value: "1",
      colSize: 6,
    },
    {
      id: "is_wht_apply",
      name: "is_wht_apply",
      label: whtApply,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  //?? DROPDOWNS INITS >
  const incomeCategoryDropDown = createDropdownField(
    axiosPrivate,
    "income_category",
    "income_category",
    IncomeCategory,
    `${financesIncomeCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );
  incomeCategoryDropDown.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "income_group_id"
    );
    if (nextField) {
      const endPoint = `${financesIncomeGroupApi}?organisation_id=${userOrganisationId}&income_category_id=${id}`
      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "income_category") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const incomeGroupDropDown = createDropdownField(
    axiosPrivate,
    "income_group_id",
    "income_group_id",
    IncomeGroup,
    `${financesIncomeGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}'`,
    "",
    6
  );

  const handleEdit = (row: (typeof data)[0] | any) => {
    setViewModalOpen(true);
    setEditMode(true);

    //row.income_category = row.income_group.income_category.id;
    incomeCategoryDropDown.onChange(
      row?.income_group.income_category.id,
      formFields
    );
    row.income_group_id = row.income_group.id
    setSelectedRowData(row);
  };

  const fetchData = () => {
    setLoading(true);
    axiosPrivate
      .get(
        `${financesIncomeClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
      )
      .then((res) => {
        const response: IncomeClassType[] = res.data;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [loading, setLoading] = useState(true);
  useLayoutEffect(() => {
    fetchData();
  }, []);

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    (async function () {
      const updatedFields = [
        incomeCategoryDropDown,
        incomeGroupDropDown,
        ...formFields,
      ];
      await incomeCategoryDropDown.fetchOptions();
      setFormFields(updatedFields);
    })();
  }, []);

  const tableColumns = [
    {
      name: Name,
      selector: (row: (typeof data)[0]) => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: Description,
      selector: (row: (typeof data)[0]) => `${row.description}`,
      sortable: false,
      center: false,
    },
    {
      name: IncomeCategory,
      selector: (row: (typeof data)[0]) => `${row.income_group.income_category.name}`,
      sortable: true,
      center: false,
    },

    {
      name: IncomeGroup,
      selector: (row: (typeof data)[0]) => `${row.income_group.name}`,
      sortable: true,
      center: false,
    },
    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    {
      name: CreatedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: ModifiedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `${process.env.PUBLIC_URL}/finance/settings/income-class/create/${layoutURL}`;
  // const [isLoading, setIsLoading] = useState(true);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0]
  ) => {
    await axiosPrivate
      .put(
        `${financesIncomeClassApi}/${id}`,
        editRecord
        // income_group_id: userOrganisationId,
      )
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.name} Editted Successfully.`)
        //fetchData();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error('Validation Errors');
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
        throw err;
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  return {
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    setViewModalOpen,
    handleView,
    handleEdit,
    loading,
    memoizedHandleEdit,
  };
}
