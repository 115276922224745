import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane  } from 'reactstrap';
import {Breadcrumbs} from '../../../../AbstractElements';
import {Vendor, VendorMapping, Administration, Members, NoRecordFound} from '../../../../Constant';
import useVendorDetails from './controller';
import UserProfile from './UserProfile';
import { ContactTypeVal } from '../../../../Data/FormField/PublicData';
import VendorMemberList from '../VendorMembers/List/VendorMemberList';
import NoRecord from '../../../Common/Component/NoRecord';

const VendorDetails = () => {
    const {
        layoutURL,
        VENDOR,
        MEMBER,
        commissionTab,
        tabDisplayNames,
        handleTabClick,
        data,
        buttonData,
        no_record_img
    } = useVendorDetails();

    const fullName = data?.contact.contact_type === ContactTypeVal.Individual ? 
                    `${data?.contact.last_name} ${data?.contact.first_name} ${data?.contact.middle_name}` : 
                    `${data?.contact.company_name}`;
    
    const locationDetails = `${data?.contact.street_number}, ${data?.contact.street_name}, ${data?.contact.city}, ${data?.contact?.ward.lga?.state.name}`;
    
    return (
        <Fragment>
            <Breadcrumbs parent={Administration} title={tabDisplayNames[commissionTab]} mainTitle={tabDisplayNames[commissionTab]}/>
            <Container fluid={true}>
                {!data ? 
                    <NoRecord
                        msg={NoRecordFound}
                        backgroundImg={no_record_img}
                        btnAction={undefined}
                        buttonData={buttonData}
                    /> :
                    <>
                        <div className="user-profile">
                            <Row>
                                <UserProfile 
                                    fullName={fullName}
                                    contactNumber={data?.contact.contact_number}
                                    contactType={data?.contact.contact_type === ContactTypeVal.Individual ? "Individual" : "Corporate"}
                                    email={data?.contact.user_login.email}
                                    phoneNumber={data?.contact.phone_number}
                                    location={locationDetails}
                                    buttonData={buttonData}
                                />
                            </Row>
                        </div>
                        <Row>
                            <Col sm='12' xl='12' className='xl-100'>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={'12'}>
                                                <Nav className='border-tab nav-secondary nav-pills' tabs>
                                                    <NavItem>
                                                        <NavLink href='#' className={commissionTab === `${VENDOR}` ? 'active' : ''}
                                                                onClick={() => handleTabClick(`${VENDOR}`)}>
                                                            <i className='icofont icofont-files'></i>
                                                            {Vendor}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href='#' className={commissionTab === `${MEMBER}` ? 'active' : ''}
                                                                onClick={() => handleTabClick(`${MEMBER}`)}>
                                                            <i className='icofont icofont-users'></i>
                                                            {Members}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav> 
                                                <TabContent activeTab={commissionTab}>
                                                    <TabPane className='fade show' tabId={VENDOR}>
                                                        Vendor Information
                                                    </TabPane>

                                                    <TabPane className='fade show' tabId={MEMBER}>
                                                        <VendorMemberList vendor_id={data.id} />
                                                    </TabPane>
                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        </Fragment>
    );
}

export default  VendorDetails