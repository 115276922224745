import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Col, Row, Table, TabPane  } from 'reactstrap';
import { P } from '../../../../../../AbstractElements';
import { DateCreated, DateModified, Description, Name, Status } from '../../../../../../Constant';
import CustomizerContext from '../../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';
import DataTableComponent from "../../../../../Common/Component/DataTableComponent";
import usePayrollPayCategory from "./controller";
import ViewModal from '../../../../../Common/Component/ViewModal';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';

const PayrollPayCategoryList = (name: any) => {
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const [allData, setAllData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    
    const {
        newBtn,
        data,
        tableColumns,
        handleView,
        handleEdit,
        viewModalOpen,
        selectedRowData,
        setViewModalOpen,
        formFields,
        memoizedHandleEdit,
        editMode,
        title,
        loading,
    } = usePayrollPayCategory();

    return (
        <Fragment>
            <TabPane className='fade show' tabId={name.name}>
                <DataTableComponent
                    tabledata={data}
                    tableColumns={tableColumns}
                    isLoading={loading}
                    onView={handleView}
                    // onDelete={handleDelete}
                    onEdit={handleEdit}
                    useTableAction={undefined}
                    addToDefaultAction={undefined}
                    onDelete={undefined}
                />

                <ViewModal
                    isOpen={viewModalOpen}
                    toggle={() => setViewModalOpen(!viewModalOpen)}
                    rowData={selectedRowData}
                    formFields={formFields}
                    editMode={editMode}
                    onEdit={memoizedHandleEdit}
                    onSubmit={undefined}
                    modalTitle={undefined}
                >
                    {selectedRowData && (
                    <>
                        <Table>
                        <tbody>
                            <tr className="border-bottom-primary">
                            <th scope="row">{Name}</th>
                            <td>{selectedRowData.name}</td>
                            </tr>

                            <tr className="border-bottom-primary">
                            <th scope="row">{Description}</th>
                            <td>{selectedRowData.description}</td>
                            </tr>

                            <tr className="border-bottom-primary">
                            <th scope="row">{Status}</th>
                            <td>
                                {selectedRowData.is_active ? (
                                <span className="badge badge-light-success">
                                    Active
                                </span>
                                ) : (
                                <span className="badge badge-light-danger">
                                    Inactive
                                </span>
                                )}
                            </td>
                            </tr>
                            <tr className="border-bottom-primary">
                            <th scope="row">{DateCreated}</th>
                            <td>
                                {formatDateWithTimeZone(selectedRowData.created_at)}
                            </td>
                            </tr>

                            <tr className="border-bottom-primary">
                            <th scope="row">{DateModified}</th>
                            <td>
                                {formatDateWithTimeZone(selectedRowData.updated_at)}
                            </td>
                            </tr>
                        </tbody>
                        </Table>
                    </>
                    )}
                </ViewModal>
            </TabPane>
        </Fragment>
    );
}

export default  PayrollPayCategoryList