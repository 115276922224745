import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { DateCreated, DateModified, DeleteSuccess, ServiceScope, Status, description } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { ServiceScopeApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import { base_form_fields } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
//import SweetAlert from 'sweetalert2';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import useAuth from '../../../../Hooks/useAuth';

const ServiceScopeList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const getAllEmployeeCategoriesData = async () => {
    try {
      await axiosPrivate.get(`${ServiceScopeApi}?organisation_id=${userOrganisationId}`).then((resp) => {
        setAllData(resp.data);
        //setIsLoading(false);
      });
    } catch (error) {
      toast.error(error.response.data.detail)
      //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllEmployeeCategoriesData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec = async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      //organisation_id: userOrganisationId,
      name: editRecord.name,
      description: editRecord.name,
      is_active: editRecord.is_active,
    };

    try {
      await axiosPrivate.put(`${ServiceScopeApi}/${id}`, editRecordTemp).then((resp) => {
        const updatedData = allData.map((row) =>
          row.id === selectedRowData.id ? { ...row, ...resp.data } : row
        );

        setAllData(updatedData);

        toast.success(`${resp.data.name} is successfully updated.`)
        handleCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${ServiceScopeApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  /* const handleDeleteSweetAlert = async (row) => {
    SweetAlert.fire({
        title: 'Delete?',
        text: `Are you sure you want to delete '${row.name}'?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
          try {
            const response = axiosPrivate.delete(`${ServiceScopeApi}/${row.id}`);
            //console.log(response)
            if (response.status === 204) {
              setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
              //toast.success(DeleteSuccess);
              SweetAlert.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
              );
            } else {
              toast.error('Delete request failed:', response.statusText);
            }
          } catch (error) {
            console.error('Error deleting record:', error);
          }
            
        } 
    });
  } */



  const addBtn = `${process.env.PUBLIC_URL}/hrm/personnel/service-scopes/create/${layoutURL}`;

  const tableColumns = [
    {
      name: `${ServiceScope}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Description',
      selector: row => `${row.description}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Status',
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="HRM" title="Service Scope " mainTitle="Service Scope" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={base_form_fields(`${ServiceScope}`)}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>

                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ServiceScope}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

        </ViewModal>

        {/* <ViewModal isOpen={viewModalOpen} toggle={() => setViewModalOpen(!viewModalOpen)} onEdit={handleEdit}>
            
          <p>ID: 456</p>
          <p>Name: Jane Smith</p>
        </ViewModal> */}
      </Container>
    </Fragment>
  );
};

export default ServiceScopeList;