import { Button } from "reactstrap";

const Button_ = ({
  text,
  callback,
  active,
  id,
}: {
  text: string;
  callback: () => void;
  active: number;
  id: number;
}) => {
  return (
    <>
      <li onClick={() => callback()}
        className="nav-item">
        <a href="#javascript" className={`${active === id ? "active nav-link" : "nav-link"} `}>
          {text}
        </a>
      </li>
    </>
  );
};

export default Button_;
