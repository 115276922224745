import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import { Breadcrumbs } from "../../../../../AbstractElements";
import {
  DateCreated,
  DateModified,
  Description,
  Name,
  Organization,
  Settings,
  Status,
} from "../../../../../Constant";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import ViewModal from "../../../../Common/Component/ViewModal";
import useFinanceBudgetDecisionList from "./FinanceBudgetDecisionList.controller";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";

export default function FinanceBudgetDecisionList() {
  const {
    title,
    data,
    tableColumns,
    newBtn,
    editMode,
    selectedRowData,
    viewModalOpen,
    formFields,
    loading,
    setViewModalOpen,
    handleView,
    handleEdit,
    memoizedHandleEdit,
  } = useFinanceBudgetDecisionList();

  return (
    <Fragment>
      <Breadcrumbs parent={Settings} title={title} mainTitle={title} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title={title}
                btnUrl={newBtn}
                span1={undefined}
                span2={undefined}
                mainClasses={undefined}
              />
              <CardBody>
                <DataTableComponent
                  tabledata={data}
                  tableColumns={tableColumns}
                  isLoading={loading}
                  onView={handleView}
                  // onDelete={handleDelete}
                  onEdit={handleEdit}
                  useTableAction={undefined}
                  addToDefaultAction={undefined}
                  onDelete={undefined}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
          onSubmit={undefined}
          modalTitle={undefined}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className="border-bottom-primary">
                    <th scope="row">{Name}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Status}</th>
                    <td>
                      {selectedRowData.is_active ? (
                        <span className="badge badge-light-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>

                  <tr className="border-bottom-secondary">
                    <th scope="row">{DateCreated}</th>
                    <td>
                      {formatDateWithTimeZone(selectedRowData.created_at)}
                    </td>
                  </tr>

                  <tr className="border-bottom-secondary">
                    <th scope="row">{DateModified}</th>
                    <td>
                      {formatDateWithTimeZone(selectedRowData.updated_at)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </ViewModal>
      </Container>
    </Fragment>
  );
}

export const StatusRow = (row: any) => {
  return row.is_active || row.STATUS ? (
    <span className="badge badge-light-success">Active</span>
  ) : (
    <span className="badge badge-light-danger">Inactive</span>
  );
};

export const StatusRow_2 = (row: { STATUS: boolean }) => {
  return row.STATUS ? (
    <span className="badge badge-light-success">PAID</span>
  ) : (
    <span className="badge badge-light-danger">SCHEDULED</span>
  );
};


export const StatusRow_3 = (row: { STATUS: string }) => {
  return row.STATUS === "Pending" ? (
    <span   className="badge badge-light-danger">PENDING</span>
  ) : (
    <span className="badge badge-light-success">APPROVED</span>
  );
};