import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../../_helper/Customizer";
import {
  Description,
  Status,
  Name,
  FixedAssetDescription,
  FixedAssetGroup,
  FixedAssetClass,
  FixedAssetType,
} from "../../../../../../Constant";

import { toast } from "react-toastify";
import useAuth from "../../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../../Hooks/useAxiosPrivate";
import { FixedAssetClassApi, FixedAssetDescriptionApi, FixedAssetGroupApi, FixedAssetTypeApi } from "../../../../../../api";
import { createDropdownField } from "../../../../../../Data/FormField/PublicData";

export default function useFixedAssetDescriptionForm() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = FixedAssetDescription;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const FixedAssetGroupDropDown = createDropdownField(
      axiosPrivate,
      "administration_fixed_asset_group_id",
      "administration_fixed_asset_group_id",
      FixedAssetGroup,
      `${FixedAssetGroupApi}?organisation_id=${userOrganisationId}`,
      "",
      6
  );

  FixedAssetGroupDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "administration_fixed_asset_class_id"
      );
      if (nextField) {
        const endPoint = `${FixedAssetClassApi}?fixed_asset_group_id=${id}`
        await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "administration_fixed_asset_group_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
  };

  const FixedAssetClassDropDown = createDropdownField(
      axiosPrivate,
      "administration_fixed_asset_class_id",
      "administration_fixed_asset_class_id",
      FixedAssetClass,
      ``,
      "",
      6
  );

  FixedAssetClassDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "administration_fixed_asset_type_id"
      );
      if (nextField) {
        const endPoint = `${FixedAssetTypeApi}?fixed_asset_class_id=${id}`
        await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "administration_fixed_asset_class_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
  };

  const FixedAssetTypeDropDown = createDropdownField(
      axiosPrivate,
      "administration_fixed_asset_type_id",
      "administration_fixed_asset_type_id",
      FixedAssetType,
      ``,
      "",
      6
  );

  async function updateFields() {
      const updatedFields = [FixedAssetGroupDropDown, FixedAssetClassDropDown, FixedAssetTypeDropDown, ...formFields];
      await FixedAssetGroupDropDown.fetchOptions();
      setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/administration/settings/fixed-asset/descriptions/${layoutURL}`;

  const onSubmit = async (data: any) => {
    data.organisation_id = userOrganisationId;
    // console.log(data);
    await axiosPrivate
      .post(FixedAssetDescriptionApi, data)
      .then((_res) => {
        toast.success(`${_res.data.data.name} Created Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  return { formTitle, formFields, backBtn, onSubmit };
}
