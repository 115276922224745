import React, { useEffect } from "react";
import { Input } from "reactstrap";
import { IYearInputsFigures } from "./types";
import dayjs from "dayjs"; // Install if you haven't: npm install dayjs

export type TYearInputs = {
    figures: IYearInputsFigures[];
    setFigures: React.Dispatch<React.SetStateAction<IYearInputsFigures[]>>;
};

export default function YearInputs({ figures, setFigures }: TYearInputs) {
    // Handle input change
    const handleAmountChange = (
        yearIndex: number,
        monthIndex: number,
        value: string
    ) => {
        const newFigures = [...figures];
        newFigures[yearIndex].date[monthIndex].amount = value;
        setFigures(newFigures); // Update the state with new values
        const updatedFigures = figures.map((yearData) => ({
            ...yearData,
            total: calculateTotal(yearData.date),
        }));
        setFigures(updatedFigures); // Update the figures state with totals
    };

    // ??
    const calculateTotal = (dates: any) => {
        return dates
            .reduce((total: string, date: any) => {
                return total + parseFloat(date.amount.replace(/,/g, ""));
            }, 0)
            .toLocaleString();
    };

    // ??
    useEffect(() => {
        const updatedFigures = figures.map((yearData) => ({
            ...yearData,
            total: calculateTotal(yearData.date),
        }));
        setFigures(updatedFigures);
    }, []);

    return (
        <div>
            {figures.map((yearData, yearIndex) => (
                <div key={yearIndex}>
                    <div className="d-flex justify-content-between gap-4 my-4">
                        <h6>{yearData.year}</h6>
                        <div className="w-100 gap-4 d-flex">
                            <Input
                                type="text"
                                className="form-control w-25 align-self-baseline"
                                placeholder="134,343"
                                value={yearData.total || "0"}
                            />
                            <div className="w-100 ">
                                <div className="d-flex flex-wrap gap-3">
                                    {yearData.date.map(
                                        (monthData, monthIndex) => (
                                            <span
                                                key={monthIndex}
                                                className="d-flex justify-content-between gap-5 rounded-2 bg-light text-dark align-items-center px-2 py-1 border"
                                            >
                                                <small>{monthData.month}</small>
                                                <small
                                                    contentEditable
                                                    className="rounded-5 px-2 py-1 bg-dark text-light"
                                                    onBlur={(e) =>
                                                        handleAmountChange(
                                                            yearIndex,
                                                            monthIndex,
                                                            e.target.innerText
                                                        )
                                                    }
                                                >
                                                    {monthData.amount}
                                                </small>
                                            </span>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            ))}
        </div>
    );
}

export const createYearFigures = (startDate: string, endDate: string) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    // Generate a list of months between start and end dates
    const months: { year: string; months: string[] }[] = [];
    let current = start;

    while (current.isBefore(end) || current.isSame(end, "month")) {
        const currentYear = current.year().toString();
        const currentMonth = current.format("MMMM"); // Full month name

        // Find the year in the months array or create it
        const yearIndex = months.findIndex((y) => y.year === currentYear);
        if (yearIndex === -1) {
            months.push({ year: currentYear, months: [currentMonth] });
        } else {
            months[yearIndex].months.push(currentMonth);
        }

        // Move to the next month
        current = current.add(1, "month");
    }

    // Create yearFigures array from the generated months
    const yearFigures = months.map((y) => ({
        year: y.year,
        total: "",
        date: y.months.map((month) => ({ month, amount: "0.00" })),
    }));

    return yearFigures;
};
