import { Fragment, useState } from "react";
import { Row, Col, Card, Input, Container, Button, CardBody } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Expense } from "../../../../Constant";
//import faceCard from "../../../../assets/images/user/3.jpg";
import ProductsRows from "../../Template/ProductsRows";
import InputProvider from "../../Template/InputProvider2";
import useRequestForm from "./controller";
import { Link } from "react-router-dom";
import SwitchRows from "../../Template/SwitchRows";
import FileUploader from "../../Template/FileUploader";
import UserCard from "../../Template/UserCard";
import YearInputs from "../../Template/YearInputs2";

export default function ExpenseRequestForm() {
    const {
        Beneficiary,
        userDetails,

        yearFigures,
        setYearFiguresData,

        switchLabels,
        setSwitchLabels,
        products,
        //setProducts,
        handleSubmit,

        receiptFormSchema,
        receiptInputData,
        handleReceiptChange,
        settleMentFormSchema,
        settlementInputData,
        handleSettlementChange,
        expenseFormSchema,
        expenseInputData,
        handleExpenseChange,
        transactionFormSchema,
        transactionInputData,
        handleTransactionChange,
        budgetCategorySchema,
        budgetCategoryInputData,
        handleBudgetCategoryChange,
    } = useRequestForm();

    return (
        <Fragment>
            <Breadcrumbs
                parent={Expense}
                title={`${Expense} Request`}
                mainTitle={`${Expense} Request`}
            />

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="ribbon-wrapper-right b-l-secondary border-2">
                            <CardBody className="pt-2">
                                <Link to={"/"}>
                                    <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                        Back To List
                                    </div>
                                </Link>

                                <form onSubmit={handleSubmit}>
                                    {/* CARD BIO INFO SECTION */}
                                    <Row>
                                        <Col md="6" sm="12">
                                            <UserCard
                                                details={{ ...userDetails }}
                                                isRequester={true}
                                            />
                                        </Col>

                                        <Col>
                                            <UserCard
                                                details={{ ...Beneficiary }}
                                                isRequester={false}
                                            />
                                        </Col>
                                    </Row>

                                    {/* <InputProvider
                                        schema={budgetCategorySchema}
                                        inputData={budgetCategoryInputData}
                                        onChange={handleBudgetCategoryChange}
                                    /> */}

                                    <InputProvider
                                        schema={settleMentFormSchema}
                                        inputData={settlementInputData}
                                        onChange={handleSettlementChange}
                                    />

                                    <InputProvider
                                        schema={expenseFormSchema}
                                        inputData={expenseInputData}
                                        onChange={handleExpenseChange}
                                    />

                                    <InputProvider
                                        schema={transactionFormSchema}
                                        inputData={transactionInputData}
                                        onChange={handleTransactionChange}
                                    />

                                    {/* <br />
                                    <ProductsRows
                                        products={products}
                                        setProducts={setProducts}
                                    /> */}

                                    
                                    <br />
                                    <InputProvider
                                        schema={receiptFormSchema}
                                        inputData={receiptInputData}
                                        onChange={handleReceiptChange}
                                    />
                                    
                                    <br />
                                    <YearInputs
                                        figures={yearFigures}
                                        setFigures={setYearFiguresData}
                                    />

                                    <SwitchRows
                                        setSwitchFormDetails={setSwitchLabels}
                                        switchFormDetails={switchLabels}
                                    />
                                    <hr />
                                    <br />
                                    <div className="">
                                        <label className="h6">Transaction Narration/Purpose</label>
                                        <Input type="textarea" />
                                    </div>

                                    <br />
                                    <FileUploader
                                        onFilesSelected={(e) => console.log(e)}
                                    />

                                    <div className="d-flex gap-4 align-content-center py-4">
                                        <Button color="primary">Submit</Button>

                                        <Button type="button" color="primary">
                                            Reject
                                        </Button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
