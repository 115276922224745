import { useContext, useState, useEffect } from 'react';
import CustomizerContext from '../../../_helper/Customizer';
import useAxiosPrivate from '../../../Hooks/useAxiosPrivate';
import {useParams, useNavigate } from "react-router-dom";

type RouteParams = {
    tabName?: string;
};

export default function useVendorManagement() {    
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const VENDOR = 'all';
    const MAPPING = 'mapping';
    
    const navigate = useNavigate();
    const { tabName } = useParams<RouteParams>();  
    const [commissionTab, setCommissionTab] = useState<string>(tabName || `${VENDOR}`); 
    
    const tabDisplayNames: { [key: string]: string } = {
        'all': 'Vendor',
        'mapping': 'Vendor Mapping',
      };

    useEffect(() => {
        if (tabName && tabName !== commissionTab) {
            setCommissionTab(tabName);
        }
    }, [tabName]);

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
        navigate(`/administration/vendor/${routeName}/${layoutURL}`); // Append to route
    };

    return {
        layoutURL,
        VENDOR,
        MAPPING,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    };
}
