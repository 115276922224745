import { Fragment } from "react";
import { Breadcrumbs } from "../../../../../../AbstractElements";
import { BackToList, GradeGroup, Settings } from "../../../../../../Constant";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import GenerateForm from "../../../../../Common/Component/GenerateForm";
import useHrmGradeGroupForm from "./controller";

//
const HrmGradeGroupForm = () => {
  const { backBtn, onSubmit, formFields, formTitle } =
    useHrmGradeGroupForm();
  return (
    <>
      <Breadcrumbs
        parent={Settings}
        title={GradeGroup}
        mainTitle={GradeGroup}
      />
      <Fragment>
        <Card className="ribbon-wrapper-right b-l-secondary border-2 mt-5">
          <CardBody>
            <Link to={backBtn}>
              <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                {BackToList}
              </div>
            </Link>
            <GenerateForm
              onSubmit={onSubmit}
              formFields={formFields}
              formTitle={formTitle}
              listUrl={backBtn}
              onEdit={undefined}
              editRecord={undefined}
            />
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default HrmGradeGroupForm;
