import { useContext, useState, useEffect } from 'react';
import CustomizerContext from '../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import {useParams, useNavigate } from "react-router-dom";

type RouteParams = {
    tabName?: string;
};

export default function useInventorySettings() {    
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const GROUP = 'groups';
    const CLASS = 'classes';
    const TYPE = 'types';
    const DESCRIPTION = 'descriptions';
    
    const navigate = useNavigate();
    const { tabName } = useParams<RouteParams>();  
    const [commissionTab, setCommissionTab] = useState<string>(tabName || `${GROUP}`); 
    
    const tabDisplayNames: { [key: string]: string } = {
        'groups': 'Group',
        'classes': 'Class',
        'types': 'Type',
        'descriptions': 'Description',
    };

    useEffect(() => {
        if (tabName && tabName !== commissionTab) {
            setCommissionTab(tabName);
        }
    }, [tabName]);

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
        navigate(`/administration/settings/inventory/${routeName}/${layoutURL}`); // Append to route
    };

    return {
        layoutURL,
        GROUP,
        CLASS,
        TYPE,
        DESCRIPTION,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    };
}
