import { HTMLInputTypeAttribute, useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

export interface InputProviderFormSchema {
    name: string;
    label: string;
    type: HTMLInputTypeAttribute | "select" | "html";
    options?: string[];
    placeholder?: string;
    required?: boolean;
    html?: string;
}

interface InputProviderProps {
    schema: InputProviderFormSchema[];
    inputData: { [key: string]: any } | null;
    onChange: (name: string, value: any) => void;
    // onSubmit: (data: { [key: string]: any }) => void;
}

type TFormElements = HTMLInputElement | HTMLSelectElement | any;

const InputProvider: React.FC<InputProviderProps> = ({
    schema,
    inputData,
    onChange,
    // onSubmit,
}) => {
    const handleChange = (e: React.ChangeEvent<TFormElements>) => {
        const { name, value, type } = e.target;
        //console.log(name, value, type);
        onChange(name, value);
    };

    // const handleSubmit = (e: React.FormEvent) => {
    //     e.preventDefault();
    //     onSubmit(inputData);
    // };

    return (
        <form
        // onSubmit={handleSubmit}
        >
            <Row>
                {schema.map((field) => (
                    <Col
                        md="6"
                        sm="12"
                        className="d-block my-2"
                        key={field.name}
                    >
                        <div className="relative d-flex gap-4 align-content-center">
                            {!field.html && (
                                <label className="d-block h7">
                                    {field.label}
                                    {field.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                            )}
                            {(() => {
                                switch (field.type) {
                                    case "select":
                                        return (
                                            <CustomSelect
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChange}
                                                options={field.options || []}
                                            />
                                        );
                                    case "radio":
                                        return (
                                            <CustomRadio
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChange}
                                                options={field.options || []}
                                            />
                                        );
                                    case "html":
                                        return (
                                            <CustomHtml
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChange}
                                            />);
                                          

                                    default:
                                        return (
                                            <CustomInput
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChange}
                                            />
                                        );
                                }
                            })()}
                        </div>
                    </Col>
                ))}
            </Row>

            <hr />
        </form>
    );
};

export default InputProvider;

type CustomInputProps = {
    field: InputProviderFormSchema;
    inputData: { [key: string]: any } | null;
    handleChange: (e: React.ChangeEvent<TFormElements>) => void;
};

type CustomSelectProps = {
    field: InputProviderFormSchema;
    inputData: { [key: string]: any } | null;
    handleChange: (e: React.ChangeEvent<TFormElements>) => void;
    options: string[];
};

type CustomHtmlProps = {
    field: InputProviderFormSchema;
    inputData: { [key: string]: any } | null;
    handleChange: (e: React.ChangeEvent<TFormElements>) => void;
};


function CustomInput(props: CustomInputProps) {
    const { field, inputData, handleChange } = props;

    return (
        <>
            <FormGroup className="w-100">
                <Input
                    type={field.type as any}
                    name={field.name}
                    className="form-control m-input digits form-control"
                    autoComplete={undefined}
                    onChange={handleChange}
                    value={
                        inputData && field.type === "checkbox"
                            ? inputData[field.name] || false
                            : (inputData && inputData[field.name]) || ""
                    }
                    required={field.required}
                />
            </FormGroup>
        </>
    );
}

function CustomSelect(props: CustomSelectProps) {
    const { field, inputData, handleChange } = props;

    return (
        <>
            <FormGroup className="w-100">
                <Input
                    name={field.name}
                    onChange={handleChange}
                    value={inputData && inputData[field.name]}
                    required={field.required}
                    id={field.name}
                    type="select"
                >
                    <option value="">Select an option</option>
                    {field.options?.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
}

function CustomRadio(props: CustomSelectProps) {
    const { field, inputData, handleChange } = props;

    return (
        <>
            <FormGroup className="row w-100">
                <ul className="radio-wrapper">
                    {field.options?.map((val, i) => (
                        <>
                            <li key={i}>
                                <Input
                                    type="radio"
                                    onChange={handleChange}
                                    className="form-check-input"
                                    style={{ cursor: "pointer" }}
                                    id={`radio-${field.name}-${i}`}
                                    defaultValue={
                                        inputData && inputData[field?.name]
                                    }
                                    name={field.name}
                                    value={val}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`radio-${field.name}-${i}`}
                                >
                                    <i className="icofont icofont-chart-pie-alt"></i>
                                    <span>{val}</span>
                                </label>
                            </li>
                        </>
                    ))}
                </ul>
            </FormGroup>
        </>
    );
}

function CustomHtml(props: CustomHtmlProps) {
    const { field, inputData, handleChange } = props;
    return (
        <>
            <div
                style={{ width: "100%", height: "100%" }}
                dangerouslySetInnerHTML={{
                    __html: field.html || "<div></div>",
                }}
            />
        </>
    );
}


