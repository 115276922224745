import { useState } from "react";

type textNode = {
    id: number;
    txtNode: string;
};

export default function useHeaderController(textNodes: textNode[]) {
    //

    //active
    const [active, setActive] = useState(textNodes[0]?.id);
    return {
        textNodes,
        active,
        setActive,
    };
}
