import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Breadcrumbs } from "../../../../../AbstractElements";
import InputProvider from "../../../Template/InputProvider";
import YearInputs from "../../../Template/YearInputs";
import useFinanceBudgetInputFormData from "./controller";

export default function FinanceBudgetInputFormData() {
    const {
        backBtn,
        handleSubmit,
        inputErrors,

        financeFormSchema,
        financeInputData,
        handleFinanceChange,

        monthAmountFormSchema,
        monthAmountInputData,
        handleMonthAmountChange,

        expenseIncomeFormSchema,
        expenseIncomeInputData,
        handleExpenseIncomeChange,

        costCenterFormSchema,
        costCenterInputData,
        handleCostCenterChange,

        yearFigures,
        setYearFigures,
    } = useFinanceBudgetInputFormData();

    return (
        <>
            <Fragment>
                <Breadcrumbs
                    parent={"Finance"}
                    title={`Finance Budget Input`}
                    mainTitle={`Finance Budget Input`}
                />

                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card className="ribbon-wrapper-right b-l-secondary border-2">
                                <CardBody className="pt-2">
                                    <Link to={backBtn}>
                                        <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                            Back To List
                                        </div>
                                    </Link>

                                    <form onSubmit={handleSubmit}>
                                        <InputProvider
                                            schema={financeFormSchema}
                                            inputData={financeInputData}
                                            onChange={handleFinanceChange}
                                            errors={inputErrors.financeFormErrors}
                                        />

                                        <InputProvider
                                            schema={expenseIncomeFormSchema}
                                            inputData={expenseIncomeInputData}
                                            onChange={handleExpenseIncomeChange}
                                            errors={inputErrors.expenseIncomeFormErrors}
                                        />

                                        <InputProvider
                                            schema={costCenterFormSchema}
                                            inputData={costCenterInputData}
                                            onChange={handleCostCenterChange}
                                            errors={inputErrors.costCenterFormErrors}
                                        />

                                        <InputProvider
                                            schema={monthAmountFormSchema}
                                            inputData={monthAmountInputData}
                                            onChange={handleMonthAmountChange}
                                            errors={inputErrors.monthAmountFormErrors}
                                        />
                                        
                                        {financeInputData.finance_fiscal_year_id && 
                                            (
                                            <>
                                            <br />
                                            <YearInputs
                                                figures={yearFigures}
                                                setFigures={setYearFigures}
                                            />
                                            </>
                                            )
                                        }

                                        {/* <div className="d-flex gap-4 align-content-center py-4"> */}
                                        <div className="text-end">
                                            <Button color="primary">
                                                Submit
                                            </Button>

                                            {/* <Button
                                                type="button"
                                                color="primary"
                                            >
                                                Reject
                                            </Button> */}
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>
    );
}
