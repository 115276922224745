import React, { Fragment, useContext, useState } from 'react';
import { Table  } from 'reactstrap';
import { H6 } from '../../../../../AbstractElements';
import { DateCreated, DateModified, EmailAddress, FirstName, LastName, NewVendorMember, PhoneNumber, Status } from '../../../../../Constant';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import useVendorMemberList from './controller';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';
import GenerateForm from '../../../../Common/Component/GenerateForm';
import { VendorMemberProps } from '../types';


const VendorMemberList: React.FC<VendorMemberProps> = ({vendor_id}) => {
    const {
        data,
        tableColumns,
        handleView,
        handleEdit,
        handleSubmit,
        viewModalOpen,
        selectedRowData,
        setViewModalOpen,
        formFields,
        memoizedHandleEdit,
        editMode,
        loading,
        showForm,
        toggleFormVisibility
    } = useVendorMemberList(vendor_id);

    return (
        <Fragment>
            <div>
                <span className='d-flex justify-content-between pull-right mb-0 '>
                    <span className='m-r-20' 
                        style={{ cursor: 'pointer' }}
                        onClick={toggleFormVisibility}>
                        <i className='fa fa-save font-primary'></i> {NewVendorMember}
                    </span>
                </span>
            </div>
            <br/>
            <div className={`display-content ${showForm ? 'show mb-4' : 'hide'}`}>
                {showForm && (
                    <>
                        <H6 attrH6={{ className: 'pb-2 f-w-600' }}>{NewVendorMember}</H6>
                        <hr className="mt-1 mb-4" />
                        <GenerateForm
                            onSubmit={handleSubmit}
                            formFields={formFields}
                            formTitle={undefined}
                            listUrl={undefined}
                            onEdit={undefined}
                            editRecord={undefined}
                        />
                    </>
                )} 
            </div>

            <DataTableComponent
                tabledata={data}
                tableColumns={tableColumns}
                isLoading={loading}
                onView={handleView}
                // onDelete={handleDelete}
                onEdit={handleEdit}
                useTableAction={undefined}
                addToDefaultAction={undefined}
                onDelete={undefined}
            />

            <ViewModal
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                rowData={selectedRowData}
                formFields={formFields}
                editMode={editMode}
                onEdit={memoizedHandleEdit}
                onSubmit={undefined}
                modalTitle={undefined}
            >
                {selectedRowData && (
                <>
                    <Table>
                        <tbody>
                            <tr className='border-bottom-secondary'>
                                <th scope='row'>{LastName}</th>
                                <td>{selectedRowData.last_name}</td>
                            </tr>
                            <tr className='border-bottom-secondary'>
                                <th scope='row'>{FirstName}</th>
                                <td>{selectedRowData.first_name}</td>
                            </tr>
                            <tr className='border-bottom-secondary'>
                                <th scope='row'>{EmailAddress}</th>
                                <td>{selectedRowData.email_address}</td>
                            </tr>
                            <tr className='border-bottom-primary'>
                                <th scope='row'>{PhoneNumber}</th>
                                <td>{selectedRowData.phone_number}</td>
                            </tr>
                            <tr className="border-bottom-primary">
                                <th scope="row">{Status}</th>
                                <td>
                                    {selectedRowData.is_active ? (
                                    <span className="badge badge-light-success">
                                        Active
                                    </span>
                                    ) : (
                                    <span className="badge badge-light-danger">
                                        Inactive
                                    </span>
                                    )}
                                </td>
                            </tr>
                            <tr className='border-bottom-primary'>
                                <th scope='row'>{DateCreated}</th>
                                <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                            </tr>
                            <tr className='border-bottom-secondary'>
                                <th scope='row'>{DateModified}</th>
                                <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </>
                )}
            </ViewModal>
        </Fragment>
    );
}

export default VendorMemberList

export const StatusRow = (row: any) => {
    return (
        <>
        {row.is_active ? (
          <span className="badge badge-light-success">Active</span>
        ) : (
          <span className="badge badge-light-danger">Inactive</span>
        )}
        </>
    )
};