import { useContext, useLayoutEffect, useMemo, useState } from "react";
import useAuth from "../../../../../Hooks/useAuth";
import CustomizerContext from "../../../../../_helper/Customizer";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { VendorMemberTypes } from "../types";
import { toast } from "react-toastify";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { VendorMemberApi } from "../../../../../api";
import {
  Status,
  DateCreated,
  DateModified,
  Email,
  PhoneNumber,
  View,
  LastName,
  FirstName,
  Phone,
  EmailAddress,
  VendorMember,
} from "../../../../../Constant";
import { StatusRow } from "./VendorMemberList";


export default function useVendorMemberList(vendor_id: number) {
  const { userOrganisationId }: any = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const title = VendorMember;
  const [data, setData] = useState<VendorMemberTypes[]>([]);
  const [showForm, setShowForm] = useState(false);

  const toggleFormVisibility = () => { 
    setShowForm(!showForm);
  };

  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "last_name",
      name: "last_name",
      label: LastName,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "first_name",
      name: "first_name",
      label: FirstName,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "phone_number",
      name: "phone_number",
      label: Phone,
      type: "text",
      value: "",
      required: false,
      colSize: 6,
    },
    {
      id: "email_address",
      name: "email_address",
      label: EmailAddress,
      type: "email",
      value: "",
      required: false,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const tableColumns = [
    {
      name: LastName,
      selector: (row: (typeof data)[0]) => `${row.last_name}`,
      sortable: true,
      center: false,
    },
    {
      name: FirstName,
      selector: (row: (typeof data)[0]) => `${row.first_name}`,
      sortable: true,
      center: false,
    },
    {
      name: Email,
      selector: (row: (typeof data)[0]) => `${row.email_address}`,
      sortable: true,
      center: false,
    },

    {
      name: PhoneNumber,
      selector: (row: (typeof data)[0]) => `${row.phone_number}`,
      sortable: true,
      center: false,
    },

    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },

    {
      name: DateCreated,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: DateModified,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `#`;
  const [loading, setLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axiosPrivate
      .get(
        `${VendorMemberApi}?with_trashed=${false}&vendor_id=${vendor_id}`
      )
      .then((res) => {
        const response: VendorMemberTypes[] = res.data;
        setData(response);

        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);

  
  const handleSubmit = async (data: any) => {
    data.vendor_id = vendor_id;
     
    await axiosPrivate
      .post(VendorMemberApi, {
        ...data,
        email_address: data.email_address || null,
        phone_number: data.phone_number || null,
      })
      .then((_res) => {
        const response: VendorMemberTypes = _res.data;

        /* setData((prevData) => ({
          ...prevData, 
          ...response
        }));  */

        toast.success(`${_res.data.last_name} Created Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0] | any
  ) => {
    await axiosPrivate
      .put(`${VendorMemberApi}/${id}`, {
        ...editRecord,
        vendor_id: vendor_id,
        email_address: editRecord.email_address || null,
        phone_number: editRecord.phone_number || null,
      })
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.last_name} Editted Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        // throw err;
        toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  const handleEdit = (row: (typeof data)[0] | any) => {
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };
  
  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  return {
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    loading,
    setViewModalOpen,
    handleView,
    handleEdit,
    memoizedHandleEdit,
    showForm,
    toggleFormVisibility,
    handleSubmit,
  };
}
