import React, { useState } from "react";
//import { PlusCircle, Trash } from "react-huge-icons/outline";
import { PlusCircle, Trash } from 'react-feather';

interface FileUploaderProps {
    onFilesSelected: (files: File[]) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onFilesSelected }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files);
            setSelectedFiles(filesArray);
            onFilesSelected(filesArray);
        }
    };

    const removeFile = (index: number) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);
        onFilesSelected(updatedFiles);
    };

    return (
        <div className="d-flex gap-5 my-2">
            <div
                style={{
                    width: "100px",
                }}
                className="h-100"
            >
                <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="hidden"
                    id="file-input"
                />
                <label
                    htmlFor="file-input"
                    style={{
                        height: "100px",
                        width: "100px",
                        border: "2px dashed",
                        cursor: "pointer",
                    }}
                    className="file-input-label border-primary block d-flex align-items-center justify-content-center rounded-4"
                >
                    <PlusCircle className="h1 w-50 h-50 text-primary mb-0" />
                </label>
            </div>
            <div className="d-flex gap-3 flex-wrap">
                {selectedFiles.map((file, index) => (
                    <div
                        key={index}
                        style={{
                            height: "100px",
                            width: "100px",
                            minWidth: "100px",
                            border: "2px dashed",
                            cursor: "pointer",
                        }}
                        className="p-relative border-primary block d-flex flex-column align-items-center justify-content-center rounded-4 overflow-hidden"
                    >
                        <div
                            style={{
                                height: "90px",
                                width: "100px",
                            }}
                            className="h-100 text-center"
                        >
                            {file.name}
                        </div>
                        <div
                            style={{
                                width: "100px",
                            }}
                        >
                            <button
                                style={{}}
                                type="button"
                                className="w-100 bg-danger text-light border-0"
                                onClick={() => removeFile(index)}
                            >
                                <Trash className="h3 w-25 h-25 mb-0 mt-1" />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileUploader;
