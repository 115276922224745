import { useContext, useLayoutEffect, useMemo, useState } from "react";
import useAuth from "../../../../../Hooks/useAuth";
import CustomizerContext from "../../../../../_helper/Customizer";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { DepartmentApi, DivisionApi, HrmRoleApi, UnitApi } from "../../../../../api";
import {
  Name,
  Status,
  Description,
  ModifiedBy,
  CreatedBy,
  Role,
  Alias,
  Division,
  Unit,
  Department
} from "../../../../../Constant";
import { HrmRoleTypes } from "../types";
import { toast } from "react-toastify";
import { StatusRow } from "../../../../Finance/Settings/ReportType/List/ReportTypeList";import { createDropdownField } from "../../../../../Data/FormField/PublicData";
;

export default function useHrmRole() {
  const { userOrganisationId }: any = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const title = Role;
  const [data, setData] = useState<HrmRoleTypes[]>([]);
  const departmentApi = DepartmentApi(userOrganisationId);
  const unitApi = UnitApi(userOrganisationId);
  const divisionApi = DivisionApi(userOrganisationId);
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "short_name",
      name: "short_name",
      label: Alias,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const tableColumns = [
    {
      name: Name,
      selector: (row: (typeof data)[0]) => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: Alias,
      selector: (row: (typeof data)[0]) => `${row.short_name}`,
      sortable: true,
      center: false,
    },
    {
      name: Description,
      selector: (row: (typeof data)[0]) => `${row.description}`,
      sortable: true,
      center: false,
    },
    {
      name: Unit,
      selector: (row: (typeof data)[0]) => `${row.unit.name}`,
      sortable: true,
      center: false,
    },

    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    {
      name: CreatedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: ModifiedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const divisionDropDown = createDropdownField(
      axiosPrivate,
      "division_id",
      "division_id",
      Division,
      divisionApi,
      "",
      6
  );

  divisionDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "department_id"
      );
      if (nextField) {
          const endPoint = `${departmentApi}?division_id=${id}`;
          await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "division_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
      //updateFields();
  };

  const departmentDropDown = createDropdownField(
      axiosPrivate,
      "department_id",
      "department_id",
      Department,
      "",
      "",
      6
  );

  departmentDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "unit_id"
      );
      if (nextField) {
          const endPoint = `${unitApi}?department_id=${id}`;
          await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "department_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
      //updateFields();
  };

  const unitDropDown = createDropdownField(
      axiosPrivate,
      "unit_id",
      "unit_id",
      Unit,
      "",
      "",
      6
  );

  // ?? Call on every dependent change
  async function updateFields() {
      /* const updatedFields = [divisionDropDown, departmentDropDown, unitDropDown, ...formFields];
      await divisionDropDown.fetchOptions();
      setFormFields(updatedFields); */

      const newFields = [divisionDropDown, departmentDropDown, unitDropDown];
      const filteredFields = formFields.filter(field => !newFields.includes(field));
      const updatedFields = [...newFields, ...filteredFields];
      await divisionDropDown.fetchOptions();
      setFormFields(updatedFields);
  }


  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `${process.env.PUBLIC_URL}/hrm/settings/roles/create/${layoutURL}`;
  const [loading, setLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axiosPrivate
      .get(
        `${HrmRoleApi}?&organisation_id=${userOrganisationId}`
      )
      .then((res) => {
        const response: HrmRoleTypes[] = res.data.data;
        setData(response);

        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);

  //
  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0] | any
  ) => {
    //console.log(editRecord);
    await axiosPrivate
      .put(`${HrmRoleApi}/${id}`, {
        ...editRecord,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.data.name} Editted Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        // throw err;
        toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };
  const handleEdit = (row: (typeof data)[0] | any) => {
    setViewModalOpen(true);
    setEditMode(true);

    row.division_id = row.unit.department.division_id;
    row.department_id = row.unit.department_id;
    row.unit_id = row.unit.id;
    
    divisionDropDown.onChange(row.division_id, formFields);
    departmentDropDown.onChange(row.department_id, formFields);
    setSelectedRowData(row);
  };
  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  return {
    handleView,
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    loading,
    setViewModalOpen,
    handleEdit,
    memoizedHandleEdit,
  };
}
