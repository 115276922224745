export const generateTransactionReference = (length: number): string => {
    const timestamp = Date.now().toString();
    const randomNum = Math.random().toString(36).substring(2, 2 + length);
    return `${timestamp}${randomNum}`;
};

const belowTwenty = [
    '', 'one', 'two', 'three', 'four', 'five', 'six', 
    'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 
    'thirteen', 'fourteen', 'fifteen', 'sixteen', 
    'seventeen', 'eighteen', 'nineteen'
];
const tens = [
    '', '', 'twenty', 'thirty', 'forty', 'fifty', 
    'sixty', 'seventy', 'eighty', 'ninety'
];
const thousands = ['', 'thousand', 'million', 'billion'];

const numberToWords = (num: number): string => {
    if (num === 0) return '';

    const words: string[] = [];
    let currentUnit = 0;

    while (num > 0) {
        const chunk = num % 1000;
        if (chunk > 0) {
            const chunkWords = [];
            if (chunk >= 100) {
                chunkWords.push(belowTwenty[Math.floor(chunk / 100)]);
                chunkWords.push('hundred');
            }
            const remainder = chunk % 100;
            if (remainder < 20) {
                chunkWords.push(belowTwenty[remainder]);
            } else {
                chunkWords.push(tens[Math.floor(remainder / 10)]);
                if (remainder % 10 > 0) {
                    chunkWords.push(belowTwenty[remainder % 10]);
                }
            }
            if (currentUnit > 0) {
                chunkWords.push(thousands[currentUnit]);
            }
            words.unshift(chunkWords.join(' ').trim());
        }
        num = Math.floor(num / 1000);
        currentUnit++;
    }

    return words.join(' ').trim();
};

export const amountToWords = (amount: number, currencyCode: string): string => {
    const currencyNames: { [key: string]: { singular: string; plural: string } } = {
        NGN: { singular: 'Naira', plural: 'Naira' }, // Naira has the same form
        USD: { singular: 'Dollar', plural: 'Dollars' },
        EUR: { singular: 'Euro', plural: 'Euros' },
        // Add more currencies as needed
    };

    const [integerPart, decimalPart] = amount.toString().split('.');
    const integerInWords = numberToWords(Number(integerPart));
    let currency = currencyNames[currencyCode] || { singular: 'Unit', plural: 'Units' };
    let result = `${integerInWords} ${integerPart !== '1' ? currency.plural : currency.singular}`;

    if (decimalPart) {
        const kobo = parseInt(decimalPart, 10); // Convert the decimal part to an integer
        if (kobo > 0) {
            const koboInWords = numberToWords(kobo);
            result += ` and ${koboInWords} ${currencyCode === 'NGN' ? 'Kobo' : 'Cents'}`;
        }
    }

    return capitalizeWords(result); // Capitalize each word
};

const capitalizeWords = (text: string): string => {
    return text
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

interface Duration {
    //days: number;
    hours: number;
    minutes: number;
}

export const convertDuration = (duration: Duration, toUnit: 'hours' | 'minutes' | 'seconds' = 'minutes'): number => {
    //const totalMinutes = duration.days * 24 * 60 + duration.hours * 60 + duration.minutes;
    const totalMinutes = duration.hours * 60 + duration.minutes;

    switch (toUnit) {
        case 'hours':
            return totalMinutes / 60; // Convert total minutes to hours
        case 'minutes':
            return totalMinutes; // Return total minutes
        case 'seconds':
            return totalMinutes * 60; // Convert total minutes to seconds
        default:
            return totalMinutes; // Default case, return minutes
    }
};

export const convertToDuration = (totalDuration: number, unit: 'minutes' | 'hours' = 'minutes'): Duration => {
    let totalMinutes: number;

    // Convert to total minutes based on the unit
    if (unit === 'hours') {
        totalMinutes = totalDuration * 60; // Convert hours to minutes
    } else {
        totalMinutes = totalDuration; // Already in minutes
    }

    // Calculate days, hours, and minutes
    //const days = Math.floor(totalMinutes / (24 * 60));
    //totalMinutes %= (24 * 60); // Remaining minutes after calculating days
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    //return { days, hours, minutes };
    return { hours, minutes };
};

export const formatDuration = (duration: Duration): string => {
    const parts: string[] = [];
    
    /* if (duration.days > 0) {
        parts.push(`${duration.days}d`);
        } */
        if (duration.hours > 0) {
           parts.push(`${duration.hours}h`);
        }
        if (duration.minutes > 0) {
            parts.push(`${duration.minutes}m`);
        }
        
        return parts.join(' ') || '0m'; // Default to '0m' if no duration is present
};
    
export const formatDurationHumanReadable = (duration: Duration): string => {
    const parts: string[] = [];
    
    /* if (duration.days > 0) {
    parts.push(`${duration.days}d`);
    } */
    if (duration.hours > 0) {
        parts.push(`${duration.hours} ${duration.hours === 1 ? "Hour" : "Hours"}`);
    }
    if (duration.minutes > 0) {
        parts.push(`${duration.minutes} ${duration.hours === 1 ? "Minute" : "Minutes"}`);
    }
    
    return parts.join(' ') || '0 Minute';
    //return `${duration?.hours} ${duration?.hours === 0 || duration?.hours === 1 ? 'hour' : 'hours'} ${duration?.minutes} ${(duration?.minutes === 0 || duration?.minutes === 1) ? 'minute' : 'minutes'}`
}

export const formatToDuration = (totalDuration: number, unit: 'minutes' | 'hours' = 'minutes'): string => {
    const durationFromMinutes = convertToDuration(totalDuration, unit); 
    
    return formatDurationHumanReadable(durationFromMinutes);
    //return formatDuration(durationFromMinutes);
}
