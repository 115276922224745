import { useContext, useState, useEffect } from 'react';
import CustomizerContext from '../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import {useParams, useNavigate } from "react-router-dom";

type RouteParams = {
    tabName?: string;
};

export default function useGradeGroupSettings() {    
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const GRADE_GROUP = 'grade-groups';
    const GRADE = 'grades';
    const STEP = 'steps';
    const GRADE_STEP = 'grade-steps';
    
    const navigate = useNavigate();
    const { tabName } = useParams<RouteParams>();  
    const [commissionTab, setCommissionTab] = useState<string>(tabName || `${GRADE_GROUP}`); 
    
    const tabDisplayNames: { [key: string]: string } = {
        'grade-groups': 'Grade Group',
        'grades': 'Grade',
        'steps': 'Step',
        'grade-steps': 'Grade Step',
      };

    useEffect(() => {
        if (tabName && tabName !== commissionTab) {
            setCommissionTab(tabName);
        }
    }, [tabName]);

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
        navigate(`/hrm/settings/${routeName}/${layoutURL}`); // Append to route
    };

    return {
        layoutURL,
        GRADE_GROUP,
        GRADE,
        STEP,
        GRADE_STEP,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    };
}
