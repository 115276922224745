import { useContext, useLayoutEffect, useMemo, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    Description,
    ExpenseCategory,
    ExpenseGroup,
    ExpenseClass,
    Status,
    TypeName,
    vatApply,
    whtApply,
    CreatedBy,
    ModifiedBy,
    Name,
} from "../../../../../Constant";
import { ExpenseClassResponse } from "../ExpenseClass.types";
import {
    financesExpenseCategoryApi,
    financesExpenseClassApi,
    financesExpenseGroupApi,
} from "../../../../../api";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { StatusRow } from "../../ReportType/List/ReportTypeList";
import { toast } from "react-toastify";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { StatusYesNo } from "./ExpenseClassList";

export default function useExpenseClassListController() {
    const axiosPrivate = useAxiosPrivate();
    const title = ExpenseClass;
    const { userOrganisationId }: any = useAuth();
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: TypeName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },
        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "",
            colSize: 6,
        },
        {
            id: "is_vat_apply",
            name: "is_vat_apply",
            label: vatApply,
            type: "switch",
            value: "",
            colSize: 6,
        },
        {
            id: "is_wht_apply",
            name: "is_wht_apply",
            label: whtApply,
            type: "switch",
            value: "",
            colSize: 6,
        },
    ]);

    //?? DROPDOWNS INITS >
    const expenseCategoryDropDown = createDropdownField(
        axiosPrivate,
        "expense_category",
        "expense_category",
        ExpenseCategory,
        `${financesExpenseCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    expenseCategoryDropDown.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "expense_group_id"
        );
        if (nextField) {
            const endPoint = `${financesExpenseGroupApi}?organisation_id=${userOrganisationId}&expense_category_id=${id}`
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "expense_category") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const expenseGroupDropDown = createDropdownField(
        axiosPrivate,
        "expense_group_id",
        "expense_group_id",
        ExpenseGroup,
        `${financesExpenseGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    expenseGroupDropDown.onChange = async (id, updatedFields) => {
        const updatedFormFields = updatedFields.map((field: any) => {
            if (field.id === "expense_group_id") {
                return { ...field, value: id };
            }
            return field;
        });
        setFormFields([...updatedFormFields]);
    };

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        (async function () {
            const updatedFields = [
                expenseCategoryDropDown,
                expenseGroupDropDown,
                ...formFields,
            ];
            await expenseCategoryDropDown.fetchOptions();
            setFormFields(updatedFields);
        })();
    }, []);

    const tableColumns = [
        {
            name: Name,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },
        {
            name: ExpenseCategory,
            selector: (row: (typeof data)[0]) =>
                `${row.expense_group.expense_category.name}`,
            sortable: true,
            center: false,
        },

        {
            name: ExpenseGroup,
            selector: (row: (typeof data)[0]) => `${row.expense_group.name}`,
            sortable: true,
            center: false,
        },

        {
            name: "VAT-Apply",
            selector: (row: (typeof data)[0]) => `${row.is_vat_apply}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusYesNo(row.is_vat_apply),
        },

        {
            name: "WHT-Apply",
            selector: (row: (typeof data)[0]) => `${row.is_wht_apply}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusYesNo(row.is_wht_apply),
        },

        {
            name: Status,
            selector: (row: (typeof data)[0]) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },

        {
            name: CreatedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.created_at)}`,
            sortable: false,
            center: false,
        },

        {
            name: ModifiedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.updated_at)}`,
            sortable: false,
            center: false,
        },
    ];

    const handleEdit = (row: (typeof data)[0] | any) => {
        setViewModalOpen(true);
        setEditMode(true);

        row.expense_group_id = row.expense_group.id;
        //expenseGroupDropDown.onChange(row.expense_group_id, formFields);

        row.expense_category = row.expense_group.expense_category.id;
        expenseCategoryDropDown.onChange(row.expense_category, formFields);

        setSelectedRowData(row);
    };

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/expense-class/create/${layoutURL}`;
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState<ExpenseClassResponse[]>([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const fetchData = () => {
        setLoading(true);
        axiosPrivate
            .get(
                `${financesExpenseClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
            )
            .then((res) => {
                const response: ExpenseClassResponse[] = res.data;
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                  toast.error(err.response.data.detail);
                } else {
                  toast.error('Something Went Wrong');
                }
              })
            .finally(() => {
                setLoading(false);
            });
    };

    useLayoutEffect(() => {
        fetchData();
    }, []);

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0] | any
    ) => {
        //?? dynamically pass the selected expense group id
        editRecord.expense_group = Number(editRecord.expense_group_id) as any;
        
        await axiosPrivate
            .put(`${financesExpenseClassApi}/${id}`, editRecord)
            .then((_res) => {
                const updatedData = data.map((row) =>
                    row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
                );
                setData(updatedData);
                toast.success(`${_res.data.name} Editted Successfully.`)
                //fetchData();
            })
            .catch((err) => {
              if (err.response && err.response.status === 422) {
                toast.error('Validation Errors');
              } else if (err.response && err.response.status === 400) {
                toast.error(err.response.data.detail);
              } else {
                toast.error('Something Went Wrong');
              }
              throw err;
              //toast.error(err.response.message || "Whoops! Something went wrong");
            });
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        editMode,
        newBtn,
        title,
        formFields,
        setViewModalOpen,
        handleView,
        handleEdit,
        loading,
        memoizedHandleEdit,
    };
}
