import { useContext, useLayoutEffect, useMemo, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    CreatedBy,
    Description,
    AccrualType,
    ModifiedBy,
    Status,
    Name,
    ShortName,
    FiscalYear,
    BudgetCategory,
    BudgetPurpose,
    Budget,
    Total,
    Code,
    View,
    ProcessType,
} from "../../../../../Constant";
import { financeBudgetApi, financesAccrualTypeApi } from "../../../../../api";
import { toast } from "react-toastify";
import { formatDateWithTimeZone, formatMoney } from "../../../../../_helper/dateHelper";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { FinanceBudgetInputRes } from "../FinanceBudgetInputFormData.types";

export default function useAccrualTypeListController() {
    const axiosPrivate = useAxiosPrivate();
    const title = Budget;
    const { userOrganisationId }: any = useAuth();
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "short_name",
            name: "short_name",
            label: ShortName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    const tableColumns = [
        {
            name: Code,
            selector: (row: (typeof data)[0]) => `${row.code}`,
            sortable: true,
            center: false,
        },
        {
            name: ProcessType,
            selector: (row: (typeof data)[0]) => `${row.item_description_type}`,
            sortable: true,
            center: false,
        },
        {
            name: FiscalYear,
            selector: (row: (typeof data)[0]) => `${row.finance_fiscal_year.short_name}`,
            sortable: true,
            center: false,
        },

        {
            name: BudgetCategory,
            selector: (row: (typeof data)[0]) => `${row.finance_budget_category.name}`,
            sortable: true,
            center: false,
        },
        {
            name: BudgetPurpose,
            selector: (row: (typeof data)[0]) => `${row.finance_budget_purpose.name}`,
            sortable: true,
            center: false,
        },
        {
            name: Total,
            selector: (row: (typeof data)[0]) => `${formatMoney(row.total_amount)}`,
            sortable: true,
            center: false,
        },
        {
            name: CreatedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.created_at)}`,
            sortable: false,
            center: false,
        },

        {
            name: ModifiedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.updated_at)}`,
            sortable: false,
            center: false,
        },
    ];

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        (async function () {
            const updatedFields = [...formFields];
            setFormFields(updatedFields);
        })();
    }, []);

    function fetchData() {
        setLoading(true);
        axiosPrivate
            .get(
                `${financeBudgetApi}?with_trashed=${false}&finance_fiscal_year_id=16&organisation_id=${userOrganisationId}`
            )
            .then((res) => {
                const response: FinanceBudgetInputRes[] = res.data.items;
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useLayoutEffect(() => {
        fetchData();
    }, []);

    const handleEdit = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(true);
        setSelectedRowData(row);
    };

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/budgets/create/${layoutURL}`;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<FinanceBudgetInputRes[]>([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const useTableAction = [
        {
          label: `${View}`,
          action: handleView,
          color: 'success',
          icon: 'icon-eye',
        },
    ];

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
        await axiosPrivate
            .put(`${financesAccrualTypeApi}/${id}`, {
                ...editRecord,
                organisation_id: userOrganisationId,
            })
            .then((_res) => {
                const updatedData = data.map((row) =>
                    row.id === selectedRowData!.id
                        ? { ...row, ..._res.data.items }
                        : row
                );
                setData(updatedData);
                toast.success(`${_res.data.name} Editted Successfully.`);
                //fetchData();
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                throw err;
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        editMode,
        newBtn,
        title,
        formFields,
        loading,
        setViewModalOpen,
        useTableAction,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    };
}
