import { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useFinanceBudgetCycle from "./FinanceBudgetCycles.controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import ViewModal from "../../../../Common/Component/ViewModal";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import {
  DateCreated,
  DateModified,
  Name,
  Settings,
  Status,
  Description,
} from "../../../../../Constant";

const FinanceBudgetCycle = () => {
  const {
    newBtn,
    data,
    tableColumns,
    handleView,
    handleEdit,
    viewModalOpen,
    selectedRowData,
    setViewModalOpen,
    formFields,
    memoizedHandleEdit,
    editMode,
    title,
    loading,
  } = useFinanceBudgetCycle();
  return (
    <Fragment>
      <Breadcrumbs
        parent={Settings}
        title={"Finance Budget Cycles"}
        mainTitle={"Finance Budget Cycles"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title={title}
                btnUrl={newBtn}
                span1={undefined}
                span2={undefined}
                mainClasses={undefined}
              />
              <CardBody>
                <DataTableComponent
                  tabledata={data}
                  tableColumns={tableColumns}
                  isLoading={loading}
                  onView={handleView}
                  // onDelete={handleDelete}
                  onEdit={handleEdit}
                  useTableAction={undefined}
                  addToDefaultAction={undefined}
                  onDelete={undefined}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
          onSubmit={undefined}
          modalTitle={undefined}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className="border-bottom-primary">
                    <th scope="row">{Name}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Status}</th>
                    <td>
                      {selectedRowData.is_active ? (
                        <span className="badge badge-light-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{DateCreated}</th>
                    <td>
                      {formatDateWithTimeZone(selectedRowData.created_at)}
                    </td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{DateModified}</th>
                    <td>
                      {formatDateWithTimeZone(selectedRowData.updated_at)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default FinanceBudgetCycle;
