import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Label, Media, Row, TabPane } from 'reactstrap';
import { Btn, H6, Image, P } from '../../../../../../AbstractElements';
import three from '../../../../../../assets/images/user/3.jpg';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';
import { Comment, Resolution, ResolutionCode, Save, Update } from '../../../../../../Constant';
import Select from 'react-select';
import { ResolutionCodeApi } from '../../../../../../api';
import { createDropdownField } from '../../../../../../Data/FormField/PublicData';
import useAuth from '../../../../../../Hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '../../../../../Common/Component/Spinner';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';

const CaseResolution = ({ Details, onCaseResolutionSubmit, canPerformAction }) => {
  const { contact } = Details; 
  const axiosPrivate = useAxiosPrivate()
  const messageInputRef = useRef(null);
  const { userOrganisationId } = useAuth();
  const [resolutionCodeOptions, setResolutionCodeOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const ResolutionCodeDropdownField = createDropdownField(axiosPrivate,
    'resolution_code_id',
    'resolution_code_id',
    `${ResolutionCode}`,
    `${ResolutionCodeApi}?organisation_id=${userOrganisationId}`,
  );

  useLayoutEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      setIsLoading((state) => !state)
      
      await ResolutionCodeDropdownField.fetchOptions();
      setResolutionCodeOptions(ResolutionCodeDropdownField.options)
      setIsLoading((state) => !state)
    }

   fetchData();

    return () => {  abortController.abort();};
  },[setValue])

   useEffect(() => {
    const abortController = new AbortController();
     if (resolutionCodeOptions.length > 0 && Details) {
      setValue('message', Details['resolved_comment']);
      
      const selectedIndex = resolutionCodeOptions.findIndex(option => option.value === Details.resolution_code_id);
      const defaultValue = selectedIndex !== -1 ? resolutionCodeOptions[selectedIndex] : null;
      setValue('resolution_code_id', defaultValue);
    }
    return () => { 
    abortController.abort();};
  }); 
  //}, [resolutionCodeOptions, setValue]); 
 
 const onSubmit = async (data) => {
    //event.preventDefault();
    setBtnLoading(true);
    if (data !== '') {
      data.resolution_code_id = data.resolution_code_id.value;
      
      await onCaseResolutionSubmit(data);
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='case-resolution'>
        <Row>
          <Col sm='12'>
            <Card>
              <CardBody>
                <div className='new-users-social'>
                  <Media className='align-items-center'>
                    <Image attrImage={{ className: 'rounded-circle image-radius m-r-15', src: `${three}`, alt: '' }} />
                    <Media body>
                      <H6 attrH6={{ className: 'mb-0 f-w-700' }}>
                        {contact && (
                          contact.company_name ? contact.company_name :
                          (contact.last_name ? `${contact.last_name} ${contact.first_name} ${contact.middle_name}` : '')
                        )}
                      </H6>
                      <P>{formatDateWithTimeZone(Details.created_at)}</P>
                    </Media>
                   </Media>
                </div>
                
                <div className='timeline-content'>
                  <H6 attrH6={{ className: 'pb-2 f-w-600' }}>{Resolution}</H6>
                  <hr className="mt-1 mb-4" />
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md='6 mb-3'>
                        <Label className='form-label'>
                          {ResolutionCode}
                        </Label>
                        <Controller
                          name="resolution_code_id"
                          control={control}
                          rules={{ required: true }}
                          //defaultValue={2}
                          render={({ field, value }) => (
                            <Select 
                              {...field}
                              name={field.name}
                              options={resolutionCodeOptions}
                              isClearable={true}
                              className="js-example-basic-single col-sm-12"
                              //value={value}
                              /* onChange={(selectedOption) => {
                                field.onChange(selectedOption ? selectedOption.value : null); // Pass only the value
                              }} */
                              //onChange={(e) => field.onChange && field.onChange(e ? e.value : null)}
                            />
                          )}
                        />
                          <span className='d-block' style={{ color: 'red' }}>{errors.resolution_code_id && errors.resolution_code_id.type === 'required' && 'Resolution Code is required'}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='12 mb-3'>
                        <Label className='form-label'>
                          {Comment}
                        </Label> 
                        <textarea ref={messageInputRef} 
                        //defaultValue={Details.resolved_comment} 
                        {...register('message', { required: true })}
                        className='form-control' style={{ height: '6rem' }} />
                        <span className='d-block' style={{ color: 'red' }}>{errors.message && 'Resolution Comment is required'}</span>
                      </Col>
                    </Row>
                    {
                      canPerformAction &&
                      <div className='text-end'>
                        <Btn attrBtn={{ color: 'primary' }}>
                          {btnLoading ? <Spinner /> : <><i className='fa fa-save'></i> {Save}</>}
                        </Btn>
                      </div>
                    }
                    
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default CaseResolution;
