import { Fragment, useContext, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { Container, Row, Col, Card, Table, CardBody, Media, Button } from 'reactstrap';
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs"
import { DateCreated, DateModified, DeleteSuccess, Description, Case, Status, TrackingNumber, Priority, ResolutionStatus, CaseGroup, CaseTypeName, Contact, Link, OpenedBy, ResolvedBy, SourceSystemUser, PickUpTime, ResolutionTeam, ResolutionTime, DelegationTime, EscalationFunctionalTime, EscalationExecutiveTime, DelegateTeam, ExternalDelegateTeam, GlobalResolutionTime, Timeline } from "../../../../Constant"
import HeaderCard from '../../../Common/Component/HeaderCard'; 
import CustomizerContext from '../../../../_helper/Customizer'; 
import { toast } from 'react-toastify';
import DataTableServerFetchComponent from '../../../Common/Component/DataTableServerFetchComponent'; 
import ViewModal from '../../../Common/Component/ViewModal'; 
import { case_form_fields } from './Case';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate'; 
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper'; 
import { CaseApi, CaseGroupApi, CaseTypeApi, CustomerApi, CustomerLinkApi, PriorityApi, ResolutionStatusApi } from '../../../../api';
import useAuth from '../../../../Hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ContactTypeVal, createDropdownField, dynamic_field } from '../../../../Data/FormField/PublicData';
import { Image } from '../../../../AbstractElements';
import noimage from '../../../../assets/images/no-image.png';
import GenerateFormTwo from '../../../Common/Component/GenerateFormTwo';
import DatePicker from 'react-datepicker';
import CommonPopover from '../../../Common/Component/CommonPopover';
import { formatToDuration } from '../../../../_helper/utils';

const CaseList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const history = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(case_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
  const [filterformFields, setFilterFormFields] = useState([]);
  const [isNewFilterFieldAdded, setIsNewFilterFieldAdded] = useState(false);
  const [caseTypeData, setCaseTypeData] = useState({});
  const [selectedCaseTypeId, setSelectedCaseTypeId] = useState(null);
  const [updatedFields, setUpdatedFields] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const [showFilter, setShowFilter] = useState(false);

  const toggleFilterVisibility = () => {
    setShowFilter(!showFilter);
  };

  const caseGroupDropdownField = createDropdownField(axiosPrivate,
    'case_group_id',
    'case_group_id',
    `${CaseGroup}`,
    `${CaseGroupApi}?organisation_id=${userOrganisationId}&service_type_id=1`,
    '', 6, '','', true
  );

  const caseTypeDropdownField = createDropdownField(axiosPrivate,
    'case_type_id',
    'case_type_id',
    `${CaseTypeName}`,
    ``,
    '', 6, '','', true
  );

  const customerDropdownField2 = createDropdownField(axiosPrivate,
    'contact_id',
    'contact_id',
    `${Contact}`,
    `${CustomerApi}?organisation_id=${userOrganisationId}`,
    '', 6, '', 'last_name, first_name, middle_name'
  );

  const customerDropdownField = {
    name: "contact_id",
    id: "contact_number, company_name, last_name, first_name",
    api: `${CustomerApi}`,
    label: `${Contact}`,
    type: "input-select",
    value: { id: "", label: "" },
    colSize: 6,
    required: true,
    disabled: true,
    onSelectedChange : async function (selectedId, updatedFields) { 
      const linkField = updatedFields.find((field) => field.id === 'link_id');
      
      if (linkField) {
        const endPoint = `${CustomerLinkApi}?organisation_id=${userOrganisationId}&contact_id=${selectedId}`
        await linkField.fetchOptions(selectedId, false, endPoint);
        setFormFields([...updatedFields]);
      } 
    }
  };

  const linkDropdownField = createDropdownField(axiosPrivate,
    'link_id',
    'link_id',
    `${Link}`,
    ``,
    '', 6, false, 
    //'contact.branch.contact_number, contact.branch.name, contact.branch.street_number, contact.branch.street_name'
    //'contact.branch.contact_number, contact.branch.name', 
    "contact_branch.contact_number, contact_branch.name | contact.contact_number, contact.company_name | contact.contact_number, contact.last_name, contact.first_name | [product.name]",
    true
  );

  const Prioritydropdown = createDropdownField(axiosPrivate,
    'priority_id',
    'priority_id',
    `${Priority}`,
    `${PriorityApi}?organisation_id=${userOrganisationId}`,
    '', 6, '','', true
  );

  const ResolutionStatusdropdown = createDropdownField(axiosPrivate,
    'resolution_status_id',
    'resolution_status_id',
    `${ResolutionStatus}`,
    `${ResolutionStatusApi}?organisation_id=${userOrganisationId}`,
    '', 6, '','', true
  );

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [caseGroupDropdownField, 
            caseTypeDropdownField, 
            customerDropdownField, 
            linkDropdownField, 
            //Prioritydropdown, 
            ResolutionStatusdropdown, 
            ...formFields];
          //const indexOfName = updatedFields.findIndex((formField) => formField.id === 'case_Category_id');

          await updatedFields[0].fetchOptions();
          //await customerDropdownField.fetchOptions();
          //await Prioritydropdown.fetchOptions();
          await ResolutionStatusdropdown.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

         }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, caseGroupDropdownField, caseTypeDropdownField, customerDropdownField]);

  //==================================
  const caseGroupDropdownFieldFilter = createDropdownField(axiosPrivate,
    'case_group_id',
    'case_group_id',
    `${CaseGroup}`,
    `${CaseGroupApi}?organisation_id=${userOrganisationId}&service_type_id=1`,
    '', 6, false,''
  );

  const caseTypeDropdownFieldFilter = createDropdownField(axiosPrivate,
    'case_type_id',
    'case_type_id',
    `${CaseTypeName}`,
    ``,
    '', 6, false,''
  );

  caseGroupDropdownFieldFilter.onChange = async (selectedCaseGroupId, updatedFields) => { 
    const caseTypeField = updatedFields.find((field) => field.id === 'case_type_id');
    
    const updatedFormFields = updatedFields.map((field) => {
      if (field.id === 'case_group_id') {
        return { ...field, value: selectedCaseGroupId };
      }
      return field;
    });

    if (caseTypeField) {
      const endPoint = `${CaseTypeApi}?organisation_id=${userOrganisationId}&case_group_id=${selectedCaseGroupId}&only_active=false`
      await caseTypeField.fetchOptions(selectedCaseGroupId, false, endPoint);
    } 
    
    setFilterFormFields([...updatedFormFields]);
  };

  const customerDropdownFieldFilter = {
    name: "contact_id",
    id: "contact_number, company_name, last_name, first_name",
    api: `${CustomerApi}`,
    label: `${Contact}`,
    type: "input-select",
    value: { id: "", label: "" },
    colSize: 6,
    required: false,
    disabled: false,
    onSelectedChange : async function (selectedId, updatedFields) { 
      const linkField = updatedFields.find((field) => field.id === 'link_id');
      
      if (linkField) {
        const endPoint = `${CustomerLinkApi}?organisation_id=${userOrganisationId}&contact_id=${selectedId}`
        await linkField.fetchOptions(selectedId, false, endPoint);
        setFilterFormFields([...updatedFields]);
      } 
    }
  };
  //==================================
  useLayoutEffect(() => {

    const fetchFilterData = async () => { 
      try {
        if (!isNewFilterFieldAdded) { 
          const NewPrioritydropdown = { ...Prioritydropdown };
          NewPrioritydropdown.setRequiredStatus(false);
          NewPrioritydropdown.setDisabledStatus(false);

          const NewResolutionStatusdropdown = { ...ResolutionStatusdropdown };
          NewResolutionStatusdropdown.setRequiredStatus(false);
          NewResolutionStatusdropdown.setDisabledStatus(false);

          const NewLinkDropdownField = { ...linkDropdownField };
          NewLinkDropdownField.setDisabledStatus(false);

          const updatedFields = [caseGroupDropdownFieldFilter, 
            caseTypeDropdownFieldFilter, 
            customerDropdownFieldFilter, 
            NewLinkDropdownField, 
            NewPrioritydropdown, 
            NewResolutionStatusdropdown,
            dynamic_field('track_number', `${TrackingNumber}`, 'text', 6, false),
            dynamic_field('source_system_user_id', `${SourceSystemUser}`, 'text', 6, false),
            ...filterformFields
          ];
          //const indexOfName = updatedFields.findIndex((formField) => formField.id === 'case_Category_id');

          await Promise.all([
            updatedFields[0].fetchOptions(),
            NewPrioritydropdown.fetchOptions(),
            NewResolutionStatusdropdown.fetchOptions(),
          ]);
          
          setFilterFormFields(updatedFields);
          setIsNewFilterFieldAdded(true);

         }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchFilterData();
  }, [filterformFields, isNewFilterFieldAdded, caseGroupDropdownFieldFilter, caseTypeDropdownFieldFilter, customerDropdownFieldFilter]);


  caseGroupDropdownField.onChange = async (selectedCaseGroupId, updatedFields) => { 
    const caseTypeField = updatedFields.find((field) => field.id === 'case_type_id');
    
    const updatedFormFields = updatedFields.map((field) => {
      if (field.id === 'case_group_id') {
        return { ...field, value: selectedCaseGroupId };
      }
      return field;
    });

    if (caseTypeField) {
      const endPoint = `${CaseTypeApi}?organisation_id=${userOrganisationId}&case_group_id=${selectedCaseGroupId}&only_active=false` 
      await caseTypeField.fetchOptions(selectedCaseGroupId, false, endPoint, true);
      setCaseTypeData(caseTypeField.data)
      setSelectedCaseTypeId(null);
    } 
    setFormFields([...updatedFormFields]);
  };

  useEffect(() => {
    if (selectedCaseTypeId !== null) { 
      const rec = caseTypeData[selectedCaseTypeId] || {}; 
      handleCaseTypeChange(updatedFields, rec); 
    } 
  }, [selectedCaseTypeId, caseTypeData]); 

  caseTypeDropdownField.onChange = async (selectedCaseTypeId, updatedFields) => {
    setSelectedCaseTypeId(selectedCaseTypeId); 
    setUpdatedFields(updatedFields)
  }

  const handleCaseTypeChange = (updatedFields, rec) => {
    const indexOfSubject = updatedFields.findIndex((formField) => formField.id === 'subject');
    const existingPriorityIndex = updatedFields.findIndex(formField => formField.id === "priority");
    const existingSlaDetailIndex = updatedFields.findIndex((formField) => formField.id === 'sla_details');
    
    if(selectedCaseTypeId <=0 && existingPriorityIndex > -1) { 
      updatedFields = updatedFields.filter(field => field.id !== "priority");
      updatedFields = updatedFields.filter(field => field.id !== "sla_details");
  
      setFormFields([...updatedFields]);
      return
    }
    
    if(selectedCaseTypeId <=0 || Object.keys(caseTypeData).length <= 0){
      return
    }
    
    const priority_field = {
      id: 'priority',
      name: 'priority',
      label: `${Priority}`,
      type: 'html',
      html: (
        <>
          <span style={{ color: rec.priority.color }}>
            <i className="fa fa-flag"></i> {rec.priority.name}
          </span>
        </>
      ),
      required: false,
      colSize: 6,
    };
    
    const popOverBody = <>
        <h6>{ResolutionTeam}</h6>
        <Table style={{ fontSize: '11px' }}>
            <tbody>
              <tr className=''>
                  <th scope='row'>{PickUpTime}</th>
                  <td>{formatToDuration(rec.resolution_pickup_time)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{ResolutionTime}</th>
                  <td>{formatToDuration(rec.resolution_time)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{DelegationTime}</th>
                  <td>{formatToDuration(rec.resolution_delegation_time)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{EscalationFunctionalTime}</th>
                  <td>{formatToDuration(rec.resolution_escalation_time1)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{EscalationExecutiveTime}</th>
                  <td>{formatToDuration(rec.resolution_escalation_time2)}</td>
              </tr>
            </tbody>
        </Table>

        <br/>
        <h6>{DelegateTeam}</h6>
        <Table style={{ fontSize: '11px' }}>
            <tbody>
              <tr className=''>
                  <th scope='row'>{PickUpTime}</th>
                  <td>{formatToDuration(rec.delegation_pickup_time)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{ResolutionTime}</th>
                  <td>{formatToDuration(rec.delegation_resolution_time)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{DelegationTime}</th>
                  <td>{formatToDuration(rec.delegation_delegation_time)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{EscalationFunctionalTime}</th>
                  <td>{formatToDuration(rec.delegation_escalation_time1)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{EscalationExecutiveTime}</th>
                  <td>{formatToDuration(rec.delegation_escalation_time2)}</td>
              </tr>
            </tbody>
        </Table>

        <br/>
        <h6>{ExternalDelegateTeam}</h6>
        <Table style={{ fontSize: '11px' }}>
            <tbody>
              <tr className=''>
                  <th scope='row'>{PickUpTime}</th>
                  <td>{formatToDuration(rec.external_delegation_pickup_time || 0)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{ResolutionTime}</th>
                  <td>{formatToDuration(rec.external_delegation_resolution_time || 0)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{EscalationFunctionalTime}</th>
                  <td>{formatToDuration(rec.external_delegation_escalation_time1 || 0)}</td>
              </tr>
              <tr className=''>
                  <th scope='row'>{EscalationExecutiveTime}</th>
                  <td>{formatToDuration(rec.external_delegation_escalation_time2 || 0)}</td>
              </tr>
            </tbody>
        </Table>
      </>

    const popover = {
      id: 3,
      placement: "left",
      Popoverheader: `${GlobalResolutionTime}: ${formatToDuration(rec.global_resolution_time)}`,
      Popoverbody: popOverBody,
      popoverBodyClass: "scrollable-popover",
      //popoverBodyStyle: { maxHeight: '250px', overflowY: 'auto' },
      //popoverStyle: { maxWidth: '500px' },
      btncolor: "primary",
      btntext: "View Timeline",
      trigger: "hover",
    };
    
    const sla_field = {
      id: 'sla_details',
      name: 'sla_details',
      label: `${Timeline}`,
      type: 'html',
      html: (
        <>
          <CommonPopover key={popover.id} item={popover} />
        </>
      ),
      required: false,
      colSize: 6,
    };

    if (existingPriorityIndex > -1) { 
      updatedFields[existingPriorityIndex] = priority_field;
      updatedFields[existingSlaDetailIndex] = sla_field;
    } else {
      updatedFields.splice(indexOfSubject + 1, 0, ...[priority_field, sla_field]);
    }

    setFormFields([...updatedFields]);
  }

  const getCaseURL = (data, page, size = perPage) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value)
    );

    const params = new URLSearchParams({
      organisation_id: userOrganisationId,
      page,
      size: size,
      ...filteredData
    }).toString();
  
    return `${CaseApi}?${params}`;
  }
  
  const getAllCaseData = async (data = {}, page = 1) => {
      try {
          setIsLoading(true);
          setFilterData(data);

          //await axiosPrivate.get(`${CaseApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`)
          await axiosPrivate.get(getCaseURL(data, page))
          .then((resp) => {
              setAllData(resp.data.items);
              setTotalRows(resp.data.total);
          });
      } catch (error) {
          toast.error(error.response.data.detail)
          //setIsLoading(false);
      } finally {
        // Set loading to false after data is fetched (regardless of success or failure)
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllCaseData(filterData);

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const showCaseDetails = async (row) => {
    const detailsLink = `${process.env.PUBLIC_URL}/crm/case-management/case/${row.track_number}/${layoutURL}`;
    history(detailsLink);
  }

  const handleEdit = async (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.case_group_id = row.case_type.case_group_id;
    row.priority_id = row.case_type.priority_id;
    await caseGroupDropdownField.onChange(row.case_group_id, formFields);
    
    caseTypeDropdownField.onChange(row.case_type_id, formFields);
    //if (customerDropdownField) {
      customerDropdownField.onSelectedChange(row.contact_id, formFields);
    //}
    const contactID = formFields.filter(
      (field) => field.name === "contact_id"
    );
    contactID[0].value.id = row.contact.id;
    contactID[0].value.label = (row.contact.contact_type === ContactTypeVal.Corporate) ?
    `${row.contact.contact_number} ${row.contact.company_name}` :
    ((row.contact.last_name) ?
      `${row.contact.contact_number} ${row.contact.last_name} ${row.contact.first_name}  ${row.contact.middle_name}` :
      "No Name");

    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      case_type_id: editRecord.case_type_id,
      contact_id: editRecord.contact_id,
      link_id: editRecord.link_id,
      //priority_id: editRecord.priority_id,
      resolution_status_id: editRecord.resolution_status_id,
      subject: editRecord.subject,
      message: editRecord.message,
      //due_date: editRecord.due_date ? editRecord.due_date : null,
    };
    
    try {
        await axiosPrivate.put(`${CaseApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);
          //setAllData(resp.data.items);
          //setTotalRows(resp.data.total);

          toast.success(`${resp.data.track_number} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${CaseApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      toast.error('Error deleting record:');
      //console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/crm/case-management/case/create/${layoutURL}`;
  
  const tableColumns = [
    {
      name: `${Contact}`,
      //selector: row => `${row.subject}`,
      sortable: true,
      center: false,
      minWidth: '20',
      cell: row => (
                      <>
                        <Media className='d-flex'>
                          {/* <Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: '' }} /> */}
                          <Media body className="align-self-center">
                              <div>
                                {row.contact.contact_type === ContactTypeVal.Individual ? 
                                  `${row.contact.last_name} ${row.contact.first_name} ${row.contact.middle_name}` : 
                                  `${row.contact?.company_name}`
                                } ({row.contact?.contact_number})
                              </div>
                          </Media>
                        </Media>
                      </>
                    )
    },
    /* {
      name: `${BranchContactID}`,
      selector: row => `${row.link?.contact_branch?.contact_number}`,
      sortable: true,
      center: false,
    }, */
    {
      name: `${Link}`,
      selector: row => `${row.link?.contact_branch?.name}`,
      sortable: true,
      center: false,
      cell: (row) => LinkName(row),
    },
    {
      name: `${TrackingNumber}`,
      selector: row => `${row.track_number}`,
      sortable: true,
      center: false,
      wrap: true,
      //minWidth: '10',
    },
    {
      name: `${Case}`,
      selector: row => `${row.subject}`,
      sortable: true,
      center: false,
      wrap: true,
    },
    {
      name: `${CaseTypeName}`,
      selector: row => `${row.case_type.name}`,
      sortable: true,
      center: false,
      wrap: true,
    },
    {
      name: `${Priority}`,
      selector: row => `${row.case_type.priority_id}`,
      sortable: true,
      center: false,
      cell: row => (<span style={{color: row.case_type.priority.color}}><i className="fa fa-flag"></i> {row.case_type.priority.name}</span>)
    },
    {
      name: `${ResolutionStatus}`,
      selector: row => `${row.resolution_status_id}`,
      sortable: true,
      center: false,
      cell: row => (<span style={{color: row.resolution_status.color}}><i className="fa fa-bell"></i> {row.resolution_status.name}</span>)
    },
    {
      name: `${OpenedBy}`,
      selector: row => `${row.opened_by_employee}`,
      sortable: true,
      center: false,
      cell: row => (
                      <>
                          <div>
                            {row.opened_by_employee?.contact?.contact_type === ContactTypeVal.Individual ? 
                              `${row.opened_by_employee?.contact?.last_name} ${row.opened_by_employee?.contact?.first_name} ${row.opened_by_employee?.contact?.middle_name}` : 
                              `${row.opened_by_employee?.contact?.company_name}`
                            } ({row.opened_by_employee?.employee_number})
                          </div>
                      </>
      ),
    },
    /* {
      name: `${ResolvedBy}`,
      selector: row => `${row.resolved_by_employee}`,
      sortable: true,
      center: false,
      cell: row => (
                      <>
                          <div>
                            {row.resolved_by_employee?.contact?.contact_type === ContactTypeVal.Individual ? 
                              `${row.resolved_by_employee?.contact?.last_name} ${row.resolved_by_employee?.contact?.first_name} ${row.resolved_by_employee?.contact?.middle_name}` : 
                              `${row.resolved_by_employee?.contact?.company_name}`
                            } ({row.resolved_by_employee?.employee_number})
                          </div>
                      </>
      ),
    }, */
    /* {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    }, */
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
      wrap: true,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
      wrap: true,
    },
  ];

  const handlePageChange = page => {
    getAllCaseData(filterData, page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    //const response = await axiosPrivate.get(`${CaseApi}?organisation_id=${userOrganisationId}&page=${page}&size=${newPerPage}`);
    const response = await axiosPrivate.get(getCaseURL(filterData, page, newPerPage));
    setAllData(response.data.items);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [sDate, eDate] = dateRange;

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="Case" mainTitle="Case" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} span1={undefined} span2={undefined} mainClasses={undefined} />
              <CardBody>
                
                {/* <DatePicker
                  selected={startDate}
                  onChange={onDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                />
                
                <DatePicker
                  className='form-control datetimepicker-input digits'
                  dateFormat="yyyy/MM/dd"
                  showWeekNumbers
                  selectsRange={true}
                  startDate={sDate}
                  endDate={eDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  withPortal
                /> */}
                <div className='d-flex justify-content-between pull-right mt-0'>
                  <Button
                      color={showFilter ? 'danger' : 'warning'}
                      className=""
                      onClick={toggleFilterVisibility}
                  >
                      <i className={`fa fa-${showFilter ? 'times-circle-o' : 'filter'}`}></i>&nbsp;
                  </Button>
                </div>

                {showFilter && (
                  <div className='mt-5'>
                  <GenerateFormTwo 
                    formFields={filterformFields} 
                    listUrl={'/'}
                    actions={[
                      {
                        name: 'allCaseData',
                        label: 'Fiter Case',
                        onClick: getAllCaseData,
                        color: 'danger',
                      }
                    ]} 
                  />
                  </div>
                )}

                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={showCaseDetails}
                  onDelete={handleDelete}
                  onEdit={handleEdit}

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />

              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                <tr className='border-bottom-primary'>
                    <th scope='row'>{Case}</th>
                    <td>{selectedRowData.subject}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default CaseList;

export const LinkName = (row) => {
  return row.link.contact_branch ? (
      `${row.link.contact_branch.name}`
  ) : (
      `${row.link.contact.last_name} ${row.link.contact.first_name}`
  );
};