import { useContext, useState, useEffect } from 'react';
import CustomizerContext from '../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import {useParams, useNavigate } from "react-router-dom";

type RouteParams = {
    tabName?: string;
};

export default function usePayGroupSettings () {
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const PAY_CATEGORY = 'pay-categories';
    const PAY_HEADLINE = 'pay-headlines';
    const GRADE_GROUP_PAY_HEADLINE = 'grade-group-pay-headlines';
    const PAY_STRUCTURE = 'pay-structures';
    
    const navigate = useNavigate();
    const { tabName } = useParams<RouteParams>();  
    const [commissionTab, setCommissionTab] = useState<string>(tabName || `${PAY_CATEGORY}`); 
    
    const tabDisplayNames: { [key: string]: string } = {
        'pay-categories': 'Pay Category',
        'pay-headlines': 'Pay Headline',
        'grade-group-pay-headlines': 'Grade Group Pay Headline',
        'pay-structures': 'Pay Structure',
      };

    useEffect(() => {
        if (tabName && tabName !== commissionTab) {
            setCommissionTab(tabName);
        }
    }, [tabName]);

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
        navigate(`/payroll/settings/${routeName}/${layoutURL}`); // Append to route
    };

    return {
        layoutURL,
        PAY_CATEGORY,
        PAY_HEADLINE,
        GRADE_GROUP_PAY_HEADLINE,
        PAY_STRUCTURE,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    };
}