import { Breadcrumbs } from "../../../../AbstractElements";
import useTeamMember from "./TeamMember.controller";
import { ExternalTeam, InternalTeam, Settings } from "../../../../Constant";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import InternalTeamAssign from "./InternalTeam/InternalTeamAssign";
import ExternalTeamAssign from "./ExternalTeam/ExternalTeamAssign";

export default function TeamMember() {
    const {
        title,
        commissionTab,
        INTERNALTEAMTAB,
        EXTERNALTEAMTAB,
        handleTabClick,
    } = useTeamMember();

    return (
        <div className="mt-5 GeneralLedgerAssign">
            <Breadcrumbs parent={Settings} title={title} mainTitle={title} />
            <Nav className='border-tab nav-secondary nav-pills' tabs>
                <NavItem>
                    <NavLink href='#' className={commissionTab === `${INTERNALTEAMTAB}` ? 'active' : ''}
                            onClick={() => handleTabClick(`${INTERNALTEAMTAB}`)}>
                        <i className='icofont icofont-users'></i>
                        {InternalTeam}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href='#' className={commissionTab === `${EXTERNALTEAMTAB}` ? 'active' : ''}
                            onClick={() => handleTabClick(`${EXTERNALTEAMTAB}`)}>
                        <i className='icofont icofont-users'></i>
                        {ExternalTeam}
                    </NavLink>
                </NavItem>
            </Nav> 
            <TabContent activeTab={commissionTab}>
                <TabPane className='fade show' tabId={INTERNALTEAMTAB}>
                    <InternalTeamAssign />
                </TabPane>

                <TabPane className='fade show' tabId={EXTERNALTEAMTAB}>
                    <ExternalTeamAssign />
                </TabPane>
                </TabContent>
        </div>
    );
}
