import React, { Fragment } from "react";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Button,
    Table,
} from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
    PaymentsTxt,
    TransactionTxt,
    Date,
    Name,
    TransactionReference,
    PaymentReference,
    Amount,
    Currency,
    EmailAddress,
    PhoneNumber,
    PaymentChannel,
    PrintReceipt,
    DownloadReceipt,
    AmountInWords,
} from "../../../../Constant";
import HeaderCard from "../../../Common/Component/HeaderCard";
import usePaymentTransaction from "./controller";
import InputProvider from "../../../Finance/Template/InputProvider";
import { amountToWords } from "../../../../_helper/utils";

export default function PaymentTransaction() {
    const {
        handleSubmit,
        topupWalletFormSchema,
        setTopupWalletFormSchema,
        topupWalletInputData,
        handleTopupWalletChange,
    } = usePaymentTransaction();

    return (
        <div>
            <Fragment>
                <Breadcrumbs
                    parent={PaymentsTxt}
                    title={TransactionTxt}
                    mainTitle={TransactionTxt}
                />
                <Container fluid={true}>
                    <Card>
                        <HeaderCard
                            title={TransactionTxt}
                            span1={null}
                            span2={null}
                            mainClasses={null}
                            btnUrl={null}
                        />
                        <CardBody>
                            <div className="col d-flex gap-2 justify-content-end align-items-center">
                                <Button
                                    color="primary"
                                    className="btn-xs"
                                    onClick={() => {}}
                                >
                                    <i className="fa fa-print"></i>&nbsp;
                                    {PrintReceipt}
                                </Button>

                                <Button
                                    color="warning"
                                    className="btn-xs"
                                    onClick={() => {}}
                                >
                                    <i className="fa fa-cloud-download"></i>&nbsp;
                                    {DownloadReceipt}
                                </Button>
                            </div>

                            <Table>
                                <tbody>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{TransactionReference}</th>
                                        <td>{"TNX1726830131177"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{PaymentReference}</th>
                                        <td>{"TNX1726830131177"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{Date}</th>
                                        <td>{"20th September, 2024"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{Name}</th>
                                        <td>{"Admin VDT"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{EmailAddress}</th>
                                        <td>{"adm@text.com"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{PhoneNumber}</th>
                                        <td>{"2348000000000"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{Currency}</th>
                                        <td>{"NGN"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{Amount}</th>
                                        <td>{"650.00"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{PaymentChannel}</th>
                                        <td>{"Web"}</td>
                                    </tr>
                                    <tr className='border-bottom-secondary'>
                                        <th scope='row'>{AmountInWords}</th>
                                        <td>{amountToWords(Number(650), 'NGN')}</td>
                                    </tr>
                                </tbody>
                            </Table>

                        </CardBody>
                    </Card>
                </Container>
            </Fragment>
        </div>
    );
}
