import { useContext, useMemo, useState } from "react";
import useAuth from "../../../../../Hooks/useAuth";
import CustomizerContext from "../../../../../_helper/Customizer";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import {StatusRow} from "../../../../Finance/Settings/ReportType/List/ReportTypeList";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { ContactMgmtApi, HrmStepApi, VendorApi } from "../../../../../api";
import {
  Name,
  Status,
  VendorMapping,
  DateCreated,
  DateModified,
  MapVendor,
  ContactID,
  PhoneNumber,
  ContactType,
  Email,
} from "../../../../../Constant";
import { VendorTypes } from "../../Vendor/types";
import { FullNameRow } from "../../Vendor/List/VendorList";
import { ContactTypeVal } from "../../../../../Data/FormField/PublicData";
import { vendor_form_fields } from "../types";
import { useNavigate } from 'react-router-dom';


export default function useVendorMapping() {
  const { userOrganisationId }: any = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const title = VendorMapping;
  const [data, setData] = useState<VendorTypes[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState<any[]>(vendor_form_fields);

  const history = useNavigate();

  const tableColumns = [
    {
      name: Name,
      selector: (row: (typeof data)[0]) => `${row.last_name}`,
      sortable: true,
      center: false,
      cell: (row: (typeof data)[0]) => FullNameRow(row),
    },
    {
      name: ContactID,
      selector: (row: (typeof data)[0]) => `${row.contact_number}`,
      sortable: true,
      center: false,
    },
    {
      name: Email,
      selector: (row: (typeof data)[0]) => `${row.user_login.email}`,
      sortable: true,
      center: false,
    },

    {
      name: PhoneNumber,
      selector: (row: (typeof data)[0]) => `${row.phone_number}`,
      sortable: true,
      center: false,
    },

    {
      name: ContactType,
      selector: (row: (typeof data)[0]) => row.contact_type === ContactTypeVal.Individual ? "Individual" : "Corporate",
      sortable: true,
      center: false,
    },

    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    {
      name: DateCreated,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: DateModified,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = ``;
  const listUrl = `${process.env.PUBLIC_URL}/administration/vendor/all/${layoutURL}`;
  const [loading, setLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  //
  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0] | any
  ) => {
    await axiosPrivate
      .put(`${HrmStepApi}/${id}`, {
        ...editRecord,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.data.name} Editted Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        // throw err;
        toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  const handleCustomerMapping = async (id: number | string, editRecord: (typeof data)[0] | any) => {
    //console.log(editRecord)
    //return false
    await axiosPrivate.post(`${VendorApi}/map-contact`, {
      ...editRecord,
      contact_id: id,
      credit_volume: editRecord.credit_volume || null,
      paid_up_capital: editRecord.paid_up_capital || null,
      tenor: editRecord.tenor || null
    }).then((resp) => {
        toast.success(`Contact is successfully mapped as Vendor.`)
        history(listUrl);
    }).catch((err) => {
      setIsLoading(false);
      if (err.response && err.response.status === 422) {
        toast.error("Validation Errors");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Whoops! Something went wrong");
      }
    });
  }

  const memoizedHandleEdit = useMemo(
    () => handleCustomerMapping,
    [data, selectedRowData]
  );

  const openCustomerMapView = async (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(true);
    row.is_active = row.user_login.is_active
    setSelectedRowData(row);
  }

  const vendorMapAction = [
    {
      label: `${MapVendor}`,
      action: openCustomerMapView,
      color: 'warning',
      icon: 'icon-book',
    },
  ];
  
  const handleSubmit = async (searchValue: any) => {
    setIsLoading(true);
    
    await axiosPrivate.get(`${ContactMgmtApi}?organisation_id=${userOrganisationId}&search_value=${searchValue}&is_vendor=false`)
    .then((resp) => {
      setData(resp.data.items);

      toast.success(`Contact Found.`)
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      if (err.response && err.response.status === 422) {
        toast.error("Validation Errors");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Whoops! Something went wrong");
      }
      // throw err;
      //toast.error(err.response.message || "Whoops! Something went wrong");
    });
  };

  return {
    isOpen,
    setIsOpen,
    handleSubmit,
    vendorMapAction,
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    isLoading,
    setViewModalOpen,
    memoizedHandleEdit,
  };
}
