import Button_ from "./Button";
import useHeaderController from "./controller";

const Header = ({ navCallBack, navTextNodes }: { navCallBack: (arg: string) => void, navTextNodes: { id: number, txtNode: string }[] }) => {
  const { textNodes, active, setActive } = useHeaderController(navTextNodes);

  //
  return (
    <>

      <div className="nav-bar">
        {textNodes.map((_, i) => (
          <ul className="border-tab nav-secondary nav-pills nav nav-tabs" key={i}>
            <Button_
              text={_.txtNode}
              callback={() => {
                navCallBack(_.txtNode);
                setActive(_.id);
              }}
              id={_.id}
              active={active}
            />
          </ul>
        ))}
      </div>
    </>
  );
};

export default Header;
