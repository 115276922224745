import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import useAuth from "../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { paymentCheckoutTransactionStatusApi } from "../../../../api";
import CustomizerContext from "../../../../_helper/Customizer";

export default function usePaymentCallBack() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const navigate = useNavigate();
    const { layoutURL } = useContext(CustomizerContext);
    
    const [referenceNo, setReferenceNo] = useState<string | undefined>(undefined);

    const handleSubmit = async () => {
        const payload: any = {}

        if (!referenceNo)   
            return false;

        await axiosPrivate
            .get(`${paymentCheckoutTransactionStatusApi(referenceNo)}`)
            .then((_res) => {
                if (_res.data.data.status == "success") {
                    
                    const refNo = _res.data.data.transaction.reference; 
                    const tranxRefNo = _res.data.data.transaction.metadata.meta.customerRef; 
                    //navigate(authorizationUrl);
                    const paymentRedirect = `${process.env.PUBLIC_URL}/payments/transactions/${tranxRefNo}/${layoutURL}`;
                    navigate(paymentRedirect)
                    //window.location.href = paymentRedirect;
                    console.log(paymentRedirect)
                }
                toast.success(`Payment Successfully Completed.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
    }

    return {
        setReferenceNo,
        handleSubmit,
    }

}