import { useContext, useState, useEffect } from 'react';
import CustomizerContext from '../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import {useParams, useNavigate } from "react-router-dom";

type RouteParams = {
    tabName?: string;
};

export default function useControlSettings() {    
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const DISPOSAL_REASON = 'disposal-reasons';
    const DISPOSAL_METHOD = 'disposal-methods';
    const ITEM_CONDITION = 'item-conditions';
    const ITEM_STATUS = 'item-statuses';
    const STORAGE_MODE = 'storage-modes';
    
    const navigate = useNavigate();
    const { tabName } = useParams<RouteParams>();  
    const [commissionTab, setCommissionTab] = useState<string>(tabName || `${DISPOSAL_REASON}`); 
    
    const tabDisplayNames: { [key: string]: string } = {
        'disposal-reasons': 'Disposal Reason',
        'disposal-methods': 'Disposal Method',
        'item-conditions': 'Item Condition',
        'item-statuses': 'Item Status',
        'storage-modes': 'Storage Mode',
      };

    useEffect(() => {
        if (tabName && tabName !== commissionTab) {
            setCommissionTab(tabName);
        }
    }, [tabName]);

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
        navigate(`/administration/settings/${routeName}/${layoutURL}`);
    };

    return {
        layoutURL,
        DISPOSAL_REASON,
        DISPOSAL_METHOD,
        ITEM_CONDITION,
        ITEM_STATUS,
        STORAGE_MODE,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    };
}
