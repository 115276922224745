import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, TabContent, Nav, NavItem, NavLink, TabPane } from "reactstrap";
import { Breadcrumbs } from "../../../../../AbstractElements";
import useCaseTypeController from "./controller";
import { CaseTypeName, CRM, DelegateTeam, ExternalDelegateTeam, ResolutionTeam } from "../../../../../Constant";
import InputProvider from "../../../../Finance/Template/InputProvider";
import SwitchRows from "../../../../Finance/Template/SwitchRows";

export default function CaseType() {
    const {
        backBtn,

        tabResolution,
        tabDelegation,
        tabXDelegation,
        activeTab,
        handleTabClick,

        handleSubmit,
        inputErrors,

        caseFormSchema,
        caseInputData,
        handleCaseChange,

        resolutionTeamFormSchema,
        resolutionTeamInputData,
        handleResolutionTeamChange,

        delegationTeamFormSchema,
        delegationTeamInputData,
        handleDelegationTeamChange,

        externalDelegationTeamFormSchema,
        externalDelegationTeamInputData,
        handleExternalDelegationTeamChange,

        switchLabels,
        setSwitchLabels,

    } = useCaseTypeController();

    return (
        <Fragment>
          <Breadcrumbs 
            parent={CRM} 
            title={`New ${CaseTypeName}`} 
            mainTitle={`New ${CaseTypeName}`}  
          />
          <Container fluid={true}>
            <Row>
              <Col sm='12'>
                <Card className="ribbon-wrapper-right b-l-secondary border-2">
                    <CardBody className="pt-2">
                        <Link to={backBtn}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                Back To List
                            </div>
                        </Link>

                        <form onSubmit={handleSubmit}>
                            <InputProvider
                                schema={caseFormSchema}
                                inputData={caseInputData}
                                onChange={handleCaseChange}
                                errors={inputErrors.caseFormErrors}
                            />

                            <Row>
                                <Col sm={'12'}>
                                    <Nav className='border-tab nav-secondary nav-pills' tabs>
                                        <NavItem>
                                            <NavLink href='#' className={activeTab === tabResolution ? 'active' : ''}
                                                        onClick={() => handleTabClick(tabResolution)}>
                                                <i className='icofont icofont-users'></i>
                                                {ResolutionTeam}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href='#' className={activeTab === tabDelegation ? 'active' : ''}
                                                        onClick={() => handleTabClick(tabDelegation)}>
                                                <i className='icofont icofont-users'></i>
                                                {DelegateTeam}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href='#' className={activeTab === tabXDelegation ? 'active' : ''}
                                                        onClick={() => handleTabClick(tabXDelegation)}>
                                                <i className='icofont icofont-users'></i>
                                                {ExternalDelegateTeam}
                                            </NavLink>
                                        </NavItem>
                                    </Nav> 
                                    <TabContent activeTab={activeTab}>
                                        <TabPane className='fade show' tabId={tabResolution}>
                                            <InputProvider
                                                schema={resolutionTeamFormSchema}
                                                inputData={resolutionTeamInputData}
                                                onChange={handleResolutionTeamChange}
                                                errors={inputErrors.resolutionTeamFormErrors}
                                            />
                                        </TabPane>

                                        <TabPane className='fade show' tabId={tabDelegation}>
                                            <InputProvider
                                                schema={delegationTeamFormSchema}
                                                inputData={delegationTeamInputData}
                                                onChange={handleDelegationTeamChange}
                                                errors={inputErrors.delegationTeamFormErrors}
                                            />
                                        </TabPane>

                                        <TabPane className='fade show' tabId={tabXDelegation}>
                                            <InputProvider
                                                schema={externalDelegationTeamFormSchema}
                                                inputData={externalDelegationTeamInputData}
                                                onChange={handleExternalDelegationTeamChange}
                                                errors={inputErrors.externalDelegationTeamErrors}
                                            />
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>

                            <SwitchRows
                                setSwitchFormDetails={setSwitchLabels}
                                switchFormDetails={switchLabels}
                            />

                            {/* <div className="d-flex gap-4 align-content-center py-4 text-end"> */}
                            <div className="text-end">
                                <Button color="primary">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
                
              </Col>
            </Row>
          </Container>
        </Fragment>
      );
}