import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  IncomeClass,
  Description,
  ClassName,
  IncomeGroup,
  IncomeCategory,
  Status,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
  
  financesIncomeCategoryApi,
  financesIncomeClassApi,
  financesIncomeGroupApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import useAuth from "../../../../../Hooks/useAuth";
export default function useIncomeClassController() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = IncomeClass;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: ClassName,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
    {
      id: "is_vat_apply", 
      name: "is_vat_apply",
      label: "wht Apply",
      type: "switch",
      value: "1",
      colSize: 6,
    },
    {
      id: "is_wht_apply",
      name: "is_wht_apply",
      label: "Vat Apply",
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const incomeCategory = createDropdownField(
    axiosPrivate,
    "income_category_id",
    "income_category_id",
    IncomeCategory,
    `${financesIncomeCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );

  incomeCategory.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "income_group_id"
    ); 
    if (nextField) {
      const endPoint = `${financesIncomeGroupApi}?organisation_id=${userOrganisationId}&income_category_id=${id}`
      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "income_category_id") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const incomeGroup = createDropdownField(
    axiosPrivate,
    "income_group_id",
    "income_group_id",
    IncomeGroup,
    `${financesIncomeGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}'`,
    "",
    6
  );

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [incomeCategory, incomeGroup, ...formFields];
    await incomeCategory.fetchOptions();
    setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/finance/settings/income-class/${layoutURL}`;

  const onSubmit = async (data: any)  =>{
    let income_group_id = data.income_group_id;
    data.income_category_id = income_group_id;
    await axiosPrivate
      .post(financesIncomeClassApi, data)
      .then((_res) => {
        toast.success(`${_res.data.name} Created Successfully.`)
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error('Validation Errors');
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
        throw err;
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  }

  return { formTitle, formFields, backBtn, onSubmit };
}
