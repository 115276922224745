import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../../_helper/Customizer";
import {
  Description,
  Status,
  Name,
  FixedAssetClass,
  FixedAssetGroup,
  UsefulLife,
  InsuranceRate,
  AnnualAllowanceRate,
  InitialAllowanceRate,
  InvestmentAllowanceRate,
} from "../../../../../../Constant";

import { toast } from "react-toastify";
import useAuth from "../../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../../Hooks/useAxiosPrivate";
import { FixedAssetClassApi, FixedAssetGroupApi } from "../../../../../../api";
import { createDropdownField } from "../../../../../../Data/FormField/PublicData";

export default function useFixedAssetClassForm() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = FixedAssetClass;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "investment_allowance_rate",
      name: "investment_allowance_rate",
      label: InvestmentAllowanceRate,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "initial_allowance_rate",
      name: "initial_allowance_rate",
      label: InitialAllowanceRate,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "annual_allowance_rate",
      name: "annual_allowance_rate",
      label: AnnualAllowanceRate,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "insurance_rate",
      name: "insurance_rate",
      label: InsuranceRate,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "useful_life",
      name: "useful_life",
      label: UsefulLife,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const FixedAssetGroupDropDown = createDropdownField(
      axiosPrivate,
      "administration_fixed_asset_group_id",
      "administration_fixed_asset_group_id",
      FixedAssetGroup,
      `${FixedAssetGroupApi}?organisation_id=${userOrganisationId}`,
      "",
      6
  );
  
  async function updateFields() {
      const updatedFields = [FixedAssetGroupDropDown, ...formFields];
      await FixedAssetGroupDropDown.fetchOptions();
      setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/administration/settings/fixed-asset/classes/${layoutURL}`;

  const onSubmit = async (data: any) => {
    data.organisation_id = userOrganisationId;
    // console.log(data);
    await axiosPrivate
      .post(FixedAssetClassApi, data)
      .then((_res) => {
        toast.success(`${_res.data.data.name} Created Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  return { formTitle, formFields, backBtn, onSubmit };
}
