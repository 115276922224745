import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DepartmentApi, LocationDropdownApi, ServiceScopeApi, TeamApi, TeamTypeApi, UnitApi, VendorApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { Department, ExternalTeam, Location, ServiceScope, Team, TeamGroup, TeamType, Unit, Vendor } from '../../../../Constant';
import useAuth from '../../../../Hooks/useAuth';

const TeamCreate = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/hrm/personnel/teams/${layoutURL}`;
  const [formFields, setFormFields] = useState(base_form_fields(`${Team}`, 6));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
  const { formState: { errors }, } = useForm();

  const teamTypeDropdown = createDropdownField(axiosPrivate,
    'team_type_id',
    'team_type_id',
    `${TeamType}`,
    `${TeamTypeApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const unitDropdown = createDropdownField(axiosPrivate,
    'unit_id',
    'unit_id',
    `${Unit}`,
    `${UnitApi(userOrganisationId)}`,
    '', 6, false
  );

  const deptDropdown = createDropdownField(axiosPrivate,
    'department_id',
    'department_id',
    `${Department}`,
    `${DepartmentApi(userOrganisationId)}`,
    '', 6, false
  );

  const locationTypeDropdown = createDropdownField(axiosPrivate,
    'location_id',
    'location_id',
    `${Location}`,
    `${LocationDropdownApi(userOrganisationId)}`,
    '', 6, false
  );

  const internalExternal = {
    id: 'is_external',
    name: 'is_external',
    label: `${ExternalTeam}`,
    type: 'switch',
    value: '1',
    required: false,
    colSize: 6,
  };

  const internalExternalDropdown = createDropdownField(axiosPrivate,
    'is_external',
    'is_external',
    `${TeamGroup}`,
    ``,
    '', 6
  );

  const serviceScopeDropdown = createDropdownField(axiosPrivate,
    'service_scope_id',
    'service_scope_id',
    `${ServiceScope}`,
    `${ServiceScopeApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const vendorDropdownField = {
    name: "vendor_id",
    id: "contact.contact_number, contact.company_name, contact.last_name, contact.first_name",
    api: `${VendorApi}/transpose`,
    label: `${Vendor}`,
    type: "input-select",
    value: "",
    colSize: 6,
    required: false,
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const updatedFields = [
            teamTypeDropdown, 
            serviceScopeDropdown, 
            ...formFields, 
            deptDropdown, 
            locationTypeDropdown, 
            internalExternal, 
            vendorDropdownField
          ];
          
          /* internalExternalDropdown.options[
            {value: 0, label: 'Internal'},
            {value: 1, label: 'External'}
          ]; */
          await teamTypeDropdown.fetchOptions();
          await serviceScopeDropdown.fetchOptions();
          await deptDropdown.fetchOptions();
          await locationTypeDropdown.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, teamTypeDropdown]);

  internalExternal.onChnage = async (selectedContactTypeId, updatedFields) => {
    alert(selectedContactTypeId)
  }
  /* contactTypeDropdownField.onChange = async (selectedContactTypeId, updatedFields) => { 
    
    if (selectedContactTypeId === `${ContactTypeVal.Corporate}`) {
      const indexContactType = updatedFields.findIndex((updatedField) => updatedField.id === 'contact_type_id');
      updatedFields.splice(indexContactType + 1, 0, ...corporate_form_fields);
    } else {
      // Remove corporate_form_fields if contact type is not '2'
      updatedFields = updatedFields.filter(field => !corporate_form_fields.includes(field));
    }

    setFormFields([...updatedFields]);
  }; */

  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      team_type_id: newRecord.team_type_id,
      service_scope_id: newRecord.service_scope_id,
      name: newRecord.name,
      description: newRecord.name,
      is_active: newRecord.is_active,
      unit_id: newRecord.unit_id || null,
      department_id: newRecord.department_id || null,
      location_id: newRecord.location_id || null,
      vendor_id: newRecord.vendor_id || null,
      is_external: newRecord.is_external,
    };

    try {
      await axiosPrivate.post(TeamApi, newRecordTemp, {
        headers: {
          //'Content-Type': 'multipart/form-data',
          'Content-Type': 'application/json',
        },
      }).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="HRM" title="New Team" mainTitle="New Team" />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TeamCreate;