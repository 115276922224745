import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import useFinanceBudgetCyclesForm from "./controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import GenerateForm from "../../../../Common/Component/GenerateForm";
import { BackToList, Settings } from "../../../../../Constant";
import { Fragment } from "react";
const FinanceBudgetCycleList = () => {
  const { onSubmit, formFields, backBtn, formTitle } =
    useFinanceBudgetCyclesForm();
  return (
    <>
      <Breadcrumbs
        parent={Settings}
        title={"Finance Budget Cycles"}
        mainTitle={"Finance Budget Cycles"}
      />
      <Fragment>
        <Card className="ribbon-wrapper-right b-l-secondary border-2 mt-5">
          <CardBody>
            <Link to={backBtn}>
              <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                {BackToList}
              </div>
            </Link>
            <GenerateForm
              onSubmit={onSubmit}
              formFields={formFields}
              formTitle={formTitle}
              listUrl={backBtn}
              onEdit={undefined}
              editRecord={undefined}
            />
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default FinanceBudgetCycleList;
