import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../../_helper/Customizer";
import {
  Description,
  Status,
  Name,
  Alias,
  InventoryType,
  CountUnit,
  ReorderLevel,
  MinOrderQuantity,
  OrderingTime,
  InventoryGroup,
  InventoryClass,
  StorageMode,
} from "../../../../../../Constant";

import { toast } from "react-toastify";
import useAuth from "../../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../../Hooks/useAxiosPrivate";
import { InventoryClassApi, InventoryGroupApi, InventoryTypeApi, StorageModeApi } from "../../../../../../api";
import { createDropdownField } from "../../../../../../Data/FormField/PublicData";

export default function useInventoryTypeForm() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = InventoryType;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "count_unit",
      name: "count_unit",
      label: CountUnit,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "reorder_level",
      name: "reorder_level",
      label: ReorderLevel,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "min_order_quantity",
      name: "min_order_quantity",
      label: MinOrderQuantity,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "ordering_time",
      name: "ordering_time",
      label: OrderingTime,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const InventoryGroupDropDown = createDropdownField(
      axiosPrivate,
      "administration_inventory_group_id",
      "administration_inventory_group_id",
      InventoryGroup,
      `${InventoryGroupApi}?organisation_id=${userOrganisationId}`,
      "",
      6
  );

  InventoryGroupDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "administration_inventory_class_id"
      );
      if (nextField) {
        const endPoint = `${InventoryClassApi}?inventory_group_id=${id}`
        await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "administration_inventory_group_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
  };

  const InventoryClassDropDown = createDropdownField(
      axiosPrivate,
      "administration_inventory_class_id",
      "administration_inventory_class_id",
      InventoryClass,
      ``,
      "",
      6
  );

  const StorageModeDropDown = createDropdownField(
      axiosPrivate,
      "administration_storage_mode_id",
      "administration_storage_mode_id",
      StorageMode,
      `${StorageModeApi}?organisation_id=${userOrganisationId}`,
      "",
      6
  );

  async function updateFields() {
      const updatedFields = [InventoryGroupDropDown, InventoryClassDropDown, StorageModeDropDown, ...formFields];
      await InventoryGroupDropDown.fetchOptions();
      await StorageModeDropDown.fetchOptions();
      setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/administration/settings/inventory/types/${layoutURL}`;

  const onSubmit = async (data: any) => {
    data.organisation_id = userOrganisationId;
    // console.log(data);
    await axiosPrivate
      .post(InventoryTypeApi, data)
      .then((_res) => {
        toast.success(`${_res.data.data.name} Created Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  return { formTitle, formFields, backBtn, onSubmit };
}
