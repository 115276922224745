import { useEffect, Fragment, useCallback, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { View } from '../../../Constant';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
//import useAuth from '../../../Hooks/useAuth';

//import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';

const DataTableServerFetchComponent = ({ tabledata,
  tableColumns,
  isLoading,
  onView,
  onDelete,
  onEdit,
  deleteMany = true,
  clearSelect = false,
  onRowSelect = (data) => data,
  useTableAction,
  addToDefaultAction,
  useDefaultAction = true,
  totalRows = 0,
  handlePerRowsChange,
  handlePageChange }) => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [noDeleteMany, setNoDeleteMany] = useState(deleteMany);
  //const [data, setData] = useState(tabledata);
  //const [pending, setPending] = useState(isLoading);

  // const { auth0_profile } = useAuth();
  // const allowed_email = ['user@example.com', 'admin@vdtcomms.com'];

  const customColumns = [...tableColumns,];

  if (useTableAction) {
    /* const tableActions = [
      {
        label: 'View',
        action: onView,
        color: 'success',
        icon: 'icon-eye',
      },
      {
        label: 'Edit',
        action: onEdit,
        color: 'primary',
        icon: 'icon-pencil-alt',
      },
      {
        label: 'Delete',
        action: onDelete,
        color: 'danger',
        icon: 'icon-trash',
      },
    ]; */

    customColumns.push({
      name: 'Actions',
      selector: 'actions',
      sortable: false,
      cell: (row, index) => (
        <div className="btn-showcase">
          {useTableAction.map((action, actionIndex) => (
            <button key={actionIndex}
              className={`btn btn-outline-${action.color} btn-xs`}
              onClick={() => action.action(row, index)}
            >
              <i className={action.icon}></i> {action.label}
            </button>
          ))}
        </div>
      ),
    });
  }

  if (!useTableAction && useDefaultAction) {
    customColumns.push({
      name: 'Actions',
      selector: 'actions',
      sortable: false,
      cell: (row, index) => (
        <div className="btn-showcase">
          <button className="btn btn-outline-success btn-xs" onClick={() => onView(row, index)}>
            <i className="icon-eye"></i> View
          </button>
          <button className="btn btn-outline-primary btn-xs" onClick={() => onEdit(row, index)}>
            <i className="icon-pencil-alt"></i> Edit
          </button>
          {/* { allowed_email.includes(auth0_profile.email) && ( */}
          {/* <button className="btn btn-outline-danger btn-xs" onClick={() => onDelete(row)}>
                  <i className="icon-trash"></i> Delete
              </button> */}
          {/* )} */}
          {addToDefaultAction && addToDefaultAction.length > 0 && (
            addToDefaultAction.map((action, actionIndex) => (
              <button key={actionIndex}
                className={`btn btn-outline-${action.color} btn-xs`}
                onClick={() => action.action(row, index)}
              >
                <i className={action.icon}></i> {action.label}
              </button>
            ))
          )}
        </div>
      ),
    });
  }


  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
    onRowSelect(state.selectedRows);
    clearSelect = false;
  }, []);

  const handleSelectedDelete = () => {
    if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
      setToggleDelet(!toggleDelet);

      //setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
      setSelectedRows('');
    }
  };

  useEffect(() => {
    //console.log(selectedRows);
    if (clearSelect) {
      setSelectedRows([]);
      //console.log(selectedRows);
    }
  }, [clearSelect])

  //===========================================================================
  /* const handleView = (row) => {
      // Handle the View action
     // console.log('View clicked for row:', row);
  };
  
  const handleDelete = (row) => {
  // Handle the Delete action
  //console.log('Delete clicked for row:', row);
  };

  const handleEdit = (row) => {
  // Handle the Edit action
  //console.log('Edit clicked for row:', row);
  }; */
  //===========================================================================

  /*  function convertArrayOfObjectsToCSV(array) {
     let result;
   
     const columnDelimiter = ',';
     const lineDelimiter = '\n';
     const keys = Object.keys(array[0]);
   
     result = '';
     result += keys.join(columnDelimiter);
     result += lineDelimiter;
   
     array.forEach(item => {
       let ctr = 0;
       keys.forEach(key => {
         if (ctr > 0) result += columnDelimiter;
   
         result += item[key];
         
         ctr++;
       });
       result += lineDelimiter;
     });
   
     return result;
   } */

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  /* function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
  
    const filename = 'export.csv';
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }
      
  const Export = ({ onExport }) => 
    <Btn attrBtn={{
      className: 'mt-2',
      type: 'button',
      color: 'danger',
      onClick: (e) => onExport(e.target.value),
    }}>Export</Btn>;
  
  const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(tabledata)} />, []);
*/
  const extractTextFromHTML = (html) => {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    //console.log(html);
    //console.log(temp.textContent || temp.innerText || '');
    return temp.textContent || temp.innerText || '';
  };

  /* function extractTextFromHTML(html) {
    var span = document.createElement('span');
    span.innerHTML = html;
    return span.textContent || span.innerText;
  } */

  let columns = customColumns.map(column => {
      /* if (typeof column.cell === 'function') {
         
          return {
              ...column,
              cellExport: row => extractTextFromHTML(column.cell(row)).toString()
          };
      } else  */if (typeof column.selector === 'function') {
      return {
        ...column,
        cellExport: row => column.selector(row)
      };
    } else {
      // Handle the case where column.cell and column.selector are not functions
      return {
        ...column,
        cellExport: row => row[column.selector] // or row[column.selector] || someDefaultValue
      };
    }
  });

  /* let columns = customColumns.map(column => {
    if (typeof column.selector === 'function') {
        return {
            ...column,
            cellExport: row => column.selector(row)
        };
    } else {
        // Handle the case where column.selector is not a function
        return {
            ...column,
            cellExport: row => row[column.selector] // or row[column.selector] || someDefaultValue
        };
    }
  });  */

  //let columns = customColumns
  let data = tabledata
  const tableData = {
    columns,
    data,
    print: false,
    exportHeaders: true
  };
  return (
    <Fragment>
      {(selectedRows.length !== 0 && noDeleteMany === true) &&
        <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
          <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
          <Btn attrBtn={{ color: 'danger', onClick: () => handleSelectedDelete() }}>Delete</Btn>
        </div>
      }
      <DataTableExtensions {...tableData}>
        <DataTable
          data={tabledata}
          columns={customColumns}
          striped={true}
          center={true}
          progressPending={isLoading}
          pagination
          //selectableRows
          //fixedHeader
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleDelet}
          //actions={actionsMemo}

          highlightOnHover

          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25, 50, 75, 100]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}

          //noDataComponent={`This is a test data`}
        />
      </DataTableExtensions>
    </Fragment>
  )
}
export default DataTableServerFetchComponent