import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  IncomeGroup,
  Description,
  Name,
  Status,
  IncomeCategory,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
  financesIncomeCategoryApi,
  financesIncomeGroupApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";

export default function useIncomeGroupController() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = IncomeGroup;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const incomeCategory = createDropdownField(
    axiosPrivate,
    "income_category_id",
    "income_category_id",
    IncomeCategory,
    `${financesIncomeCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [incomeCategory, ...formFields];
    await incomeCategory.fetchOptions();
    setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/finance/settings/income-group/${layoutURL}`;

  const onSubmit = async (data: any)  =>{
    data.organisation_id = userOrganisationId;
    
    await axiosPrivate
      .post(financesIncomeGroupApi, data)
      .then((_res) => {
        toast.success(`${_res.data.name} Created Successfully.`)
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error('Validation Errors');
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
        throw err;
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
    //console.log(data);
  }

  return { formTitle, formFields, backBtn, onSubmit };
}
