import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { StatusRow } from "./SubLedgerAccountList";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    Contact,
    CreatedBy,
    Description,
    GeneralLedgerAccount,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    GeneralLedgerType,
    ModifiedBy,
    Name,
    Number,
    Status,
    SubLedgerAccount,
    SubLedgerClass,
    SubLedgerType,
    ExtAccNumber,
    ExtAccName,
    SLAccountName,
    SLAccountNumber,
} from "../../../../../Constant";
import { ContactTypeVal, createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    // crmContactApi,
    ContactMgmtApi,
    financesGeneralLedgerGroupApi,
    financeSubLedgerAccountApi,
    financeSubLedgerClassApi,
    financeSubLedgerTypeApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { ISubLedgerAccountResponse } from "../SubLedgerAccount.types";
import useAuth from "../../../../../Hooks/useAuth";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { toast } from "react-toastify";

export default function useSubLedgerAccountListController() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const title = SubLedgerAccount;
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: SLAccountName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "external_account_number",
            name: "external_account_number",
            label: ExtAccNumber,
            type: "text",
            value: "",
            required: false,
            colSize: 6,
        },

        {
            id: "external_account_name",
            name: "external_account_name",
            label: ExtAccName,
            type: "text",
            value: "",
            required: false,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //!! DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, updatedFields) => {
        // await generalLedgerClassDropDown.fetchOptions();
        const nextField = updatedFields.find(
            (field: any) => field.id === "sl_class_id"
        );
        if (nextField) {
            const endPoint = `${financeSubLedgerClassApi}?organisation_id=${userOrganisationId}&gl_group_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_group_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const subLedgerClassDropDown = createDropdownField(
        axiosPrivate,
        "sl_class_id",
        "sl_class_id",
        SubLedgerClass,
        `${financeSubLedgerClassApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );
    subLedgerClassDropDown.onChange = async (id, updatedFields) => {
        // await generalLedgerClassDropDown.fetchOptions();
        const nextField = updatedFields.find(
            (field: any) => field.id === "sl_type_id"
        );
        if (nextField) {
            const endPoint = `${financeSubLedgerTypeApi}?organisation_id=${userOrganisationId}&sl_class_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "sl_class_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const subLedgerTypeDropDown = createDropdownField(
        axiosPrivate,
        "sl_type_id",
        "sl_type_id",
        SubLedgerType,
        `${financeSubLedgerTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    subLedgerTypeDropDown.onChange = async (id, updatedFields) => {
        const updatedFormFields = updatedFields.map((field: any) => {
            if (field.id === "sl_type_id") {
                return { ...field, value: id };
            }
            return field;
        });
        setFormFields([...updatedFormFields]);
    };

    const contactsDropDown = createDropdownField(
        axiosPrivate,
        "contact_id",
        "contact_id",
        Contact,
        `${ContactMgmtApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );

    const customerDropdownField = {
        name: "contact_id",
        id: "contact_number, company_name, last_name, first_name",
        api: `${ContactMgmtApi}`,
        label: `${Contact}`,
        type: "input-select",
        value: { id: "", label: "" },
        colSize: 6,
        required: true,
        disabled: true,
    };

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        (async function () {
            const updatedFields = [
                generalLedgerGroupDropDown,
                subLedgerClassDropDown,
                subLedgerTypeDropDown,
                //contactsDropDown,
                customerDropdownField,
                ...formFields,
            ];
            await generalLedgerGroupDropDown.fetchOptions();
            //await contactsDropDown.fetchOptions();
            setFormFields(updatedFields);
        })();
    }, []);

    const tableColumns = [
        {
            name: SLAccountName,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: SLAccountNumber,
            selector: (row: (typeof data)[0]) => `${row.number}`,
            sortable: false,
            center: false,
        },

        {
            name: ExtAccName,
            selector: (row: (typeof data)[0]) => `${row.external_account_name}`,
            sortable: false,
            center: false,
        },

        {
            name: ExtAccNumber,
            selector: (row: (typeof data)[0]) =>
                `${row.external_account_number}`,
            sortable: false,
            center: false,
        },

        /* {
            name: `${Contact}`,
            selector: (row: (typeof data)[0]) => row.contact.first_name,
            sortable: true,
            center: false,
            cell: (row: (typeof data)[0]) => (
                <>
                    {row.contact.contact_type === ContactTypeVal.Individual ? 
                        `${row.contact.last_name} ${row.contact.first_name} ${row.contact.middle_name}` : 
                        `${row.contact.company_name}`
                    }
                </>
            )
        }, */

        {
            name: Contact,
            selector: (row: (typeof data)[0]) => `${row.contact.first_name}`,
            sortable: false,
            center: false,
        },

        {
            name: SubLedgerType,
            selector: (row: (typeof data)[0]) => `${row.sl_type.name}`,
            sortable: true,
            center: false,
        },

        /* {
            name: SubLedgerClass,
            selector: (row: (typeof data)[0]) =>
                `${row.sl_type.sl_class.name}`,
            sortable: true,
            center: false,
        },

        {
            name: GeneralLedgerGroup,
            selector: (row: (typeof data)[0]) =>
                `${row.sl_type.sl_class.gl_group.name}`,
            sortable: true,
            center: false,
        }, */

        {
            name: Status,
            selector: (row: (typeof data)[0]) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },

        {
            name: CreatedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.created_at)}`,
            sortable: false,
            center: false,
        },

        {
            name: ModifiedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.updated_at)}`,
            sortable: false,
            center: false,
        },
    ];

    function fetchData() {
        setLoading(true);
        axiosPrivate
            .get(
                `${financeSubLedgerAccountApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
            )
            .then((res) => {
                const response: ISubLedgerAccountResponse[] = res.data;
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useLayoutEffect(() => {
        fetchData();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account/create/${layoutURL}`;
    // const gotoEdit = (row: any) => {
    //     navigate(
    //         `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account/edit/${row.id}/${layoutURL}`
    //     );
    // };

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ISubLedgerAccountResponse[]>([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const addToDefaultAction: any[] = [
        // {
        //     label: `${Edit}`,
        //     action: gotoEdit,
        //     color: "info",
        //     icon: "icon-user",
        // },
    ];

    const handleEdit = (row: (typeof data)[0] | any) => {
        setViewModalOpen(true);
        setEditMode(true);

        row.gl_group_id = row.sl_type.sl_class.gl_group.id;
        generalLedgerGroupDropDown.onChange(row.gl_group_id, formFields);

        row.sl_class_id = row.sl_type.sl_class.id;
        subLedgerClassDropDown.onChange(row.sl_class_id, formFields);

        row.sl_type_id = row.sl_type.id;
        subLedgerTypeDropDown.onChange(row.sl_type_id, formFields);

        const contactID = formFields.filter(
            (field) => field.name === "contact_id"
        );
        
        contactID[0].value.id = row.contact.id;
        contactID[0].value.label = (row.contact.contact_type === ContactTypeVal.Corporate) ?
        `${row.contact.contact_number} ${row.contact.company_name}` :
        ((row.contact.last_name) ?
        `${row.contact.contact_number} ${row.contact.last_name} ${row.contact.first_name}  ${row.contact.middle_name}` :
        "No Name");

        setSelectedRowData(row);
    };

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
        editRecord.contact_id = editRecord.contact_id.id
        await axiosPrivate
            .put(`${financeSubLedgerAccountApi}/${id}`, {
                ...editRecord,
                organisation_id: userOrganisationId,
            })
            .then((_res) => {
                const updatedData = data.map((row) =>
                    row.id === selectedRowData!.id
                        ? { ...row, ..._res.data }
                        : row
                );
                setData(updatedData);
                toast.success(`${_res.data.name} Editted Successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                throw err;
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        editMode,
        newBtn,
        title,
        formFields,
        addToDefaultAction,
        setViewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    };
}
