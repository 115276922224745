import { Fragment, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { H4, Btn, H6, UL, LI, P } from '../../../../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, TabPane, Row } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Edit, ChevronDown, ChevronUp } from 'react-feather';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';

const CaseHistory = ({ history }) => {
    const [isFilter, setIsFilter] = useState(true);
    let histories = [];
    
    try {
        // Parse the JSON string
       // histories = JSON.parse(history);
    } catch (error) {
        //console.error('Failed to parse history:', error);
    }

    return (
        <Fragment>
            <TabPane className='fade show' tabId='case-history'>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardHeader>
                                <h5> History </h5>
                            </CardHeader>
                            <CardBody className="filter-cards-view animate-chk">
                                <VerticalTimeline>
                                    {Array.isArray(histories) && histories.map((item, i) => (
                                        <VerticalTimelineElement  key={i}
                                            className="vertical-timeline-element--work"
                                            animate={true}
                                            date={formatDateWithTimeZone(item.date_change)}
                                            icon={<Edit />}>
                                            {/* <H4 attrH4={{ className: 'vertical-timeline-element-subtitle' }}>{'Title of section 1'}</H4> */}
                                            <P>
                                                Action: {item.action_performed}<br />
                                                By: {item.performed_by}
                                            </P>
                                        </VerticalTimelineElement>
                                    ))}
                                </VerticalTimeline>
                                    {/* <UL>
                                        {histories && histories.map((item, i) => (
                                        <LI key={i} attrLI={{ className: 'd-flex' }}>
                                            <div className={`activity-dot-${item.color}`} />
                                            <div className='w-100 ms-3'>
                                            <P attrPara={{ className: 'd-flex justify-content-between mb-2' }}>
                                                <span className='date-content light-background'>{item.subTitle}</span>
                                                <span>{item.time}</span>
                                            </P>
                                            <H6>
                                                {item.title}
                                                <span className='dot-notification' />
                                            </H6>
                                            <p className='f-light'>{item.dis}</p>
                                            </div>
                                        </LI>
                                        ))}
                                    </UL> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
        </Fragment>
    );
};

export default CaseHistory;