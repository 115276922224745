import React, { Fragment } from 'react';
import { Card, CardBody, Col, Row, TabPane  } from 'reactstrap';
import { Btn, P } from '../../../../../AbstractElements';
import { ContactSearch, MapContactVendor, VendorForMapping } from '../../../../../Constant';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import useVendorMapping from './controller';
import FindContact from '../../../../Common/Component/FindContact';
import HeaderCard from '../../../../Common/Component/HeaderCard';

const MappingList = (name: any) => {

    const {
        isOpen,
        setIsOpen,
        handleSubmit,
        vendorMapAction,
        newBtn,
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        setViewModalOpen,
        formFields,
        memoizedHandleEdit,
        editMode,
        isLoading,
    } = useVendorMapping();

    return (
        <Fragment>
            <TabPane className='fade show' tabId={name.name}>
                <Row className='learning-block'>
            
                    <Col xl='2' className='xl-30 box-col-12 learning-filter order-1 order-xl-1'>
                        <div className='md-sidebar'>
                        <Btn attrBtn={{ onClick: (e: any) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>{ContactSearch}</Btn>
                        <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'} `}>
                            <div className='default-according style-1 faq-accordion job-accordion' id='accordionoc'>
                            <Row>
                                <FindContact onSubmit={handleSubmit} />
                            </Row>
                            </div>
                        </div>
                        </div>
                    </Col>

                    <Col xl='10' className='xl-70 box-col-8 order-2 order-xl-2'>
                        <Row>
                        <Col sm="12">
                            <Card>
                                <HeaderCard 
                                    title={VendorForMapping} 
                                    btnUrl={newBtn}
                                    span1={undefined}
                                    span2={undefined}
                                    mainClasses={undefined}  />
                                
                                <CardBody>
                                    <DataTableComponent
                                    tabledata={data}
                                    tableColumns={tableColumns}
                                    isLoading={isLoading}
                                    useTableAction={vendorMapAction}

                                    onView={undefined}
                                    onDelete={undefined}
                                    onEdit={undefined}

                                    deleteMany={true}
                                    clearSelect={false}
                                    addToDefaultAction={undefined}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        </Row>
                    </Col>
                </Row>

                <ViewModal 
                    isOpen={viewModalOpen} 
                    toggle={() => setViewModalOpen(!viewModalOpen)} 
                    rowData={selectedRowData} 
                    formFields={formFields}
                    editMode={editMode}
                    onEdit={memoizedHandleEdit}
                    onSubmit={undefined}
                    modalTitle={MapContactVendor}
                >
                        <></>
                </ViewModal>
            </TabPane>
        </Fragment>
    );
}

export default  MappingList