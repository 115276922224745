import { Dispatch, SetStateAction } from "react";
import { Row, FormGroup } from "reactstrap";

export type TSwitchFormDetails = {
    id: number;
    label: string;
    value: boolean;
};

type TSwitchRows = {
    switchFormDetails: TSwitchFormDetails[];
    setSwitchFormDetails?: Dispatch<SetStateAction<TSwitchFormDetails[]>>;
};

const SwitchRows = ({
    switchFormDetails,
    setSwitchFormDetails,
}: TSwitchRows) => {
    return (
        <>
            <Row>
                <form
                    className="relative w-100 h-auto text-black d-grid"
                    style={{
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "1rem",
                    }}
                >
                    {switchFormDetails.map((_, i) => (
                        <FormGroup
                            className="d-flex align-items-center gap-1"
                            switch
                            key={i}
                        >
                            <span className="">{_.label}</span>
                            <div className="text-end icon-state media-body">
                                <label className="switch form-label">
                                    <input
                                        onChange={(e) => {
                                            setSwitchFormDetails &&
                                                setSwitchFormDetails((prev) => {
                                                    const newState = [...prev];
                                                    newState[i].value =
                                                        e.target.checked;
                                                    return newState;
                                                });
                                        }}
                                        checked={Boolean(_?.value)}
                                        type="checkbox"
                                        className="form-check-input"
                                    />
                                    <span className="switch-state"></span>
                                </label>
                            </div>
                        </FormGroup>
                    ))}
                </form>
            </Row>
        </>
    );
};

export default SwitchRows;
