import React, { useContext, useEffect } from 'react';
import Loader from '../../../../Layout/Loader';
import { useLocation } from 'react-router';
import usePaymentCallBack from './controller';

const PaymentCallback = () => {
  const { setReferenceNo, handleSubmit } = usePaymentCallBack()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reference = queryParams.get('reference') || ''
  const status = queryParams.get('status')

  useEffect(() => {
      if (reference) {
          setReferenceNo(reference);
          handleSubmit(); // Call handleSubmit when referenceNo is set
      }
  }, [reference, setReferenceNo, handleSubmit]);

  return (
    <div>
      <Loader showTimeOut = "False" />
      {reference}
      {status}
    </div>
  );
};

export default PaymentCallback;
