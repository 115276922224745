import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    Description,
    AccrualType,
    Name,
    Status,
    ShortName,
} from "../../../../../Constant";
import { toast } from "react-toastify";
import { IAccrualTypePayload } from "../AccrualType.types";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useAccrualTypeController() {
    const axiosPrivate = useAxiosPrivate();
    const formTitle = AccrualType;
    const { userOrganisationId }: any = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/accrual-type/${layoutURL}`;
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "short_name",
            name: "short_name",
            label: ShortName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            ...formFields,
            // balanceLedgerDropDown,
        ];
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const onSubmit = async (data: IAccrualTypePayload) => {
        data.organisation_id = userOrganisationId;
        await axiosPrivate
            .post("/finances/accrual-types", data)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                throw err;
            });
    };

    return { formTitle, formFields, backBtn, onSubmit };
}
