import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  Description,
  Status,
  Code,
  Name,
  FinanceBudgetDecision,
} from "../../../../../Constant";

import { toast } from "react-toastify";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { financesBudgetDecisionApi } from "../../../../../api";

export default function useFinanceBudgetDecisionForm() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = FinanceBudgetDecision;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: FinanceBudgetDecision,
      type: "text",
      value: "",
      required: true,
      colSize: 12,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 12,
    },

    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 12,
    },
  ]);

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [...formFields];
    setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/finance/budgets/decisions/${layoutURL}`;

  const onSubmit = async (data: any) => {
    data.organisation_id = userOrganisationId;
    // console.log(data);
    await axiosPrivate
      .post(financesBudgetDecisionApi, data)
      .then((_res) => {
        toast.success(`${_res.data.data.name} Created Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        throw err;
        //toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  return { formTitle, formFields, backBtn, onSubmit };
}
