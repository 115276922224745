import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent  } from 'reactstrap';
import {Breadcrumbs} from '../../../AbstractElements';
import {Vendor, VendorMapping, Administration} from '../../../Constant';
import VendorList from "./Vendor/List/VendorList";
import MappingList from "./Mapping/List/MappingList";
import useVendorManagement from './controller';

const VendorManagement = () => {
    const {
        layoutURL,
        VENDOR,
        MAPPING,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    } = useVendorManagement();
    
    return (
        <Fragment>
            <Breadcrumbs parent={Administration} title={tabDisplayNames[commissionTab]} mainTitle={tabDisplayNames[commissionTab]}/>
            <Container fluid={true}>
                <Row>
                    <Col sm='12' xl='12' className='xl-100'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={'12'}>
                                        <Nav className='border-tab nav-secondary nav-pills' tabs>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${VENDOR}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${VENDOR}`)}>
                                                    <i className='icofont icofont-users'></i>
                                                    {Vendor}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${MAPPING}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${MAPPING}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {VendorMapping}
                                                </NavLink>
                                            </NavItem>
                                        </Nav> 
                                        <TabContent activeTab={commissionTab}>
                                            {commissionTab === `${VENDOR}` && <VendorList name={VENDOR} />}
                                            {commissionTab === `${MAPPING}` && <MappingList name={MAPPING} />}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default  VendorManagement