import { useContext, useState, useEffect } from 'react';
import CustomizerContext from '../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import {useParams, useNavigate } from "react-router-dom";
import { VendorApi } from '../../../../api';
import { VendorResType } from './types';
import { toast } from "react-toastify";
import no_record_img from '../../../../assets/images/other-images/no_record_found.jpg'

type RouteParams = {
    tabName?: string;
    contactNumber?: string;
};

export default function useVendorDetail() {    
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState<VendorResType>();
    const buttonData = {
      label: "Back to List", 
      link: `${process.env.PUBLIC_URL}/administration/vendor/all/${layoutURL}`
    }
    const VENDOR = 'all';
    const MEMBER = 'members';
    
    const navigate = useNavigate();
    const { contactNumber } = useParams<RouteParams>();
    const { tabName } = useParams<RouteParams>(); 
    const [commissionTab, setCommissionTab] = useState<string>(tabName || `${VENDOR}`); 
    const [currentContactNumber, setCurrentContactNumber] = useState<string | undefined>(); 
    const [loading, setLoading] = useState(true);

    const tabDisplayNames: { [key: string]: string } = {
        'all': 'Vendor Information',
        'members': 'Vendor Members',
      };

    useEffect(() => {
        if (tabName && tabName !== commissionTab) {
            setCommissionTab(tabName);
        }
    }, [tabName]);

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
        //navigate(`/administration/vendor/${routeName}/${layoutURL}`); // Append to route
    };

    useEffect(() => {
        if (contactNumber && contactNumber !== currentContactNumber) {
            setCurrentContactNumber(contactNumber);
            fetchData()
        }
    }, [contactNumber]);

    const fetchData = () => {
        setLoading(true);
        axiosPrivate
          .get(
            `${VendorApi}/${contactNumber}?with_trashed=${false}`
          )
          .then((res) => {
            const response: VendorResType = res.data;
            setData(response);
    
            setLoading(false);
          })
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              toast.error(err.response.data.detail);
            } else {
              toast.error("Whoops! Something went wrong");
            }
          })
          .finally(() => {
            setLoading(false);
          });
    };

    const [url, setUrl] = useState<string>('');

    const readUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (!files || files.length === 0) return; // Check for null and length

        const file = files[0];
        const mimeType = file.type;

        if (!mimeType.match(/image\/*/)) {
        return;
        }
    
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.result) {
            setUrl(reader.result as string); // Ensure result is treated as a string
          }
        };
    }; 

    return {
        layoutURL,
        VENDOR,
        MEMBER,
        url,
        readUrl,
        commissionTab,
        tabDisplayNames,
        handleTabClick,
        data,
        buttonData,
        no_record_img
    };
}
