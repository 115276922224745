import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../Hooks/useAuth";
import { useLayoutEffect, useState } from "react";
import { IncomeCategoryType, IncomeGroupRes } from "./IGReport.types";
import {
  financesIncomeCategoryApi,
  financesIncomeGroupApi,
} from "../../../../api";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
export default function IncomeGroupReport() {
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId }: any = useAuth();
  const [incomeCategoryDropdown, setDropDown] = useState<IncomeCategoryType[]>(
    []
  );
  const [incomeCategoryValue, setIncomeCategoryValue] = useState("0");
  const [printedData, setPrintedData] = useState<IncomeGroupRes[]>([]);

  async function fetchData() {
    try {
      const allApis = [financesIncomeCategoryApi];

      const fetchPromises = allApis.map((url) =>
        axiosPrivate.get(
          `${url}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
        )
      );

      const responses = await Promise.all(fetchPromises);
      setDropDown(responses[0].data);
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Whoops! Something went wrong");
      }
    }
  }

  useLayoutEffect(() => {
    fetchData();
    // getTableFunction();
  }, []);

  async function getTableFunction() {
    try {
      const endpoint = [financesIncomeGroupApi];
      const fetchPromises = endpoint.map((url) =>
        axiosPrivate.get(
          `${url}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&income_category_id=${incomeCategoryValue}`
        )
      );
      const responses = await Promise.all(fetchPromises);
      setPrintedData(responses[0].data);
    } catch (error: any) {
      console.log(error);
      setPrintedData([]);
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.detail);
      } else {
        toast.error("Whoops! Something went wrong");
      }
    }
  }

  function generatePrint(res: IncomeGroupRes[]) {
    console.log(res);
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }

          td,
          th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }

          tr:nth-child(even) {
            background-color: #dddddd;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
             <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Status </th>
              <th>Report Type</th>
            </tr>
          </thead>
          <tbody>
            ${res
              .map(
                (item) =>
                  `<tr>
                        <td>${item.name}</td>
                        <td>${item.description}</td>
                        <td>${item.is_active}</td>
                       <td>${item.name}</td>
                    </tr>`
              )
              .join("")}
            </tbody>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </tfoot>
        </table>

        <script>
        window.print();
        setTimeout(() => {
          window.close();
        if (!window.closed) {
          alert('Please allow popups for this website to close the window automatically.');
         }
        }, 100);
        </script>
      </body>
    </html>`;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
    URL.revokeObjectURL(blobUrl);
  }

  function exportToCSV(res: IncomeGroupRes[]) {
    const csvHeaders = [
      "Name",
      "Description",
      "Code",
      "Balance Type",
      "Report Type",
    ];
    const data = res.map((item) => ({
      name: item.name,
      description: item.description,
      // code: item.code,
      gl_group: item.income_category.name,
      report_type: item.name,
    }));

    const dataKeys: (keyof (typeof data)[0])[] = [
      "name",
      "description",
      // "code",
      "gl_group",
      "report_type",
    ];

    const csvContent =
      csvHeaders.join(",") +
      "\n" +
      data.map((row) => `${dataKeys.map((key) => row[key]) + ","}`).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "data.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <div>
      <Breadcrumbs
        parent={"Report"}
        title={"Income Group Report"}
        mainTitle={"Income Group Report"}
      />
      <br />

      <div className="row gap-y-3">
        <div className="col-md-4 mb-3">
          <p className="mb-0">Income Category</p>
          <Input
            onChange={(e) => setIncomeCategoryValue(e.target.value)}
            type="select"
          >
            <option hidden>Default Select</option>
            {incomeCategoryDropdown.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Input>
        </div>

        <div className="col-md-4  mb-3 d-flex align-align-items-center gap-2">
          <Button color="success" onClick={getTableFunction} className="mt-4">
            Generate Table
          </Button>
        </div>
      </div>

      <br />
      <br />

      {printedData.length !== 0 && (
        <>
          <div className="row">
            <Form className="col">
              <FormGroup>
                <Label for="search">Search</Label>
                <Input id="search" />
              </FormGroup>
            </Form>
            <div className="col d-flex gap-2 justify-content-end align-items-center">
              <Button color="info" onClick={() => generatePrint(printedData)}>
                Generate Print
              </Button>

              <Button color="info" onClick={() => exportToCSV(printedData)}>
                Generate CSV
              </Button>
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Created at</th>
                <th>Updated at</th>
                {/* <th>Balance Type</th> */}
                <th>Report Type</th>
              </tr>
            </thead>
            <tbody>
              {printedData.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{item.is_active ? "Active" : "Not Active"}</td>
                  <td>{item.created_at}</td>
                  <td>{item.updated_at}</td>
                  <td>{item.income_category.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
