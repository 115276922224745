import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent  } from 'reactstrap';
import {Breadcrumbs} from '../../../../AbstractElements';
import {Administration, FixedAssetPriceGuide, InventoryPriceGuide } from '../../../../Constant';
import {Link} from "react-router-dom";
import {PlusCircle} from "react-feather";
import useBudgetPriceGuides from './controller';
import InventoryList from './Inventory/List/InventoryList';
import Spinner from '../../../Common/Component/Spinner';
import FixedAssetList from './FixedAsset/List/FixedAssetList';

const BudgetPriceGuides = () => {
    const {
        layoutURL,
        fiscalYear,
        INVENTORY,
        FIXED_ASSET,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    } = useBudgetPriceGuides();

    return (
        <Fragment>
            <Breadcrumbs parent={Administration} title={tabDisplayNames[commissionTab]} mainTitle={tabDisplayNames[commissionTab]}/>
            <Container fluid={true}>
                <Row>
                    <Col sm='12' xl='12' className='xl-100'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        {/*<H5></H5>*/} 
                                    </Col>
                                    <Col md="6">
                                        <div className="text-end file-content">
                                            <Link className="btn btn-primary ms-2" style={{color: 'white'}} to={`/administration/procurement/budget-price-guides/${commissionTab}/create/${layoutURL}`}>
                                                <PlusCircle/> New {tabDisplayNames[commissionTab] || 'Item'}
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={'12'}>
                                        <Nav className='border-tab nav-secondary nav-pills' tabs>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${INVENTORY}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${INVENTORY}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {InventoryPriceGuide}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href='#' className={commissionTab === `${FIXED_ASSET}` ? 'active' : ''}
                                                         onClick={() => handleTabClick(`${FIXED_ASSET}`)}>
                                                    <i className='icofont icofont-files'></i>
                                                    {FixedAssetPriceGuide}
                                                </NavLink>
                                            </NavItem>
                                        </Nav> 
                                        <TabContent activeTab={commissionTab}>
                                            {fiscalYear === undefined ? (
                                                <Spinner /> 
                                            ) : (
                                                <>
                                                    {commissionTab === `${INVENTORY}` && (
                                                        <InventoryList name={INVENTORY} fiscal_year={fiscalYear} />
                                                    )}
                                                    {commissionTab === `${FIXED_ASSET}` && (
                                                        <FixedAssetList name={FIXED_ASSET} fiscal_year={fiscalYear} />
                                                    )}
                                                </>
                                            )}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default  BudgetPriceGuides