import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../Hooks/useAuth";
import { useLayoutEffect, useState } from "react";

import { ExpenseGroupRes, ExpenseCategory } from "./types";
import {
  financesExpenseGroupApi,
  financesExpenseCategoryApi,
} from "../../../../api";
import { toast } from "react-toastify";

export default function useExpenseGroupController() {
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId }: any = useAuth();
  const [ExpenseCategoryDropdown, setExpenseDropDown] = useState<
    ExpenseCategory[]
  >([]);
  const [expenseCategoryValue, setExpenseCategoryValue] = useState("0");

  const [printedData, setPrintedData] = useState<ExpenseGroupRes[]>([]);

  async function fetchData() {
    try {
      const allApis = [financesExpenseCategoryApi];

      const fetchPromises = allApis.map((url) =>
        axiosPrivate.get(
          `${url}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
        )
      );
      const responses = await Promise.all(fetchPromises);
      setExpenseDropDown(responses[0].data);
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Whoops! Something went wrong");
      }
    }
  }
  useLayoutEffect(() => {
    fetchData();
  }, []);

  async function getTableFunction() {
    try {
      const endpoint = [financesExpenseGroupApi];
      const fetchPromises = endpoint.map((url) =>
        axiosPrivate.get(
          `${url}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&expense_category_id=${expenseCategoryValue}`
        )
      );
      const responses = await Promise.all(fetchPromises);
      setPrintedData(responses[0].data);
    } catch (error: any) {
      console.log(error);
      setPrintedData([]);
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.detail);
      } else {
        toast.error("Whoops! Something went wrong");
      }
    }
  }

  function generatePrint(res: ExpenseGroupRes[]) {
    console.log(res);
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }

          td,
          th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }

          tr:nth-child(even) {
            background-color: #dddddd;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
             <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Status </th>
              <th>Report Type</th>
            </tr>
          </thead>
          <tbody>
            ${res
              .map(
                (item) =>
                  `<tr>
                        <td>${item.name}</td>
                        <td>${item.description}</td>
                        <td>${item.is_active}</td>
                       <td>${item.name}</td>
                    </tr>`
              )
              .join("")}
            </tbody>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </tfoot>
        </table>

        <script>
        window.print();
        setTimeout(() => {
          window.close();
        if (!window.closed) {
          alert('Please allow popups for this website to close the window automatically.');
         }
        }, 100);
        </script>
      </body>
    </html>`;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
    URL.revokeObjectURL(blobUrl);
  }

  function exportToCSV(res: ExpenseGroupRes[]) {
    const csvHeaders = [
      "Name",
      "Description",
      "Code",
      "Balance Type",
      "Report Type",
    ];
    const data = res.map((item) => ({
      name: item.name,
      description: item.description,
      expense_category: item.expense_category.name,
      report_type: item.name,
    }));

    const dataKeys: (keyof (typeof data)[0])[] = [
      "name",
      "description",
      "expense_category",
      "report_type",
    ];

    const csvContent =
      csvHeaders.join(",") +
      "\n" +
      data.map((row) => `${dataKeys.map((key) => row[key]) + ","}`).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "data.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return {
    exportToCSV,
    printedData,
    generatePrint,
    getTableFunction,
    ExpenseCategoryDropdown,
    setExpenseCategoryValue,
  };
}
