import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Col, Media, Row, Table, TabPane  } from 'reactstrap';
import { Image, P } from '../../../../../AbstractElements';
import { ContactID, ContactType, DateCreated, DateModified, Description, EmailAddress, FullName, Name, PhoneNumber, Status } from '../../../../../Constant';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import useVendor from './controller';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';
import { ContactTypeVal } from '../../../../../Data/FormField/PublicData';
import noimage from '../../../../../assets/images/no-image.png';

const VendorList = (name: any) => {
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const [allData, setAllData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const {
        newBtn,
        data,
        tableColumns,
        vendorViewAction,
        viewModalOpen,
        selectedRowData,
        setViewModalOpen,
        formFields,
        editMode,
        loading,
    } = useVendor();

    return (
        <Fragment>
            <TabPane className='fade show' tabId={name.name}>
            <DataTableComponent
                    tabledata={data}
                    tableColumns={tableColumns}
                    isLoading={loading}
                    onView={undefined}
                    // onDelete={handleDelete}
                    onEdit={undefined}
                    useTableAction={vendorViewAction}
                    addToDefaultAction={undefined}
                    onDelete={undefined}
                />

                <ViewModal
                    isOpen={viewModalOpen}
                    toggle={() => setViewModalOpen(!viewModalOpen)}
                    rowData={selectedRowData}
                    formFields={formFields}
                    editMode={editMode}
                    onEdit={undefined}
                    onSubmit={undefined}
                    modalTitle={undefined}
                >
                    {selectedRowData && (
                    <>
                         <Table>
                            <tbody>
                            <tr className='border-bottom-primary'>
                                <th scope='row'>{ContactType}</th>
                                <td>{selectedRowData.contact_type == 1 ? "Individual" : "Corporate"}</td>
                            </tr>
                            <tr className='border-bottom-secondary'>
                                <th scope='row'>{ContactID}</th>
                                <td>{selectedRowData.contact_number}</td>
                            </tr>
                            <tr className='border-bottom-primary'>
                                <th scope='row'>{FullName}</th>
                                <td>
                                {selectedRowData.contact_type === ContactTypeVal.Individual ? 
                                    `${selectedRowData.last_name} ${selectedRowData.first_name} ${selectedRowData.middle_name}` : 
                                    `${selectedRowData.company_name}`
                                }
                                </td>
                            </tr>
                            <tr className='border-bottom-secondary'>
                                <th scope='row'>{EmailAddress}</th>
                                <td>{selectedRowData.user_login.email}</td>
                            </tr>
                            <tr className='border-bottom-primary'>
                                <th scope='row'>{PhoneNumber}</th>
                                <td>{selectedRowData.phone_number}</td>
                            </tr>
                            <tr className='border-bottom-secondary'>
                                <th scope='row'>{Status}</th>
                                <td>{selectedRowData.user_login.is_active ? (
                                    <span className="badge badge-light-success">Active</span>
                                    ) : (
                                    <span className="badge badge-light-danger">Inactive</span>
                                    )}
                                
                                    {selectedRowData.user_login.email_verified_at ? (
                                    <span className="badge badge-light-success">Verified</span>
                                    ) : (
                                    <span className="badge badge-light-danger">Not Verified</span>
                                    )}
                                </td>
                            </tr>
                            <tr className='border-bottom-primary'>
                                <th scope='row'>{DateCreated}</th>
                                <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                            </tr>
                            <tr className='border-bottom-secondary'>
                                <th scope='row'>{DateModified}</th>
                                <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </>
                    )}
                </ViewModal>
            </TabPane>
        </Fragment>
    );
}

export default VendorList

export const FullNameRow = (row: any) => {
    return (
        <>
          <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: '' }} />
            <Media body className="align-self-center">
                <div>
                  {row.contact_type === ContactTypeVal.Individual ? 
                    `${row.last_name} ${row.first_name} ${row.middle_name}` : 
                    `${row.company_name}`
                  }
                  </div>
            </Media>
          </Media>
        </>
      )
}

export const StatusRow = (row: any) => {
    return (
        <>
        {row.user_login.is_active ? (
          <span className="badge badge-light-success">Active</span>
        ) : (
          <span className="badge badge-light-danger">Inactive</span>
        )}
        {row.user_login.email_verified_at ? (
          <span className="badge badge-light-success">Verified</span>
        ) : (
          <span className="badge badge-light-danger">Not Verified</span>
        )}
      </>
    )
};