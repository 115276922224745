import TaskAssignerTwinTables from "../../../../Common/TwinTables/TwinTable";
import useGradeStep from "./GradeStep.controller";
import GenerateForm from "../../../../Common/Component/GenerateForm";
import { Fragment } from "react";
import { TabPane } from "reactstrap";

export default function GradeStepForm(name: any) {
    const {
        title,
        formFields,
        tableColumns,
        available,
        assigned,
        assignTask,
        usassignTask,
    } = useGradeStep();

    return (
        <Fragment>
            <TabPane className='fade show' tabId={name.name}>
                <div className="GeneralLedgerAssign">
                    <div className="filter-head-pane">
                        <GenerateForm
                            onSubmit={undefined}
                            formFields={formFields}
                            formTitle={title}
                            listUrl={undefined}
                            onEdit={undefined}
                            editRecord={undefined}
                        />
                    </div>

                    <br />

                    <TaskAssignerTwinTables
                        rightTableTitle={"Assigned Step (s)"}
                        rightTable={assigned}
                        rightTableColumns={tableColumns}
                        rightButtonClick={(e: any) => assignTask(e)}
                        rightButtonTitle={"Assign"}
                        
                        leftTableTitle={"Unassigned Step (s)"}
                        leftTable={available}
                        leftTableColumns={tableColumns}
                        leftButtonClick={(e: any) => usassignTask(e)}
                        leftButtonTitle={"UnAssign"}
                    />
                </div>
            </TabPane>
        </Fragment>
    );
}
