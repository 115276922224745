import { Fragment, useRef, useState } from 'react';
import { Card, CardBody, Col, Media, Row, TabPane } from 'reactstrap';
import { H5, H6, Image, P } from '../../../../../../AbstractElements';
import three from '../../../../../../assets/images/user/3.jpg';
import { MoreVertical } from 'react-feather';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';
import CaseReply from '../CaseReply';
import TimeCounter from '../../../../../Common/Component/TimeCounter';

const CaseMessage = ({ Details, onCaseReply, canPerformAction }) => {
  const { contact, case_replies } = Details;
  const [stopped, setStopped] = useState(false);
  const [timeWorked, setTimeWorked] = useState(0);
  const messageInputRef = useRef(null);
  const timeCounterRef = useRef(null);

/*   let performAction = false;
  if (Details.resolution_pickup_at && !Details.resolution_delegation_at) {
    performAction = true;
  }
  else if (Details.resolution_delegation_at && Details.delegation_pickup_at && !Details.delegation_delegation_at) {
    performAction = true;
  }
  else if (Details.delegation_delegation_at && Details.external_delegation_pickup_at) {
    performAction = true;
  } */

  const handleCaseReply = async (event) => {
    event.preventDefault();
    const message = messageInputRef.current ? messageInputRef.current.value : '';
    const timeWorked = timeCounterRef.current.getCurrentTime();
    
    await onCaseReply(event, message, timeWorked);
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='case-detail'>
        <Row>
          <Col sm='12'>
            <Card>
              <CardBody>
                <div className='new-users-social'>
                  <Media className='align-items-center'>
                    <Image attrImage={{ className: 'rounded-circle image-radius m-r-15', src: `${three}`, alt: '' }} />
                    <Media body>
                      <H6 attrH6={{ className: 'mb-0 f-w-700' }}>
                        {contact && (
                          contact.company_name ? contact.company_name :
                          (contact.last_name ? `${contact.last_name} ${contact.first_name} ${contact.middle_name}` : '')
                        )}
                      </H6>
                      <P>{formatDateWithTimeZone(Details.created_at)}</P>
                    </Media>
                    <span className='pull-right mt-0'>
                      <MoreVertical />
                    </span>
                  </Media>
                </div>
                {/* <Image attrImage={{ className: 'img-fluid', alt: '', src: `${timeline1}` }} /> */}
                <div className='timeline-content'>
                  <H5>{Details.subject}</H5>
                  <P>{Details.message}</P>

                  { !Details.resolved_at &&  (  
                    <>
                      <p className='f-w-600'>Reply</p>
                      <form onSubmit={handleCaseReply}>
                        
                        <TimeCounter ref={timeCounterRef} onStop={() => {}} />
                        
                        <textarea ref={messageInputRef} className='form-control' style={{ height: '6rem' }} /><br/>
                        {
                          canPerformAction &&
                          <button className='form-control btn btn-dark'>Submit Reply</button>
                        }
                      </form>
                    </>)
                  }
                  
                  <div className='social-chat'>
                    {case_replies && (<CaseReply Replies={case_replies}/>)}

                    {/* <div className='your-msg'>
                      <Media>
                        <Image attrImage={{ className: 'img-50 img-fluid m-r-20 rounded-circle', alt: '', src: `${three}` }} />
                        <Media body>
                          <span className='f-w-500'>
                            {IssaBell}{' '}
                            <span>
                              {'1 Year Ago'} <i className='fa fa-reply font-primary'></i>
                            </span>
                          </span>
                          <P>{'we are doing dance and singing songs, please vote our post which is very good for all young peoples'}</P>
                        </Media>
                      </Media>
                    </div>

                    <div className='text-center'>
                      <a href='#javascript'>{MoreCommnets}</a>
                    </div> */}
                  </div>

                  {/* <div className='comments-box'>
                    <Media>
                      <Image attrImage={{ className: 'img-50 img-fluid m-r-20 rounded-circle', alt: '', src: `${three}` }} />
                      <Media body>
                        <InputGroup className='text-box'>
                          <Input className='form-control input-txt-bx' type='text' name='message-to-send' placeholder='Post Your commnets' />
                          <div className='input-group-append'>
                            <Btn attrBtn={{ color: 'transparent' }}>
                              <i className='fa fa-smile-o'> </i>
                            </Btn>
                          </div>
                        </InputGroup>
                      </Media>
                    </Media>
                  </div> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default CaseMessage;
