import { Col, FormGroup, Input, Row } from "reactstrap";
import { InputProviderFormSchema } from "./types";
import { SelectOption } from "../../../Constant";
import { toast } from "react-toastify";
import { useRef, useState } from "react";
import { formatDuration } from "../../../_helper/utils";

interface InputProviderProps {
    schema: InputProviderFormSchema[];
    inputData: { [key: string]: any } | null;
    onChange: (name: string, value: any) => void;
    // onSubmit: (data: { [key: string]: any }) => void;
    errors?: { [key: string]: string };
}

interface Duration {
    //days: number;
    hours: number;
    minutes: number;
}

export const isValidInput = (schema: any, inputData: any): boolean => {
    for (const field of schema) {
      if (field.required && !inputData[field.name]) {
        toast.error(`${field.label} is required.`);
        return false;
      }
    }
    return true;
};

export const parseDuration = (input: string): Duration | null => {

    if (typeof input !== 'string') {
        //return { days: 0, hours: 0, minutes: 0 }; // Return a default Duration
        return { hours: 0, minutes: 0 }; // Return a default Duration
    }
    //const regex = /(\d+d)?\s*(\d+h)?\s*(\d+m)?/; d h s
    const regex = /(\d+h)?\s*(\d+m)?/;
    const match = input.match(regex);
  
    if (!match) return null;
    /* const days = match && match[1] ? parseInt(match[1].replace('d', '')) : 0;
    const hours = match && match[2] ? parseInt(match[2].replace('h', '')) : 0;
    const minutes = match && match[3] ? parseInt(match[3].replace('m', '')) : 0; */
    
    const hours = match && match[1] ? parseInt(match[1].replace('h', '')) : 0;
    const minutes = match && match[2] ? parseInt(match[2].replace('m', '')) : 0;
  
    return { hours, minutes };
};

const validateDurationInput = (input: string): boolean => {
    // Ensure input is a string
    if (typeof input !== 'string') return false;
        
    if (input.trim() === '') return true;

    // Check for invalid characters
    //const regex = /^[0-9d h m]*$/;
    //const regex = /^(?!(.*[dh].*d)|.*(d.*m)|.*(h.*m))([0-9]*[dh]?)*[0-9]*m?$/;
    // Regex: Allow only digits followed by h (with one space allowed) and m
    //const regex = /^(?!(.*h.*m))([0-9]*h\s?)*[0-9]*m?$/;
    
    //const regex = /^(?!(.*h\s*m))([0-9]+h\s*[0-9]+m?|[0-9]+h?|[0-9]+m?)$/;
    //if (!regex.test(input)) return false;

    const duration = parseDuration(input);
    // Check if hours are present and minutes exceed 59
    /* if (duration && duration.hours > 0 && duration.minutes > 59) {
        return false;
    } */


    //const regex = /^(?!(.*h\s*m))([0-9]+h\s*[0-9]+m?|[0-9]+h?|[0-9]+m?)$/;
    const regex = /^(?!(.*h\s*m))([0-9]+h\s*[0-9]{0,2}m?|[0-9]+h?|[0-9]{1,2}m?)$/;
    if (!regex.test(input)) return false;

    
    if (duration) {
        // Check for maximum minutes (0-59)
        if (duration.minutes > 59) {
            return false;
        }
        // Check if minutes exceed 59 when hours are present
        if (duration.hours > 0 && duration.minutes > 59) {
            return false;
        }
    }
    return true;
};

/* export const validateInputs = (schema: any, inputData: any): { isValid: boolean; error?: string } => {
    for (const field of schema) {
      if (field.required && !inputData[field.name]) {
        return { isValid: false, error: `${field.label} is required.` };
      }
    }
    return { isValid: true };
}; */

export const validateInputs = (schema: any, inputData: any): { isValid: boolean; errors: { [key: string]: string } } => {
    const errors: { [key: string]: string } = {};
    let isValid = true;
  
    for (const field of schema) {
        const value = inputData[field.name];

        // Check if required and not present
        if (field.required && !value) {
            isValid = false;
            errors[field.name] = `${field.label} is required.`;
        } else if (field.type === 'duration') {
            // Check if value is present and validate
            if (value) {
                const duration: Duration | null = value;
                
                if (!duration || (duration.hours <= 0 && duration.minutes <= 0)) {
                    isValid = false;
                    errors[field.name] = `${field.label} is not a valid duration. Please use the format "2h 30m".`;
                }
            }
        }
    }
  
    return { isValid, errors };
};

export const formatCurrency = (value: string, locale = 'en-NG') => {
    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    // Format as currency
    const formattedValue = Number(numericValue).toLocaleString(locale, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    
    return formattedValue;
  };

type TFormElements = HTMLInputElement | HTMLSelectElement | any;

const InputProvider: React.FC<InputProviderProps> = ({
    schema,
    inputData,
    onChange,
    errors = {},
    // onSubmit,
}) => {
    const handleChange = (e: React.ChangeEvent<TFormElements>) => {
        const { name, value, type } = e.target;
        //console.log(name, value, type);
        onChange(name, value);
    };

    const handleDurationChange = (name: string, value: Duration | null) => {
        //const { name, value, type } = e.target;
        //console.log(name, value, type);
        onChange(name, value);
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const handleChangeCurrenctFormat = (e: React.ChangeEvent<TFormElements>) => {
        const { name, value, type } = e.target;
        //console.log(name, value, type);
        onChange(name, formatCurrency(value));
    
        // Update the input value to the formatted one
        /* const cursorPosition = e.target.selectionStart;
        const newFormattedValue = formatCurrency(value);

        // Set the formatted value
        setTimeout(() => {
        if (inputRef.current) {
            inputRef.current.value = newFormattedValue;

            // Adjust cursor position
            const newCursorPosition = calculateCursorPosition(value, newFormattedValue, cursorPosition);
            inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
        }
        }, 0); */
    };

    const calculateCursorPosition = (rawValue: string, formattedValue: string, cursorPosition: number): number => {
        const numericPart = rawValue.slice(0, cursorPosition).replace(/[^0-9]/g, '');
        const numberOfDigitsBeforeCursor = numericPart.length;

        // Get the number of digits in the formatted value before the decimal
        const decimalIndex = formattedValue.indexOf('.');
        const digitsBeforeDecimal = decimalIndex > -1 ? decimalIndex : formattedValue.length;

        // Calculate the new cursor position
        return Math.min(digitsBeforeDecimal, numberOfDigitsBeforeCursor);
    };

    // const handleSubmit = (e: React.FormEvent) => {
    //     e.preventDefault();
    //     onSubmit(inputData);
    // };

    return (
        <form
        // onSubmit={handleSubmit}
        >
            <Row>
                {schema.map((field) => (
                    <Col
                        md="6"
                        sm="12"
                        //className="d-block my-2"
                        className="d-block"
                        key={field.name}
                    >
                        <div className="relative d-flex gap-4 align-content-center">
                            {!field.html && (
                                <label className="col-sm-3 col-form-label d-block h7">
                                    {field.label}
                                    {field.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                            )}
                            {(() => {
                                switch (field.type) {
                                    case "select":
                                        return (
                                            <CustomSelect
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChange}
                                                options={field.options || []}
                                                errors={errors}    
                                            />
                                        );
                                    case "radio":
                                        return (
                                            <CustomRadio
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChange}
                                                options={field.options || []}
                                                errors={errors} 
                                            />
                                        );
                                    case "html":
                                        return (
                                            <CustomHtml
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChange}
                                                errors={errors} 
                                            />
                                        );

                                    case "money":
                                        return (
                                            <CustomInputMoney
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChangeCurrenctFormat}
                                                //inputRef={inputRef}
                                                errors={errors} 
                                            />
                                        );    
                                    case "duration":
                                        return (
                                            <CustomDuration
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleDurationChange}
                                                errors={errors}
                                            />
                                        );
                                    default:
                                        return (
                                            <CustomInput
                                                field={field}
                                                inputData={inputData}
                                                handleChange={handleChange}
                                                errors={errors} 
                                            />
                                        );
                                }
                            })()}
                        </div>
                    </Col>
                ))}
            </Row>

            <hr />
        </form>
    );
};

export default InputProvider;

type CustomInputProps = {
    field: InputProviderFormSchema;
    inputData: { [key: string]: any } | null;
    handleChange: (e: React.ChangeEvent<TFormElements>) => void;
    errors?: { [key: string]: string };
};

type CustomInputMoneyProps = {
    field: InputProviderFormSchema;
    inputData: { [key: string]: any } | null;
    handleChange: (e: React.ChangeEvent<TFormElements>) => void;
    //inputRef: React.RefObject<HTMLInputElement>;
    errors?: { [key: string]: string };
};

type CustomSelectProps = {
    field: InputProviderFormSchema;
    inputData: { [key: string]: any } | null;
    handleChange: (e: React.ChangeEvent<TFormElements>) => void;
    options: { value: string | number; label: string | number }[];
    errors?: { [key: string]: string };
};

type CustomHtmlProps = {
    field: InputProviderFormSchema;
    inputData: { [key: string]: any } | null;
    handleChange: (e: React.ChangeEvent<TFormElements>) => void;
    errors?: { [key: string]: string };
};

type CustomDurationProps = {
    field: InputProviderFormSchema;
    inputData: { [key: string]: any } | null;
    handleChange: (name: string, value: Duration  | null) => void;
    errors?: { [key: string]: string };
}

function CustomInput(props: CustomInputProps) {
    const { field, inputData, handleChange, errors = {} } = props;

    return (
        <>
            <FormGroup className="w-100">
                <Input
                    type={field.type as any}
                    name={field.name}
                    placeholder={field.placeholder}
                    className="form-control m-input digits form-control"
                    autoComplete={undefined}
                    onChange={handleChange}
                    value={
                        inputData && field.type === "checkbox"
                            ? inputData[field.name] || false
                            : (inputData && inputData[field.name]) || ""
                    }
                    required={field.required}
                />
                {/* {error && <span style={{ color: 'red' }}>{error}</span>} */}
                {errors[field.name] && <span style={{ color: 'red' }}>{errors[field.name]}</span>}
            </FormGroup>
        </>
    );
}

function CustomDuration(props: CustomDurationProps) {
    const { field, inputData, handleChange, errors = {} } = props;
//const CustomDuration: React.FC<CustomDurationProps> = ({ field, inputData, handleChange, errors = {} }) => {
    const initialDuration = inputData?.[field.name];
    let initialValue = '';

    // Determine the initial value based on the type of initialDuration
    if (typeof initialDuration === 'object' && initialDuration !== null) {
        initialValue = formatDuration(initialDuration);
    } else if (typeof initialDuration === 'string') {
        initialValue = initialDuration; 
    }

    const [duration, setDuration] = useState<string>(initialValue);

    const handleInputChange = (e: React.ChangeEvent<TFormElements>) => {
        const value = e.target.value;
        // If 'm' is present, don't allow further input
        //if (value.includes('m')) return;

        if (validateDurationInput(value)) {
            setDuration(value);
            handleChange(field.name, parseDuration(value)); // Notify parent with parsed duration
        }
    };

    const getParsedDuration = parseDuration(duration);

    return (
        <>
            <FormGroup className="w-100">
                <Input
                    type={field.type as any}
                    name={field.name}
                    placeholder={field.placeholder}
                    className="form-control m-input digits form-control"
                    autoComplete={undefined}
                    onChange={handleInputChange}
                    value={duration}
                    required={field.required}
                />
                <span style={{ color: '#ce9e31' }}><i className="fa fa-clock-o"></i>: {getParsedDuration?.hours} {getParsedDuration?.hours === 0 || getParsedDuration?.hours === 1 ? 'hour' : 'hours'}, {getParsedDuration?.minutes} {(getParsedDuration?.minutes === 0 || getParsedDuration?.minutes === 1) ? 'minute' : 'minutes'}</span><br/>
                {errors[field.name] && <span className="" style={{ color: 'red' }}>{errors[field.name]}</span>}
            </FormGroup>
        </>
    );
};

function CustomInputMoney(props: CustomInputMoneyProps) {
    const { field, inputData, handleChange
        //, inputRef
        , errors = {} } = props;

    return (
        <>
            <FormGroup className="w-100">
                <Input
                    type={field.type as any}
                    name={field.name}
                    className="form-control m-input digits form-control"
                    autoComplete={undefined}
                    onChange={handleChange}
                    //inputRef={inputRef}
                    value={
                        inputData && field.type === "checkbox"
                            ? inputData[field.name] || false
                            : (inputData && inputData[field.name]) || ""
                    }
                    required={field.required}
                />
                {errors[field.name] && <span style={{ color: 'red' }}>{errors[field.name]}</span>}
            </FormGroup>
        </>
    );
}

function CustomSelect(props: CustomSelectProps) {
    const { field, inputData, handleChange, errors = {} } = props;

    return (
        <>
            <FormGroup className="w-100">
                <Input
                    name={field.name}
                    onChange={handleChange}
                    className="form-select form-control"
                    value={inputData && inputData[field.name]}
                    //disabled={field.disabled} readOnly={field.disabled}
                    required={field.required}
                    id={field.name}
                    type="select"
                >
                    <option value="">{SelectOption}</option>
                    {field.options?.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Input>
                {errors[field.name] && <span style={{ color: 'red' }}>{errors[field.name]}</span>}
            </FormGroup>
        </>
    );
}

function CustomRadio(props: CustomSelectProps) {
    const { field, inputData, handleChange, errors = {} } = props;

    return (
        <>
            <FormGroup className="row w-100">
                <ul className="radio-wrapper">
                    {field.options?.map((val, i) => (
                        <>
                            <li key={i}>
                                <Input
                                    type="radio"
                                    onChange={handleChange}
                                    className="form-check-input"
                                    style={{ cursor: "pointer" }}
                                    id={`radio-${field.name}-${i}`}
                                    defaultValue={
                                        inputData && inputData[field?.name]
                                    }
                                    name={field.name}
                                    value={val.value}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`radio-${field.name}-${i}`}
                                >
                                    <i className="icofont icofont-chart-pie-alt"></i>
                                    <span>{val.label}</span>
                                </label>
                            </li>
                        </>
                    ))}
                </ul>
                {errors[field.name] && <span style={{ color: 'red' }}>{errors[field.name]}</span>}
            </FormGroup>
        </>
    );
}

function CustomHtml(props: CustomHtmlProps) {
    const { field, inputData, handleChange, errors = {} } = props;
    return (
        <>
            <div
                style={{ width: "100%", height: "100%" }}
                dangerouslySetInnerHTML={{
                    __html: field.html || "<div></div>",
                }}
            />
            {errors[field.name] && <span style={{ color: 'red' }}>{errors[field.name]}</span>}
        </>
    );
}



