import { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Table, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useCaseTypeListController from './controller';
import { Breadcrumbs } from '../../../../../AbstractElements';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import DataTableServerFetchComponent from '../../../../Common/Component/DataTableServerFetchComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import { CaseGroup, CaseTypeName, DateCreated, DateModified, DelegateTeam, Description, EscalationTeam, ExternalDelegateTeam, GlobalResolutionTime, Priority, ResolutionTeam, ServiceTeam, Status, Update } from '../../../../../Constant';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';
import InputProvider from '../../../../Finance/Template/InputProvider';
import SwitchRows from '../../../../Finance/Template/SwitchRows';

const CaseTypeList = () => {
  const {
    title,
    addBtn,
    formFields,
    isLoading,
    viewModalOpen,
    selectedRowData,
    editMode,
    totalRows,
    tabResolution,
    tabDelegation,
    tabXDelegation,
    activeTab,
    handleTabClick,
    tabModalOpen,
    setTabModalOpen,
    useTableAction,
    allData,
    tableColumns,
    setViewModalOpen,
    handleView,
    handleDelete,
    handleEdit,
    handlePerRowsChange,
    handlePageChange,
    memoizedHandleEdit,
    handleEditRec,

    inputErrors,

        caseFormSchema,
        caseInputData,
        handleCaseChange,

        resolutionTeamFormSchema,
        resolutionTeamInputData,
        handleResolutionTeamChange,

        delegationTeamFormSchema,
        delegationTeamInputData,
        handleDelegationTeamChange,

        externalDelegationTeamFormSchema,
        externalDelegationTeamInputData,
        handleExternalDelegationTeamChange,

        switchLabels,
        setSwitchLabels,
  } = useCaseTypeListController();

  const tableColumns2 = [
      {
        name: 'Priority',
        selector: (row: (typeof allData)[0]) => row.priority.name,
        sortable: true,
        center: false,
        cell: (row: (typeof allData)[0]) => (
            <span style={{ color: row.priority.color }}>
                <i className="fa fa-flag"></i> {row.priority.name}
            </span>
        ),
    },
  ]
  
  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title={title} mainTitle={title} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                  title={title}
                  btnUrl={addBtn}
                  span1={undefined}
                  span2={undefined}
                  mainClasses={undefined}
              />
              <CardBody>
                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  useTableAction={useTableAction}
                  
                  onEdit={undefined}
                  deleteMany={undefined}
                  clearSelect={undefined}
                  onRowSelect={undefined}
                  addToDefaultAction={undefined}
                  useDefaultAction={undefined}

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
          modalTitle={undefined}
          onSubmit={undefined}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>

                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{CaseGroup}</th>
                    <td>{selectedRowData.case_group.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{CaseTypeName}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Priority}</th>
                    <td><span style={{color: selectedRowData.priority.color}}><i className="fa fa-flag"></i> {selectedRowData.priority.name}</span></td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{GlobalResolutionTime}</th>
                    <td>{selectedRowData.global_resolution_time}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ServiceTeam}</th>
                    <td>{selectedRowData.service_team.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{EscalationTeam}</th>
                    <td>{selectedRowData.escalation_team.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

        </ViewModal>

        <ViewModal
          isOpen={tabModalOpen}
          toggle={() => setTabModalOpen(!tabModalOpen)}
          rowData={selectedRowData}
          formFields={undefined}
          editMode={editMode}
          onEdit={undefined}
          modalTitle={undefined}
          onSubmit={undefined}
        > 
          <form onSubmit={handleEditRec}>
              <InputProvider
                  schema={caseFormSchema}
                  inputData={caseInputData}
                  onChange={handleCaseChange}
                  errors={inputErrors.caseFormErrors}
              />

              <Row>
                  <Col sm={'12'}>
                      <Nav className='border-tab nav-secondary nav-pills' tabs>
                          <NavItem>
                              <NavLink href='#' className={activeTab === tabResolution ? 'active' : ''}
                                          onClick={() => handleTabClick(tabResolution)}>
                                  <i className='icofont icofont-users'></i>
                                  {ResolutionTeam}
                              </NavLink>
                          </NavItem>
                          <NavItem>
                              <NavLink href='#' className={activeTab === tabDelegation ? 'active' : ''}
                                          onClick={() => handleTabClick(tabDelegation)}>
                                  <i className='icofont icofont-users'></i>
                                  {DelegateTeam}
                              </NavLink>
                          </NavItem>
                          <NavItem>
                              <NavLink href='#' className={activeTab === tabXDelegation ? 'active' : ''}
                                          onClick={() => handleTabClick(tabXDelegation)}>
                                  <i className='icofont icofont-users'></i>
                                  {ExternalDelegateTeam}
                              </NavLink>
                          </NavItem>
                      </Nav> 
                      <TabContent activeTab={activeTab}>
                          <TabPane className='fade show' tabId={tabResolution}>
                              <InputProvider
                                  schema={resolutionTeamFormSchema}
                                  inputData={resolutionTeamInputData}
                                  onChange={handleResolutionTeamChange}
                                  errors={inputErrors.resolutionTeamFormErrors}
                              />
                          </TabPane>

                          <TabPane className='fade show' tabId={tabDelegation}>
                              <InputProvider
                                  schema={delegationTeamFormSchema}
                                  inputData={delegationTeamInputData}
                                  onChange={handleDelegationTeamChange}
                                  errors={inputErrors.delegationTeamFormErrors}
                              />
                          </TabPane>

                          <TabPane className='fade show' tabId={tabXDelegation}>
                              <InputProvider
                                  schema={externalDelegationTeamFormSchema}
                                  inputData={externalDelegationTeamInputData}
                                  onChange={handleExternalDelegationTeamChange}
                                  errors={inputErrors.externalDelegationTeamErrors}
                              />
                          </TabPane>
                      </TabContent>
                  </Col>
              </Row>

              <SwitchRows
                  setSwitchFormDetails={setSwitchLabels}
                  switchFormDetails={switchLabels}
              />

              <div className="text-end">
                  <Button color="primary">
                      {Update}
                  </Button>
              </div>
          </form>
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default CaseTypeList;

export const StatusRow = (row: any) => {
  return row.is_active ? (
      <span className="badge badge-light-success">Active</span>
  ) : (
      <span className="badge badge-light-danger">Inactive</span>
  );
};

export const PriorityRow = (row: any) => {
  return <span style={{ color: row.priority.color }}>
    <i className="fa fa-flag"></i> {row.priority.name}
  </span>
};