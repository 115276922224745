import { Fragment } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Expense, STATUS } from "../../../../Constant";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";

import "../../Template/Components/ERstyle.css";
import Header from "../../Template/Components/Header";
import DataTableComponent from "../../../Common/Component/DataTableComponent";
import useExpenseRequestController from "./controller";
import ViewModal from "../../../Common/Component/ViewModal";

const ExpenseRequest = () => {
  const {
    newExpenseBtn,
    tableColumns,
    expenseRequestData,
    handleView,
    viewModalOpen,
    setEditMode,
    selectedRowData,
    setViewModalOpen,
    editMode,
    handleFilteringByNav,
    navsTextNode
  } = useExpenseRequestController();

  return (
    <Fragment>
      <Breadcrumbs
        parent={Expense}
        title={Expense + " " + "Request"}
        mainTitle={Expense + " " + "Request"}
      />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title={"Expenses"}
                span1={null}
                span2={null}
                mainClasses={null}
                btnUrl={newExpenseBtn}
              />
              <CardBody>
                <Header
                  navCallBack={handleFilteringByNav}
                  navTextNodes={navsTextNode}
                />
                <DataTableComponent
                  tableColumns={tableColumns}
                  isLoading={undefined}
                  tabledata={expenseRequestData}
                  onView={handleView}
                  // onDelete={handleDelete}
                  onEdit={() => ""}
                  useTableAction={false}
                  addToDefaultAction={true}
                  onDelete={undefined}
                  notEditable={false}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          modalTitle={undefined}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={undefined}
          editMode={editMode}
          onEdit={undefined}
          onSubmit={undefined}
        >
          {selectedRowData &&
            <Table>
              <tbody>
                <tr className="border-bottom-primary">
                  <th scope="row">
                    {"REQUESTER"}
                  </th>
                  <td>
                    {selectedRowData.REQUESTER}
                  </td>
                </tr>

                <tr className="border-bottom-primary">
                  <th scope="row">
                    {"BENEFICIARY"}
                  </th>
                  <td>
                    {selectedRowData.BENEFICIARY}
                  </td>
                </tr>

                <tr className="border-bottom-primary">
                  <th scope="row">
                    {"TOTAL"}
                  </th>
                  <td>
                    {selectedRowData.TOTAL}
                  </td>
                </tr>

                <tr className="border-bottom-primary">
                  <th scope="row">
                    {"AMORTISATION TYPE"}
                  </th>
                  <td>
                    {selectedRowData.AMORTISATION_TYPE}
                  </td>
                </tr>

                <tr className="border-bottom-primary">
                  <th scope="row">
                    {"TRANSACTION DATA"}
                  </th>
                  <td>
                    {selectedRowData.TRANSACTION_DATA}
                  </td>
                </tr>
                <tr className="">
                  <th scope="row">
                    {STATUS}
                  </th>
                  <td>
                    <td>
                      {selectedRowData.STATUS
                        ? <span className="badge badge-light-success">
                            APPROVED
                          </span>
                        : <span className="badge badge-light-danger">
                            PENDING
                          </span>}
                    </td>
                  </td>
                </tr>
              </tbody>
            </Table>}
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default ExpenseRequest;
