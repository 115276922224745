import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import { General, Settings } from "../../../Constant";
import countries from "./countries.json";
import dataJson from "./data.json";
import TaskAssignerTwinTables, { tableColumns } from "./TwinTable";
import {
    Description,
    DescriptionName,
    IncomeCategory,
} from "../../../Constant";
import TaskAssignerSearchForm, { FormField } from "./TaskAssignerSearchForm";

export default function TaskAssignerPage() {
    const [form, setForm] = useState<FormField[]>([
        {
            name: "search",
            placeholder: "Search...",
            label: "Search",
            value: "FIRE 🔥",
            type: "text",
        },

        {
            name: "email",
            placeholder: "Search By Email...",
            label: "Email Search",
            value: "",
            type: "email",
            required: false,
            disabled: false,
        },

        {
            name: "isdone",
            placeholder: "Search By Email...",
            label: "Is Done",
            value: "0",
            type: "switch",
        },

        {
            name: "country",
            placeholder: "Search By Country...",
            label: "Country Search",
            value: "",
            type: "select",
            options: countries, // [{label: "United States", value: "US"}]
        },
    ]);

    const [data] = useState(dataJson);
    const tableColumns: tableColumns = [
        {
            name: DescriptionName,
            selector: (row: (typeof data)[0]) => `${row.group_name}`,
            sortable: true,
            center: false,
        },

        {
            name: Description,
            selector: (row: (typeof data)[0]) => `${row.description}`,
            sortable: false,
            center: false,
        },

        {
            name: IncomeCategory,
            selector: (row: (typeof data)[0]) => `${row.income_category}`,
            sortable: true,
            center: false,
        },
    ];

    const [assigned, setAssigned] = React.useState<typeof data>([]);
    const [unassigned, setUnassigned] = React.useState<typeof data>([]);

    useEffect(() => {
        setAssigned(data.filter((item) => item.is_assigned));
        setUnassigned(data.filter((item) => item.is_assigned === false));
    }, []);

    return (
        <div className="mt-5 GeneralLedgerAssign">
            <Breadcrumbs
                parent={Settings}
                title={General}
                mainTitle={General}
            />
            <div className="filter-head-pane">
                <TaskAssignerSearchForm
                    form={form}
                    setForm={setForm}
                    onSubmit={(e: FormField[]) => {
                        //console.log(e);
                    }}
                />
            </div>

            <br />

            <TaskAssignerTwinTables
                tableData={dataJson}
                leftTableTitle={"Left Side Table"}
                leftTable={assigned}
                leftTableColumns={tableColumns}
                rightTableTitle={"Right Side Table"}
                rightTable={unassigned}
                rightTableColumns={tableColumns}
            />
        </div>
    );
}
