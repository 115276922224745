import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { DateCreated, DateModified, DeleteSuccess, Department, Description, EscalationExecutiveTeam, EscalationTeam, ExternalTeam, Location, Members, ServiceScope, ServiceTeam, Status, Team, TeamMember, TeamType, Unit, Vendor, description } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { CaseTeamMappingApi, DepartmentApi, LocationDropdownApi, ServiceScopeApi, TeamApi, TeamMemberApi, TeamTypeApi, UnitApi, VendorApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import { base_form_fields, ContactTypeVal, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import useAuth from '../../../../Hooks/useAuth';

const TeamList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(base_form_fields(`${Team}`, 6));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
  const [isNewMFieldAdded, setIsNewMFieldAdded] = useState(false);
  
  const teamTypeDropdown = createDropdownField(axiosPrivate,
    'team_type_id',
    'team_type_id',
    `${TeamType}`,
    `${TeamTypeApi}?organisation_id=${userOrganisationId}`,
    '', 6
    );
    
    const unitDropdown = createDropdownField(axiosPrivate,
      'unit_id',
      'unit_id',
      `${Unit}`,
      `${UnitApi(userOrganisationId)}`,
      '', 6, false
    );
  
    const deptDropdown = createDropdownField(axiosPrivate,
      'department_id',
      'department_id',
      `${Department}`,
      `${DepartmentApi(userOrganisationId)}`,
      '', 6, false
    );

    const locationTypeDropdown = createDropdownField(axiosPrivate,
      'location_id',
      'location_id',
      `${Location}`,
      `${LocationDropdownApi(userOrganisationId)}`,
      '', 6, false
    );

    const teamDropdown = createDropdownField(axiosPrivate,
      'team_id',
      'team_id',
      `${Team}`,
      '',
      '', 6
    );

    const member_form_fields = [
      {
        id: 'contacts',
        name: 'contacts',
        label: `${TeamMember}`,
        type: 'checkbox',
        value: '',
        required: false,
        colSize: 6,
        options: []
      },
    ];

    const internalExternal = {
      id: 'is_external',
      name: 'is_external',
      label: `${ExternalTeam}`,
      type: 'switch',
      value: '1',
      required: false,
      colSize: 6,
    };

    const serviceScopeDropdown = createDropdownField(axiosPrivate,
      'service_scope_id',
      'service_scope_id',
      `${ServiceScope}`,
      `${ServiceScopeApi}?organisation_id=${userOrganisationId}`,
      '', 6
    );

    const escalationFunctionalTeamDropdown = createDropdownField(axiosPrivate,
      'escalation_team_functional_id',
      'escalation_team_functional_id',
      `${EscalationTeam}`,
      '',
      '', 6, false
    );

    const escalationExecutiveTeamDropdown = createDropdownField(axiosPrivate,
      'escalation_team_executive_id',
      'escalation_team_executive_id',
      `${EscalationExecutiveTeam}`,
      '',
      '', 6, false
    );

    const vendorDropdownField = {
      name: "vendor_id",
      id: "contact.contact_number, contact.company_name, contact.last_name, contact.first_name",
      api: `${VendorApi}/transpose`,
      label: `${Vendor}`,
      type: "input-select",
      value: { id: "", label: "" },
      colSize: 6,
      required: false,
      //useKeyID: 'user_login.id'
    };

    const [formMemberFields, setFormMemberFields] = useState(member_form_fields);
      
  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const updatedFields = [teamTypeDropdown, serviceScopeDropdown, ...formFields, 
            deptDropdown, locationTypeDropdown, internalExternal, vendorDropdownField,
            escalationFunctionalTeamDropdown, escalationExecutiveTeamDropdown];
          
          await teamTypeDropdown.fetchOptions();
          await serviceScopeDropdown.fetchOptions();
          await deptDropdown.fetchOptions();
          await locationTypeDropdown.fetchOptions();
          const teamUrl = `${TeamApi}?with_trashed=false&organisation_id=${userOrganisationId}`;

          fetchTeamMappingData().then(async (data) => {
            if(data) {
                const escalationFunctionalTeam = data.caseTeamMappingData.find(item => item.key === "escalation_functional_team")
                const escalationExecutiveTeam = data.caseTeamMappingData.find(item => item.key === "escalation_executive_team")
                
                await escalationFunctionalTeamDropdown.fetchOptions(null, false, `${teamUrl}&team_type_id=${escalationFunctionalTeam?.value}`);
                await escalationExecutiveTeamDropdown.fetchOptions(null, false, `${teamUrl}&team_type_id=${escalationExecutiveTeam?.value}`);
            }
          });

          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, teamTypeDropdown]);

  const fetchTeamMappingData = async () => {
    try {
          const caseTeamMappingUrl = `${CaseTeamMappingApi}`;

          const [
              caseTeamMappingRes,
          ] = await Promise.all([
              axiosPrivate.get(caseTeamMappingUrl),
          ]);

          const caseTeamMappingData = await caseTeamMappingRes.data.data.settings;

          return {
              caseTeamMappingData,
          };
      } catch (error) {
          //console.error("Error fetching data:", error);
      }
  };

  useEffect(() => {

    const fetchMemberData = async () => {
      try {
        if (!isNewMFieldAdded) {

          const updatedFields = [teamTypeDropdown, teamDropdown, ...formMemberFields];
          
          await updatedFields[0].fetchOptions();
          
          setFormMemberFields(updatedFields);
          setIsNewMFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchMemberData();
  }, [formMemberFields, isNewMFieldAdded, teamTypeDropdown, teamDropdown]);

  teamTypeDropdown.onChange = async (selectedTeamTypeId, updatedFields) => {    
    const teamField = updatedFields.find((field) => field.id === 'team_id');
    
    if (teamField) {
      const endPoint = `${TeamApi}?organisation_id=${userOrganisationId}&team_type_id=${selectedTeamTypeId}`
      await teamField.fetchOptions(selectedTeamTypeId, false, endPoint);
      setFormMemberFields([...updatedFields]);
    } 
  };

  teamDropdown.onChange = async (selectedTeamId, updatedFields) => {    
    const indexOfContact = updatedFields.findIndex((updatedField) => updatedField.id === 'contacts');
    
    if (indexOfContact !== -1) {
      updatedFields[indexOfContact].options.splice(0);
      const endPoint = `${TeamMemberApi}/by-team-unit?organisation_id=${userOrganisationId}&team_id=${selectedTeamId}`
      try {
        await axiosPrivate.get(endPoint).then((resp) => {
          resp.data.forEach(option => {
            updatedFields[indexOfContact].options.push({ value: option.id, label: option.name });
          });
        });
      } catch (error) { }

      setFormMemberFields([...updatedFields]);
    } 
  };

  const getAllTeamsData = async () => {
    try {
      await axiosPrivate.get(`${TeamApi}?organisation_id=${userOrganisationId}`).then((resp) => {
        setAllData(resp.data);
        //setIsLoading(false);
      });
    } catch (error) {
      toast.error(error.response.data.detail)
      //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllTeamsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);

    const vendorID = formFields.filter(
      (field) => field.name === "vendor_id"
    );
    vendorID[0].value.id = null;
    vendorID[0].value.label = null;

    row.service_scope_id = null
    if(row.service_scope) {
      row.service_scope_id = row.service_scope.id
    }
    
    if(row.escalation_team_functional) {
      row.escalation_team_functional_id = row.escalation_team_functional.id
    }

    if(row.escalation_team_executive) {
      row.escalation_team_executive_id = row.escalation_team_executive.id
    }


    if(row.vendor) {
      vendorID[0].value.id = row.vendor.id;
      vendorID[0].value.label = (row.vendor.contact.contact_type === ContactTypeVal.Corporate) ?
      `${row.vendor.contact.contact_number} ${row.vendor.contact.company_name}` :
      ((row.vendor.contact.last_name) ?
        `${row.vendor.contact.contact_number} ${row.vendor.contact.last_name} ${row.vendor.contact.first_name}  ${row.vendor.contact.middle_name}` :
        "No Name");
    }

    setSelectedRowData(row);
  };

  const handleEditRec = async (id, editRecord) => {
    //console.log(editRecord.vendor_id)
    
    let vendor_id; //: string | number | null;

    if (typeof editRecord.vendor_id === 'object' && editRecord.vendor_id !== null && editRecord.vendor_id.id !== '') {
      // If vendor_id is an object and id is not an empty string, use the id
      vendor_id = editRecord.vendor_id.id;
    } else if (typeof editRecord.vendor_id === 'number' && editRecord.vendor_id !== null) {
      // If vendor_id is a number, use the number directly
      vendor_id = editRecord.vendor_id;
    } else {
      // Otherwise, set vendor_id to null
      vendor_id = null;
    }
        
    
    //return false
    const editRecordTemp = {
      team_type_id: editRecord.team_type_id,
      service_scope_id: editRecord.service_scope_id,
      name: editRecord.name,
      description: editRecord.name,
      is_active: editRecord.is_active,
      unit_id: editRecord.unit_id || null,
      department_id: editRecord.department_id || null,
      location_id: editRecord.location_id || null,
      //vendor_id: editRecord.vendor_id.id || editRecord.vendor_id || null,
      vendor_id: vendor_id,
      is_external: editRecord.is_external,
      escalation_team_functional_id: editRecord.escalation_team_functional_id || null,
      escalation_team_executive_id: editRecord.escalation_team_executive_id || null,
    };

    try {
      await axiosPrivate.put(`${TeamApi}/${id}`, editRecordTemp).then((resp) => {
        const updatedData = allData.map((row) =>
          row.id === selectedRowData.id ? { ...row, ...resp.data } : row
        );

        setAllData(updatedData);

        toast.success(`${resp.data.name} is successfully updated.`)
        handleCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const OpenMemberModal = async (row) => {
    setMemberModalOpen(true);
    setEditMode(true);
    row.team_id = row.id;
    teamTypeDropdown.onChange(row.team_type_id, formMemberFields);
    teamDropdown.onChange(row.team_id, formMemberFields) 
    setSelectedRowData(row);
  }

  const addToDefaultAction = [
    {
      label: `${Members}`,
      action: OpenMemberModal,
      color: 'warning',
      icon: 'icon-user',
    },
  ];

  const handleMemberCreateUpdate = async (id, editRecord) => {
    editRecord.contacts = editRecord.contacts.map(contact => {
      return { 
        "contact_id": parseInt(contact),
        "is_active": true
      }; 
    });

    const editRecordTemp = {
      team_id: editRecord.team_id,
      contacts: editRecord.contacts
    }

    try {
      await axiosPrivate.post(`${TeamMemberApi}`, editRecordTemp).then((resp) => {
        toast.success(`Team Member is successfully updated.`)
        handleMemberCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  }

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);
  const memoizedOpenMemberModal = useMemo(() => handleMemberCreateUpdate, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleMemberCloseModal = () => {
    setMemberModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${TeamApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/hrm/personnel/teams/create/${layoutURL}`;

  const tableColumns = [
    {
      name: `${TeamType}`,
      selector: row => `${row.team_type.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Team}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Description}`,
      selector: row => `${row.description}`,
      sortable: true,
      center: false,
    },
    {
      name: `${ServiceScope}`,
      selector: row => `${row.service_scope?.name}`,
      sortable: true,
      center: false,
      cell: row => (row.service_scope ? row.service_scope.name : "")
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="HRM" title="Team" mainTitle="Team" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                  //addToDefaultAction={addToDefaultAction}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                <tr className='border-bottom-secondary'>
                    <th scope='row'>{TeamType}</th>
                    <td>{selectedRowData.team_type.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Team}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ServiceScope}</th>
                    <td>{selectedRowData.service_scope?.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

        </ViewModal>

        <ViewModal
          isOpen={memberModalOpen}
          toggle={() => setMemberModalOpen(!memberModalOpen)}
          rowData={selectedRowData}
          formFields={formMemberFields}
          editMode={editMode}
          onEdit={memoizedOpenMemberModal}
        />   
      </Container>
    </Fragment>
  );
};

export default TeamList;