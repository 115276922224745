import { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Breadcrumbs } from "../../../../../AbstractElements";
import useCaseTeamMappingController from "./controller";
import { TeamMapping, CRM, Update } from "../../../../../Constant";
import InputProvider from "../../../../Finance/Template/InputProvider";

export default function CaseTeamMapping() {
    const {
        handleSubmit,
        inputErrors,

        caseTeamMappingFormSchema,
        caseTeamMappingInputData,
        handleCaseTeamMappingChange,
    } = useCaseTeamMappingController();

    return (
        <Fragment>
          <Breadcrumbs 
            parent={CRM} 
            title={`${TeamMapping}`} 
            mainTitle={`${TeamMapping}`}  
          />
          <Container fluid={true}>
            <Row>
              <Col sm='12'>
                <Card className="ribbon-wrapper-right b-l-secondary border-2">
                    <CardBody className="pt-2">
                        <form onSubmit={handleSubmit}>
                            <InputProvider
                                schema={caseTeamMappingFormSchema}
                                inputData={caseTeamMappingInputData}
                                onChange={handleCaseTeamMappingChange}
                                errors={inputErrors.caseTeamMappingFormErrors}
                            />

                            <div className="text-end">
                                <Button color="primary">
                                    {Update}
                                </Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
                
              </Col>
            </Row>
          </Container>
        </Fragment>
      );
}