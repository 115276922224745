import { useContext, useLayoutEffect, useMemo, useState } from "react";
import useAuth from "../../../../../../Hooks/useAuth";
import CustomizerContext from "../../../../../../_helper/Customizer";
import useAxiosPrivate from "../../../../../../Hooks/useAxiosPrivate";
import { InventoryTypes } from "../types";
import { toast } from "react-toastify";
import {StatusRow} from "../../../../../Finance/Settings/ReportType/List/ReportTypeList";
import { formatDateWithTimeZone } from "../../../../../../_helper/dateHelper";
import { BudgetPriceGuideInventoryApi, InventoryClassApi, InventoryDescriptionApi, InventoryGroupApi, InventoryTypeApi } from "../../../../../../api";
import {
  Name,
  Status,
  Description,
  DateCreated,
  DateModified,
  InventoryPriceGuide,
  Amount,
  InventoryDescription,
  InventoryType,
  InventoryClass,
  InventoryGroup,
  FiscalYear,
} from "../../../../../../Constant";
import { IFiscalYearResponse } from "../../controller";
import { createDropdownField } from "../../../../../../Data/FormField/PublicData";


export default function useBudgetInventoryPriceGuide(fiscal_year: IFiscalYearResponse) {
  const { userOrganisationId }: any = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const title = InventoryPriceGuide;
  const [data, setData] = useState<InventoryTypes[]>([]);
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "amount",
      name: "amount",
      label: Amount,
      type: "number",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const InventoryGroupDropDown = createDropdownField(
      axiosPrivate,
      "administration_inventory_group_id",
      "administration_inventory_group_id",
      InventoryGroup,
      `${InventoryGroupApi}?organisation_id=${userOrganisationId}`,
      "",
      6, '','', true
  );

  InventoryGroupDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "administration_inventory_class_id"
      );
      if (nextField) {
        const endPoint = `${InventoryClassApi}?inventory_group_id=${id}`
        await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "administration_inventory_group_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
  };

  const InventoryClassDropDown = createDropdownField(
      axiosPrivate,
      "administration_inventory_class_id",
      "administration_inventory_class_id",
      InventoryClass,
      ``,
      "",
      6, '','', true
  );

  InventoryClassDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "administration_inventory_type_id"
      );
      if (nextField) {
        const endPoint = `${InventoryTypeApi}?inventory_class_id=${id}`
        await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "administration_inventory_class_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
  };

  const InventoryTypeDropDown = createDropdownField(
      axiosPrivate,
      "administration_inventory_type_id",
      "administration_inventory_type_id",
      InventoryType,
      ``,
      "",
      6, '','', true
  );

  InventoryTypeDropDown.onChange = async (id, updatedFields) => {
      const nextField = updatedFields.find(
          (field: any) => field.id === "administration_inventory_description_id"
      );
      if (nextField) {
        const endPoint = `${InventoryDescriptionApi}?inventory_class_id=${id}`
        await nextField.fetchOptions(id, false, endPoint);
          const updatedFormFields = updatedFields.map((field: any) => {
              if (field.id === "administration_inventory_type_id") {
                  return { ...field, value: id };
              }
              return field;
          });
          setFormFields([...updatedFormFields]);
      }
  };

  const InventoryDescriptionDropDown = createDropdownField(
      axiosPrivate,
      "administration_inventory_description_id",
      "administration_inventory_description_id",
      InventoryDescription,
      ``,
      "",
      6, '','', true
  );

  async function updateFields() {
      const updatedFields = [
        InventoryGroupDropDown, 
        InventoryClassDropDown, 
        InventoryTypeDropDown, 
        InventoryDescriptionDropDown, 
        ...formFields];
      await InventoryGroupDropDown.fetchOptions();
      setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    updateFields();
  }, []);

  const tableColumns = [
    {
      name: InventoryDescription,
      selector: (row: (typeof data)[0]) => `${row.administration_inventory_description.name}`,
      sortable: true,
      center: false,
    },
    {
      name: Amount,
      selector: (row: (typeof data)[0]) => `${row.amount}`,
      sortable: true,
      center: false,
    },
    {
      name: FiscalYear,
      selector: (row: (typeof data)[0]) => `${row.finance_fiscal_year.name}`,
      sortable: true,
      center: false,
    },
    {
      name: InventoryType,
      selector: (row: (typeof data)[0]) => `${row.administration_inventory_description.administration_inventory_type.name}`,
      sortable: true,
      center: false,
    },
    {
      name: InventoryClass,
      selector: (row: (typeof data)[0]) => `${row.administration_inventory_description.administration_inventory_type.administration_inventory_class.name}`,
      sortable: true,
      center: false,
    },
    {
      name: InventoryGroup,
      selector: (row: (typeof data)[0]) => `${row.administration_inventory_description.administration_inventory_type.administration_inventory_class.administration_inventory_group.name}`,
      sortable: true,
      center: false,
    },
    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    {
      name: DateCreated,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: DateModified,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const { layoutURL } = useContext(CustomizerContext);
  const [loading, setLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axiosPrivate
      .get(
        `${BudgetPriceGuideInventoryApi}?with_trashed=${false}&fiscal_year_id=16`
      )
      .then((res) => {
        const response: InventoryTypes[] = res.data.data;
        setData(response);

        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useLayoutEffect(() => {
    fetchData();
  }, []);

  //
  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0] | any
  ) => {
    await axiosPrivate
      .put(`${BudgetPriceGuideInventoryApi}/${id}`, {
        ...editRecord,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.data.administration_inventory_description.name} Editted Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error("Validation Errors");
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Whoops! Something went wrong");
        }
        // throw err;
        toast.error(err.response.message || "Whoops! Something went wrong");
      });
  };

  const handleEdit = (row: (typeof data)[0] | any) => {
    setViewModalOpen(true);
    setEditMode(true);

    row.administration_inventory_group_id = row.administration_inventory_description.administration_inventory_type.administration_inventory_class.administration_inventory_group.id;
    InventoryGroupDropDown.onChange(row.administration_inventory_group_id, formFields);
    
    row.administration_inventory_class_id = row.administration_inventory_description.administration_inventory_type.administration_inventory_class.id;
    InventoryClassDropDown.onChange(row.administration_inventory_class_id, formFields);
    
    row.administration_inventory_type_id = row.administration_inventory_description.administration_inventory_type.id;
    InventoryTypeDropDown.onChange(row.administration_inventory_type_id, formFields);

    row.administration_inventory_description_id = row.administration_inventory_description.id;

    setSelectedRowData(row);
  };
  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  return {
    handleView,
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    title,
    formFields,
    loading,
    setViewModalOpen,
    handleEdit,
    memoizedHandleEdit,
  };
}
