import { useEffect, useState } from "react";
import { InputProviderFormSchema } from "../../Template/InputProvider2";
import { IProducts, IUserCardDetails } from "../../Template/types";
import { TYearInputsFigures } from "../../Template/YearInputs2";

export default function useRequestForm() {
    const [switchLabels, setSwitchLabels] = useState([
        { id: 1, label: "Within Expense Limit ?", value: true },
        { id: 1, label: "Budgeted ?", value: false },
        { id: 1, label: "Budgeted Funding Covered?", value: true },
    ]);
    const [yearFigures, setYearFiguresData] = useState<TYearInputsFigures[]>([
        {
            year: "2024",
            date: [
                { month: "January", amount: "0.00" },
                { month: "February", amount: "0.00" },
                { month: "March", amount: "0.00" },
                { month: "April", amount: "0.00" },
                { month: "May", amount: "0.00" },
                { month: "June", amount: "0.00" },
                { month: "July", amount: "0.00" },
                { month: "August", amount: "0.00" },
                { month: "September", amount: "0.00" },
                { month: "October", amount: "0.00" },
                { month: "November", amount: "0.00" },
                { month: "December", amount: "0.00" },
            ],
        },
        /* {
            year: "2025",
            date: [
                { month: "January", amount: "0.00" },
                { month: "February", amount: "0.00" },
                { month: "March", amount: "0.00" },
                { month: "April", amount: "0.00" },
                { month: "May", amount: "0.00" },
                { month: "June", amount: "0.00" },
                { month: "July", amount: "0.00" },
                { month: "August", amount: "0.00" },
                { month: "September", amount: "0.00" },
                { month: "October", amount: "0.00" },
                { month: "November", amount: "0.00" },
                { month: "December", amount: "0.00" },
            ],
        }, */
    ]);

    const [products, seIProducts] = useState<IProducts[]>([
        {
            product: "LVG 45BG",
            rate: "17,463.00",
            quantity: "0",
            amount: "2123000",
        },
        {
            product: "STS 45BG",
            rate: "17,463.00",
            quantity: "0",
            amount: "2123000",
        },
    ]);

    const userDetails: IUserCardDetails = {
        for: "Requester's Information",
        ID: "CS0001",
        name: "Ayomide Cole",
        unitName: "unit name",
        cost_center: "cost center",
        date: "09/2/24",
        department: "Financial",
    };

    const Beneficiary: IUserCardDetails = {
        for: "Beneficiary",
        ID: "CS0001",
        name: "Gorimakpa Jandon",
        unitName: "unit name",
        cost_center: "cost center",
        date: "09/2/24",
        department: "Financial",
    };

    const [settleMentFormSchema, setSettleMentFormSchemaForm] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "settlementMode",
            label: "Settlement Mode",
            type: "select",
            options: ["Cash", "Bank Transfer"],
            required: true,
        },
    ]);

    const [expenseFormSchema, setExpenseFormSchemaForm] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "expenseGroup",
            label: "Expense Group",
            type: "select",
            options: ["Cost of Sales", "Administration", "Establishment"],
            required: true,
        },
        {
            name: "expenseClass",
            label: "Expense Class",
            type: "select",
            options: ["APN Connectivity", "Bandwidth", "Fiber Installation"],
            required: true,
        },
        {
            name: "expenseType",
            label: "Expense Type",
            type: "select",
            options: ["APN Data Service", "Equipment For APN", "Internet Bandwidth"],
            required: true,
        },
        {
            name: "expenseDescription",
            label: "Expense Description",
            type: "select",
            options: ["Apn Data Service", "Equipment For APN", "Internet Bandwidth"],
            required: true,
        },
        {
            name: "processType",
            label: "Process Type",
            type: "select",
            options: ["Imprest", "Claim", "Cash Advance", "Bill"],
            required: true,
        },
    ]);

    const [transactionFormSchema, setTransactionFormSchemaForm] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "transactionNarration",
            label: "Transaction Narration / Purpose",
            type: "textarea",
            required: true,
        },
        {
            name: "exchangeRate",
            label: "Exchange Rate",
            type: "text",
            required: true,
        },
    ]);

    const [receiptFormSchema, setReceiptFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "receiptDate",
            label: "Receipt Date",
            type: "date",
            required: true,
        },
        {
            name: "receiptNumber",
            label: "Receipt Number",
            type: "number",
            required: true,
        },
        {
            name: "dueDate",
            label: "Due Date",
            type: "date",
            required: true,
        },
        {
            name: "transactionDate",
            label: "Transaction Date",
            type: "date",
            required: true,
        },
        {
            name: "utilisationPeriod",
            label: "Utilisation Period",
            placeholder: "1",
            type: "text",
            required: true,
        },
        {
            name: "utilisationStart",
            label: "Utilisation Start",
            type: "date",
            required: true,
        },
        {
            name: "utilisationEnd",
            label: "Utilisation End",
            type: "date",
            required: true,
        },
    ]);

    const [budgetCategorySchema, setBudgetCategorySchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "budgetCategory",
            label: "Budget Category",
            type: "radio",
            required: true,
            options: ["Standard", "Module"],
        },
        {
            name: "paymentOption",
            label: "Payment Option",
            type: "radio",
            required: true,
            options: ["Full Payment", "Half Payment"],
        },
    ]);

    //?? --- INPUT DATA'S ---- ??//
    //?? --- INPUT DATA'S ---- ??//
    const [settlementInputData, setSettlementInputData] = useState<{
        [key: string]: any;
    }>({});

    const [expenseInputData, setExpenseInputData] = useState<{
        [key: string]: any;
    }>({});

    const [transactionInputData, setTransactionInputData] = useState<{
        [key: string]: any;
    }>({});

    const [receiptInputData, setReceiptInputData] = useState<{
        [key: string]: any;
    }>({});

    const [budgetCategoryInputData, setBudgetCategoryInputData] = useState<{
        [key: string]: any;
    }>({});

    const handleSettlementChange = (name: string, value: any) => {
        setSettlementInputData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleExpenseChange = (name: string, value: any) => {
        setExpenseInputData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleTransactionChange = (name: string, value: any) => {
        setTransactionInputData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleReceiptChange = (name: string, value: any) => {
        setReceiptInputData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleBudgetCategoryChange = (name: string, value: any) => {
        console.log(name);
        console.log(value);
        setBudgetCategoryInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const handleSubmit = (data: { [key: string]: any }) => {
    //     console.log("Form data submitted:", data);
    // };

    useEffect(() => {
        console.log(switchLabels);
    }, [switchLabels]);

    useEffect(() => {
        setTimeout(() => {
            setSettleMentFormSchemaForm((prev) => [
                ...prev,
                {
                    name: "userBio",
                    label: "",
                    type: "html",
                    html: `<div>
                      <h6 style="margin-bottom:10px">Beneficiary Account Details</h6>
                      <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 5px;">
                        <div>
                          <h7 style="font-weight:600;">Bank Name</h7>
                          <p>GTB</p>
                        </div>
                        <div>
                          <h7 style="font-weight:600;">Account Name</h7>
                          <p>Balae Cargo</p>
                        </div>
                        <div>
                          <h7 style="font-weight:600;">Account Number</h7>
                          <p>${Math.random() * 100}</p>
                        </div>
                      </div>
                    </div>`,
                },
            ]);
        }, 2000);
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        //console.log("Form submitted");
        // Handle form submission logic here, log all values
        //console.log("Settlement Mode:", settlementInputData);
        //console.log("Expense Group:", expenseInputData);
        //console.log("Transaction Narration:", transactionInputData);
        //console.log("Receipt Date:", receiptInputData);
        //console.log("Budget Category:", budgetCategoryInputData);
        //console.log("Switch Labels:", switchLabels);
        //console.log("Products:", products);
        //console.log("Year Figures:", yearFigures);
        //console.log("User Details:", userDetails);
        //console.log("Beneficiary:", Beneficiary);
    };

    return {
        userDetails,
        Beneficiary,

        yearFigures,
        setYearFiguresData,

        handleSubmit,
        switchLabels,
        setSwitchLabels,
        products,
        seIProducts,
        settleMentFormSchema,
        settlementInputData,
        handleSettlementChange,
        expenseFormSchema,
        expenseInputData,
        handleExpenseChange,
        transactionFormSchema,
        transactionInputData,
        handleTransactionChange,
        receiptFormSchema,
        receiptInputData,
        handleReceiptChange,
        budgetCategorySchema,
        budgetCategoryInputData,
        handleBudgetCategoryChange,
        // handleSubmit,
    };
}
