import { useContext, useState, useEffect, useLayoutEffect } from 'react';
import CustomizerContext from '../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import {useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { financesFiscalYearCurrentApi } from '../../../../api';

type RouteParams = {
    tabName?: string;
};

export interface IFiscalYearResponse {
    id: number;
    organisation_id: null | string | number;
    name: string;
    description: string;
    is_active: boolean;
    short_name: string;
    start_date: string;
    end_date: string;
    is_current: boolean;
    created_at: string;
    updated_at: string;
}

export default function useBudgetPriceGuides() {    
    const { layoutURL } = useContext(CustomizerContext);
    const axiosPrivate = useAxiosPrivate();
    const INVENTORY = 'inventories';
    const FIXED_ASSET = 'fixed-assets';
    const [loading, setLoading] = useState(true);
    const [fiscalYear, setFiscalYear] = useState<IFiscalYearResponse | undefined>(undefined);
    
    const navigate = useNavigate();
    const { tabName } = useParams<RouteParams>();  
    const [commissionTab, setCommissionTab] = useState<string>(tabName || `${INVENTORY}`); 
    
    const tabDisplayNames: { [key: string]: string } = {
        'inventories': 'Inventory Price Guide',
        'fixed-assets': 'Fixed Asset Price Guide'
    };

    const fetchCurrentFiscalYear = () => {
        setLoading(true);
        axiosPrivate
          .get(
            `${financesFiscalYearCurrentApi}`
          )
          .then((res) => {
            const response: IFiscalYearResponse = res.data.data;
            setFiscalYear(response);
    
            setLoading(false);
          })
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              toast.error(err.response.data.detail);
            } else {
              toast.error("Whoops! Something went wrong");
            }
          })
          .finally(() => {
            setLoading(false);
          });
      };

    useLayoutEffect(() => {
        fetchCurrentFiscalYear();
    }, []);

    useLayoutEffect(() => {
        if (tabName && tabName !== commissionTab) {
            setCommissionTab(tabName);
        }
    }, [tabName]);

    const handleTabClick = (routeName: string) => {
        setCommissionTab(routeName);
        navigate(`/administration/procurement/budget-price-guides/${routeName}/${layoutURL}`);
    };

    return {
        layoutURL,
        fiscalYear,
        INVENTORY,
        FIXED_ASSET,
        commissionTab,
        tabDisplayNames,
        handleTabClick
    };
}
