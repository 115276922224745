import { Fragment, useState } from 'react';
import { AssignedTo, CaseGroup, CaseTypeName, DateCreated, DateModified, DelegateTeam, Details, DueDate, ExternalDelegateTeam, Impact, Information, Priority, Replies, Resolution, ResolutionStatus, ServiceTeam, TimeWorked, TrackingNumber, Urgency } from '../../../../../Constant';
import { H5, Btn } from '../../../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Table, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';
import DropdownCommon2 from '../../../../Common/Dropdown/index-d';
import { CaseAssignedToUri, CaseDelegateToTeamMemberUri, CaseDelegateToTeamUri, CaseExternalDelegateToTeamMemberUri, CaseExternalDelegateToTeamUri, CasePriorityUri, CaseResolutionStatusUri } from '../../../../../api';
import SweetAlert from 'sweetalert2';
import ScrollBars from 'react-custom-scrollbars-2';

const CaseMoreInfo = ({ details, priorities, resolutionStatuses, teams, teamMembers, delegateTeamMembers, externalDelegateTeams, externalDelegateTeamMembers, onCaseUpdate }) => {
    const [isFilter, setIsFilter] = useState(true);
    
    const DETAILS = 'details';
    const DELEGATION = 'delegation';
    
    const [caseTab, setCaseTab] = useState(DETAILS);

    const handleResolutionStatusUpdate = async (id) => {
        const methodName = `${CaseResolutionStatusUri}`;
        const payload = { resolution_status_id : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const handlePriorityUpdate = async (id) => {
        const methodName = `${CasePriorityUri}`;
        const payload = { priority_id : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const handleAssignedToUpdate = async (id) => {
        const methodName = `${CaseAssignedToUri}`;
        const payload = { assigned_to : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const handleDelegateTeamUpdate = async (id) => {
        const methodName = `${CaseDelegateToTeamUri}`;
        
        SweetAlert.fire({
            title: 'Reason for Delegating',
            input: 'text',
            showCancelButton: true, // Add this to show Cancel button
        }).then(async (result) => {
            if (result.value) {
                const payload = {
                    team_id: id,
                    message: result.value
                };
                await onCaseUpdate(methodName, payload);
                //SweetAlert.fire(`You typed: ${result.value}`);
            } else if (result.dismiss === "cancel") {
                SweetAlert.fire('You cancelled the delegation');
            } else {
                SweetAlert.fire('You did not enter any reason for delegation');
            }
        });    

    };

    const handleDelegateAssignedToUpdate = async (id) => {
        const methodName = `${CaseDelegateToTeamMemberUri}`;
        const payload = { assigned_to : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const handleExternalDelegateTeamUpdate = async (id) => {
        const methodName = `${CaseExternalDelegateToTeamUri}`;
        
        SweetAlert.fire({
            title: 'Reason for External Delegating',
            input: 'text',
            showCancelButton: true, // Add this to show Cancel button
        }).then(async (result) => {
            if (result.value) {
                const payload = {
                    team_id: id,
                    message: result.value
                };
                await onCaseUpdate(methodName, payload);
                //SweetAlert.fire(`You typed: ${result.value}`);
            } else if (result.dismiss === "cancel") {
                SweetAlert.fire('You cancelled the external delegation');
            } else {
                SweetAlert.fire('You did not enter any reason for external delegation');
            }
        });    

    };
    
    const handleExternalDelegateAssignedToUpdate = async (id) => {
        const methodName = `${CaseExternalDelegateToTeamMemberUri}`;
        const payload = { assigned_to : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const caseContactDelegate = details.assigned_to ? teamMembers.find(member => member.id === details.assigned_to)?.name : "Not Assigned";
    const caseTeamsForDelegate = teams;//.filter(team => team.id !== details?.case_type?.service_team_id);
    //const caseTeamsDelegatedID = details?.delegates?.find(delegate => delegate.is_active === true )?.team_id;
    const caseTeamsDelegatedID = details?.delegates?.find(delegate => delegate.team_id === details?.case_type?.delegate_team_id )?.team_id;
    const caseTeamDelegated = caseTeamsDelegatedID ? teams.find(team => team.id === caseTeamsDelegatedID)?.name : "Not Delegated";
    const caseTeamsDelegatedContactID = details?.delegates?.find(delegate => delegate.is_active === true )?.employee_id;
    const caseDelegateContact = caseTeamsDelegatedContactID ? delegateTeamMembers.find(member => member.id === caseTeamsDelegatedContactID)?.name : "Not Assigned";
    
    const caseTeamsForExternalDelegate = externalDelegateTeams;
    const caseTeamsExternalDelegatedID = details?.delegates?.find(delegate => delegate.is_active === true && delegate.is_internal_external_delegation === true )?.team_id;
    const caseTeamExternalDelegated = caseTeamsExternalDelegatedID ? externalDelegateTeams.find(team => team.id === caseTeamsExternalDelegatedID)?.name : "Not Delegated";
    const caseTeamsExternalDelegatedContactID = details?.delegates?.find(delegate => delegate.is_active === true && delegate.is_internal_external_delegation === true )?.employee_id;
    const caseExternalDelegateContact = caseTeamsExternalDelegatedContactID ? externalDelegateTeamMembers.find(member => member.id === caseTeamsExternalDelegatedContactID)?.name : "Not Assigned";

    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{
                                className: 'd-flex justify-content-between btn btn-link',
                                onClick: () => setIsFilter(!isFilter),
                                color: 'transperant'
                            }} >{Details}
                            {isFilter ? <ChevronUp className='m-0' /> : <ChevronDown className='m-0' />}
                            </Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                            <Nav className='border-tab nav-secondary nav-pills overflow-x' tabs>
                                    <NavItem>
                                        <NavLink href='#' className={caseTab === `${DETAILS}` ? 'active' : ''}
                                                    onClick={() => setCaseTab(`${DETAILS}`)}>
                                            <i className='icofont icofont-files'></i>
                                            {Information}
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink href='#' className={caseTab === `${DELEGATION}` ? 'active' : ''}
                                                    onClick={() => setCaseTab(`${DELEGATION}`)}>
                                            <i className='icofont icofont-users'></i>
                                            {`Assign`}
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={caseTab}>
                                    <TabPane className='fade show' tabId={DETAILS}>
                                        <ScrollBars className='vertical-scroll scroll-demo' style={{width: '100%',height:250}}>
                                            <div className="table-responsive">
                                                <Table>
                                                    <tbody>
                                                        <tr className=''>
                                                            <th scope='row'>{TrackingNumber}</th>
                                                            <td>{details.track_number}</td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{CaseGroup}</th>
                                                            <td>{details?.case_type?.case_group?.name}</td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{CaseTypeName}</th>
                                                            <td>{details?.case_type?.name}</td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{Impact}</th>
                                                            <td>
                                                                <span style={{color: details?.case_type?.priority?.impact?.color}} className='d-flex'>
                                                                    <span className='m-r-5'><i className="fa fa-flag"></i> {details?.case_type?.priority?.impact?.name}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{Urgency}</th>
                                                            <td>
                                                                <span style={{color: details?.case_type?.priority?.urgency?.color}} className='d-flex'>
                                                                    <span className='m-r-5'><i className="fa fa-flag"></i> {details?.case_type?.priority?.urgency?.name}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{Priority}</th>
                                                            <td>
                                                                <span style={{color: details?.priority?.color}} className='d-flex'>
                                                                <span className='m-r-5'><i className="fa fa-flag"></i> {details?.case_type?.priority?.name}</span>
                                                                {/* { details.resolution_pickup_at && !details.resolved_at && (<DropdownCommon2 
                                                                    dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                                    options={priorities} 
                                                                    iconName='icon-angle-down' 
                                                                    btn={{ tag: 'span' }}
                                                                    onClick={handlePriorityUpdate} />)} */}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{ResolutionStatus}</th>
                                                            <td>
                                                                <span style={{color: details?.resolution_status?.color}} className='d-flex'>
                                                                    <span className='m-r-5'><i className="fa fa-bell"></i> {details?.resolution_status?.name}</span>
                                                                    { details.resolution_pickup_at && !details.resolved_at &&
                                                                    <DropdownCommon2 
                                                                        dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                                        options={resolutionStatuses} 
                                                                        iconName='icon-angle-down' 
                                                                        btn={{ tag: 'span' }} 
                                                                        onClick={handleResolutionStatusUpdate} />}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    <tr className=''>
                                                            <th scope='row'>{DateCreated}</th>
                                                            <td>{formatDateWithTimeZone(details.created_at)}</td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{DateModified}</th>
                                                            <td>{formatDateWithTimeZone(details.updated_at)}</td>
                                                        </tr>
                                                        {/*<tr className=''>
                                                            <th scope='row'>{Replies}</th>
                                                            <td>{details.replies_count}</td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{LastReplier}</th>
                                                            <td>Group</td>
                                                        </tr> 
                                                        <tr className=''>
                                                            <th scope='row'>{TimeWorked}</th>
                                                            <td>{details.time_worked}</td>
                                                        </tr>*/}
                                                        <tr className=''>
                                                            <th scope='row'>{DueDate}</th>
                                                            <td>{details.due_date ? formatDateWithTimeZone(details.due_date) : ""}</td>
                                                        </tr>
                                                        
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </ScrollBars>
                                    </TabPane>

                                    <TabPane className='fade show' tabId={DELEGATION}>
                                        <ScrollBars className='vertical-scroll scroll-demo' style={{width: '100%',height:250}}>
                                            <Table>
                                                <tbody>
                                                <tr className=''>
                                                            <th scope='row'>{ServiceTeam}</th>
                                                            <td>
                                                                <span className='d-flex'>
                                                                    <span className='m-r-5'><i className="fa fa-users"></i> {details?.case_type?.service_team?.name}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className=''>
                                                            <th scope='row'>{AssignedTo}</th>
                                                            <td>
                                                                <span className='d-flex'>
                                                                <span className='m-r-5'><i className="fa fa-user"></i> {caseContactDelegate}</span>
                                                                { !(details?.resolved_at || caseTeamsDelegatedID) && (
                                                                <DropdownCommon2 
                                                                    dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                                    options={teamMembers} 
                                                                    iconName='icon-angle-down' 
                                                                    btn={{ tag: 'span' }}
                                                                    onClick={handleAssignedToUpdate} />
                                                                )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        { 
                                                            details.resolution_pickup_at && (
                                                                <>
                                                                    <tr className=''>
                                                                        <th scope='row'>{DelegateTeam}</th>
                                                                        <td>
                                                                            <span className='d-flex'>
                                                                                <span className='m-r-5'><i className="fa fa-users"></i> {caseTeamDelegated}</span>
                                                                                { !details.resolved_at && !details.delegation_delegation_at && (<DropdownCommon2 
                                                                                    dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                                                    options={caseTeamsForDelegate} 
                                                                                    iconName='icon-angle-down' 
                                                                                    btn={{ tag: 'span' }}
                                                                                    onClick={handleDelegateTeamUpdate} />)}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{AssignedTo}</th>
                                                                        <td>
                                                                            <span className='d-flex'>
                                                                            <span className='m-r-5'><i className="fa fa-user"></i> {caseDelegateContact}</span>
                                                                            { !details.resolved_at && !details.delegation_delegation_at && (<DropdownCommon2 
                                                                                dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                                                options={delegateTeamMembers} 
                                                                                iconName='icon-angle-down' 
                                                                                btn={{ tag: 'span' }}
                                                                                onClick={handleDelegateAssignedToUpdate} />)}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }

                                                        { 
                                                            details.delegation_pickup_at && (
                                                                <>
                                                                    <tr className=''>
                                                                        <th scope='row'>{ExternalDelegateTeam}</th>
                                                                        <td>
                                                                            <span className='d-flex'>
                                                                                <span className='m-r-5'><i className="fa fa-users"></i> {caseTeamExternalDelegated}</span>
                                                                                { !details.resolved_at && (<DropdownCommon2 
                                                                                    dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                                                    options={caseTeamsForExternalDelegate} 
                                                                                    iconName='icon-angle-down' 
                                                                                    btn={{ tag: 'span' }}
                                                                                    onClick={handleExternalDelegateTeamUpdate} />)}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className=''>
                                                                        <th scope='row'>{AssignedTo}</th>
                                                                        <td>
                                                                            <span className='d-flex'>
                                                                            <span className='m-r-5'><i className="fa fa-user"></i> {caseExternalDelegateContact}</span>
                                                                            { !details.resolved_at && (<DropdownCommon2 
                                                                                dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                                                options={externalDelegateTeamMembers} 
                                                                                iconName='icon-angle-down' 
                                                                                btn={{ tag: 'span' }}
                                                                                onClick={handleExternalDelegateAssignedToUpdate} />)}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }
                                                </tbody>
                                            </Table>
                                        </ScrollBars>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default CaseMoreInfo;