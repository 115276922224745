import { useContext, useEffect, useMemo, useState } from "react";
import { AutoAssignToTeamMember, CaseGroup, CaseTypeName, DateCreated, DateModified, DelegateTeam, DelegationSLA, DelegationTime, DeleteSuccess, Description, Edit, EscalationExecutiveTeam, EscalationExecutiveTime, EscalationFunctionalTime, EscalationSLA, EscalationTeam, GlobalResolutionTime, Name, PickUpTime, Priority, ResolutionSLA, ResolutionTime, ServiceCategory, ServiceTeam, Status, Team, Timeline, View } from "../../../../../Constant";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import CustomizerContext from "../../../../../_helper/Customizer";
import { base_form_fields, createDropdownField } from "../../../../../Data/FormField/PublicData";
import { CaseTypeFormDataPayload, CaseTypeRes, MiniResType, PriorityResType, TeamMappingData } from "../CaseType.type";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { CaseGroupApi, CaseTeamMappingApi, CaseTypeApi, PriorityApi, ServiceTypeApi, SlaApi, TeamApi } from "../../../../../api";
import { toast } from "react-toastify";
import { member_auto_assign_field } from "../../../../../Data/FormField/CrmData";
import { InputProviderFormSchema } from "../../../../Finance/Template/types";
import { validateInputs } from "../../../../Finance/Template/InputProvider";
import { PriorityRow, StatusRow } from "./CaseTypeList";
import { convertDuration, convertToDuration, formatToDuration } from "../../../../../_helper/utils";

export default function useCaseTypeListController() {
    const axiosPrivate = useAxiosPrivate();
    const title = CaseTypeName;
    const { userOrganisationId }: any = useAuth();
    const [allData, setAllData] = useState<CaseTypeRes[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [tabModalOpen, setTabModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof allData)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);

    const tabResolution: string = "resolution";
    const tabDelegation: string = "delagation";
    const tabXDelegation: string = "x-delagation";

    const [prioritiesDetails, setPrioritiesDetails] = useState<PriorityResType[]>([]); 
    const [activeTab, setActiveTab] = useState<string>(tabResolution); 
    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    const [switchLabels, setSwitchLabels] = useState([
        { id: 1, label: Status, value: false },
        { id: 2, label: AutoAssignToTeamMember, value: false },
    ]);

    const [inputErrors, setInputErrors] = useState<{
        caseFormErrors: { [key: string]: string };
        resolutionTeamFormErrors: { [key: string]: string };
        delegationTeamFormErrors: { [key: string]: string };
        externalDelegationTeamErrors: { [key: string]: string };
      }>({
        caseFormErrors: {},
        resolutionTeamFormErrors: {},
        delegationTeamFormErrors: {},
        externalDelegationTeamErrors: {},
    });

    //=======================================================================
    // ==== FORM SCHEMA'S ==== //
    const [caseFormSchema, setCaseFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "service_type_id",
            label: ServiceCategory,
            type: "select",
            required: true,
        },
        {
            name: "case_group_id",
            label: CaseGroup,
            type: "select",
            required: true,
        },
        {
            name: "name",
            label: Name,
            type: "text",
            required: true,
        },
        {
            name: "description",
            label: Description,
            type: "textarea",
            required: true,
        },
        {
            name: "priority_id",
            label: Priority,
            type: "select",
            required: true,
        },
        {
            name: "global_resolution_time",
            label: GlobalResolutionTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
    ]);

    const [resolutionTeamFormSchema, setResolutionTeamFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "service_team_id",
            label: ServiceTeam,
            type: "select",
            required: true,
        },
        {
            name: "resolution_escalation_team_id",
            label: EscalationTeam,
            type: "select",
            required: true,
        },
        {
            name: "resolution_escalation_executive_team_id",
            label: EscalationExecutiveTeam,
            type: "select",
            required: true,
        },
        {
            name: "resolution_pickup_time",
            label: PickUpTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "resolution_delegation_time",
            label: DelegationTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "resolution_escalation_time1",
            label: EscalationFunctionalTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "resolution_escalation_time2",
            label: EscalationExecutiveTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "resolution_time",
            label: ResolutionTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
    ]);

    const [delegationTeamFormSchema, setDelegationTeamFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        {
            name: "delegate_team_id",
            label: DelegateTeam,
            type: "select",
            required: true,
        },
        {
            name: "delegation_escalation_team_id",
            label: EscalationTeam,
            type: "select",
            required: true,
        },
        {
            name: "delegation_escalation_executive_team_id",
            label: EscalationExecutiveTeam,
            type: "select",
            required: true,
        },
        {
            name: "delegation_pickup_time",
            label: PickUpTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "delegation_delegation_time",
            label: DelegationTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "delegation_escalation_time1",
            label: EscalationFunctionalTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "delegation_escalation_time2",
            label: EscalationExecutiveTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "delegation_resolution_time",
            label: ResolutionTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
    ]);
    //, setExternalDelegationTeamFormSchema
    const [externalDelegationTeamFormSchema] = useState<
        InputProviderFormSchema[]
    >([
        /* {
            name: "external_delegation_escalation_team_id",
            label: EscalationTeam,
            type: "select",
            required: false,
        },
        {
            name: "external_delegation_escalation_executive_team_id",
            label: EscalationExecutiveTeam,
            type: "select",
            required: false,
        }, */
        {
            name: "external_delegation_pickup_time",
            label: PickUpTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "external_delegation_escalation_time1",
            label: EscalationFunctionalTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "external_delegation_escalation_time2",
            label: EscalationExecutiveTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
        {
            name: "external_delegation_resolution_time",
            label: ResolutionTime,
            type: "duration",
            required: true,
            placeholder: "e.g., 2h 30m",
        },
    ]);

    // ==== INPUT DATA'S ==== //
    const [caseInputData, setCaseInputData] = useState<{
        [key: string]: any;
    }>({});

    const [resolutionTeamInputData, setResolutionTeamInputData] = useState<{
        [key: string]: any;
    }>({});

    const [delegationTeamInputData, setDelegationTeamInputData] = useState<{
        [key: string]: any;
    }>({});

    const [externalDelegationTeamInputData, setExternalDelegationTeamInputData] = useState<{
        [key: string]: any;
    }>({});

    // ==== ON CHANGE HANDLER'S ==== //
    const handleCaseChange = (name: string, value: any) => {
        setCaseInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        name === "priority_id" && getImpactUrgency(value);
        name === "service_type_id" && fetchCaseGroup(value);
    };

    const handleResolutionTeamChange = (name: string, value: any) => {
        setResolutionTeamInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDelegationTeamChange = (name: string, value: any) => {
        setDelegationTeamInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleExternalDelegationTeamChange = (name: string, value: any) => {
        setExternalDelegationTeamInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const fetchCaseGroup = async (value: string) => {
        const api = `${CaseGroupApi}?with_trashed=${false}&organisation_id=${userOrganisationId}&service_type_id=${value}`;

        try {
            setCaseFormSchema((prevSchema) => {
                return prevSchema.map((field) => {
                    if (field.name === "case_group_id") {
                        return {
                            ...field,
                            options: value ? [] : [], // Clear existing options if value is invalid
                        };
                    }
                    return field;
                });
            });
    
            if (!value) {
                return; 
            }

            axiosPrivate.get(api).then((res) => {
                const response: any[] = res.data;
                //console.log(response);
                setCaseFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "case_group_id") {
                            return {
                                ...field,
                                options: response.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }
                        return field;
                    })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                  toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                  toast.error(err.response.data.detail);
                } else {
                  toast.error("Whoops! cannot fetch case group");
                }
                //toast.error(err.response.message || "Whoops! Something went wrong");
              });
            //return;
        } 
        catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const getImpactUrgency = async (value: string) => {
        setCaseFormSchema((prevSchema) => {
            if (value <= "0") {
                return prevSchema.filter(field => field.name !== "impact_urgency_info");
            } 
    
            const priorityId = parseInt(value);
            const priorityDetails = prioritiesDetails.find(priority => priority.id === priorityId);
    
            if (!priorityDetails) {
                return prevSchema;
            }
    
            const newElement = {
                name: "impact_urgency_info",
                label: "",
                type: "html",
                html: `
                  <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 5px;">
                    <h7 style="font-weight:600; margin-bottom:10px">Impact & Urgency Details</h7>
                    <div>
                      <h7 style="font-weight:600;">Impact</h7>
                      <p>
                        Name: <span style="color: ${priorityDetails.impact?.color}" class="">
                            <span className='m-r-5'><i className="fa fa-flag"></i> ${priorityDetails.impact.name}</span>
                        </span><br>
                        Weight: ${priorityDetails.impact.weight}
                      </p>
                    </div>
                    <div>
                      <h7 style="font-weight:600;">Urgency</h7>
                      <p>
                        Name: <span style="color: ${priorityDetails.urgency?.color}" class="">
                            <span className='m-r-5'><i className="fa fa-flag"></i> ${priorityDetails.urgency.name}</span>
                        </span><br>
                        Weight: ${priorityDetails.urgency.weight}
                      </p>
                    </div>
                  </div>
                `,
            };
    
            const existingIndex = prevSchema.findIndex(field => field.name === "impact_urgency_info");
            if (existingIndex > -1) {
                const updatedSchema = [...prevSchema];
                updatedSchema[existingIndex] = newElement;
                return updatedSchema;
            } else {
                return [...prevSchema, newElement];
            }
        });
    };
    
    const fetchCaseTypeData = async () => {
        try {
            const serviceCategoriesUrl = `${ServiceTypeApi}`;
            const prioritiesUrl = `${PriorityApi}?with_trashed=false&organisation_id=${userOrganisationId}`;
            const teamUrl = `${TeamApi}?with_trashed=false&organisation_id=${userOrganisationId}`;
            const caseTeamMappingUrl = `${CaseTeamMappingApi}`;

            // Use Promise.all to fetch both APIs concurrently
            /* const [
                serviceCategoriesRes,
                prioritiesRes,
                teamRes,
                caseTeamMappingRes,
            ] = await Promise.all([
                axiosPrivate.get(serviceCategoriesUrl),
                axiosPrivate.get(prioritiesUrl),
                axiosPrivate.get(teamUrl),
                axiosPrivate.get(caseTeamMappingUrl),
            ]);

            const serviceCategoriesData: MiniResType[] =
                await serviceCategoriesRes.data;
            const prioritiesData: PriorityResType[] =
                await prioritiesRes.data;
            const teamData: MiniResType[] =
                await teamRes.data;
                const caseTeamMappingData: TeamMappingData[] = await caseTeamMappingRes.data.data.settings;
 */
            const results = await Promise.allSettled([
                axiosPrivate.get(serviceCategoriesUrl),
                axiosPrivate.get(prioritiesUrl),
                axiosPrivate.get(teamUrl),
                axiosPrivate.get(caseTeamMappingUrl),
            ]);
            
            const serviceCategoriesData: MiniResType[] = results[0].status === 'fulfilled' ? results[0].value.data : null;
            const prioritiesData: PriorityResType[] = results[1].status === 'fulfilled' ? results[1].value.data : null;
            const teamData: MiniResType[] = results[2].status === 'fulfilled' ? results[2].value.data : null;
            const caseTeamMappingData: TeamMappingData[] = results[3].status === 'fulfilled' ? results[3].value.data.data.settings : null;
    
            setPrioritiesDetails(prioritiesData);

            // Return or use the combined data
            return {
                serviceCategoriesData,
                prioritiesData,
                teamData,
                caseTeamMappingData,
            };
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchCaseTypeData().then((data) => {
            if(data) {
                const resolution_team_rec = data.caseTeamMappingData?.find(item => item.key === "resolution_team")
                const delegation_team_rec = data.caseTeamMappingData?.find(item => item.key === "delegation_team")
                const escalation_functional_team_rec = data.caseTeamMappingData?.find(item => item.key === "escalation_functional_team")
                const escalation_executive_team_rec = data.caseTeamMappingData?.find(item => item.key === "escalation_executive_team")
                
                const serviceTeam = data.teamData?.filter(item => String(item.team_type_id) === resolution_team_rec?.value)
                const delgationTeam = data.teamData?.filter(item => String(item.team_type_id) === delegation_team_rec?.value)
                const escalationFunctionalTeam = data.teamData?.filter(item => String(item.team_type_id) === escalation_functional_team_rec?.value)
                const escalationExecutiveTeam = data.teamData?.filter(item => String(item.team_type_id) === escalation_executive_team_rec?.value)
                
                setCaseFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "service_type_id") {
                            return {
                                ...field,
                                options: data.serviceCategoriesData?.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "priority_id") {
                            return {
                                ...field,
                                options: data.prioritiesData?.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        return field; 
                    })
                ); 

                setResolutionTeamFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "service_team_id") {
                            return {
                                ...field,
                                options: serviceTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "resolution_escalation_team_id") {
                            return {
                                ...field,
                                options: escalationFunctionalTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "resolution_escalation_executive_team_id") {
                            return {
                                ...field,
                                options: escalationExecutiveTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        return field; 
                    })
                );

                setDelegationTeamFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if (field.name === "delegate_team_id") {
                            return {
                                ...field,
                                options: delgationTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "delegation_escalation_team_id") {
                            return {
                                ...field,
                                options: escalationFunctionalTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        if (field.name === "delegation_escalation_executive_team_id") {
                            return {
                                ...field,
                                options: escalationExecutiveTeam.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        return field; 
                    })
                );

                /* setExternalDelegationTeamFormSchema((prevSchema) =>
                    prevSchema.map((field) => {
                        if ((field.name === "external_delegation_escalation_team_id") || (field.name === "external_delegation_escalation_executive_team_id")) {
                            return {
                                ...field,
                                options: data.teamData.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })),
                            };
                        }

                        return field; 
                    })
                ); */
            }
        });
    }, []);
    //=======================================================================
    const [formFields, setFormFields] = useState<any[]>(base_form_fields(`${CaseTypeName}`, 6));

    const { layoutURL } = useContext(CustomizerContext);
    const addBtn = `${process.env.PUBLIC_URL}/crm/settings/case-type/create/${layoutURL}`;

    const serviceTypedropdown = createDropdownField(axiosPrivate,
        'service_type_id',
        'service_type_id',
        `${ServiceCategory}`,
        `${ServiceTypeApi}`,
        '', 6
    );
    
    const caseGroupDropdown = createDropdownField(axiosPrivate,
        'case_group_id',
        'case_group_id',
        `${CaseGroup}`,
        `${CaseGroupApi}?organisation_id=${userOrganisationId}`,
        '', 6
    );

    const PriorityCategorydropdown = createDropdownField(axiosPrivate,
        'priority_id',
        'priority_id',
        `${Priority}`,
        `${PriorityApi}?organisation_id=${userOrganisationId}`,
        '', 6
    );
      
    const ResolutionSLAdropdown = createDropdownField(axiosPrivate,
        'sla_id',
        'sla_id',
        `${ResolutionSLA}`,
        ``,
        '', 6
    );
    
    const DelegationSLAdropdown = createDropdownField(axiosPrivate,
        'delegation_sla_id',
        'delegation_sla_id',
        `${DelegationSLA}`,
        ``,
        '', 6
    );
    
    const EscalationSLAdropdown = createDropdownField(axiosPrivate,
        'escalation_sla_id',
        'escalation_sla_id',
        `${EscalationSLA}`,
        ``,
        '', 6
    );
    
    const ServiceTeamDropDown = createDropdownField(axiosPrivate,
        'service_team_id',
        'service_team_id',
        `${ServiceTeam}`,
        ``,
        '', 6
    );
      
    const EscalationTeamDropDown = createDropdownField(axiosPrivate,
        'escalation_team_id',
        'escalation_team_id',
        `${EscalationTeam}`,
        ``,
        '', 6
    );
    
    const EscalationExecutiveTeamDropDown = createDropdownField(axiosPrivate,
        'escalation_executive_team_id',
        'escalation_executive_team_id',
        `${EscalationExecutiveTeam}`,
        ``,
        '', 6
    );

    useEffect(() => {

        const fetchData = async () => {
          try {
            if (!isNewFieldAdded) {
    
              const updatedFields = [serviceTypedropdown, caseGroupDropdown, ...formFields];
              const indexOfName = updatedFields.findIndex((formField) => formField.id === 'description');
              updatedFields.splice(indexOfName + 1, 0, PriorityCategorydropdown, 
                { type: 'group-title', label: `${Team}`},
                ServiceTeamDropDown, EscalationTeamDropDown, EscalationExecutiveTeamDropDown,
                { type: 'group-title', label: `${Timeline}`},
                ResolutionSLAdropdown, DelegationSLAdropdown, EscalationSLAdropdown, 
                member_auto_assign_field(0,0,6));
    
              await updatedFields[0].fetchOptions();
              await PriorityCategorydropdown.fetchOptions();
    
              const indexOfServiceTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'service_team_id');
              const indexOfEscalationTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_team_id');
              const indexOfEscalationExecutiveTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_executive_team_id');
    
              try {
                await axiosPrivate.get(`${TeamApi}?organisation_id=${userOrganisationId}`).then((resp) => {
                  resp.data.forEach((option: { id: any; name: any; }) => {
                    updatedFields[indexOfServiceTeam].options.push({ value: option.id, label: option.name });
                    updatedFields[indexOfEscalationTeam].options.push({ value: option.id, label: option.name });
                    updatedFields[indexOfEscalationExecutiveTeam].options.push({ value: option.id, label: option.name });
                  });
                });
              } catch (error) { }
    
              const indexOfResolutionSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'sla_id');
              const indexOfDelegationSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'delegation_sla_id');
              const indexOfEscalationSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_sla_id');
              
              try {
                await axiosPrivate.get(`${SlaApi}?organisation_id=${userOrganisationId}`).then((resp) => {
                  resp.data.forEach((option: { id: any; name: any; }) => {
                    updatedFields[indexOfResolutionSLA].options.push({ value: option.id, label: option.name });
                    updatedFields[indexOfDelegationSLA].options.push({ value: option.id, label: option.name });
                    updatedFields[indexOfEscalationSLA].options.push({ value: option.id, label: option.name });
                  });
                });
              } catch (error) { }
    
              setFormFields(updatedFields);
              setIsNewFieldAdded(true);
    
            }
          } catch (error) {
            //console.error('Error fetching options:', error);
          }
        };
    
        fetchData();
    }, [formFields, isNewFieldAdded, caseGroupDropdown]);
    
    const tableColumns = [
        {
          name: `${CaseTypeName}`,
          selector: (row: (typeof allData)[0]) => `${row.name}`,
          sortable: true,
          center: false,
          wrap: true,
          reorder: true,
        },
        {
          name: `${CaseGroup}`,
          selector: (row: (typeof allData)[0]) => `${row.case_group.name}`,
          sortable: true,
          center: false,
          reorder: true,
          wrap: true
        },
        
        /* {
          name: `${Description}`,
          selector: (row: (typeof allData)[0]) => `${row.description}`,
          sortable: true,
          center: false,
        }, */
        {
            name: `${Priority}`,
            selector: (row: (typeof allData)[0]) => row.priority.name,
            sortable: true,
            center: false,
            reorder: true,
            cell: (row: (typeof allData)[0]) => PriorityRow(row),
        },
        {
          name: `${GlobalResolutionTime}`,
          selector: (row: (typeof allData)[0]) => `${formatToDuration(row.global_resolution_time)}`,
          sortable: true,
          reorder: true,
          center: false,
          grow: 2,
        },
        /* {
          name: `${EscalationTeam}`,
          selector: (row: (typeof allData)[0]) => `${row.EscalationTeam}`,
          sortable: true,
          center: false,
        },
        {
          name: `${ServiceTeam}`,
          selector: (row: (typeof allData)[0]) => `${row.ServiceTeam}`,
          sortable: true,
          center: false,
        }, */
        {
          name: `${Status}`,
          selector: (row: (typeof allData)[0]) => row.is_active,
          sortable: true,
          center: false,
          reorder: true,
          cell: (row: (typeof allData)[0]) => StatusRow(row)
        },
        {
          name: `${DateCreated}`,
          selector: (row: (typeof allData)[0]) => `${formatDateWithTimeZone(row.created_at)}`,
          sortable: true,
          center: false,
          wrap: true
        },
        {
          name: `${DateModified}`,
          selector: (row: (typeof allData)[0]) => `${formatDateWithTimeZone(row.updated_at)}`,
          sortable: true,
          center: false,
          wrap: true
        },
    ];
    
    const getAllCaseTypesData = async (page = 1) => {
        
          setIsLoading(true);
          await axiosPrivate.get(`${CaseTypeApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}&only_active=false`)
            .then((resp) => {
                setAllData(resp.data.items);
                setTotalRows(resp.data.total);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const abortController = new AbortController();
        getAllCaseTypesData();
    
        return () => {
          abortController.abort();
        };
    }, [setAllData]);

    const handleView = (row: (typeof allData)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const OpenTabModal = async (row: (typeof allData)[0]) => {
        setTabModalOpen(true);
        setEditMode(false);
        
        const caseEditData = {
            service_type_id: row.case_group.service_type_id,
            case_group_id: row.case_group.id,
            priority_id: row.priority.id,
            name: row.name,
            description: row.description,
            global_resolution_time: convertToDuration(row.global_resolution_time),
        };

        if ((row.case_group.service_type_id ?? 0) > 0) {
            fetchCaseGroup(String(row.case_group.service_type_id));
        }

        if ((row.priority.id ?? 0) > 0) {
            getImpactUrgency(String(row.priority.id));
        }

        const resolutionTeamEditData = {
            service_team_id: row.service_team_id,
            resolution_escalation_team_id: row.escalation_team_id,
            resolution_escalation_executive_team_id: row.escalation_executive_team_id,
            resolution_pickup_time: convertToDuration(row.resolution_pickup_time),
            resolution_delegation_time: convertToDuration(row.resolution_delegation_time),
            resolution_escalation_time1: convertToDuration(row.resolution_escalation_time1),
            resolution_escalation_time2: convertToDuration(row.resolution_escalation_time2),
            resolution_time: convertToDuration(row.resolution_time),
        }

        const delegationTeamEditData = {
            delegate_team_id: row.delegate_team_id,
            delegation_escalation_team_id: row.delegation_escalation_team_id,
            delegation_escalation_executive_team_id: row.delegation_escalation_executive_team_id,
            delegation_pickup_time: convertToDuration(row.delegation_pickup_time),
            delegation_delegation_time: convertToDuration(row.delegation_delegation_time),
            delegation_escalation_time1: convertToDuration(row.delegation_escalation_time1),
            delegation_escalation_time2: convertToDuration(row.delegation_escalation_time2),
            delegation_resolution_time: convertToDuration(row.delegation_resolution_time),
        }

        const externalDelegationTeamEditData = {
            //external_delegation_escalation_team_id: row.external_delegation_escalation_team_id,
            //external_delegation_escalation_executive_team_id: row.external_delegation_escalation_executive_team_id,
            external_delegation_pickup_time: convertToDuration(row.external_delegation_pickup_time),
            external_delegation_escalation_time1: convertToDuration(row.external_delegation_escalation_time1),
            external_delegation_escalation_time2: convertToDuration(row.external_delegation_escalation_time2),
            external_delegation_resolution_time: convertToDuration(row.external_delegation_resolution_time),
        }

        const switchEditData = [
            { id: 1, label: Status, value: row.is_active },
            { id: 2, label: AutoAssignToTeamMember, value: row.auto_assign },
        ]
        
        setCaseInputData(caseEditData);
        setResolutionTeamInputData(resolutionTeamEditData)
        setDelegationTeamInputData(delegationTeamEditData)
        setExternalDelegationTeamInputData(externalDelegationTeamEditData)
        setSwitchLabels(switchEditData)
        
        setSelectedRowData(row);
    }

    /* const handleTabCloseModal = () => {
        setTabModalOpen(false);
    }; */

    const handlePageChange = (page: number) => {
        getAllCaseTypesData(page)
    };
    
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setIsLoading(true);
        const response = await axiosPrivate.get(`${CaseTypeApi}?organisation_id=${userOrganisationId}&page=${page}&size=${newPerPage}&only_active=false`);
        setAllData(response.data.items);
        setPerPage(newPerPage);
        setIsLoading(false);
    };

    const handleCloseModal = () => {
        setViewModalOpen(false);
    };

    const handleDelete = async (row: (typeof allData)[0]) => {
        // Prompt for confirmation
        const shouldDelete = window.confirm('Are you sure you want to delete this record?');

        if (!shouldDelete) {
            return; // Do nothing if the user cancels the deletion
        }

        try {
            const response = await axiosPrivate.delete(`${CaseTypeApi}/${row.id}`);

            if (response.status === 204) {
                setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
                toast.success(DeleteSuccess);
            } else {
                //toast.error('Delete request failed:', response.statusText);
                toast.error('Delete request failed:');
            }
        } catch (error) {
            console.error('Error deleting record:', error);
        }
    };

    const handleEdit = (row: (typeof allData)[0] | any) => {
        // Handle the Edit action
        setViewModalOpen(true);
        setEditMode(true);
        row.service_type_id = row.case_group.service_type_id
        
        serviceTypedropdown.onChange(row.service_type_id, formFields);
        setSelectedRowData(row);
    };
    
    const convertToMinutes = (hours: number | undefined): number => {
        // If hours is empty or not a number, return 0
        if (hours === undefined || hours === null || isNaN(hours)) {
          return 0;
        }
        return hours * 60;
    };

    const calculateTeamTime = () => {
        const resolutionTotalTime =
            convertDuration(resolutionTeamInputData.resolution_pickup_time) +
            convertDuration(resolutionTeamInputData.resolution_time);

        const delegationTotalTime =
            convertDuration(delegationTeamInputData.delegation_pickup_time) +
            convertDuration(delegationTeamInputData.delegation_resolution_time);

        const consultingTotalTime =
            convertDuration(externalDelegationTeamInputData.external_delegation_pickup_time) +
            convertDuration(externalDelegationTeamInputData.external_delegation_resolution_time);

        // Sum total time for all teams, excluding delegation times
        const totalTeamTime = 
            resolutionTotalTime +
            delegationTotalTime +
            consultingTotalTime;

        return {
            resolutionTotalTime,
            delegationTotalTime,
            consultingTotalTime,
            totalTeamTime
        }    
    }

    /* const handleEditRec = async (
        id: number | string
       // editRecord: (typeof allData)[0] | any
    ) => { */
    const handleEditRec = async (
        event: React.FormEvent<HTMLFormElement>
    ) => { 
        event.preventDefault(); 
            
        const id = selectedRowData!.id; 
        setInputErrors({
            caseFormErrors: {},
            resolutionTeamFormErrors: {},
            delegationTeamFormErrors: {},
            externalDelegationTeamErrors: {},
        }); 
           
        const caseValidation = validateInputs(caseFormSchema, caseInputData);
        const resolutionValidation = validateInputs(resolutionTeamFormSchema, resolutionTeamInputData);
        const delegationValidation = validateInputs(delegationTeamFormSchema, delegationTeamInputData);
        const externalDelegationValidation = validateInputs(externalDelegationTeamFormSchema, externalDelegationTeamInputData);

        if (!caseValidation.isValid) {
            setInputErrors(prev => ({ ...prev, caseFormErrors: caseValidation.errors }));
            return;
        }

        if (!resolutionValidation.isValid) {
            setInputErrors(prev => ({ ...prev, resolutionTeamFormErrors: resolutionValidation.errors }));
            setActiveTab(tabResolution)
            return;
        }

        if (!delegationValidation.isValid) {
            setInputErrors(prev => ({ ...prev, delegationTeamFormErrors: delegationValidation.errors }));
            setActiveTab(tabDelegation)
            return;
        }

        if (!externalDelegationValidation.isValid) {
            setInputErrors(prev => ({ ...prev, externalDelegationTeamErrors: externalDelegationValidation.errors }));
            setActiveTab(tabXDelegation)
            return;
        }

        const {
            resolutionTotalTime,
            delegationTotalTime,
            consultingTotalTime,
            totalTeamTime
        } = calculateTeamTime();

        const global_resolution_time = convertDuration(caseInputData.global_resolution_time);

        if(global_resolution_time <= 0) {
            toast.error("Global Resolution Time is not defined");
            return false;
        }

        if(resolutionTotalTime <= 0) {
            toast.error("Resolution Team time is not defined");
            return false;
        }

        if (resolutionTotalTime > global_resolution_time) {
            toast.error("Resolution Team allocation time exceeds the Global Resolution Time");
            return false;
        }

        if(delegationTotalTime <= 0) {
            toast.error("Delegation Team time is not defined");
            return false;
        }

        if (delegationTotalTime > global_resolution_time) {
            toast.error("Delegation Team allocation time exceeds the Global Resolution Time");
            return false;
        }

        if ((consultingTotalTime > 0) && (consultingTotalTime > global_resolution_time)) {
            toast.error("External Delegation Team allocation time exceeds the Global Resolution Time");
            return false;
        }

        // Check against Global Resolution Time
        if (totalTeamTime > global_resolution_time) {
            toast.error("Total team allocation time exceeds the Global Resolution Time");
            return;
        }

        const payload: CaseTypeFormDataPayload = {
            organisation_id: userOrganisationId,
            case_group_id: caseInputData.case_group_id,
            priority_id: caseInputData.priority_id,
            name: caseInputData.name,
            description: caseInputData.description,
            global_resolution_time: global_resolution_time,

            service_team_id: resolutionTeamInputData.service_team_id,
            escalation_team_id: resolutionTeamInputData.resolution_escalation_team_id,
            escalation_executive_team_id: resolutionTeamInputData.resolution_escalation_executive_team_id,
            resolution_pickup_time: convertDuration(resolutionTeamInputData.resolution_pickup_time),
            resolution_delegation_time: convertDuration(resolutionTeamInputData.resolution_delegation_time),
            resolution_escalation_time1: convertDuration(resolutionTeamInputData.resolution_escalation_time1),
            resolution_escalation_time2: convertDuration(resolutionTeamInputData.resolution_escalation_time2),
            resolution_time: convertDuration(resolutionTeamInputData.resolution_time),

            delegate_team_id: delegationTeamInputData.delegate_team_id,
            delegation_escalation_team_id: delegationTeamInputData.delegation_escalation_team_id,
            delegation_escalation_executive_team_id: delegationTeamInputData.delegation_escalation_executive_team_id,
            delegation_pickup_time: convertDuration(delegationTeamInputData.delegation_pickup_time),
            delegation_delegation_time: convertDuration(delegationTeamInputData.delegation_delegation_time),
            delegation_escalation_time1: convertDuration(delegationTeamInputData.delegation_escalation_time1),
            delegation_escalation_time2: convertDuration(delegationTeamInputData.delegation_escalation_time2),
            delegation_resolution_time: convertDuration(delegationTeamInputData.delegation_resolution_time),

            //external_delegation_escalation_team_id: externalDelegationTeamInputData.external_delegation_escalation_team_id || null,
            //external_delegation_escalation_executive_team_id: externalDelegationTeamInputData.external_delegation_escalation_executive_team_id || null,
            external_delegation_pickup_time: convertDuration(externalDelegationTeamInputData.external_delegation_pickup_time) || 0,
            external_delegation_escalation_time1: convertDuration(externalDelegationTeamInputData.external_delegation_escalation_time1) || 0,
            external_delegation_escalation_time2: convertDuration(externalDelegationTeamInputData.external_delegation_escalation_time2) || 0,
            external_delegation_resolution_time: convertDuration(externalDelegationTeamInputData.external_delegation_resolution_time) || 0,

            is_active: switchLabels[0].value,
            auto_assign: switchLabels[1].value,
        }
    
        await axiosPrivate.put(`${CaseTypeApi}/${id}`, payload).then((resp) => {
            const updatedData = allData.map((row) =>
                row.id === selectedRowData!.id ? { ...row, ...resp.data } : row
            );

            setAllData(updatedData);

            toast.success(`${resp.data.name} is successfully updated.`)
            handleCloseModal()
        })
        .catch((err) => {
            if (err.response && err.response.status === 422) {
              toast.error("Validation Errors");
            } else if (err.response && err.response.status === 400) {
              toast.error(err.response.data.detail);
            } else {
              toast.error("Whoops! cannot fetch case group");
            }
            //toast.error(err.response.message || "Whoops! Something went wrong");
          });
       
    };  

    /* const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [allData, selectedRowData]
    );  */

    const memoizedHandleEdit = useMemo(() => {
        return async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault(); 
            
            //const id = selectedRowData!.id; 
            //const editRecord = selectedRowData; 

            //await handleEditRec(id); 
        };
    }, [selectedRowData]);

    const useTableAction = [
        {
          label: `${View}`,
          action: handleView,
          color: 'success',
          icon: 'icon-eye',
        },
        {
          label: `${Edit}`,
          action: OpenTabModal,
          color: 'warning',
          icon: 'icon-pencil-alt',
        },
    ];

    return{
        title,
        addBtn,
        formFields,
        isLoading,
        viewModalOpen,
        selectedRowData,
        editMode,
        totalRows,

        tabResolution,
        tabDelegation,
        tabXDelegation,
        activeTab,
        handleTabClick,

        tabModalOpen,
        setTabModalOpen,
        useTableAction,

        allData,
        tableColumns,
        setViewModalOpen,
        handleView,
        handleDelete,
        handleEdit,
        handlePerRowsChange,
        handlePageChange,
        memoizedHandleEdit,
        handleEditRec,

        inputErrors,

        caseFormSchema,
        caseInputData,
        handleCaseChange,

        resolutionTeamFormSchema,
        resolutionTeamInputData,
        handleResolutionTeamChange,

        delegationTeamFormSchema,
        delegationTeamInputData,
        handleDelegationTeamChange,

        externalDelegationTeamFormSchema,
        externalDelegationTeamInputData,
        handleExternalDelegationTeamChange,

        switchLabels,
        setSwitchLabels,
    }
}
