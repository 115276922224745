import { useLayoutEffect, useState } from "react";
import {
    Name,
    AssignUnAssignGradeStep,
    Alias,
    Status,
    GradeGroup,
    Grade,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";
import {
    AssignStepURI,
    FetchStepURI,
    HrmGradeApi,
    HrmGradeGroupApi,
    HrmGradeStepApi,
    RemoveStepURI,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { StatusRow } from "../../../../Finance/Settings/ReportType/List/ReportTypeList";

export default function useGradeStep() {
    const axiosPrivate = useAxiosPrivate()
    const title = AssignUnAssignGradeStep;
    const { userOrganisationId }: any = useAuth();
    const [GradeId, setGradeId] = useState(0);
    const [available, setAvailable] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [formFields, setFormFields] = useState<any[]>([]);

    //?? DROPDOWNS INITS >
    const GradeGroupDropDown = createDropdownField(axiosPrivate,
        'hrm_grade_group_id',
        'hrm_grade_group_id',
        `${GradeGroup}`,
        `${HrmGradeGroupApi}?organisation_id=${userOrganisationId}`,
        '', 6
      );

    GradeGroupDropDown.onChange = async (id, updatedFields) => {
        setGradeId(0);
        setAssigned([]);
        setAvailable([]);
        const GradeDropDown = updatedFields.find(
            (field: any) => field.id === "hrm_grade_id"
        );
        if (GradeDropDown) {
            const endPoint = `${HrmGradeApi}?hrm_grade_group_id=${id}`;
            await GradeDropDown.fetchOptions(id, false, endPoint);
            setFormFields([...updatedFields]);
        }
    };
    const GradeDropDown = createDropdownField(axiosPrivate,
        "hrm_grade_id",
        "hrm_grade_id",
        Grade,
        "",
        "",
        6
    );

    GradeDropDown.onChange = async (id, updatedFields) => {
        try {
            setGradeId(id);
            const endPoint = `${HrmGradeStepApi(id)}/${FetchStepURI}`;
            axiosPrivate
                .get(endPoint)
                .then((res) => {
                    //console.log(res.data);
                    setAssigned([]);
                    setAvailable([]);

                    if (res.data) {
                        setAssigned(res.data.data.assigned_items);
                        setAvailable(res.data.data.available_items);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                    toast.error("Error: Please Select All Fields");
                    setAssigned([]);
                    setAvailable([]);
                });
        } catch (error) {
            //console.log("CAUGHT ERROR:", error);
        }
    };

    const tableColumns = [
        {
            name: Name,
            selector: (row: any) => `${row.name}`,
            sortable: true,
            center: false,
        },
        {
            name: Alias,
            selector: (row: any) => `${row.short_name}`,
            sortable: true,
            center: false,
        },
        {
            name: Status,
            selector: (row: any) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: any) => StatusRow(row),
        },
    ];

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [...formFields, GradeGroupDropDown, GradeDropDown];
        await GradeGroupDropDown.fetchOptions();
        setFormFields(updatedFields);
       // console.log(updatedFields);
    }
    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const assignTask = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No Step (s) selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `${HrmGradeStepApi(GradeId)}/${AssignStepURI}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Step (s) assigned successfully");
                //console.log(res.data);
            });
    };

    const usassignTask = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No Step (s) selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `${HrmGradeStepApi(GradeId)}/${RemoveStepURI}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Step (s) unassigned successfully");
            });
    };

    return {
        title,
        formFields,
        tableColumns,
        available,
        assigned,
        assignTask,
        usassignTask,
    };
}
