import useAuth from "../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { InputProviderFormSchema } from "../../../Finance/Template/types";
import { useState } from "react";
import { paymentCheckoutApi } from "../../../../api";
import { CustomerFormDataPayload, MetaDataFormDataPayload, PaymentCheckoutFormDataPayload } from "./type";
import { useNavigate } from "react-router";

export default function usePaymentTransaction() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const navigate = useNavigate();

    const [topupWalletFormSchema, setTopupWalletFormSchema] = useState<
        InputProviderFormSchema[]
    >([{
        name: "amount",
        label: "Amount",
        type: "number",
        required: true,
    }]);

    const [topupWalletInputData, setTopupWalletInputData] = useState<{
        [key: string]: any;
    }>({});

    const handleTopupWalletChange = (name: string, value: any) => {
        setTopupWalletInputData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const customer: CustomerFormDataPayload = {
            first_name: "Obi",
            last_name: "Ojo",
            email: "obi_ojo@test.com",
            phone: "234800000001",
            transaction_reference: "Trx0004"
        }

        const metadataInput: MetaDataFormDataPayload = {
            transaction_reference: "Trx0004",
            customer: customer
        }
        
        const payload: PaymentCheckoutFormDataPayload = {
            organisation_id: userOrganisationId,
            amount: topupWalletInputData.amount,
            currency: "NGN",
            metadata: metadataInput
        }

        await axiosPrivate
            .post(paymentCheckoutApi, payload)
            .then((_res) => {
                if (_res.data.success) {
                    const authorizationUrl = _res.data.data.authorization_url; 
                    //navigate(authorizationUrl);
                    window.location.href = authorizationUrl;
                }
                toast.success(`Payment Successfully Initialized.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Whoops! Something went wrong");
                }
                //throw err;
                //toast.error(err.response.message || "Whoops! Something went wrong");
            });

        //console.log(payload);
    }

    return {
        handleSubmit,
        topupWalletFormSchema,
        setTopupWalletFormSchema,
        topupWalletInputData,
        handleTopupWalletChange,
    }

}