import { Card, Input } from "reactstrap";
import faceCard from "../../../assets/images/user/3.jpg";
import { IUserCardDetails } from "./types";

const UserCard = ({
    details,
    isRequester,
}: {
    details: IUserCardDetails;
    isRequester: boolean;
}) => {
    return (
        <>
            {details.for === "Beneficiary" ? (
                // <Card className="border p-4">
                    <>
                    <div className="d-flex justify-content-between gap-4">
                        <h6 className="">{details.for}</h6>

                        <Input
                            placeholder="search"
                            bsSize="sm"
                            className="w-25"
                        />
                    </div>

                    {/* <hr /> */}

                    <div className="user-info border p-3 py-4 rounded-4 d-flex gap-4 bg-light text-dark">
                        <img
                            src={faceCard}
                            className="w-25 h-25 rounded-circle"
                            alt="faceCard"
                        />
                        <div className="w-100 d-flex flex-column gap-1">
                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Contact ID</p>
                                <p className="text-end mb-0">{details.ID}</p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Name</p>
                                <p className="text-end mb-0">{details.name}</p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Unit Name</p>
                                <p className="text-end mb-0">
                                    {details.unitName}
                                </p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Cost Center</p>
                                <p className="text-end mb-0">
                                    {details.cost_center}
                                </p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p
                                    title="Department Name"
                                    className="mb-0 fw-bold"
                                >
                                    Dept. Name
                                </p>
                                <p className="text-end mb-0">
                                    {details.department}
                                </p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Date</p>
                                <p className="text-end mb-0">{details.date}</p>
                            </div>
                        </div>
                    </div>
                    {/* </Card> */}
                </>
            ) : (
                //<Card className="border p-4">
                <>
                    <h6 className="text-center">{details.for}</h6>

                    {/* <hr /> */}

                    <div className="user-info border p-3 py-3 mb-4 rounded-4 d-flex gap-4 bg-light text-dark">
                        <img
                            src={faceCard}
                            className="w-25 h-25 rounded-circle"
                            alt="faceCard"
                        />
                        <div className="w-100 d-flex flex-column gap-1">
                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Request ID</p>
                                <p className="text-end mb-0">{details.ID}</p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Name</p>
                                <p className="text-end mb-0">{details.name}</p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p
                                    title="Department Name"
                                    className="mb-0 fw-bold"
                                >
                                    Department Name
                                </p>
                                <p className="text-end mb-0">
                                    {details.department}
                                </p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Unit Name</p>
                                <p className="text-end mb-0">
                                    {details.unitName}
                                </p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Cost Center</p>
                                <p className="text-end mb-0">
                                    {details.cost_center}
                                </p>
                            </div>

                            <div
                                style={{
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                className="d-grid w-100"
                            >
                                <p className="mb-0 fw-bold">Date</p>
                                <p className="text-end mb-0">{details.date}</p>
                            </div>

                            {isRequester && (
                                <div
                                    style={{
                                        gridTemplateColumns: "1fr 1fr",
                                    }}
                                    className="d-grid w-100"
                                >
                                    <p className="mb-0 fw-bold">Currency</p>
                                    <Input
                                        type="select"
                                        style={{ marginLeft: "auto" }}
                                        className="w-75"
                                    >
                                        <option>NGN</option>
                                        <option>GBP</option>
                                        <option>USD</option>
                                    </Input>
                                </div>
                            )}
                        </div>
                    </div>
                </>
                //</Card>
            )}
        </>
    );
};

export default UserCard;
