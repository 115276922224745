import { Fragment } from "react";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { Card, CardBody } from "reactstrap";
import GenerateForm from "../../../../Common/Component/GenerateForm";
import { Link } from "react-router-dom";
import { BackToList, Settings } from "../../../../../Constant";
import useFinanceBudgetPurposeForm from "./controller";
//

const FinanceBudgetPurposeForm = () => {
  const { backBtn, onSubmit, formFields, formTitle } =
    useFinanceBudgetPurposeForm();
  return (
    <>
      <Breadcrumbs
        parent={Settings}
        title={"Finance Budget Purpose"}
        mainTitle={"Finance Budget Purpose"}
      />
      <Fragment>
        <Card className="ribbon-wrapper-right b-l-secondary border-2 mt-5">
          <CardBody>
            <Link to={backBtn}>
              <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                {BackToList}
              </div>
            </Link>
            <GenerateForm
              onSubmit={onSubmit}
              formFields={formFields}
              formTitle={formTitle}
              listUrl={backBtn}
              onEdit={undefined}
              editRecord={undefined}
            />
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default FinanceBudgetPurposeForm;
