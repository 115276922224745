//import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
//import axios from '../../api/axios';
const getLabelFromKeys = (item, itemKeyName) => {
  const parseItemKeyName = itemKeyName.split(',').map(key => {
      const keys = key.trim().split('.');
      const originalKey = keys[0];
      const nestedKeys = keys.slice(1);
      return { originalKey, nestedKeys };
  });

  return parseItemKeyName.map(({ originalKey, nestedKeys }) => {
      // Check if the key has nested properties
      if (nestedKeys.length > 0) {
          // Retrieve the nested object
          let nestedObject = item[originalKey];
          // Iterate through nested keys to access nested properties
          for (const nestedKey of nestedKeys) {
              nestedObject = nestedObject[nestedKey];
          }
          return nestedObject;
      } else {
          // Return value of non-nested key
          return item[originalKey];
      }
  }).join(' ');
};

const parseItemKeyName = (itemKeyName) => {
  const parts = itemKeyName.split('|').map(part => part.trim());
  const conditions = parts.slice(0, -1);
  const optionalKeysPart = parts[parts.length - 1];
  
  const optionalKeys = optionalKeysPart.startsWith('[') && optionalKeysPart.endsWith(']')
    ? optionalKeysPart.slice(1, -1).split(',').map(key => key.trim())
    : [];
  
  return {
    conditions: conditions.map(condition => condition.split(',').map(key => key.trim())),
    optionalKeys
  };
};

const getLabelFromKeys2 = (item, itemKeyName) => {
  const { conditions, optionalKeys } = parseItemKeyName(itemKeyName);
  
  for (const condition of conditions) {
    const labelParts = [];
    let isValid = true;

    for (const key of condition) {
      const keyParts = key.split('.');
      let nestedValue = item;

      for (const part of keyParts) {
        if (nestedValue && nestedValue[part] !== undefined) {
          nestedValue = nestedValue[part];
        } else {
          nestedValue = null;
          break;
        }
      }

      if (nestedValue !== null) {
        labelParts.push(nestedValue);
      } else {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      const optionalParts = optionalKeys.map(key => {
        const keyParts = key.split('.');
        let nestedValue = item;

        for (const part of keyParts) {
          if (nestedValue && nestedValue[part] !== undefined) {
            nestedValue = nestedValue[part];
          } else {
            nestedValue = null;
            break;
          }
        }

        return nestedValue;
      }).filter(value => value !== null);

      return [...labelParts, ...optionalParts].join(' ');
    }
  }

  return ''; // Return an empty string if no valid label is found
};

const FetchDynamicDropDownFieldOptions = async (axios, apiEndpoint, newApiEndpoint, value, dependentField, itemKeyName, setData) => {
    try {
        //const axios = useAxiosPrivate();
        
        const endPoint = newApiEndpoint ? newApiEndpoint : apiEndpoint + (value ? `?parentId=${value}` : '');
        const response = await axios.get(endPoint);
        //console.log(value);
        //console.log(endPoint);
        let keyedItems = [];
        
        const items = response.data.items || response.data.data || response.data;
        const options = items.map(item => ({ 
          value: item.id, 
          label: itemKeyName ? getLabelFromKeys2(item, itemKeyName) : item.name 
          //label: itemKeyName ? item[capacity_type].name : item.name 
        }));
        
        if (dependentField && typeof dependentField.fetchOptions === 'function' && value > 0) {
          dependentField.fetchOptions(value);
        }
        
        if (setData){
          keyedItems = items.reduce((acc, item) => {
              acc[item.id] = item; // Use the item's id as the key
              return acc;
          }, {});
        }

        return {options, keyedItems};
      } catch (error) {
        //console.error(`Error fetching options:`, error);
        return [];
      } 
};

export default FetchDynamicDropDownFieldOptions;
